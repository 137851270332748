import { Chip, Grid, Theme, Typography } from "@mui/material"
import { LanguageConsumer, getTranslation } from "../../../../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBox } from "../../../../../../../Layout/CustomBox"
import { CourseFileErrorItem } from "../../../Models/FileContentValidationResult"

export const getSingleError = (error: CourseFileErrorItem, key: string, theme: Theme) => {
    
    return (<LanguageConsumer>
        {({getTranslatedText}) =>
        <>
         <Grid item key={key} xs={12}>
            <CustomBox elevation={0} sx={{
                  height: '100%',
                  border: `1px solid ${theme.palette.cardBorder.main}`,
                  backgroundSize: 'cover',
                  position: 'relative',
                  maxWidth: '100%'
            }}>
                <Grid container direction={'row'} spacing={2}>
                    <Grid item xs={3} my={'auto'} sx={{textAlign: 'left'}}>
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={12} sx={{textAlign: 'center'}}><Typography fontSize={12} fontWeight={300}>{getTranslatedText("Cell")}</Typography></Grid>
                            <Grid item xs={12}  sx={{textAlign: 'center'}}><Chip sx={{ fontSize: 35, mt: -2, p: 2}} component={'span'} label={`${error.Column}${error.Row}`} /></Grid>
                        </Grid>
                        
                    </Grid>

                    <Grid item xs={9} my={'auto'} sx={{textAlign: 'left'}}>
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={12}>
                                <Typography>{getTranslatedText(error.Error)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography fontSize={13} fontWeight={600}>{getTranslatedText("FoundValue")} <Chip size="small" sx={{ml: 1}} label={error.FoundValue.trim().length > 0 ? error.FoundValue : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>} /></Typography>
                            </Grid>
                        </Grid>
                           
                    </Grid>

                </Grid>

            </CustomBox>
        </Grid>
        </>
        }
        </LanguageConsumer>
    )
}