import { zodResolver } from "@hookform/resolvers/zod";
import { AttachFile, PhotoCamera, Videocam } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast, { LoaderIcon } from "react-hot-toast";
import { PiArrowDownLight, PiArrowUpLight, PiImageLight } from "react-icons/pi";
import { DropzoneArea } from "react-mui-dropzone";
import Sticky from "react-stickynode";
import { AnswerTicketFormValidator } from "../Models/AnswerTicketFormValidator";
import {
  EmptyCreateTicketArticleFormData,
  IEmptyCreateTicketArticleFormData,
} from "../Models/CreateTicketArticleEmptyForm";
import { TicketArticleResponseDto } from "../Models/TicketArticleResponseDto";
import {
  CreateTicketArticleAttachmentsForm,
  requestCloseTicket,
  requestCreateTicketArticle,
  requestReopenTicket,
  restFormToInitialState,
  setAttachmentsCallOnlyOnceBeforeSubmit,
  setValidationFormData,
} from "../Store/ServiceRequestsDetailSlice";
import "../Style/ServiceRequestsDetail.css";
import { TicketArticle } from "./TicketArticle";
import { Link } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../Infrastructure/Store/hooks";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { RootState, store } from "../../../../../../Infrastructure/Store/store";
import { fileToBase64 } from "../../../../../../Infrastructure/Utils/FileToBase64";
import {
  LanguageConsumer,
  getTranslation,
} from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { stringAvatar } from "../../../../../../Infrastructure/Utils/UserColorGenerator";
import { CustomBox } from "../../../../../../Layout/CustomBox";
import { TextFieldFormControl } from "../../../../../../Layout/Inputs/TextFieldFormControl";
import {
  MediaType,
  setMediaType,
  toggleVideoAndImageRecorder,
} from "./VideoAndImageRecorder/Store/VideoAndImageRecorderSlice";
import { VideoAndImageRecorder } from "./VideoAndImageRecorder/VideoAndImageRecorder";
import { useScrollToBottomHandler } from "../../../../../../Infrastructure/Hooks/Scroll/useScrollToBottomHandler";
import { globalDateFormatOptions } from "../../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";
import {
  getStateColor,
  getStateTranslationKey,
} from "../../ServiceRequestsDefaults";
import { CopyChip } from "../../../../../../Layout/CopyChip";
import { YesNoDialog } from "../../../../../Infrastructure/Dialogs/YesNoDialog";

interface RequestServiceRequestsDetailFormProps {
  id: string | undefined;
}

export const ServiceRequestsDetailForm = (
  props: RequestServiceRequestsDetailFormProps
) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const language = useAppSelector((store) => store.settings.language);
  const isDarkMode = useAppSelector((store) => store.settings.theme === "dark");
  const [serverDown, setServerDown] = useState<boolean>(true);
  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: EmptyCreateTicketArticleFormData,
    values: EmptyCreateTicketArticleFormData,
    resolver: zodResolver(AnswerTicketFormValidator),
  });
  const { success } = useAppSelector((store) => store.serviceRequestsDetail);
  const { isDepragAmbergMember } = useAppSelector(store => store.authentication);
  const formIsReadyToSend = useAppSelector(
    (store) => store.serviceRequestsDetail.formIsReadyToSend
  );
  const [uploadFileList, setUploadFileList] = useState<File[] | null>(null);
  const formData = useAppSelector((store) => store.serviceRequestsDetail.form);
  const loading = useAppSelector(
    (store) => store.serviceRequestsDetail.loading
  );
  const updateStateLoading = useAppSelector(store => store.serviceRequestsDetail.updateStateLoading);
  const [visibleRows, setVisibleRows] = useState<number>(1);
  const isMobile = useMediaQuery("(max-width:650px)");
  const [dummyKey, setDummyKey] = useState<number>(0);
  const [stickEnabled, setStickEnabled] = useState<boolean>(false);
  const [showImages, setShowImages] = useState<boolean>(false);
  const { isScrolledToBottom } = useScrollToBottomHandler(350);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const { isLoading, data, refetch, isError, isSuccess } = useQuery({
    queryKey: ["serviceRequestTicketDetails"],
    queryFn: async () =>
      await fetchMyDepragApi<TicketArticleResponseDto>(
        null,
        "ticketarticles/byticket/" + parseInt(props.id as string),
        "GET",
        dispatch,
        store.getState() as RootState
      ),
    enabled: props.id !== undefined,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!isError && isSuccess) {
      setServerDown(false);
    }
  }, [isError, isSuccess]);

  const onSubmit = (data: IEmptyCreateTicketArticleFormData) => {
    dispatch(
      setValidationFormData({
        ticketId: parseInt(props.id as string),
        subject: "",
        body: data.body,
      })
    );
    submitToBackend();
  };

  const submitToBackend = async () => {
    if (uploadFileList == null) return;
    const base64convertedFileList = await Promise.all(
      uploadFileList.map(async (uploadFile) => {
        var fileAsBase64 = await fileToBase64(uploadFile);
        var onlyBase64 = fileAsBase64.split(",")[1]
          ? fileAsBase64.split(",")[1]
          : "";
        return {
          data: onlyBase64,
          fileName: uploadFile?.name,
          mimetype: uploadFile?.type,
        } as CreateTicketArticleAttachmentsForm;
      })
    );
    dispatch(setAttachmentsCallOnlyOnceBeforeSubmit(base64convertedFileList));
    setUploadFileList(null);
  };

  const addFileToList = (file: File) => {
    if (!uploadFileList || uploadFileList.length >= 5) {
      return toast.error(getTranslation("MaxFileLimitSucceded"), {
        id: "MaxFileLimitSucceded",
      });
    }

    if (file.size > 1e7) {
      return toast.error(getTranslation("FileSizeExceededServiceTicket"));
    }

    setUploadFileList((oldList) =>
      oldList && oldList.filter((x) => x.name === file.name).length === 0
        ? [...oldList, file]
        : [file]
    );
  };

  const handleFileDrop = (files: File[]) => {
    var filesToAdd = [] as File[];

    if (uploadFileList && uploadFileList.length > 0) {
      files.forEach((singleFile) => {
        if (!uploadFileList.includes(singleFile)) {
          filesToAdd.push(singleFile);
        }
      });
    } else {
      filesToAdd = files;
    }

    setUploadFileList((oldFiles) =>
      oldFiles && oldFiles.length > 0
        ? [...oldFiles, ...filesToAdd]
        : filesToAdd
    );
  };

  const handleFileDelete = (file: File) => {
    setUploadFileList((x) =>
      x ? [...x.filter((y) => y.name !== file.name)] : null
    );
    setDummyKey((oldKey) => oldKey + 1);
  };

  const resetFormAndRefetch = () => {
    refetch();
    dispatch(restFormToInitialState());
    reset();
  };

  useEffect(() => {
    if (formIsReadyToSend) {
      dispatch(requestCreateTicketArticle(formData));
    }
  }, [formIsReadyToSend]);

  useEffect(() => {
    if (success) {
      resetFormAndRefetch();
    }
  }, [success]);

  useEffect(() => {
    if (data && data.Items) {
      setTimeout(() => {
        scrollToPosition(document.body.scrollHeight - 200);
      }, 200);
    }
  }, [data]);

  const handleStateChange = (status) => {
    if (status.status === Sticky.STATUS_FIXED) {
      setStickEnabled(true);
    } else {
      setStickEnabled(false);
    }
  };

  const scrollToPosition = (height: number) => {
    document.documentElement.scrollTo({ behavior: "smooth", top: height });
  };

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <>
          {isLoading && (
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                display: "flex",
                height: "50%",
                position: "static",
              }}
              justifyContent={"space-evenly"}
            >
              <LoaderIcon style={{ width: 100, height: 100 }} />
            </Grid>
          )}

          {data &&
            !isLoading &&
            data.Ticket &&
            data.Items &&
            data.Items.length > 0 && (
              <Sticky
                enabled={!isMobile}
                innerZ={isMobile ? 0 : 1}
                onStateChange={handleStateChange}
              >
                <CustomBox
                  elevation={0}
                  sx={{
                    position: "relative",
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    transition: "all 0.2s ease-in-out",
                    background: isDarkMode ? "#111" : "#fff",
                    px: { xs: 1, md: 2, lg: 5, xl: 10 },
                    py: 5,
                    pt: stickEnabled ? 12 : 5,
                    boxShadow: stickEnabled ? "0 4px 8px -8px #000" : "none",
                  }}
                >
                  <Stack
                    direction={{ sm: "column", xl: "row" }}
                    spacing={{ xs: 2, sm: 0, md: 4 }}
                    alignItems={"flex-start"}
                  >
                    <Stack
                      className="stack-left"
                      direction={"column"}
                      spacing={1}
                      sx={{
                        maxWidth: {
                          sm: "100%",
                          xs: "100%",
                          md: "50%",
                          lg: "50%",
                        },
                        minWidth: {
                          sm: "100%",
                          xs: "100%",
                          md: "50%",
                          lg: "50%",
                        },
                      }}
                    >
                      <Typography
                        className="title"
                        lineHeight={1}
                        fontSize={{ xs: 26, md: 40 }}
                        fontWeight={700}
                        fontFamily={"Raleway"}
                      >
                        {getTranslatedText(data.Ticket.Title)}
                      </Typography>
                      <Box component={"div"}>
                        {" "}
                        <Stack
                          direction={{xs: "column", sm: "row"}}
                          spacing={1}
                          alignItems={{xs: "inherit", sm: "center"}}
                          sx={{ mt: 1}}
                        >
                          <Chip
                            color={getStateColor(data.Ticket.State)}
                            label={getStateTranslationKey(data.Ticket.State)}
                          />
                          <CopyChip
                            label={`Ticket #${data.Ticket.Number}`}
                            isCopyEnabled
                            copyText={`#${data.Ticket.Number}`}
                          />
                          {data.Ticket.CustomerName &&
                            <Chip
                              label={`${getTranslatedText("ServiceTicketCustomerName")}: ${data.Ticket.CustomerName}`}/>
                          }
                          <Chip
                            variant="outlined"
                            label={`${getTranslatedText(
                              "ServiceTicketCreatedAt"
                            )} ${new Date(
                              data.Items[0].CreatedAt
                            ).toLocaleDateString(
                              language,
                              globalDateFormatOptions
                            )}`}
                          />
                        </Stack>
                      </Box>
                    </Stack>

                    <Stack
                      className="stack-right"
                      direction={"column"}
                      spacing={2}
                      sx={{
                        width: "100%",
                        height: "auto",
                        pt: { sm: 3, md: 1 },
                        mt: { xs: 3, sm: 3, md: 0 },
                      }}
                    >
                      <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"flex-end"}
                        justifyContent={"flex-end"}
                      >
                        {stickEnabled && (
                          <Button
                            color="darky"
                            sx={{ zIndex: 99999 }}
                            component={Link}
                            to={"/serviceRequests"}
                            variant="outlined"
                          >
                            {getTranslatedText("Prev")}
                          </Button>
                        )}
                        <Button
                          variant="outlined"
                          color="darky"
                          startIcon={<PiImageLight />}
                          onClick={() => setShowImages((old) => !Boolean(old))}
                        >
                          {getTranslatedText(
                            showImages ? "HideImages" : "ShowImages"
                          )}
                        </Button>
                        <Button
                          color="darky"
                          onClick={() =>
                            scrollToPosition(
                              isScrolledToBottom
                                ? 0
                                : document.body.scrollHeight - 200
                            )
                          }
                          startIcon={
                            isScrolledToBottom ? (
                              <PiArrowUpLight />
                            ) : (
                              <PiArrowDownLight />
                            )
                          }
                          variant="outlined"
                        >
                          {getTranslatedText(
                            isScrolledToBottom
                              ? "GoToFirstMessage"
                              : "GoToLastMessage"
                          )}
                        </Button>
                        {
                          data && data.Ticket.State === 4 ?
                          <LoadingButton
                          disabled={
                            serverDown ||
                            false ||
                            (data && data.Ticket.State !== 4)
                          }
                          loading={updateStateLoading}
                          color="success"
                          variant="contained"
                          onClick={() => {
                            dispatch(requestReopenTicket({TicketId: parseInt(props.id as string)})).then(() => resetFormAndRefetch())
                          }}
                        >
                          {getTranslatedText("ReopenTicket")}
                        </LoadingButton>
                        :
                        <LoadingButton
                        disabled={
                          serverDown ||
                          false ||
                          (data && data.Ticket.State === 4)
                        }
                        loading={false}
                        color="error"
                        variant="contained"
                        onClick={() => {
                          setConfirmDialogOpen(true)
                        }}
                      >
                        {getTranslatedText("CloseTicket")}
                      </LoadingButton>
                        }
                      </Stack>
                    </Stack>
                  </Stack>
                </CustomBox>
              </Sticky>
            )}

          {isSuccess &&
            !isLoading &&
            data &&
            data.Items.map((Item, index) => {
              return (
                <TicketArticle
                  key={index}
                  item={Item}
                  index={index}
                  title={data.Ticket.Title}
                  owner={Item.Sender}
                  showImages={showImages}
                />
              );
            })}

          {data && data.Ticket.State !== 4 && (
            <Grid container direction="row" sx={{ mt: 10, mb: 4 }}>
              <Grid
                item
                md={1}
                display={{ xs: "none", md: "flex" }}
                justifyContent={"flex-end"}
              >
                <Tooltip title={getTranslatedText("Deprag")}>
                      <Avatar  src={'deprag_avatar.webp'} sx={{mr: 2}}></Avatar>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={10} my={"auto"}>
                <CustomBox
                  elevation={0}
                  sx={{
                    height: "100%",
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundBlendMode: "multiply",
                    backgroundColor: "transparent",
                    border: `1px solid ${theme.palette.cardBorder.main}`,
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction="row" sx={{ mt: 0 }}>
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <Grid container direction={"row"} spacing={2}>
                          <Grid item xs={12} sx={{ mt: 0 }}>
                            <TextFieldFormControl
                              onFocus={() => setVisibleRows(8)}
                              onBlur={() =>
                                watch("body").trim().length === 0 &&
                                setVisibleRows(1)
                              }
                              inputProps={{
                                "data-testid": "RequestTicketArticleBody",
                                maxLength: 10000,
                              }}
                              disabled={
                                loading ||
                                isLoading ||
                                (data && data.Ticket.State === 4)
                              }
                              hasEndAdornment={false}
                              labelText={getTranslatedText(
                                "ServiceTicketYourAnswer"
                              )}
                              propertyName="body"
                              multiline
                              rows={visibleRows}
                              register={register}
                              registerOptions={{ valueAsNumber: false }}
                              errors={errors}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ mt: 0 }} key={dummyKey}>
                            {!isLoading && !loading && (
                              <DropzoneArea
                                onChange={handleFileDrop}
                                onDelete={handleFileDelete}
                                onAlert={(message) => {
                                  if (
                                    (uploadFileList &&
                                      uploadFileList.length === 5) ||
                                    message ===
                                      "Maximum allowed number of files exceeded. Only 5 allowed"
                                  ) {
                                    toast.error(
                                      getTranslatedText("MaxFileLimitSucceded"),
                                      { id: "MaxFileLimitSucceded" }
                                    );
                                  }
                                }}
                                initialFiles={
                                  uploadFileList ? uploadFileList : undefined
                                }
                                filesLimit={5}
                                maxFileSize={1e7}
                                clearOnUnmount={true}
                                acceptedFiles={[".txt", ".png", ".jpg", ".odt", ".pdf", ".zip", ".docx", ".xlsx"]}
                                
                                dropzoneText={getTranslatedText(
                                  "RequestArticleDragAndDropZoneText"
                                )}
                                showPreviews={false}
                                previewText={getTranslatedText(
                                  "ServiceRequestsCreateLoadedFiles"
                                )}
                                showPreviewsInDropzone={false}
                                showAlerts={false}
                                onDropRejected={(rejectedFiles) => {
                                      toast.error(
                                        getTranslatedText(
                                          "FileSizeExceededServiceTicket"))
                                  }}                                
                                useChipsForPreview={false}
                                dropzoneParagraphClass="dropzone-text-ticket"
                                dropzoneClass="dropzone-body-ticket"
                                Icon={() => (
                                  <>
                                    <Button
                                      startIcon={<AttachFile />}
                                      fullWidth={isMobile}
                                      variant="contained"
                                      color="success"
                                    >
                                      {getTranslatedText("AddAttachement")}
                                    </Button>

                                    <Button
                                      sx={{
                                        ml: isMobile ? 0 : 1,
                                        mt: isMobile ? 1 : 0,
                                      }}
                                      fullWidth={isMobile}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(setMediaType(MediaType.Video));
                                        dispatch(toggleVideoAndImageRecorder());
                                      }}
                                      startIcon={<Videocam />}
                                      variant="outlined"
                                      color="darky"
                                    >
                                      {getTranslatedText("RecordVideo")}
                                    </Button>

                                    <Button
                                      sx={{
                                        ml: isMobile ? 0 : 1,
                                        mt: isMobile ? 1 : 0,
                                      }}
                                      fullWidth={isMobile}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(setMediaType(MediaType.Image));
                                        dispatch(toggleVideoAndImageRecorder());
                                      }}
                                      startIcon={<PhotoCamera />}
                                      variant="outlined"
                                      color="darky"
                                    >
                                      {getTranslatedText("RecordImage")}
                                    </Button>
                                  </>
                                )}
                              />
                            )}
                          </Grid>

                          {uploadFileList && uploadFileList.length > 0 && (
                            <>
                              <Grid item xs={12}>
                                <Typography>
                                  {getTranslatedText(
                                    "ServiceRequestsCreateLoadedFiles"
                                  )}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sx={{ mt: -1 }}>
                                {uploadFileList.map((file, fileKey) => {
                                  return (
                                    <Chip
                                      sx={{ mr: 1, mt: 1 }}
                                      variant="outlined"
                                      label={file.name}
                                      key={`file-${fileKey}`}
                                      onDelete={() => handleFileDelete(file)}
                                    />
                                  );
                                })}
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                          <LoadingButton
                            disabled={
                              serverDown ||
                              isLoading ||
                              (data && data.Ticket.State === 4)
                            }
                            fullWidth
                            loading={loading}
                            color="success"
                            variant="contained"
                            type="submit"
                          >
                            {getTranslatedText("ServiceRequest_Send")}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </CustomBox>
              </Grid>
              <Grid
                item
                md={1}
                display={{ xs: "none", md: "flex" }}
                justifyContent={"flex-start"}
              ></Grid>
            </Grid>
          )}

          <VideoAndImageRecorder addFileToList={addFileToList} />
          <YesNoDialog
                        title={getTranslatedText("DeactivateLicencesByDeviceConfirmationDialogTitle")}
                        content={getTranslatedText("CloseTicketDialogContent")}
                        titleYesButton={getTranslatedText("CloseTicket")}
                        titleNoButton={getTranslatedText("CommonCancel")}
                        titleYesLoading={updateStateLoading}
                        open={confirmDialogOpen}
                        onNoClick={() => setConfirmDialogOpen(false)}
                        onYesClick={() => {
                          dispatch(requestCloseTicket({TicketId: parseInt(props.id as string)})).then(() => {resetFormAndRefetch(); setConfirmDialogOpen(false);}, () => setConfirmDialogOpen(false));
                        }}
                    />
        </>
      )}
    </LanguageConsumer>
  );
};
