import { RefreshTokenRequest } from "../../Api/Authentication/Models/RefreshTokenRequest";
import { refreshingAuthenticationData } from "../../Features/Infrastructure/Login/Store/RefreshTokenSlice";
import { RootState } from "../Store/store";

export const checkIfTokenIsStillValidAndRefreshIfNecessary = async (
    dispatch: (action: { payload: undefined; type: string; }) => { payload: undefined; type: string; },
    rootState: RootState,
) => {

    let currentDate = new Date();
    let recievedTokenDate = new Date(rootState.authentication.tokenRecieved);
    let secondsBetweenValid = rootState.authentication.tokenValidForSeconds;
    let difDates = currentDate.getTime() - recievedTokenDate.getTime();
    let secondsBetweenCalculated = difDates / 1000;
    let newToken = null as string | null;

    if (secondsBetweenCalculated > secondsBetweenValid) {
        newToken = await handleRefreshToken(dispatch, rootState);
    }

    return newToken
}


const handleRefreshToken = async (
    dispatch: (action: { payload: undefined; type: string; }) => { payload: undefined; type: string; },
    rootState?: RootState,
) => {
    if (dispatch && rootState) {
        var refreshToken = rootState.authentication.refreshToken;
        if (refreshToken) {
            let refreshTokenObject = { Token: refreshToken } as RefreshTokenRequest
            let newToken = await refreshingAuthenticationData(refreshTokenObject, dispatch, rootState);

            return newToken;
        }

        return null;
    }

    return null
}
