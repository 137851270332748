import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppBarLayout } from './Layout/AppBarLayout';
import { DrawerHeader, DrawerLayout } from './Layout/DrawerLayout';
import { ThemeProvider } from '@mui/material/styles';
import { themeLight, themeDark } from './Theme/ThemeProvider';
import { useAppDispatch, useAppSelector } from './Infrastructure/Store/hooks';
import { useEffect } from 'react';
import { loadCsv, setLanguageFileIsLoading } from './Infrastructure/Internationalisation/Store/LanguageFileSlice';
import { LanguageConsumer } from './Infrastructure/Internationalisation/TranslationService';
import { ScrollToTop, handelRightClick } from './Infrastructure/AppUtility';
import { DepragRoutesContent } from './DepragRoutesContent';
import { Toaster } from 'react-hot-toast';
import 'material-icons/iconfont/material-icons.css';
import { FavoriteApps } from './Layout/FavoriteApps';
import { useMediaQuery } from '@mui/material';
import { MobileDrawerLayout } from './Layout/MobileDrawerLayout';
import { toggleMobileDrawer } from './Store/AppSlice';
import { DepragFooter } from './Layout/Footer/DepragFooter';
import { DepragRoutesExternal } from './DepragRoutesExternal';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import Loading from 'react-fullscreen-loading';
import { useHandleLogout } from './Infrastructure/Hooks/Global/useHandleLogout';
import { ErrorBoundary } from './ErrorBoundary/ErrorBoundeary';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/de'
import 'dayjs/locale/en'

dayjs.extend(utc)

export default function App() {
  useHandleLogout();
  const dispatch = useAppDispatch();
  const isDarkTheme = useAppSelector(store => store.settings.theme === 'dark');
  const {colorScheme,language} = useAppSelector(store => store.settings);
  const isMobile = useMediaQuery('(max-width:1200px)');
  const mobileDrawerIsOpen = useAppSelector(store => store.global.mobileDrawerOpen);
  const hasFavoriteAppsDrawer = useAppSelector(store => store.global.hasFavoriteAppDrawer);
  const languageFileIsLoading = useAppSelector(store => store.languageFile.languageFileIsLoading);
  

  useEffect(() => {
    window.addEventListener('contextmenu', handelRightClick);
    dispatch(setLanguageFileIsLoading(true));
    dispatch(loadCsv());

    return () => {
      window.removeEventListener('contextmenu', handelRightClick);
    };
  }, []);

  useEffect(() => {
    if (!isMobile && mobileDrawerIsOpen) {
      dispatch(toggleMobileDrawer())
    }
  }, [isMobile])

  return (<LanguageConsumer>
    {({ getTranslatedText }) =>
        <ThemeProvider theme={isDarkTheme ? themeDark(colorScheme) : themeLight(colorScheme)}>
        <LocalizationProvider
          adapterLocale={language}
          dateAdapter={AdapterDayjs}
        >
       {!languageFileIsLoading ? <Box component={'div'} sx={{ display: 'flex' }}>
            <Router>
                <ErrorBoundary>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>

                        <DepragRoutesExternal />

                        <ScrollToTop>
                        <CssBaseline />
                        {!isMobile && hasFavoriteAppsDrawer && <FavoriteApps />}
                    <AppBarLayout />

                        {!isMobile && <DrawerLayout />}
                        <MobileDrawerLayout />

                        <Box component="main" sx={{ 
                            ml: isMobile ? 0 : (hasFavoriteAppsDrawer ? '50px' : 0), 
                            overflow: 'hidden',
                            p: isMobile ? 1 : 3, 
                            pt: 0, 
                            flexGrow: 1,
                            maxWidth: isMobile ? '100%' : 'calc(100% - 140px)' }}>
                            <DrawerHeader />

                            <DepragRoutesContent />
                            <DepragFooter />
                            <Toaster toastOptions={{ duration: 5000, style: { maxWidth: '60%'} }} containerStyle={{ zIndex: 99999999 }} />
                        </Box>
                        </ScrollToTop>

                </QueryParamProvider>
              </ErrorBoundary>
            </Router>
          </Box> : <Loading loading background="#fff" loaderColor="#32992c" />}
          </LocalizationProvider>
        </ThemeProvider>
    }
  </LanguageConsumer>

  );
}
