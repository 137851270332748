import { Add, Analytics, AutoGraph, Cloud, QueryStats } from "@mui/icons-material"
import { Box, Fade, Grid, Grow, IconButton, ListItemButton, ListItemIcon, ListItemText, Stack, styled, Tooltip, tooltipClasses, TooltipProps, Typography, useTheme } from "@mui/material"
import { NavLink } from "react-router-dom";
import { LanguageConsumer } from "../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../Infrastructure/Store/hooks";
import { toggleFavoriteAppDrawer } from "../Store/AppSlice";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: '#f1f1f1',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
  }));

export const FavoriteApps = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isDarkMode = useAppSelector(store => store.settings.theme === 'dark');
    const isFavoriteAppDrawerOpen = useAppSelector(store => store.global.favoriteAppsOpen);

    const favoriteApps = [
        {id: 1, title: "MainMenuSoftwareGraphViewer", link: '/software/graphviewer', icon: <AutoGraph/>, previewImage: 'FavoriteApps/gvc.png'},
        {id: 2, title: "MainMenuSoftwareCockpit", link: '/software/graphviewer', icon: <Cloud/>, previewImage: 'FavoriteApps/gvc.png'},
        {id: 3, title: "MainMenuSoftwareJointAnalysis", link: '/software/screwJointLaboratory', icon: <QueryStats/>, previewImage: 'FavoriteApps/gvc.png'}
    ]

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
                <Box 
                onMouseEnter={() => dispatch(toggleFavoriteAppDrawer(true))} 
                onMouseLeave={() => dispatch(toggleFavoriteAppDrawer(false))} 
                component={'div'} 
                className="favoriteAppsBar global-favoriteApps" 
                sx={{
                    position: 'fixed', 
                    left: 0, 
                    top: 0, 
                    width: `${isFavoriteAppDrawerOpen ? 250 : 60}px`, 
                    background: theme.palette.background.default, 
                    height: '100vh',
                    borderTopRightRadius: isFavoriteAppDrawerOpen ? 20 : 0,
                    borderBottomRightRadius: isFavoriteAppDrawerOpen ? 20 : 0,
                    boxShadow: isFavoriteAppDrawerOpen ?  '8px 6px 10px rgba(0, 0, 0, 0.05)' : 'none'
                    }}>
                        <Grid container direction={'row'} spacing={2} sx={{ mt: 16, textAlign: 'center' }}>
                            {isFavoriteAppDrawerOpen && <Fade in={true} timeout={300}>
                                <Grid item xs={12} sx={{mt: -13, mb: 3}}>
                                    <Typography fontFamily={'Dancing Script'} fontSize={30} sx={{ position: 'relative', ml: -12, }}>my</Typography>
                                    <Typography variant="h4" fontFamily={'Fira Sans'} fontWeight={900} sx={{ color: '#32992c', mt: -3 }} color='success'>APPS</Typography>
                                </Grid>
                            </Fade>}
                            {favoriteApps.map((app, appKey) => {
                                return (<Grid item xs={12} key={`favoriteApp-${appKey}`}>
                                    {/* <HtmlTooltip
                                        enterNextDelay={500}
                                        title={<>
                                            <img style={{height: 100, maxWidth: 200}} alt="" src={app.previewImage} />
                                            </>}
                                        placement='right' 
                                        sx={{ mb: 2, zIndex: 9999 }}> */}
                                        <ListItemButton component={NavLink} to={app.link} sx={{color: theme.palette.darky.main}}>
                                            <ListItemIcon sx={{ml: -.6}}>
                                                <IconButton sx={{ background: isDarkMode ? '#444' : theme.palette.darky.dark }} color="darky" >
                                                    {app.icon}
                                                </IconButton>
                                            </ListItemIcon>
                                          {isFavoriteAppDrawerOpen &&  <Fade in={true} timeout={{ enter: 300, exit: 300}} style={{ transitionDelay: `${100}ms` }}><ListItemText primaryTypographyProps={{ sx: {fontWeight: 700, transition: 'all 0.3s ease-in-out 0.3s'}}} primary={getTranslatedText(app.title)} /></Fade>}
                                        </ListItemButton>
                                    {/* </HtmlTooltip> */}
                                </Grid>
                                )
                            })}

                            <Grid item xs={12}>
                                <Tooltip sx={{zIndex: 10001}} title={getTranslatedText("AddNewApp")}>
                                    <IconButton>
                                        <Add />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                </Box>
            </>
            }
        </LanguageConsumer>
    )
}