import { Grid } from "@mui/material"
import { DashboardHeaderAndCards } from "../DashboardStatusCard/DashboardHeaderAndCards"
import { ResellerStatistic } from "../ResellerStatistic/ResellerStatistic"


export const ResellerCardsAndStatistic = () => {

    return (<>
        <Grid item xs={12} >
            <DashboardHeaderAndCards />
        </Grid>

     <Grid container spacing={2} sx={{ mb: 5, mt: 5 }}>
            <Grid item xs={12}>
                <ResellerStatistic />
            </Grid>
        </Grid>
    </>)
}