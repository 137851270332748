import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { LoadingButton } from '@mui/lab';

export interface YesNoDialogProps {
    open: boolean;
    title: string;
    content: string | null;
    onYesClick: () => void;
    onNoClick: () => void;
    titleYesButton: string;
    titleNoButton: string;
    titleYesLoading?: boolean;
  }
  
  export const YesNoDialog = ({ open, title, titleYesButton, titleNoButton, onYesClick, onNoClick, content, titleYesLoading }: YesNoDialogProps) => {
    return (
      <Dialog
        open={open}
        keepMounted
        onClose={onNoClick}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        {
        content !== null && <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: content }}>
          </div>
        </DialogContent>
        }
  
        <DialogActions>
          <Button color="darky" variant="outlined" fullWidth onClick={onNoClick}>{titleNoButton}</Button>
          <LoadingButton color="error" variant="contained" loading={titleYesLoading} fullWidth onClick={onYesClick}>{titleYesButton}</LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }