import { FormControl, TextField, InputAdornment, IconButton } from "@mui/material"
import { FieldErrors, Path, RegisterOptions, UseFormRegister } from "react-hook-form"
import React, { useState } from "react"
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService"
import { Visibility, VisibilityOff } from "@mui/icons-material"

export interface TextFieldFormControlProps<T extends object> {

    labelText: string,
    propertyName: string
    disabled?: boolean,
    registerOptions?: RegisterOptions<T, Path<T>>
    endAdornmentText?: string | any
    register: UseFormRegister<any>,
    errors: FieldErrors<T>,
    hasEndAdornment?: boolean,
    type?: string,
    inputProps?: any,
    autoFocus?:boolean,
    rows?: number,
    shrink?: boolean,
    maxLength?: number,
    multiline?: boolean,
    onFocus?: () => void,
    onBlur?: () => void
}

export const TextFieldFormControl = React.memo(<T extends object>(props: TextFieldFormControlProps<T>) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (<LanguageConsumer>
        {({getTranslatedText}) =>
        <>
                <FormControl fullWidth>
            <TextField variant="outlined"
                onFocus={props.onFocus}
                autoFocus={props.autoFocus ?? false}
                disabled={props.disabled ?? false}
                label={props.labelText}
                type={props.type ? props.type === "password"? showPassword? "text" : "password" : props.type : 'text'}
                rows={props.rows ? props.rows : 1}
                multiline={props.multiline ? true : false}
                id={ 'TxtField_' + props.propertyName}
                error={!!props.errors[props.propertyName]}
                helperText={props.errors[props.propertyName] ? getTranslatedText(props.errors[props.propertyName].message) as string : ''}
                {...props.register(props.propertyName as Path<T>, props.registerOptions)}
                onBlur={props.onBlur}
                InputProps={{
                    endAdornment: props.type === "password"?  (<InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>): props.hasEndAdornment ? (<InputAdornment position='end'>
                        {props.endAdornmentText}
                    </InputAdornment>) : undefined
                }}
                inputProps={{...props.inputProps, maxLength: props.maxLength || 1000}}
                InputLabelProps={{
                    shrink: props.shrink ? props.shrink : true,
                }}
            />
        </FormControl>
        </>
        }
    </LanguageConsumer>
    )
})