import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthenticationRequestDto } from "../../../../Api/Authentication/Models/AuthenticationRequestDto";
import { AuthenticationResponseDto } from "../../../../Api/Authentication/Models/AuthenticationResponseDto";
import { fetchMyDepragApi } from "../../../../Infrastructure/Api/ApiBaseCall";
import { RootState } from "../../../../Infrastructure/Store/store";
import { MyDepragClaim } from "../../../MainMenus/Highlevel/Claims/Details/Models/ClaimsEditResponseDto";

export type LoginState = {
  token: string;
  refreshToken: string;
  tokenValidForSeconds: number;
  tokenRecieved: Date,
  firstName: string;
  lastName: string;
  company: string;
  isDepragAmbergMember: boolean;
  customerId: number;
  userId: number;
  isReseller: boolean;
  resellerUsesTicketSystem: boolean;
  loading: boolean
  myDepragClaims: MyDepragClaim[];
}

export const initialStateLogin: LoginState = {
  token: "",
  refreshToken: "",
  tokenValidForSeconds: 15 * 60,
  tokenRecieved: new Date(),
  firstName: "",
  lastName: "",
  company: "",
  loading: false,
  isDepragAmbergMember: false,
  customerId: 0,
  userId: 0,
  isReseller: false,
  resellerUsesTicketSystem: false,
  myDepragClaims: [],
};

const mockClaims: MyDepragClaim[] = [
  MyDepragClaim.AccountOwner,
  MyDepragClaim.BackofficeCustomers,
  MyDepragClaim.BackofficeHelp,
  MyDepragClaim.BackofficeLicencesByCompany,
  MyDepragClaim.BackofficeLicencesByDevice,
  MyDepragClaim.BackofficeLicencesByDeviceDeactivate,
  MyDepragClaim.BackofficeLicencesByOrder,
  MyDepragClaim.BackofficeMyGoldLicences,
  MyDepragClaim.BackofficeLoginAllowed,
  MyDepragClaim.BackofficeProductManagement,
  MyDepragClaim.BackofficeUsers,
  MyDepragClaim.BackofficeVideoCourses,
  MyDepragClaim.HighlevelClaim,
  MyDepragClaim.DepragReseller,
];

export const logIn = createAsyncThunk("authentication", async (request: AuthenticationRequestDto, thunkAPI) => {
  var response = await fetchMyDepragApi<AuthenticationResponseDto>(request, "auth/login", "POST", thunkAPI.dispatch, thunkAPI.getState() as RootState);
  return response;
});

export const loginSlice = createSlice({
  name: "authentication",
  initialState: initialStateLogin,
  reducers: {
    removeAuthentication: (state) => {
      state.token = "";
      state.refreshToken = "";
      state.tokenValidForSeconds = 15 * 60;
      state.tokenRecieved = new Date();
      state.firstName = "";
      state.lastName = "";
      state.company = "";
      state.isDepragAmbergMember = false;
      state.customerId = 0;
      state.isReseller = false;
      state.resellerUsesTicketSystem = false;
      state.myDepragClaims = [];
    },
    refreshingAuthenticationDataReducer: (state, action) => {
      state.token = action.payload.Token;
      state.refreshToken = action.payload.RefreshToken;
      state.tokenValidForSeconds = action.payload.TokenValidForSeconds;
      state.tokenRecieved = new Date();
      state.firstName = action.payload.FirstName;
      state.lastName = action.payload.LastName;
      state.company = action.payload.Company;
      state.isDepragAmbergMember = action.payload.IsDepragAmbergMember;
      state.customerId = action.payload.CustomerId;
      state.isReseller = action.payload.IsReseller;
      state.resellerUsesTicketSystem = action.payload.ResellerUsesTicketSystem;
      state.myDepragClaims = action.payload.MyDepragClaims;
      state.loading = false;
    },
    resetLoading: (state) => {
      state.loading = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(logIn.fulfilled, (state, action) => {
      state.token = action.payload.Token;
      state.refreshToken = action.payload.RefreshToken;
      state.tokenValidForSeconds = action.payload.TokenValidForSeconds;
      state.tokenRecieved = new Date();
      state.firstName = action.payload.FirstName;
      state.lastName = action.payload.LastName;
      state.company = action.payload.Company;
      state.isDepragAmbergMember = action.payload.IsDepragAmbergMember;
      state.customerId = action.payload.CustomerId;
      state.userId = action.payload.UserId;
      state.isReseller = action.payload.IsReseller;
      state.resellerUsesTicketSystem = action.payload.ResellerUsesTicketSystem;
      state.myDepragClaims = action.payload.MyDepragClaims;
      state.loading = false;
    }).addCase(logIn.pending, (state) => {
      state.loading = true;
    }).addCase(logIn.rejected, (state) => {
      state.token = "";
      state.refreshToken = "";
      state.tokenValidForSeconds = 15 * 60;
      state.tokenRecieved = new Date();
      state.firstName = "";
      state.lastName = "";
      state.company = "";
      state.loading = false;
    });
  },
});

export const {resetLoading, removeAuthentication, refreshingAuthenticationDataReducer} = loginSlice.actions;

export default loginSlice.reducer;
