import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Grid,
  Typography,
  List,
  ListItem,
  Tooltip,
  ListItemButton,
  ListItemText,
  Collapse,
  useTheme,
  ListItemIcon,
  Icon,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../Store/AppSlice";
import { DrawerHeader } from "../DrawerLayout";
import { toggleNaviagtionItemOpen } from "../Store/DrawerSlice";
import { useOpenedMenuItem } from "../../Infrastructure/Hooks/Global/useOpenedMenuItem";
import { useNavigationItems } from "./NavigationItems/useNavigationItems";


export const MobileAndDesktopDrawerContent = () => {
  const dispatch = useAppDispatch();
  const openedMenuItem = useAppSelector(store => store.navigation.openedMenuItem);
  const open = useAppSelector(store => store.global.drawerOpen);
  const theme = useTheme();
  const location = useLocation();
  const {navigationItems} = useNavigationItems();
  const isDarkMode = useAppSelector(store => store.settings.theme === "dark");

  useOpenedMenuItem();

  return (
    <LanguageConsumer>
      {({getTranslatedText}) =>
        <>
          <DrawerHeader sx={{flexGrow: 0, flexShrink: 0, textAlign: "left"}}>

            <Grid container direction={"row"} sx={{mt: 2, mb: 1}}>
              <Grid item xs={12} sx={{textAlign: "center"}}>
                <NavLink to={"/"} style={{textDecoration: "none"}}>
                  <Typography fontFamily={"Dancing Script"} fontSize={40}
                              sx={{color: isDarkMode ? "#fff" : "#444", position: "relative", ml: -22}}>my</Typography>
                  <Typography variant="h3" fontFamily={"Fira Sans"} fontWeight={900} sx={{color: "#32992c", mt: -3}}
                              color="success">DEPRAG</Typography>
                  <Typography variant="h4" fontFamily={"Fira Sans"} fontWeight={900}
                              sx={{color: theme.palette.darky.main, mt: -1}} color="success">ADMIN</Typography>
                  {/* <DrawerSearch /> */}
                </NavLink>
              </Grid>
            </Grid>

          </DrawerHeader>

          <List sx={{flexShrink: 0, p: open ? 4 : 1}} className={"global-menu"}>
            {navigationItems.map(x => {
              return {
                ...x,
                isOpen: openedMenuItem === x.id,
              };
            }).map((text, index) => text.disabled ? <></> : (
              <ListItem key={index} color="secondary" onClick={(e) => {
                !text.children && !text.disabled && dispatch(setAppTitle(getTranslatedText(text.name)));
              }} component={!text.children && !text.disabled ? NavLink : "div"} to={text.route} disablePadding
                        sx={{display: "block", borderRadius: 2}}>
                <Tooltip className={text.className} title={getTranslatedText(text.name)} placement={"right"}>
                  <ListItemButton disabled={text.disabled}
                                  selected={text.route === location.pathname}
                                  sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                    color: theme.palette.darky.main,
                                    borderRadius: 2,
                                    my: 1,
                                  }}
                                  onClick={() => {
                                    if (text.children) {
                                      dispatch(toggleNaviagtionItemOpen({id: text.id}));
                                    }
                                  }}
                  >
                    <ListItemIcon sx={{mr: -2.5}}><Icon>{text.icon}</Icon></ListItemIcon>
                    <ListItemText primaryTypographyProps={{style: {fontWeight: 600}}}
                                  primary={getTranslatedText(text.name)}
                                  sx={{
                                    opacity: open ? 1 : 0,
                                    maxWidth: "97%",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    "& span": {
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    },
                                  }}/>
                    {text.children && open ? (text.isOpen ? <ExpandLess/> : <ExpandMore/>) : <></>}
                  </ListItemButton>
                </Tooltip>
                {open && text.children && <Collapse in={text.isOpen} unmountOnExit>
                  <List component="div" disablePadding>
                    {text.children && text.children.length > 0 && text.children.map((child, childKey) =>
                      child.disabled ? <div key={childKey}/>
                        : (
                          <Tooltip key={`childMenu-${index}-${childKey}`} className={text.className}
                                   title={getTranslatedText(child.name)} placement={"right"}>
                            <ListItemButton
                              selected={child.route === location.pathname || (location.pathname.includes(child.route) && text.route !== "/")}
                              component={NavLink}
                              to={child.route}
                              sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                                pl: 4,
                                color: theme.palette.darky.main,
                                borderRadius: 2,
                                my: childKey > 0 ? 1 : 0,
                              }}>
                              <ListItemText sx={{
                                ml: -1,
                                maxWidth: "97%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                "& span": {
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                },
                              }}
                                            primary={getTranslatedText(child.name)}/>
                            </ListItemButton>
                          </Tooltip>
                        ),
                    )
                    }
                  </List>
                </Collapse>}
              </ListItem>
            ))}
          </List>
        </>
      }
    </LanguageConsumer>
  );
};
