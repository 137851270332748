import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material"
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks"
import { closeDeletePopup } from "../../Store/WaitingForEmailVerificationSlice";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { DefaultResponse } from "../../../../../../Infrastructure/Api/Model/DefaultResponse";
import { store, RootState } from "../../../../../../Infrastructure/Store/store";

export const WaitForEmailVerificationDeletePopup = () => {
    const { visible, deleteEmail, deleteUserId} = useAppSelector(store => store.waitingForEmailVerifications.deletePopup);
    const dispatch = useAppDispatch();

    const close = () => {
        dispatch(closeDeletePopup());
    }

    const deleteRegistration = async() => {
        await fetchMyDepragApi<DefaultResponse>({userIdToDeny: deleteUserId}, "users/WaitingForEmailVerification/deny", "POST", dispatch, store.getState() as RootState, "SuccessfullyDeletedEmailVerification")
    }

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
            <Dialog
                open={visible}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                   {getTranslatedText("AreYouSure")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography><span dangerouslySetInnerHTML={{__html: getTranslatedText("WaitForEmailVerificationDeleteBody", [deleteEmail])}}></span></Typography>
                        <Typography color={'error'} sx={{mt: 1}}>{getTranslatedText("WaitForEmailVerificationDeleteHint")}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="darky" onClick={close}>{getTranslatedText("CommonCancel")}</Button>
                    <Button variant="outlined" color="error" onClick={async () => {
                        await deleteRegistration();
                        close();
                    }} autoFocus>{getTranslatedText("WaitForEmailVerificationDeleteUser")}</Button>
                </DialogActions>
            </Dialog>
            </>
            }
        </LanguageConsumer>
    )
}