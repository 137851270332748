import { Autocomplete, Grid, TextField } from "@mui/material";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { TextFieldFormControl } from "../../../../../Layout/Inputs/TextFieldFormControl";
import { UserState } from "../../AllUsers/Models/UserOverviewItem";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { UserDetailFormValues } from "../../AllUsers/Models/UserDetailRequestDto";


interface UserEditBAsicValuesProps {
    errors: FieldErrors<UserDetailFormValues>;
    acceptPrivacyOn: string;
    lastLoggedIn: string;
    lastLoginTimeBackoffice: string;
    register: UseFormRegister<UserDetailFormValues>;
    isLoading: boolean;
    selectedUserState: string,
    setSelectedUserState: React.Dispatch<React.SetStateAction<string>>
    userStateError: boolean,
    setUserStateError: React.Dispatch<React.SetStateAction<boolean>>,
    isReseller: boolean
}

export const UserEditBasicValues = (props: UserEditBAsicValuesProps) => {
    const availableUserStates = Object.keys(UserState).map((key) => ({ key, value: UserState[key] }));

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item xs={6}>
                            <TextFieldFormControl
                                inputProps={{ "data-testid": "UserEdit-LastName" }}
                                disabled
                                hasEndAdornment={false}
                                labelText={getTranslatedText('UserEditLastName')}
                                propertyName='lastName'
                                register={props.register} registerOptions={{ valueAsNumber: false }} errors={props.errors} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldFormControl
                                inputProps={{ "data-testid": "UserEdit-FirstName" }}
                                disabled
                                hasEndAdornment={false}
                                labelText={getTranslatedText('UserEditFirstName')}
                                propertyName='firstName'
                                register={props.register} registerOptions={{ valueAsNumber: false }} errors={props.errors} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFieldFormControl
                                inputProps={{ "data-testid": "UserEdit-PostalCode" }}
                                disabled
                                hasEndAdornment={false}
                                labelText={getTranslatedText('UserEditPostalCode')}
                                propertyName='postalCode'
                                register={props.register} registerOptions={{ valueAsNumber: false }} errors={props.errors} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFieldFormControl
                                inputProps={{ "data-testid": "UserEdit-City" }}
                                disabled
                                hasEndAdornment={false}
                                labelText={getTranslatedText('UserEditCity')}
                                propertyName='city'
                                register={props.register} registerOptions={{ valueAsNumber: false }} errors={props.errors} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextFieldFormControl
                                inputProps={{ "data-testid": "UserEdit-Street" }}
                                disabled
                                hasEndAdornment={false}
                                labelText={getTranslatedText('UserEditStreet')}
                                propertyName='street'
                                register={props.register} registerOptions={{ valueAsNumber: false }} errors={props.errors} />
                        </Grid>
                        <Grid item xs={1}>
                            <TextFieldFormControl
                                inputProps={{ "data-testid": "UserEdit-HouseNumber" }}
                                disabled
                                hasEndAdornment={false}
                                labelText={getTranslatedText('UserEditHouseNumber')}
                                propertyName='houseNumber'
                                register={props.register} registerOptions={{ valueAsNumber: false }} errors={props.errors} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldFormControl
                                inputProps={{ "data-testid": "UserEdit-CountryName" }}
                                disabled
                                hasEndAdornment={false}
                                labelText={getTranslatedText('CustomerCountryName')}
                                propertyName='countryName'
                                register={props.register} registerOptions={{ valueAsNumber: false }} errors={props.errors} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldFormControl
                                inputProps={{ "data-testid": "UserEdit-Email" }}
                                disabled={props.isLoading || props.isReseller}
                                hasEndAdornment={false}
                                labelText={getTranslatedText('UserEditEmail')}
                                propertyName='email'
                                register={props.register} registerOptions={{ valueAsNumber: false }} errors={props.errors} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label={getTranslatedText('UserEditAcceptPrivacyOn')} value={props.acceptPrivacyOn} disabled fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                disabled={props.isReseller}
                                fullWidth
                                id="Status"
                                options={availableUserStates ? availableUserStates.map(option => getTranslatedText(option.value)) : []}
                                value={props.selectedUserState}
                                ListboxProps={{
                                    style: { zIndex: 10002 }
                                }}
                                onChange={(event: any, newValue: string | null) => {
                                    if (newValue) {
                                        props.setUserStateError(false)
                                        props.setSelectedUserState(newValue)
                                    }
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    fullWidth
                                    error={props.userStateError}
                                    helperText={props.userStateError ? getTranslatedText("PleaseProvideAUserStatus") : ''}
                                    label={getTranslatedText("UserEditState")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField label={getTranslatedText('UserEditLastLoginOn')} value={props.lastLoggedIn} disabled fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label={getTranslatedText('UserEditLastLoginOnBackoffice')} value={props.lastLoginTimeBackoffice} disabled fullWidth />
                        </Grid>

                    </Grid>
                </>
            }
        </LanguageConsumer >
    )
}