import { Button, Chip, Grid, IconButton, SwipeableDrawer, Tooltip, Typography, useTheme } from "@mui/material"
import { LanguageConsumer, getTranslation } from "../../../../../../../../../Infrastructure/Internationalisation/TranslationService"
import { DropzoneArea } from "react-mui-dropzone";
import { Close, Download, Upload } from "@mui/icons-material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../Infrastructure/Store/hooks";
import toast from "react-hot-toast";
import { fileToBase64 } from "../../../../../../../../../Infrastructure/Utils/FileToBase64";
import { licenseActivate, resetActivationBooleans } from "./Store/LicenseActivationSlice";
import { globalDateFormatOptions } from "../../../../../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";
import { base64ToFile, downloadFile } from "../../../../../../../../../Infrastructure/Utils/Base64ToFile";
import "./Style/LicenseActivationDrawer.css"

interface LicenseActivationDrawerProps {
    open: boolean;
    onClose: () => void;
    paidLicenceId: number;
}

export const LicenseActivationDrawer = (props: LicenseActivationDrawerProps) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const language = useAppSelector(store => store.settings.language);
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
    const [activationFile, setActivationFile] = useState<File | null>(null);
    const isActivating = useAppSelector(store => store.licenseActivation.loading);
    const success = useAppSelector(store => store.licenseActivation.success);
    const error = useAppSelector(store => store.licenseActivation.error);
    const releaseFile = useAppSelector(store => store.licenseActivation.releaseFile);
    const releaseResult = useAppSelector(store => store.licenseActivation.releaseResult);
    const [dropKey, setDropKey] = useState<number>(0);

    const handleFileDrop = (files: File[]) => {
        setActivationFile(files[0]);
    }

    const uploadActivationKey = async () => {
        if(activationFile) {
            var fileAsBase64 = await fileToBase64(activationFile);
            var onlyBase64 = fileAsBase64.split(",")[1] ? fileAsBase64.split(",")[1] : "";

            dispatch(licenseActivate({PaidLicenceId: props.paidLicenceId, RequestFile: onlyBase64, RequestFileName: activationFile?.name}))
        }
    } 

    const closeAndReset = () => {
        dispatch(resetActivationBooleans())
        setActivationFile(null);
        setDropKey(oldKey => oldKey + 1);
        props.onClose();
    }

    const downloadReleaseFile = async (fileAsBase64: string, fileName: string) => {
        var isTxt = fileName.includes(".txt");
        var fileToDownload = await base64ToFile(`${isTxt ? 'data:text/plain' : 'data:application/octet-stream'};base64,${fileAsBase64}`, fileName);
        downloadFile(fileToDownload, fileName, isTxt);
    }


    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <SwipeableDrawer
                        sx={{ zIndex: 100003 }}
                        PaperProps={{ sx: { p: 3, pt: 3, width: { xs: '95%', md: '70%', lg: '40%' }, maxWidth: '100%', background: theme.palette.background.default, height: '100%', borderTopLeftRadius: 20, borderBottomLeftRadius: 20 } }}
                        anchor={'right'}
                        open={props.open}
                        onOpen={console.log}
                        onClose={closeAndReset}
                    >
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={8}>
                                <Typography variant="h5">
                                    {getTranslatedText("ActivateLicenseTitle")}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} sx={{textAlign: 'right'}}>
                               <IconButton onClick={closeAndReset}>
                                    <Close />
                               </IconButton>
                            </Grid>

                            <Grid item xs={12} sx={{textAlign: 'justify'}}>
                                <Typography>
                                        <div
                                            dangerouslySetInnerHTML={{__html: getTranslatedText("ActivateLicenseDescription")}} />
                                </Typography>
                            </Grid>
                        </Grid>


                        {!releaseFile && !releaseResult && <>
                        <Grid container direction={'row'} spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sx={{ height: '100%', mt: 2 }}>
                                <DropzoneArea
                                    key={dropKey}
                                    onChange={handleFileDrop}
                                    filesLimit={1}
                                    maxFileSize={524288}
                                    clearOnUnmount={true}
                                    dropzoneText={getTranslatedText("DragAndDropActivationKeyHere")}
                                    showPreviews={true}
                                    previewText={getTranslatedText("PreviewActivationKey")}
                                    showPreviewsInDropzone={false}
                                    showAlerts={false}
                                    onDropRejected={(rejectedFiles) => {
                                        if(rejectedFiles[0].size > 524288){
                                            toast.error(getTranslatedText("FileSizeExceeded"))
                                        }
                                    }}
                                    useChipsForPreview={true}
                                    dropzoneParagraphClass="dropzone-text"
                                    dropzoneClass="dropzone"
                                    Icon={() => <IconButton color="success" size="large"><Upload /></IconButton>}

                                />
                            </Grid>
                        </Grid>

                        <Grid container direction={'row'} spacing={2} sx={{mt: 2}}>
                            <Grid item xs={12}>
                                <LoadingButton loading={isActivating} onClick={uploadActivationKey} variant="outlined" fullWidth color="success" disabled={!activationFile}>{getTranslatedText("UnlockLicense")}</LoadingButton>
                            </Grid>
                        </Grid>
                        </>}

                        {releaseFile && releaseResult && <>
                            <Grid container direction={'row'} spacing={2} sx={{ mt: 1 }}>
                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <div className="success-checkmark">
                                        <div className={isDarkMode ? "check-icon-dark" : "check-icon"}>
                                            <span className="icon-line line-tip"></span>
                                            <span className="icon-line line-long"></span>
                                            <div className="icon-circle"></div>
                                            <div className={isDarkMode ? "icon-fix-dark" : "icon-fix"}></div>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h6">{getTranslatedText("ReleaseKeyInformationTitle")}</Typography>
                                </Grid>
                            </Grid>

                            {releaseResult.UnlockInformation.FileName &&
                                <Grid container direction={'row'} spacing={2} sx={{ mt: 1 }}>
                                    <Grid item xs={5} my={'auto'}>{getTranslatedText("ReleaseKeyFile")}</Grid>
                                    <Grid item xs={7} my={'auto'}>
                                        <Tooltip PopperProps={{ style: { zIndex: 999999 } }} title={getTranslation("DownloadReleaseKey")}>
                                            <Chip sx={{ cursor: "pointer" }} onClick={() => {
                                                downloadReleaseFile(releaseFile, releaseResult.UnlockInformation.FileName);
                                            }} icon={<Download />} color="success" label={releaseResult.UnlockInformation.FileName} />
                                        </Tooltip>
                                    </Grid>
                                </Grid>}

                            {releaseResult.UnlockInformation.UnlockedUntil &&
                                <Grid container direction={'row'} spacing={2} sx={{ mt: 1 }}>
                                    <Grid item xs={5} my={'auto'}>{getTranslatedText("ReleaseKeyUnlockedUntil")}</Grid>
                                    <Grid item xs={7} my={'auto'}><Chip label={new Date(releaseResult.UnlockInformation.UnlockedUntil).toLocaleDateString(language, globalDateFormatOptions)} /></Grid>
                                </Grid>}

                            {releaseResult.ReleaseDetails.DeviceSerialNumber &&
                                <Grid container direction={'row'} spacing={2} sx={{ mt: 1 }}>
                                    <Grid item xs={5} my={'auto'}>{getTranslatedText("ReleaseKeyDeviceSerialNumber")}</Grid>
                                    <Grid item xs={7} my={'auto'}><Chip label={releaseResult.ReleaseDetails.DeviceSerialNumber} /></Grid>
                                </Grid>}

                            {releaseResult.ReleaseDetails.SoftwareVersion &&     
                                <Grid container direction={'row'} spacing={2} sx={{ mt: 1 }}>
                                    <Grid item xs={5} my={'auto'}>{getTranslatedText("ReleaseKeySoftwareVersion")}</Grid>
                                    <Grid item xs={7} my={'auto'}><Chip label={releaseResult.ReleaseDetails.SoftwareVersion} /></Grid>
                                </Grid>}

                            <Grid container direction={'row'} spacing={2} sx={{ mt: 3}}>
                                <Grid item xs={12} my={'auto'}>
                                    <Button onClick={() => {
                                            downloadReleaseFile(releaseFile, releaseResult.UnlockInformation.FileName);
                                        }}
                                    variant="outlined" color="success" fullWidth>{getTranslatedText("DownloadReleaseKey")}</Button>
                                </Grid>
                            </Grid>

                        </>}
                    </SwipeableDrawer>
                </>
            }
        </LanguageConsumer>
    )
}