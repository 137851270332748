import {
    Grid,
    Divider,
    Box,
    Skeleton,
    Button,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { RootState, store } from "../../../../../../Infrastructure/Store/store";
import { LanguageConsumer, getTranslation } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { DataGridSearch } from "../../../../../../Layout/DataGrid/DataGridSearch";
import { StripedDataGrid } from "../../../../../../Layout/DataGrid/StripedDataGrid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { PrereleaseOverviewResultDto } from "../Models/PrereleaseOverviewResultDto";
import { PrereleaseOverviewItem } from "../Models/PrereleaseOverviewItem";
import { AddCircleOutline } from "@mui/icons-material";
import { CopyChip } from "../../../../../../Layout/CopyChip";

export const AllSoftwarePrereleasesTable = () => {
    const dispatch = useAppDispatch();
    const langIsDe = useAppSelector(store => store.settings.language === "de");
    const location = useLocation();
    const [searchValue, setSearchValue] = useState<string>("");

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["softwareprereleases"],
        queryFn: async () => await fetchMyDepragApi<PrereleaseOverviewResultDto>(
            {},
            `software/prerelease/getall`,
            "GET",
            dispatch,
            store.getState() as RootState),
    });

    useEffect(() => {
        refetch();
    }, [location, refetch]);

    const search = (inputText: string) => {
        setSearchValue(inputText);
    };

    const columns: GridColDef[] = [
        {
            field: "Description",
            headerName: getTranslation("Description"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: "CreatedByUserEmail",
            headerName: getTranslation("UserEmail"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: "PrereleaseCreatedOn",
            headerName: getTranslation("PrereleaseCreatedOn"),
            flex: 1,
            minWidth: 150,
            valueGetter: (params: GridRenderCellParams<any, PrereleaseOverviewItem, any>) => new Date(params.row.CreatedOn).toLocaleString(langIsDe ? 'DE-de' : 'EN-us')
        },
        {
            field: "CountOfDownloads",
            headerName: getTranslation("CountOfDownloads"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: "DownloadValidUntil",
            headerName: getTranslation("DownloadValidUntil"),
            flex: 1,
            minWidth: 150,
            valueGetter: (params: GridRenderCellParams<any, PrereleaseOverviewItem, any>) => new Date(params.row.DownloadValidUntil).toLocaleDateString(langIsDe ? 'DE-de' : 'EN-us')
        },
        {
            field: "DownloadUrl",
            headerName: getTranslation("DownloadUrl"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, PrereleaseOverviewItem, any>) => (
                <>
                    <CopyChip
                        label={params.row.DownloadUrl}
                        isCopyEnabled={true}
                        copyText={params.row.DownloadUrl} />
                </>
            ),
            sortable: false,
            filterable: false,
        },
    ];

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign="center">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: "left" }}>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("SoftwarePrereleasesInfoText") }}
                                    />


                                    <Divider sx={{ my: 3, mb: 2 }} />
                                </Grid>

                                <Grid item xs={4} my={"auto"} sx={{ textAlign: "left", overflowX: "hidden" }}>
                                    <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>
                                <Grid item xs={8} my={'auto'} sx={{ textAlign: 'right' }}>
                                    <Button startIcon={<AddCircleOutline />} component={NavLink} to={`/software/prerelease/create`} variant="contained" color="success">{getTranslatedText("CreateSoftwarePrerelease")}</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={"div"} sx={{ width: "100%" }}>
                                    <StripedDataGrid
                                        localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={data ? data.PrereleaseOverviewItems.filter(x =>
                                        (x.Description.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            (x.FileName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                            (x.CreatedByUserEmail.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                            (x.CountOfDownloads.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                            (new Date(x.CreatedOn).toLocaleString(langIsDe ? 'DE-de' : 'EN-us').includes(searchValue.toLocaleLowerCase()))||
                                            (new Date(x.DownloadValidUntil).toLocaleDateString(langIsDe ? 'DE-de' : 'EN-us').includes(searchValue.toLocaleLowerCase()))),
                                        ) : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        getRowId={(x) => x.DownloadUrl}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                                        }
                                        components={{
                                            LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                        }}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    );
};
