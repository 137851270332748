
import { createSlice } from '@reduxjs/toolkit';
import { ReactElement } from 'react';

export interface NavigationItem {
    id: number,
    name: string,
    route: string,
    icon: ReactElement,
    className: string,
    isOpen: boolean,
    children: NavigationItem[] | null,
    disabled: boolean
}


export interface DrawerState {
  openedMenuItem: number
}

export const initialDrawerState: DrawerState = {
  openedMenuItem: 0
};

interface ToggleNavigationLocalRequest {
  payload: ToggleNavigationLocalRequestPayload
}

interface ToggleNavigationLocalRequestPayload {
  id: number,
  isHookCall?: boolean
}

export const drawerSlice = createSlice({
  name: 'navigation',
  initialState: initialDrawerState,
  reducers: {
    toggleNaviagtionItemOpen: (state, action: ToggleNavigationLocalRequest) => {
      // if(state.openedMenuItems.includes(action.payload)){
      //   state.openedMenuItems = [...state.openedMenuItems.filter(x => x !== action.payload)]
      // } else {
      //   state.openedMenuItems = [...state.openedMenuItems, action.payload]
      // }
      if(action.payload && action.payload.isHookCall){
        state.openedMenuItem = action.payload.id
      } else {
        state.openedMenuItem = state.openedMenuItem === action.payload.id ? 0 : action.payload.id
      }
      
    },
  }
});

export const { toggleNaviagtionItemOpen } = drawerSlice.actions;


export default drawerSlice.reducer;
