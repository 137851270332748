import { Box, Chip, Grid, Rating, Typography, useTheme } from "@mui/material"
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBox } from "../../../../../Layout/CustomBox";
import { OnlineTrainingDetailResponseDto } from "../Models/OnlineTrainingDetailResponseDto";
import { globalDateFormatOptions } from "../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";
import Flag from 'react-world-flags'
import { useAppSelector } from "../../../../../Infrastructure/Store/hooks";

interface CourseInformationProps {
    informations: OnlineTrainingDetailResponseDto
}

export const CourseInformation = (props: CourseInformationProps) => {
    const theme = useTheme();
    const language = useAppSelector(store => store.settings.language);
    let currentDate = new Date();
    let updatedDate = new Date(props.informations.Updated)
    const isToday = currentDate.getDay() === updatedDate.getDay() && currentDate.getMonth() === updatedDate.getMonth() && currentDate.getFullYear() === updatedDate.getFullYear();
    
    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
                <CustomBox elevation={0} sx={{
                    minHeight: 200,
                    height: '100%',
                    backgroundColor: theme.palette.cardBackground.main,
                    border: `1px solid ${theme.palette.cardBorder.main}`,
                    backgroundSize: 'cover',
                    position: 'relative',
                }}>

                    <Grid container direction={'row'} spacing={2}>
                        <Grid item xs={12} sx={{ textAlign: 'left' }}>
                            <Typography variant="h6" fontWeight={600}>{getTranslatedText("CourseInformations")}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'left', mt: 1 }}>
                            <Grid container direction={'row'} spacing={2}>

                                <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}><Typography fontSize={12}>{getTranslatedText("RatingInfo")}</Typography></Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}>
                                    <Rating readOnly name="rating-set" value={props.informations.AverageRating} />
                                    <Box component={'span'} sx={{ ml: 0.5, top: -5, position: 'relative', fontSize: 12, color: theme.palette.footer.main }}>{`(${props.informations.RatingsCount})`}</Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}><Typography fontSize={12}>{getTranslatedText("LanguageInfo")}</Typography></Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}> {props.informations.LanguageIsoCode ? <Chip size="small" label={<Box component={'div'} sx={{ display: 'inline', position: 'relative', top: 2 }}><Flag height={13} code={props.informations.LanguageIsoCode.toLocaleLowerCase() === 'de' ? 'de' : 'us'} /></Box>} /> : <></>}</Grid>


                                <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}><Typography fontSize={12}>{getTranslatedText("DurationInfo")}</Typography></Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}><Chip size={'small'} label={`${props.informations.DurationInMinutes}min`} /></Grid>


                                <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}><Typography fontSize={12}>{getTranslatedText("UpdatedInfo")}</Typography></Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}><Chip size={'small'} color="success" variant={isToday ? "filled" : "outlined"} label={new Date(props.informations.Updated).toLocaleDateString(language, globalDateFormatOptions)} /></Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </CustomBox>
            </>
            }
        </LanguageConsumer>
    )
}