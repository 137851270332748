import { AddCircleOutline } from "@mui/icons-material"
import { Box, Button, Divider, Grid, Skeleton, useTheme } from "@mui/material"
import { GridRowParams } from "@mui/x-data-grid"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall"
import { LanguageConsumer, getTranslation } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks"
import { RootState, store } from "../../../../../../Infrastructure/Store/store"
import { DataGridSearch } from "../../../../../../Layout/DataGrid/DataGridSearch"
import { StripedDataGrid } from "../../../../../../Layout/DataGrid/StripedDataGrid"
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_de"
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_en"
import { MailTriggerItem } from "../Models/MailTriggerItem"
import { EmailTriggerRulesDelete } from "./DeletePopup/EmailTriggerRulesDelete"
import { useEmailTriggerRulesOverviewTableColumnDefinitions } from "./useEmailTriggerRulesOverviewTableColumnDefinitions"

export const EmailTriggerRulesOverviewTable = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const location = useLocation();
    const [searchValue, setSearchValue] = useState<string>("");
    const deletePopupVisible = useAppSelector(store => store.emailTriggerRules.deletePopupVisible);
    const {columns} = useEmailTriggerRulesOverviewTableColumnDefinitions();


    const { isLoading, data, refetch } = useQuery({
        queryKey: ["emailTriggerRulesOverview"],
        queryFn: async () => await fetchMyDepragApi<MailTriggerItem[]>(
            null,
            "highlevel/mailtriggers/overview",
            'GET',
            dispatch,
            store.getState() as RootState)
    });

    useEffect(() => {
        refetch();
    }, [location, refetch, deletePopupVisible]);

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const openDetails = (params: GridRowParams) => {
        navigate(`/highlevel/emailTriggerRules/createOrEdit/${params.row.MailTriggerItemId}`)
    }

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
             <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("EmailTriggerRulesInfoText") }}
                                    />


                                    <Divider sx={{ my: 3, mb: 2 }} />
                                </Grid>

                                <Grid item xs={4} my={'auto'} sx={{textAlign: 'left', overflowX: 'hidden'}}>
                                  <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>
                              <Grid item xs={8} my={'auto'} sx={{ textAlign: 'right' }}>
                                    <Button startIcon={<AddCircleOutline />} sx={{ml: 1}} component={NavLink} to={'/highlevel/emailTriggerRules/createOrEdit/0'}  variant="contained" color="success">{getTranslatedText("CreateEmailRule")}</Button>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={data ? data.filter(x =>
                                            (x.MailTriggerItemId && x.MailTriggerItemId.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            (x.RuleName && x.RuleName.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                            (x.MailHeaderDe && x.MailHeaderDe.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                            (x.MailHeaderEn && x.MailHeaderEn.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                            getTranslation(x.CustomerShouldBeInState).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                                                )
                                        ) : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        disableColumnSelector
                                        hideFooter={false}
                                        getRowId={(x) => x.MailTriggerItemId}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                          }
                                        onRowDoubleClick={openDetails}
                                        components={{
                                            LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                        }}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <EmailTriggerRulesDelete />
            </>
            }
        </LanguageConsumer>
    )
}