import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { Box, Button, Chip, Divider, Grid, IconButton, Skeleton, Tooltip } from "@mui/material";
import { PiMagnifyingGlass } from "react-icons/pi";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { AddCircleOutline } from "@mui/icons-material";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { LegalDocumentsOverviewResponse } from "../Models/LegalDocumentsOverviewItem";
import { IoPencilSharp } from "react-icons/io5";
import moment from "moment";


export const LegalDocumentsTable = () => {
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const PreferredIsoLanguageCode = useAppSelector(store => store.settings.language);
    const [searchValue, setSearchValue] = useState<string>("");


    const { isLoading, data } = useQuery({
        queryKey: ["legalDocumentsOverview"],
        queryFn: async () => await fetchMyDepragApi<LegalDocumentsOverviewResponse[]>(
            null,
            "legalDocuments/overview",
            'POST',
            dispatch,
            store.getState() as RootState),
    });

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const openDetails = (params: GridRowParams) => {
        navigate(`/legalDocuments/detail/${params.row.LegalDocumentId}`)
    }

    const columns: GridColDef[] = [
        {
            field: 'Id',
            headerName: getTranslation("LegalDocumentsId"),
            flex: 0.1,
            minWidth: 50,
            valueGetter: (params: GridRenderCellParams<any, LegalDocumentsOverviewResponse, any>) => params.row.LegalDocumentId,

        },
        {
            field: 'ValidFrom',
            headerName: getTranslation("LegalDocumentsValidFrom"),
            flex: 0.3,
            minWidth: 75,
            valueGetter: (params: GridRenderCellParams<any, LegalDocumentsOverviewResponse, any>) => moment(params.row.ValidFrom).format("DD/MM/yyyy"),
        },
        {
            field: 'ValidUntil',
            headerName: getTranslation("LegalDocumentsValidUntil"),
            flex: 0.3,
            minWidth: 75,
            valueGetter: (params: GridRenderCellParams<any, LegalDocumentsOverviewResponse, any>) => moment(params.row.ValidUntil).format("DD/MM/yyyy"),
        },
        {
            field: 'DocumentType',
            headerName: getTranslation("LegalDocumentsDocumentType"),
            flex: 0.4,
            minWidth: 140,
            valueGetter: (params: GridRenderCellParams<any, LegalDocumentsOverviewResponse, any>) => getTranslation(params.row.DocumentType),
        },
        {
            field: 'LanguageIsoCode',
            headerName: getTranslation("LegalDocumentsLanguageIsoCode"),
            flex: 0.25,
            minWidth: 50,
            valueGetter: (params: GridRenderCellParams<any, LegalDocumentsOverviewResponse, any>) => params.row.LanguageIsoCode,
        },
        {
            field: '',
            headerName: '',
            flex: 0.1,
            minWidth: 65,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, LegalDocumentsOverviewResponse, any>) => (
                <strong>
                    <Tooltip title={getTranslation("Details")}>
                        <IconButton color={'darky'} component={NavLink} to={`/legalDocuments/detail/${params.row.LegalDocumentId}`}>
                            <IoPencilSharp />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },

    ];

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("LegalDocumentsText") }}
                                    />

                                    <Divider sx={{ my: 3, mb: 2 }} />
                                </Grid>

                                <Grid item xs={4} my={'auto'} sx={{ textAlign: 'left', overflowX: 'hidden' }}>
                                    <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>

                                <Grid item xs={8} my={'auto'} sx={{ textAlign: 'right' }}>
                                    <Button startIcon={<AddCircleOutline />} sx={{ ml: 1 }} component={NavLink} to={'/legalDocuments/add'} variant="contained" color="success">{getTranslatedText("CreateReseller")}</Button>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        localeText={PreferredIsoLanguageCode === "de" ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={data ? data.filter(x =>
                                        (x.DocumentType.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            x.LegalDocumentId.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            x.ValidFrom.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            x.ValidUntil.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
                                        ) : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        getRowId={(x) => x.LegalDocumentId}
                                        onRowDoubleClick={openDetails}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                        }
                                        components={{
                                            LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                        }}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}
