import {
  Alert,
  AlertTitle,
  Grid,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../Infrastructure/Store/hooks";
import {
  LanguageConsumer,
  getTranslation,
} from "../../../Infrastructure/Internationalisation/TranslationService";
import { setAppTitle } from "../../../Store/AppSlice";
import { CustomBox } from "../../../Layout/CustomBox";
import { ServiceRequestsTable } from "./Components/ServiceRequestsTable";

export const ServiceRequests = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const language = useAppSelector((store) => store.settings.language);
  const languageFileWasLoaded = useAppSelector(
    (store) => store.languageFile.languageFileWasLoaded
  );
  const isDarkMode = useAppSelector((store) => store.settings.theme === "dark");
  const { resellerUsesTicketSystem } = useAppSelector(
    (store) => store.authentication
  );

  useEffect(() => {
    dispatch(
      setAppTitle(getTranslation("MainMenuServicesServiceRequestsBreadcrumb"))
    );
  }, [location, language, languageFileWasLoaded, dispatch]);

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <>
          <Grid
            container
            direction="row"
            spacing={2}
            className="main-content"
            sx={{ mt: 1 }}
          >
            <Grid item xs={12}>
              {resellerUsesTicketSystem ? (
                <CustomBox
                  elevation={0}
                  sx={{
                    mx: 3,
                    height: "100%",
                    backgroundSize: "cover",
                    backgroundBlendMode: "multiply",
                    backgroundColor: isDarkMode ? "transparent" : "#fff",
                    border: `1px solid ${theme.palette.cardBorder.main}`,
                  }}
                >
                  <ServiceRequestsTable />
                </CustomBox>
              ) : (
                <Alert severity="warning">
                  <AlertTitle sx={{mt: 0}}>{getTranslatedText("ServiceRequestNotEnabledForReseller")}</AlertTitle>
                </Alert>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </LanguageConsumer>
  );
};
