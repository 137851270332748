import { Button, Grid } from "@mui/material";
import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../../../Store/AppSlice";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { ServiceRequestsDetailForm } from "./Components/ServiceRequestsDetailForm";

export const ServiceRequestsDetail = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuServicesServiceRequestsBreadcrumb')));
    }, [location, language, languageFileWasLoaded, dispatch, id]);

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2} className="main-content" sx={{mt: 1}}>
                        <Grid item xs={12} mx={{ xs: 0, md: 3 }}>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12} sx={{ mx: { xs: 1, md: 3, lg: 6, xl: 8 } }}>
                                    <Button color="darky" sx={{ zIndex: 99999 }} component={Link} to={'/serviceRequests'} variant="outlined">{getTranslatedText("Prev")}</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <ServiceRequestsDetailForm id={id} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}