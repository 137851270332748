import { Moment } from "moment";

export const momentToClearedDate = (momentDate: Moment) => {
  const timezoneOffsetInMinutes = momentDate.toDate().getTimezoneOffset();
  return new Date(momentDate.toDate().getTime() + (timezoneOffsetInMinutes * -1) * 60000);
};

/**
 * taking a Number like 202402 which is a Date with only Month and Year YYYYMM
 * and returning string like "02-2024"
 * @param input number something like 202402 YYYYMM
 * @return string in format "02-2024"
 */
export const formatYearMonth = (input: number): string => {
  const year = Math.floor(input / 100);
  const month = input % 100;
  return `${month.toString().padStart(2, "0")}-${year}`;
};
