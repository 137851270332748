import { Chip, Tooltip, IconButton, Box, Divider, Grid, Skeleton, TextField, useTheme, Button } from "@mui/material";
import { GridRowParams, GridColDef, GridRenderCellParams, DataGrid } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { IoPencilSharp, IoTrashBin } from "react-icons/io5";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { HelpOverviewItem, HelpOverviewResponseDto } from "../Models/HelpOverviewResponseDto";
import { PiCheckCircleLight, PiGlobeHemisphereEastLight, PiTrash, PiTrashLight, PiXCircleLight } from "react-icons/pi";
import { AddCircleOutline } from "@mui/icons-material";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { setDeleteTitleAndIdExternalHelp, toggleDeletePopupExternalHelp } from "../Store/HelpOverviewSlice";
import { HelpOverviewDelete } from "./HelpOverviewDelete";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";

export const HelpOverviewTable = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const location = useLocation();
    const isDepragAmbergMember = useAppSelector(store => store.authentication.isDepragAmbergMember);
    const [searchValue, setSearchValue] = useState<string>("");
    const deletePopupVisible = useAppSelector(store => store.externalHelp.deletePopupVisible);

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["users"],
        queryFn: async () => await fetchMyDepragApi<HelpOverviewResponseDto>(
            null,
            "help/externalproducts",
            'GET',
            dispatch,
            store.getState() as RootState)
    });

    useEffect(() => {
        refetch();
    }, [location, refetch, deletePopupVisible]);


    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const openDetails = (params: GridRowParams) => {
        isDepragAmbergMember && navigate(`/help/createOrEdit/${params.row.Id}`)
    }

    const columns: GridColDef[] = [
                 {
            field: 'Id',
            headerName: "Id",
            flex: 0,
            minWidth: 0,
        },
        {
            field: 'Title',
            headerName: getTranslation("HelpTitle"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'Product',
            headerName: getTranslation("HelpProduct"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, HelpOverviewItem, any>) => {
                return <Chip label={params.row.Product} />
            }
        },
        {
            field: 'ContentDe',
            headerName: getTranslation("HelpContentDe"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, HelpOverviewItem, any>) => {
                return params.row.ContentDe  ? <PiCheckCircleLight size={26}  color={theme.palette.success.main} /> : <PiXCircleLight size={26}  color={theme.palette.error.main} />
            },
        },
        {
            field: 'ContentEn',
            headerName: getTranslation("HelpContentEn"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, HelpOverviewItem, any>) => {
                return params.row.ContentEn ? <PiCheckCircleLight size={26}  color={theme.palette.success.main} /> : <PiXCircleLight size={26} color={theme.palette.error.main} />
            },
        },
        {
            field: 'IsPublished',
            headerName: getTranslation("HelpIsPublished"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, HelpOverviewItem, any>) => {
                return params.row.IsPublished ? <PiGlobeHemisphereEastLight size={26} /> : <></>
            },
        },
        {
            field: '',
            headerName: '',
            flex: 0.1,
            minWidth: 100,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, HelpOverviewItem, any>) => (
              isDepragAmbergMember ?  <strong>
                    <Tooltip title={getTranslation("CommonEdit")}>
                        <IconButton color={'darky'} component={NavLink} to={`/help/createOrEdit/${params.row.Id}`}>
                            <IoPencilSharp />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={getTranslation("CommonDelete")}>
                        <IconButton sx={{ml: 1}} color={'darky'} onClick={() => {
                            dispatch(setDeleteTitleAndIdExternalHelp({title: params.row.Title, id: params.row.Id}));
                            dispatch(toggleDeletePopupExternalHelp())
                        }}>
                            <PiTrash />
                        </IconButton>
                    </Tooltip>
                </strong> : <></>
            ),
            sortable: false,
            filterable: false
        },
    ];

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
            <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("AllHelpsInfoText") }}
                                    />


                                    <Divider sx={{ my: 3, mb: 2 }} />
                                </Grid>

                                <Grid item xs={4} my={'auto'} sx={{textAlign: 'left', overflowX: 'hidden'}}>
                                  <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>
                              {isDepragAmbergMember &&  <Grid item xs={8} my={'auto'} sx={{ textAlign: 'right' }}>
                                    <Button startIcon={<AddCircleOutline />} sx={{ml: 1}} component={NavLink} to={'/help/createOrEdit/0'}  variant="contained" color="success">{getTranslatedText("CreateHelp")}</Button>
                                </Grid>}

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={data ? data.Items.filter(x =>
                                            (x.Title && x.Title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            (x.Id && x.Id.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                            x.Product && x.Product.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                                                )
                                        ) : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        disableColumnSelector
                                        hideFooter={false}
                                        getRowId={(x) => x.Id}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                          }
                                        onRowDoubleClick={openDetails}
                                        components={{
                                            LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                        }}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <HelpOverviewDelete />
            </>
            }
        </LanguageConsumer>
    )
}