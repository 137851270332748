import { LanguageConsumer } from "../../../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../../../Infrastructure/Store/hooks";
import { setDeleteTitleAndIdEmailTrigger, toggleDeletePopupEmailTrigger } from "../../../Store/EmailTriggerRulesSlice";
import { fetchMyDepragApi } from "../../../../../../../Infrastructure/Api/ApiBaseCall";
import { DefaultResponse } from "../../../../../../../Infrastructure/Api/Model/DefaultResponse";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { store, RootState } from "../../../../../../../Infrastructure/Store/store";

export const EmailTriggerRulesDelete = () => {
    const dispatch = useAppDispatch();
    const { deletePopupVisible, deleteId, deleteTitle} = useAppSelector(store => store.emailTriggerRules);

    const closeModal = () => {
        dispatch(setDeleteTitleAndIdEmailTrigger({title: "", id: 0}));
        dispatch(toggleDeletePopupEmailTrigger());
    }

    const deleteHelp = async () => {
        await fetchMyDepragApi<DefaultResponse>(null, `highlevel/mailtriggers/delete/${deleteId}`, 'DELETE', dispatch, store.getState() as RootState, "SuccessfullyDeletedEmailRule");
    }

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
                <Dialog
                    open={deletePopupVisible}
                    onClose={closeModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {getTranslatedText("AreYouSure")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography><span dangerouslySetInnerHTML={{ __html: getTranslatedText("DoYouReallyWantToDeleteEmailRule", [deleteTitle]) }}></span></Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="darky" onClick={closeModal}>{getTranslatedText("CommonCancel")}</Button>
                        <Button variant="outlined" color="error" onClick={async () => {
                            await deleteHelp();
                            closeModal();
                        }} autoFocus>{getTranslatedText("DeleteEmailRule")}</Button>
                    </DialogActions>
                </Dialog>
            </>
            }
        </LanguageConsumer>
    )
}