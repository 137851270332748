import { createSlice } from '@reduxjs/toolkit';


export type DeleteMessagePopupState = {
  deletePopupVisible: boolean,
}

export const initialDeleteMessagePopup: DeleteMessagePopupState = {
  deletePopupVisible: false,
};

export const deleteMessagePopupSlice = createSlice({
  name: 'deleteMessage',
  initialState: initialDeleteMessagePopup,
  reducers: {
    toggleMessagePopup: (state) => {
      state.deletePopupVisible = !Boolean(state.deletePopupVisible)
    },
  }
});

export const { toggleMessagePopup } = deleteMessagePopupSlice.actions;

export default deleteMessagePopupSlice.reducer;
