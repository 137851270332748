import { createSlice } from '@reduxjs/toolkit';

export type SearchByOrderNumberState = {
    currentFilter: string,
    showOnlyAvailable: boolean
}

export const initialStateSearchByOrderNumberState: SearchByOrderNumberState = {
    currentFilter: "",
    showOnlyAvailable: true
  };

  export const searchByOrderNumberSlice = createSlice({
    name: 'licenseDeactivation',
    initialState: initialStateSearchByOrderNumberState,
    reducers: {
      setFilter: (state, action : { payload: string }) => {
          state.currentFilter = action.payload;
      },
      setShowOnlyAvailable: (state, action: { payload: boolean }) => {
        state.showOnlyAvailable = action.payload;
    }
    },
  });
  
  export const { setFilter, setShowOnlyAvailable } = searchByOrderNumberSlice.actions;
  
  export default searchByOrderNumberSlice.reducer;  