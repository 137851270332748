import { Button, Divider, Grid, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { LanguageConsumer, getTranslation } from "../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../../Store/AppSlice";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../Infrastructure/Api/ApiBaseCall";
import { UserDetailResponseDto, UserMailHistoryItem } from "./Models/UserDetailResponseDto";
import { store, RootState } from "../../../../Infrastructure/Store/store";
import { CustomBox } from "../../../../Layout/CustomBox";
import { useForm } from "react-hook-form";
import { EmptyUserForm, UserDetailFormValues, UserDetailRequestDto } from "../AllUsers/Models/UserDetailRequestDto";
import { UserState } from "../AllUsers/Models/UserOverviewItem";
import { DefaultResponse } from "../../../../Infrastructure/Api/Model/DefaultResponse";
import { UserEditBasicValues } from "./Components/UserEditBasicValues";
import { UserEditCompanyValues } from "./Components/UserEditCompanyValues";
import { LoadingButton } from "@mui/lab";
import { UserEditOutgoingMailSideDrawer } from "./Components/UserEditOutgoingMailSideDrawer";
import { UserEditLegalDocumentsDrawer } from "./Components/UserEditLegalDocumentsDrawer";
import { LegalDocumentsContentResponseDto } from "./Models/LegalDocumentsConsent";
import { MyDepragClaim } from "../../Highlevel/Claims/Details/Models/ClaimsEditResponseDto";
import { UserEditPermissionDrawer } from "./Components/UserEditPermissionDrawer";

export const UserEdit = () => {
    const { isReseller, customerId } = useAppSelector(store => store.authentication);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const theme = useTheme();
    const { id } = useParams();

    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);

    const availableUserStates = Object.keys(UserState).map((key) => ({ key, value: UserState[key] }));

    const [availableCustomers, setAvailableCustomers] = useState<{ Id: number, CompanyName: string }[]>();
    const [selectedCustomer, setSelectedCustomer] = useState<string>("");
    const [customerError, setCustomerError] = useState<boolean>(false);
    const [selectedUserState, setSelectedUserState] = useState<string>("")
    const [userStateError, setUserStateError] = useState<boolean>(false);
    const [acceptPrivacyOn, setAcceptPrivacyOn] = useState<string>("");
    const [lastLoginOn, setLastLoginOn] = useState<string>("");
    const [lastLoginOnBackoffice, setLastLoginOnBackoffice] = useState<string>("");
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [sideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
    const [outgoingMailHistory, setOutgoingMailHistory] = useState<UserMailHistoryItem[]>([])

    const claims = useAppSelector(store => store.authentication.myDepragClaims);


    const hasClaim = (necessaryClaim: MyDepragClaim): boolean => {

        if (claims === undefined || claims.length === 0)
            return false;

        const hasCorrectClaim = Object.values(claims).includes(necessaryClaim);

        return hasCorrectClaim;
    }

    const closeSideDrawer = () => {
        setSideDrawerOpen(false);
        setOutgoingMailHistory([]);
    }

    const setOutgoingMailHistorysAndOpenDrawer = (history: UserMailHistoryItem[]) => {
        setOutgoingMailHistory(history);
        setSideDrawerOpen(true);
    }

    const [sideDrawerLegalDocumentsOpen, setSideDrawerLegalDocumentsOpen] = useState<boolean>(false);
    const [sideDrawerPermissionOpen, setSideDrawerPermissionOpen] = useState<boolean>(false);

    const closeSideDrawerLegalDocuments = () => {
        setSideDrawerLegalDocumentsOpen(false);
    }

    const openLegalDocumentsDrawer = () => {
        setSideDrawerLegalDocumentsOpen(true);
    }

    const openPermissionDrawer = () => {
        setSideDrawerPermissionOpen(true);
    }

    const closePermissionDrawer = () => {
        setSideDrawerPermissionOpen(false);
    }


    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuUserEditBreadcrumb')));
    }, [location, language, languageFileWasLoaded, dispatch]);


    const { isLoading, data } = useQuery({
        queryKey: ["userEdit"],
        queryFn: async () => await fetchMyDepragApi<UserDetailResponseDto>({},
            'users/' + id,
            'GET',
            dispatch,
            store.getState() as RootState),
        enabled: id !== undefined
    });

    const { register, handleSubmit, formState: { errors } } = useForm<UserDetailFormValues>({
        defaultValues: EmptyUserForm, values: data ?
            {
                lastName: data.LastName,
                firstName: data.FirstName,
                postalCode: data.PostalCode,
                city: data.City,
                street: data.Street,
                houseNumber: data.HouseNumber,
                email: data.Email,
                countryName: data.CountryName,
            } : EmptyUserForm
    });

    useEffect(() => {
        if (data?.State !== undefined && availableUserStates !== undefined) {
            let key = data?.State
            const userState = key ? UserState[key] : ""
            setSelectedUserState(getTranslation(userState))
        }
    }, [data, availableCustomers])

    useEffect(() => {
        if (data?.AcceptPrivacyOn !== undefined) {
            const dateObject = new Date(data?.AcceptPrivacyOn ?? '');
            const formattedDateTime = dateObject.toLocaleString(language);
            setAcceptPrivacyOn(formattedDateTime);
        }
    }, [data?.AcceptPrivacyOn, language])


    useEffect(() => {
        if (data?.LastLoggedIn !== undefined) {
            if (data?.LastLoggedIn === null) {
                setLastLoginOn(' ');
            } else {

                const dateObject = new Date(data?.LastLoggedIn ?? '');
                const formattedDateTime = dateObject.toLocaleString(language);
                setLastLoginOn(formattedDateTime);
            }

        }
    }, [data?.LastLoggedIn, language])


    useEffect(() => {
        if (data?.LastLoggedInBackoffice !== undefined) {
            if (data?.LastLoggedInBackoffice === null) {
                setLastLoginOnBackoffice(' ');
            } else {

                const dateObject = new Date(data?.LastLoggedInBackoffice ?? '');
                const formattedDateTime = dateObject.toLocaleString(language);
                setLastLoginOnBackoffice(formattedDateTime);
            }

        }
    }, [data?.LastLoggedInBackoffice, language])

    const onSubmit = async (values: UserDetailFormValues) => {
        if (id !== undefined) {
            setIsSaving(true);
            let dataToPost: UserDetailRequestDto = {
                UserId: data?.UserId ?? 0,
                Email: values.email,
                State: availableUserStates?.filter(x => getTranslation(x.value) === selectedUserState)[0].key ?? UserState.WaitingApproval,
                CustomerId: availableCustomers?.filter(x => x.CompanyName === selectedCustomer)[0].Id ?? 1
            }
            var response = await fetchMyDepragApi<DefaultResponse>(dataToPost, "users/edit", "POST", dispatch, store.getState() as RootState, "SuccessfullyChangedUser", true)
            if (response) {
                setIsSaving(false);
            }
        }
    }

    function GetLegalDocuments() {
        const { isLoading, data } = useQuery({
            queryKey: ["userlegaldocuments"],
            queryFn: async () => await fetchMyDepragApi<LegalDocumentsContentResponseDto[]>({},
                'users/legaldocuments/' + id,
                'GET',
                dispatch,
                store.getState() as RootState),
            enabled: id !== undefined
        });

        return {
            Data: data,
            IsLoading: isLoading
        }
    }

    const legalDocuments = GetLegalDocuments()

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
                        <Grid item xs={12} mx={3}>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Button color="darky" component={NavLink} to={'/users/allUsers'} variant="outlined">{getTranslatedText("Prev")}</Button>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <CustomBox elevation={0} sx={{
                                        backgroundSize: 'cover',
                                        backgroundBlendMode: 'multiply',
                                        backgroundColor: 'transparent',
                                        border: `1px solid ${theme.palette.cardBorder.main}`
                                    }}>
                                        <form onSubmit={handleSubmit(onSubmit)}>

                                            <UserEditBasicValues
                                                errors={errors}
                                                isLoading={isLoading}
                                                register={register}
                                                acceptPrivacyOn={acceptPrivacyOn}
                                                lastLoggedIn={lastLoginOn}
                                                lastLoginTimeBackoffice={lastLoginOnBackoffice}
                                                selectedUserState={selectedUserState}
                                                setSelectedUserState={setSelectedUserState}
                                                userStateError={userStateError}
                                                setUserStateError={setUserStateError}
                                                isReseller={isReseller} />

                                            <Divider sx={{ mt: 4, mb: 4 }} />

                                            <Grid container direction={'row'} spacing={2}>

                                                <UserEditCompanyValues
                                                    userId={parseInt(id ?? "0")}
                                                    customerId={data?.CustomerId}
                                                    companyName={data?.CompanyName}
                                                    selectedCustomer={selectedCustomer}
                                                    setSelectedCustomer={setSelectedCustomer}
                                                    availableCustomers={availableCustomers}
                                                    setAvailableCustomers={setAvailableCustomers}
                                                    customerError={customerError}
                                                    setCustomerError={setCustomerError} />

                                            </Grid>

                                            <Divider sx={{ mt: 4, mb: 4 }} />


                                            <Grid item xs={12}>

                                                <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2 }} justifyContent={'space-between'}>
                                                    <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2 }}>
                                                        <Button
                                                            color="darky"
                                                            variant="contained"
                                                            onClick={() => setOutgoingMailHistorysAndOpenDrawer(data ? data.UserMailHistoryItems : [])}>
                                                            {sideDrawerOpen ? getTranslation("HideOutgoingMailOverview") : getTranslation("ShowOutgoingMailOverview")}
                                                        </Button>

                                                        <Button
                                                            color="darky"
                                                            variant="contained"
                                                            onClick={() => openLegalDocumentsDrawer()}>
                                                            {sideDrawerLegalDocumentsOpen ? getTranslation("HideLegalDocumentsDrawer") : getTranslation("ShowLegalDocumentsDrawer")}
                                                        </Button>
                                                    </Stack>
                                                    {
                                                        (hasClaim(MyDepragClaim.HighlevelClaim)) && <Button
                                                            color="darky"
                                                            variant="contained"
                                                            onClick={() => openPermissionDrawer()}>
                                                            {sideDrawerLegalDocumentsOpen ? getTranslation("HidePermissionsDrawer") : getTranslation("ShowPermissionsDrawer")}
                                                        </Button>
                                                    }
                                                </Stack>
                                            </Grid>

                                            {!(isReseller && data?.CustomerId === customerId) &&
                                                <Grid container direction={'row'} spacing={2} sx={{ mt: 2 }}>
                                                    <Grid item xs={12}>
                                                        <LoadingButton loading={isSaving} color="success" disabled={!!errors["sapCustomerId"] || !!errors["companyName"]} variant="contained" type="submit" fullWidth>{getTranslatedText("Submit")}</LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </form>
                                    </CustomBox>

                                    <UserEditOutgoingMailSideDrawer
                                        onClose={closeSideDrawer}
                                        open={sideDrawerOpen}
                                        hasDeniedToReceiveMails={data?.HasDeniedToReceiveMails}
                                        outgoingMailHistory={outgoingMailHistory}
                                    />

                                    <UserEditLegalDocumentsDrawer open={sideDrawerLegalDocumentsOpen} onClose={closeSideDrawerLegalDocuments}
                                        legalDocuments={legalDocuments.Data ?? []} />

                                    {id && <UserEditPermissionDrawer open={sideDrawerPermissionOpen} onClose={closePermissionDrawer} userId={id} />}
                                    

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer >
    )
}
