import { Grid, IconButton, Skeleton, Typography, useTheme } from "@mui/material"
import { AttachFile, FileUpload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { DropzoneArea } from "react-mui-dropzone";
import { useState } from "react";
import toast from "react-hot-toast";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch } from "../../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../../Infrastructure/Store/store";
import { fileToBase64 } from "../../../../../../Infrastructure/Utils/FileToBase64";
import { CustomBox } from "../../../../../../Layout/CustomBox";
import { PrereleaseCreateRequestDto } from "../Models/PrereleaseCreateRequestDto";
import dayjs from 'dayjs';
import { TextFieldFormControl } from "../../../../../../Layout/Inputs/TextFieldFormControl";
import { EmptyPrereleaseCreateForm, PrereleaseCreateForm } from "./Models/PrereleaseCreateForm";
import { usePrereleaseCreateFormValidator } from "./Models/usePrereleaseCreateFormValidator";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ControlledDatePicker } from "../../../../../../Layout/Inputs/ControlledDatePicker";
import { PrereleaseCreateDoneProps } from "./PrereleaseCreateDone";
import { PrereleaseCreateResultDto } from "../Models/PrereleaseCreateResultDto";
import { DefaultResponse } from "../../../../../../Infrastructure/Api/Model/DefaultResponse";

const maxFileSizeInBytes = 10000000000

const formatBytes = (valueInBytes) => {
    if (valueInBytes === 0) return "0 Bytes";

    const units = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    const factor = 1000;
    let unitIndex = 0;

    while (valueInBytes >= factor && unitIndex < units.length - 1) {
        valueInBytes /= factor;
        unitIndex++;
    }

    return `${Math.round(valueInBytes)} ${units[unitIndex]}`;
}


interface PrereleaseCreateContentProps {
    onPrereleaseCreateDone: (props: PrereleaseCreateDoneProps) => void
}

export const PrereleaseCreateContent = ({ onPrereleaseCreateDone }: PrereleaseCreateContentProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [uploadFileList, setUploadFileList] = useState<File[] | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { PrereleaseCreateFormValidator } = usePrereleaseCreateFormValidator();

    const {
        control, register, handleSubmit, formState: { errors, }
    } = useForm<PrereleaseCreateForm>({
        defaultValues: EmptyPrereleaseCreateForm,
        values: EmptyPrereleaseCreateForm,
        resolver: zodResolver(PrereleaseCreateFormValidator),
    });

    const handleFileDrop = (files: File[]) => {
        setUploadFileList(files);
    }

    const handleFileDelete = (file: File) => {
        setUploadFileList(x => x ? [...x.filter(y => y.name !== file.name)] : null)
    }

    const submitPrereleaseToBackend = async (formData: PrereleaseCreateForm) => {
        if (uploadFileList && uploadFileList.length > 0) {
            setIsSubmitting(true);
            let fileToUpload = uploadFileList[0];
            var fileAsBase64 = await fileToBase64(fileToUpload);
            var onlyBase64 = fileAsBase64.split(",")[1] ? fileAsBase64.split(",")[1] : "";
            try {
                const request = {
                    Description: formData.description,
                    DownloadValidUntil: formData.validUntil?.toDate(),
                    FileName: uploadFileList[0].name,
                    FileContentAsBase64String: onlyBase64
                } as PrereleaseCreateRequestDto

                const result = await fetchMyDepragApi<DefaultResponse>(request, `software/prerelease/create`, 'POST', dispatch, store.getState() as RootState, "SuccessfullyUploadedFile", true);
                const payload = result.Payload as PrereleaseCreateResultDto;
                setIsSubmitting(false);
                onPrereleaseCreateDone({
                    DownloadUrl: payload.DownloadUrl,
                    DownloadValidUntil: payload.DownloadValidUntil
                } as PrereleaseCreateDoneProps)

            } catch (error) {
                setIsSubmitting(false);
                toast.error(error as string);
            }
        }
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <CustomBox elevation={0} sx={{
                        minHeight: 200,
                        height: '100%',
                        backgroundColor: 'transparent',
                        border: `1px solid ${theme.palette.cardBorder.main}`,
                        backgroundSize: 'cover',
                        position: 'relative',
                    }}>

                        <form onSubmit={handleSubmit(submitPrereleaseToBackend)}>
                            <Grid container direction={'row'} spacing={2}>

                                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                    <Typography variant="h6" fontWeight={600}>
                                        {getTranslatedText("PrereleaseCreateTitle")}
                                    </Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldFormControl
                                        hasEndAdornment={false}
                                        labelText={getTranslatedText('PrereleaseDescrition')}
                                        propertyName='description'
                                        register={register} registerOptions={{ valueAsNumber: false }} errors={errors} />


                                </Grid>
                                <Grid item xs={12}>
                                    <ControlledDatePicker
                                        name="validUntil"
                                        control={control}
                                        label={getTranslatedText("ValidUntil")}
                                        minDate={dayjs()}
                                        maxDate={dayjs().add(30, 'day')}
                                    />
                                </Grid>

                                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                    {!isSubmitting ? <DropzoneArea
                                        onChange={handleFileDrop}
                                        onDelete={handleFileDelete}
                                        onAlert={(message) => {
                                            if (message === "Maximum allowed number of files exceeded. Only 1 allowed") {
                                                toast.error(getTranslatedText("MaxFileLimitSucceded"), { id: "MaxFileLimitSucceded" })
                                            }
                                        }}
                                        filesLimit={1}
                                        maxFileSize={maxFileSizeInBytes} //in Bytes
                                        clearOnUnmount={false}
                                        dropzoneText={getTranslatedText("PrereleaseCreateDragAndDropZoneText")}
                                        showPreviews={true}
                                        previewText={getTranslatedText("PrereleaseCreateLoadedFiles")}
                                        showPreviewsInDropzone={false}
                                        showAlerts={false}
                                        onDropRejected={(rejectedFiles) => {
                                            rejectedFiles.forEach(x => {
                                                if (x.size > maxFileSizeInBytes) {
                                                    toast.error(getTranslatedText("MaxFileSizePrereleaseExceeded", [formatBytes(maxFileSizeInBytes.toString())]))
                                                }
                                            })
                                        }}
                                        useChipsForPreview={true}
                                        dropzoneParagraphClass="dropzone-text-xls"
                                        dropzoneClass="dropzone-body-xls"
                                        Icon={() => <IconButton><AttachFile /></IconButton>}
                                    /> : <Skeleton width={'100%'} height={300} sx={{ mt: -8, mb: -5 }} />}
                                </Grid>

                                <Grid item xs={12}>
                                    <LoadingButton
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={!uploadFileList || uploadFileList.length === 0}
                                        startIcon={<FileUpload />}
                                        fullWidth
                                        color="success"
                                        variant="contained">
                                        {getTranslatedText("UploadPrerelease")}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    </CustomBox>
                </>
            }
        </LanguageConsumer>
    )
}