import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material"
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks"
import { closeResendPopup } from "../../Store/WaitingForEmailVerificationSlice";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { DefaultResponse } from "../../../../../../Infrastructure/Api/Model/DefaultResponse";
import { store, RootState } from "../../../../../../Infrastructure/Store/store";

export const WaitForEmailVerificationResendPopup = () => {
    const { visible, resendEmail, resendUserId} = useAppSelector(store => store.waitingForEmailVerifications.resendPopup);
    const dispatch = useAppDispatch();

    const close = () => {
        dispatch(closeResendPopup());
    }

    const resendVerificationEmail = async() => {
        await fetchMyDepragApi<DefaultResponse>({UserIdToResendMail: resendUserId}, "users/WaitingForEmailVerification/resendmail", "POST", dispatch, store.getState() as RootState, "SuccessfullyResendEmailVerification")
    }

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
            <Dialog
                open={visible}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                   {getTranslatedText("AreYouSure")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography><span dangerouslySetInnerHTML={{__html: getTranslatedText("WaitForEmailVerificationResendBody", [resendEmail])}}></span></Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="darky" onClick={close}>{getTranslatedText("CommonCancel")}</Button>
                    <Button variant="outlined" color="success" onClick={() => {
                        resendVerificationEmail();
                        close();
                    }} autoFocus>{getTranslatedText("WaitForEmailVerificationResendUser")}</Button>
                </DialogActions>
            </Dialog>
            </>
            }
        </LanguageConsumer>
    )
}