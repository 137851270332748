import { Button, Grid, Typography, useTheme } from "@mui/material"
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBox } from "../../../../../Layout/CustomBox";
import HtmlParser from "react-html-parser";
import { FileDownload } from "@mui/icons-material";
import { useState } from "react";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { OnlineTrainingDownloadResponseDto } from "../Models/OnlineTrainingDownloadResponseDto";
import { useAppDispatch } from "../../../../../Infrastructure/Store/hooks";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { LoadingButton } from "@mui/lab";

interface CourseDownloadProps {
    courseId: number
}

export const CourseDownload = (props: CourseDownloadProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const getDownloadFile = async () => {
        setIsDownloading(true);
        var response = await fetchMyDepragApi<OnlineTrainingDownloadResponseDto>(null, `onlinetraining/courses/${props.courseId}/download`, 'GET', dispatch, store.getState() as RootState);
        await downloadAttachmentFile(response.Content, response.FileName);
    }

    const downloadAttachmentFile = async (fileAsBase64: string, fileName: string) => {
        var base64String = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${fileAsBase64}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = base64String;
        downloadLink.download = fileName;
        downloadLink.click();
        setIsDownloading(false);
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <CustomBox elevation={0} sx={{
                        minHeight: 200,
                        height: '100%',
                        backgroundColor: 'transparent',
                        border: `1px solid ${theme.palette.cardBorder.main}`,
                        backgroundSize: 'cover',
                        position: 'relative',
                    }}>

                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                <Typography variant="h6" fontWeight={600}>{getTranslatedText(props.courseId > 0 ? "CourseEditDownloadTitle" : "CourseCreateDownloadTitle")}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                <Typography>{HtmlParser(getTranslatedText(props.courseId > 0 ? "CourseEditDownloadDescription" : "CourseCreateDownloadDescription"))}</Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 11 }} alignSelf={'flex-end'}>
                                <LoadingButton loading={isDownloading} onClick={getDownloadFile} startIcon={<FileDownload />} fullWidth color="success" variant="contained">{getTranslatedText(props.courseId > 0 ? "DownloadVideoCourseXls" : "DownloadVideoCourseTemplateXls")}</LoadingButton>
                            </Grid>
                        </Grid>

                    </CustomBox>
                </>
            }
        </LanguageConsumer>
    )
}