import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { LanguageConsumer } from "../Infrastructure/Internationalisation/TranslationService";
import { useAppSelector } from "../Infrastructure/Store/hooks";
import { CustomBox } from "../Layout/CustomBox";
import { Helmet } from "react-helmet";
import HtmlParser from "react-html-parser";
import { PiPlayLight } from "react-icons/pi";
import { Routes, useNavigate } from "react-router-dom";


export const ErrorBoundaryDisplay = () => {
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width:1200px)");
    const style = {
        position: 'absolute' as 'absolute',
        top: 0,
        left: 0,
        minWidth: '100%',
        minHeight: '100%',
        maxWidth: '100%',
        height: '100%',
        bgcolor: !isDarkMode ? '#555' : '#888',
        borderRadius: 0,
        boxShadow: 24,
        p: 0,
        outline: 'none',
        zIndex: 999999,
        overflow: 'hidden',
        overflowY: 'auto',
        textAlign: 'center',
        scrollBehavior: 'smooth',
    };

    const handleClick = () => {
        navigate("/login");
        window.location.reload();
    }
    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Box component={'div'} sx={style} className="verticalScroll" id="landing-home">
                    <Grid item xs={8}>
                        <CustomBox sx={{ background: isDarkMode ? '#222' : '#fff', mx: isMobile ? 0 : 10, my: 10 }}>
                            <Helmet>
                                <title>{getTranslatedText("ErrorBoundaryTitle")}</title>
                            </Helmet>

                            <Typography sx={{ my: isMobile ? 0 : 10, fontSize: 30, color: isDarkMode ? "#fff" : "rgba(0, 0, 0, 0.87)" }} >
                                {HtmlParser(getTranslatedText("ErrorBoundaryTitle"))}
                            </Typography>

                            <Typography sx={{ mt: 10, color: isDarkMode ? "#fff" : "rgba(0, 0, 0, 0.87)" }}>
                                {HtmlParser(getTranslatedText("ErrorBoundaryContent"))}
                            </Typography>

                            <Button onClick={handleClick} startIcon={<PiPlayLight />} fullWidth={isMobile} variant="contained" color="success" sx={{ mt: 4, color: '#fff', minWidth: 200, p: 2 }} >
                                {getTranslatedText("GoBackToMyDeprag")}
                            </Button>
                        </CustomBox>
                    </Grid>
                </Box>
            }
        </LanguageConsumer>
    )
}