import { useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { useEffect } from "react";
import { setRedirect } from "../../../Store/AppSlice";

export const useRedirect = () => {
    const location = useLocation();
    const {tempRedirectPathName, savePath} = useAppSelector(store => store.global.redirect);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if(location.pathname === "/" && savePath && tempRedirectPathName.trim().length > 0){
            navigate(tempRedirectPathName);
        }
        dispatch(setRedirect({tempRedirectPathName: '', savePath: true }));
    }, [location, tempRedirectPathName])

}