
import { BoxProps, StyledComponentProps, Box, useTheme, Typography, Paper } from "@mui/material";
import { useAppSelector } from "../Infrastructure/Store/hooks";

interface CustomBoxProps {
  noShadow?: boolean,
  elevation?: number
}

interface Props extends BoxProps, CustomBoxProps, StyledComponentProps {}

export const CustomBoxHeader = ({text, ...props}) => {

  return(
    <Typography fontWeight={600} textTransform={'uppercase'} fontSize={15} sx={{mt: -1, mb: 2.5, ...props.sx}} color="darky">{text}</Typography>
  )
}

export const CustomBox = ({ children, noShadow = false, ...props }: Props) => {
  const theme = useTheme();
  const isDarkMode = useAppSelector(store => store.settings.theme === 'dark');

  return <Box
    component={Paper}
    className={props.className}
    onClick={props.onClick}
    onContextMenu={props.onContextMenu}
    elevation={props.elevation}
    sx={{
      //boxShadow: noShadow ? 'none' : (isDarkMode ? '0px 1px 16px -3px rgba(0,0,0,0.55)' : '0px 1px 16px -3px rgba(0,0,0,0.15)'),
      
      p: 3,
      borderRadius: 2,
      backgroundColor: `${theme.palette.background.paper}`,
      ...props.sx
    }}
  >
    {children}
  </Box>;
};