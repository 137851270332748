import { Grid, Typography, Divider, Box } from "@mui/material"
import { LanguageConsumer, getTranslation } from "../../Infrastructure/Internationalisation/TranslationService"
import { Fragment } from "react";

interface StepsForRegistrationProps {
    maxStepKeyForSuccessColor: number,
    hideText?: boolean,
    sx?: any
}

export const StepsForRegistration = (props: StepsForRegistrationProps) => {
    const { maxStepKeyForSuccessColor, hideText, sx } = props;
    const howToRegister = [
        { stepNumber: 1, title: getTranslation("HowToRegisterRegisterTitleBackoffice"), description: getTranslation("HowToRegisterRegisterDescriptionBackoffice") },
        { stepNumber: 2, title: getTranslation("HowToRegisterConfirmTitleBackoffice"),  description: getTranslation("HowToRegisterConfirmDescriptionBackoffice") },
        { stepNumber: 3, title: getTranslation("HowToRegisterMyDepragTitleBackoffice"), description: getTranslation("HowToRegisterMyDepragDescriptionBackoffice") }
    ]

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    {howToRegister.map((step, stepkey) => {
                        return (
                            <Grid key={`howTo-${stepkey}`} item xs={12} sm={4} sx={{...sx}}>
                                <Grid container direction={'row'} spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h3" color={stepkey < maxStepKeyForSuccessColor ? "success.main" : "footer.light"}>{step.stepNumber}</Typography>
                                        <Divider sx={{ mt: 2 }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontWeight={700} color={stepkey < maxStepKeyForSuccessColor ? "success.main" : "footer.dark"}>{step.title.toLocaleUpperCase()}</Typography>
                                    </Grid>
                                  {!hideText && <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography>{step.description}</Typography>
                                    </Grid>}
                                </Grid>
                               
                            </Grid>
                        )
                    })}
                </>
            }
        </LanguageConsumer>
    )
}