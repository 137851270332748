import { Button, FormControl, Grid, TextField, Typography, useTheme } from "@mui/material";
import {
  LanguageConsumer,
  getTranslation,
} from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { CustomBox } from "../../../../../../Layout/CustomBox";
import { TextFieldFormControl } from "../../../../../../Layout/Inputs/TextFieldFormControl";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { useAppSelector, useAppDispatch } from "../../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../../Infrastructure/Store/store";
import { MaintenanceMessage } from "../../Overview/Models/MaintenanceMessage";
import { MaintenanceMessagesValidator } from "../Models/MaintenanceMessagesValidator";
import { EmptyMessage } from "../Models/EmptyMaintenanceMessage";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { momentToClearedDate } from "../../../../../../Infrastructure/Utils/DateFunctions";

export const MaintenanceMessageFormEdit = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {id} = useParams();

  const [loading, setLoading] = useState(false);
  const isDarkMode = useAppSelector((store) => store.settings.theme === "dark");
  const language = useAppSelector((store) => store.settings.language);

  const {isLoading, data} = useQuery({
    queryKey: [`maintenance-${id}`],
    queryFn: async () => {
      return await fetchMyDepragApi<MaintenanceMessage>(
        {preferredIsoLanguageCode: language},
        "highlevel/maintenance/get/" + id,
        "POST",
        dispatch,
        store.getState() as RootState);
    },
    enabled: !!id,
  });

  const [validFrom, setValidFrom] = useState<Moment>(data ? moment(data.ValidFrom) : moment().startOf("hour"));
  const [validUntil, setValidUntil] = useState<Moment>(data ? moment(data.ValidUntil) : moment().startOf("hour").add(1, "day"));


  const {register, handleSubmit, formState: {errors}} = useForm({
    defaultValues: EmptyMessage,
    values: data ? data : EmptyMessage,
    resolver: zodResolver(MaintenanceMessagesValidator),
  });

  const onSubmit = async (dataToSend: MaintenanceMessage) => {
    setLoading(true);

    try {
      await fetchMyDepragApi<MaintenanceMessage>(
        {
          ...dataToSend,
          ValidFrom: momentToClearedDate(validFrom),
          ValidUntil: momentToClearedDate(validUntil),
          MaintenanceMessageId: data ? data.MaintenanceMessageId : 0,
        } as MaintenanceMessage,
        "highlevel/maintenance/edit",
        "POST",
        dispatch,
        store.getState() as RootState,
        getTranslation("HighLevelMaintenanceMessageSuccessEdit"),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) setLoading(true);
    if (!isLoading) setLoading(false);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      if (data.ValidFrom) setValidFrom(moment(data.ValidFrom));
      if (data.ValidUntil) setValidUntil(moment(data.ValidUntil));
    }
  }, [data]);


  return (
    <LanguageConsumer>
      {({getTranslatedText}) =>
        <Grid container direction="row" spacing={2} className="main-content" sx={{mt: 1}}>
          <Grid item xs={12} sx={{mb: 2, mx: 1}}>
            <Button color="darky" component={Link} to={"/highlevel/maintenance"} variant="outlined">
              {getTranslatedText("Prev")}
            </Button>
          </Grid>

          <CustomBox
            elevation={0}
            sx={{
              m: 3,
              pt: 2,
              height: "100%",
              width: "100%",
              backgroundSize: "cover",
              backgroundBlendMode: "multiply",
              backgroundColor: isDarkMode ? "transparent" : "#fff",
              border: `1px solid ${theme.palette.cardBorder.main}`,
            }}
          >
            <Typography paddingLeft={2} fontWeight={700}>
              {getTranslatedText("HighLevelMaintenanceMessageId")}
            </Typography>

            <Grid item xs={12} container direction={"row"} spacing={3}>
              <Grid item xs={6}>
                <TextField value={data ? data.MaintenanceMessageId : ""} disabled/>
              </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>

              <Grid container direction={"row"} spacing={2} sx={{mt: 1}}>
                <Typography paddingLeft={2} fontWeight={700}>
                  {getTranslatedText("HighLevelMaintenanceMessageTitle")}
                </Typography>

                <Grid item xs={12} container direction={"row"} spacing={3}>
                  <Grid item xs={6}>
                    <TextFieldFormControl
                      inputProps={{
                        "data-testid": "maintenance-titleDe",
                      }}
                      labelText="DE"
                      disabled={loading}
                      propertyName="TitleDE"
                      register={register}
                      errors={errors}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextFieldFormControl
                      inputProps={{
                        "data-testid": "maintenance-titleEn",
                      }}
                      labelText="EN"
                      disabled={loading}
                      propertyName="TitleEN"
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container direction={"row"} spacing={2} sx={{mt: 1}}>
                <Typography paddingLeft={2} fontWeight={700}>
                  {getTranslatedText("HighLevelMaintenanceMessageDescription")}
                </Typography>

                <Grid item xs={12} container direction={"row"} spacing={3}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        inputProps={{
                          "data-testid": "maintenance-descriptionDe",
                        }}
                        multiline
                        maxRows={15}
                        label="DE"
                        disabled={loading}
                        id="TxtField_DescriptionDE"
                        error={!!errors["DescriptionDE"]}
                        helperText={errors["DescriptionDE"] ? getTranslatedText(errors["DescriptionDE"].message as string) : ""}
                        InputLabelProps={{shrink: true}}
                        {...register("DescriptionDE")}/>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        inputProps={{
                          "data-testid": "maintenance-descriptionEn",
                        }}
                        multiline
                        maxRows={15}
                        label="EN"
                        disabled={loading}
                        id="TxtField_DescriptionEN"
                        error={!!errors["DescriptionEN"]}
                        helperText={errors["DescriptionEN"] ? getTranslatedText(errors["DescriptionEN"].message as string) : ""}
                        InputLabelProps={{shrink: true}}
                        {...register("DescriptionEN")}/>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container direction={"row"} spacing={2} sx={{mt: 1}}>
                <Typography paddingLeft={2} fontWeight={700}>
                  {getTranslatedText("HighLevelMaintenanceMessageTiming")}
                </Typography>

                <Grid item xs={12} container direction={"row"} spacing={3}>
                  <Grid item xs={6}>
                    <DateTimePicker
                      maxDate={validUntil}
                      label={getTranslatedText("HighLevelMaintenanceMessageValidFrom")}
                      format="HH:mm DD/MM/YYYY"
                      defaultValue={validFrom}
                      value={validFrom}
                      disabled={loading}
                      onChange={(x) => x && setValidFrom(x)}/>
                  </Grid>

                  <Grid item xs={6}>
                    <DateTimePicker
                      minDate={validFrom}
                      label={getTranslatedText("HighLevelMaintenanceMessageValidUntil")}
                      format="HH:mm DD/MM/YYYY"
                      defaultValue={validUntil}
                      value={validUntil}
                      disabled={loading}
                      onChange={(x) => x && setValidUntil(x)}/>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container direction={"row"} spacing={2} sx={{mt: 2}}>
                <Grid item xs={12}>
                  <LoadingButton
                    type="submit"
                    color="success"
                    fullWidth
                    variant="contained"
                    loading={loading}
                  >
                    {getTranslatedText("CommonSave")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </CustomBox>
        </Grid>
      }
    </LanguageConsumer>
  );
};
