import { Check } from "@mui/icons-material"
import { Badge, Typography } from "@mui/material"

export const getTabTitle = (title: string, errorCount: number) => {
    return <>
        <Typography>
            {errorCount > 0 && <Badge overlap="rectangular" anchorOrigin={{vertical: 'top',horizontal: 'right',}} badgeContent={errorCount} color="error">{title}</Badge>}

            {errorCount === 0 && <>{title} <Check color="success" sx={{ position: 'relative', top: 5 }} /></>}
        </Typography>
    </>
}