import {
    Chip,
    Tooltip,
    IconButton,
    Grid,
    Divider,
    FormControlLabel,
    Box,
    Skeleton,
  } from "@mui/material";
  import { useMutation, useQuery } from "@tanstack/react-query";
  import { useState, useEffect } from "react";
  import { useLocation, NavLink } from "react-router-dom";
  import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
  import { IoPencilSharp, IoPowerSharp, IoToggle, IoToggleSharp, IoTrash, IoTrashSharp } from "react-icons/io5";
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { CockpitCloudGetResponseDto } from "../Models/CockpitCloudGetResponseDto";
import { RootState, store } from "../../../../../../Infrastructure/Store/store";
import { toggleShowAllAreas } from "../../Store/CockpitCloudSlice";
import { LanguageConsumer, getTranslation } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { CockpitCloudAreaItem } from "../Models/CockpitCloudAreaItem";
import { DataGridSearch } from "../../../../../../Layout/DataGrid/DataGridSearch";
import { CustomSwitch } from "../../../../../../Layout/Switch/CustomSwitch";
import { StripedDataGrid } from "../../../../../../Layout/DataGrid/StripedDataGrid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { CustomSimpleDialog } from "../../../../../../Layout/Dialogs/CustomSimpleDialog";
import { toast } from "react-hot-toast";
import { useNumberLanguageFormatter } from "../../../../../../Infrastructure/Hooks/NumberLanguageFormatter/useNumberLanguageFormatter";
  
  export const MAX_YEAR = 9999;
  
  export const AllCockpitCloudAreasTable = () => {
    const dispatch = useAppDispatch();
    const langIsDe = useAppSelector(store => store.settings.language === "de");
    const location = useLocation();
    const isDepragAmbergMember = useAppSelector(store => store.authentication.isDepragAmbergMember);
  const { formatNumberAccordingLanguage } = useNumberLanguageFormatter();
    const [searchValue, setSearchValue] = useState<string>("");
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedAreaName, setSelectedAreaName] = useState<string>("");
    const showAllAreas = useAppSelector(store => store.cockpitCloud.showAllAreas);
    
    const {isLoading, data, refetch} = useQuery({
      queryKey: ["cockpitAreas"],
      queryFn: async () => await fetchMyDepragApi<CockpitCloudGetResponseDto>(
        {},
        `software/cockpitcloud/get/${isDepragAmbergMember ? showAllAreas : false}` ,
        "GET",
        dispatch,
        store.getState() as RootState),
    });

    const deleteMutation = useMutation({
        mutationFn: (areaName: string) => {
            return fetchMyDepragApi<any>
                (
                    null,
                    `software/cockpitcloud/delete/${areaName}`,
                    "GET",
                    dispatch,
                    store.getState() as RootState
                )
        },
        onError: () => {
            setOpenDialog(false)
            toast.error(getTranslation("CockpitCloudAreaDeleteDidNotWork"));
        },
        onSuccess: () => {
            setOpenDialog(false)
            toast.success(getTranslation("CockpitCloudAreaDeleteWorked"))
        }
    });

    const activateDeactivateMutation = useMutation({
        mutationFn: (item: CockpitCloudAreaItem) => {
            return fetchMyDepragApi<any>
                (
                    null,
                    `software/cockpitcloud/${item.IsDeactivated? "activate" : "deactivate"}/${item.AreaName}`,
                    "GET",
                    dispatch,
                    store.getState() as RootState
                )
        },
        onError: () => {
            setOpenDialog(false)
            toast.error(getTranslation("CockpitCloudAreaActivateDeactivateDidNotWork"));
        },
        onSuccess: () => {
            setOpenDialog(false)
            toast.success(getTranslation("CockpitCloudAreaActivateDeactivateWorked"))
        }
    })
  
    useEffect(() => {
      refetch();
    }, [location, refetch, showAllAreas, deleteMutation.isLoading,activateDeactivateMutation.isLoading]);
  
  
    const search = (inputText: string) => {
      setSearchValue(inputText);
    };
  
    const showAllAreasChanged = () => {
      dispatch(toggleShowAllAreas());
    };
  
    const columns: GridColDef[] = [
      {
        field: "CompanyName",
        headerName: getTranslation("UserCompanyName"),
        flex: 1,
        minWidth: 150,
      },
      {
        field: "AreaName",
        headerName: getTranslation("CockpitAreaName"),
        flex: 1,
        minWidth: 150,
      },
      {
        field: "UsedStorageInGb",
        headerName: getTranslation("UsedStorageInGb"),
        flex: 1,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<any, CockpitCloudAreaItem, any>) => {
            return <div>{formatNumberAccordingLanguage(params.row.UsedStorageInBytes/1000000000)}</div> ;
        },
      },
      {
        field: "AvailableStorageInGb",
        headerName: getTranslation("AvailableStorageInGb"),
        flex: 1,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<any, CockpitCloudAreaItem, any>) => {
            return <div>{formatNumberAccordingLanguage(params.row.AvailableStorageInBytes/1000000000)}</div> ;
        },
      },
      {
        field: "IsDeactivated",
        headerName: getTranslation("State"),
        flex: 1,
        minWidth: 80,
        renderCell: (params: GridRenderCellParams<any, CockpitCloudAreaItem, any>) => {
            return <Chip label={getTranslation(params.row.IsDeactivated? "IsAreaDeactivated" : "IsAreaActivated")} color={params.row.IsDeactivated? "error" : "success"}/>;
        },
        valueGetter: (params: GridRenderCellParams<any, CockpitCloudAreaItem, any>) => getTranslation(params.row.IsDeactivated? "IsAreaDeactivated" : "IsAreaActivated"),
      },      
      {
        field: "",
        headerName: "",
        flex: 0.1,
        minWidth: 100,
        align: "right",
        renderCell: (params: GridRenderCellParams<any, CockpitCloudAreaItem, any>) => (
            <>
            <strong>
            <Tooltip title={getTranslation("ActivateDeactivateCockpitCloudArea")}>
              <IconButton color={"darky"} onClick={() => activateDeactivateMutation.mutate(params.row)}>
                <IoPowerSharp/>
              </IconButton>
            </Tooltip>
          </strong>
          <strong>
            <Tooltip title={getTranslation("CommonDelete")}>
              <IconButton color={"darky"} onClick={() => {
                setSelectedAreaName(params.row.AreaName);
                setOpenDialog(true);
              }}>
                <IoTrashSharp/>
              </IconButton>
            </Tooltip>
          </strong>
          </>
        ),
        sortable: false,
        filterable: false,
      },
    ];
  
    return (
      <LanguageConsumer>
        {({getTranslatedText}) =>
          <>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} textAlign="center">
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{textAlign: "left"}}>
  
                    <div
                      dangerouslySetInnerHTML={{__html: getTranslatedText("AllCockpitCloudAreasInfoText")}}
                    />
  
  
                    <Divider sx={{my: 3, mb: 2}}/>
                  </Grid>
  
                  <Grid item xs={4} my={"auto"} sx={{textAlign: "left", overflowX: "hidden"}}>
                    <DataGridSearch searchValue={searchValue} search={search}/>
                  </Grid>
                  {isDepragAmbergMember && <Grid item xs={8} my={"auto"} sx={{textAlign: "right"}}>
                    <FormControlLabel control={<CustomSwitch/>} checked={showAllAreas}
                                      onChange={() => showAllAreasChanged()}
                                      label={getTranslatedText("ShowAllAreas")}/>
                  </Grid>}
  
                </Grid>
                <Grid item xs={12} sx={{mt: 1}}>
                  <Box component={"div"} sx={{width: "100%"}}>
                    <StripedDataGrid
                      localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                      rows={data ? data.Items.filter(x =>
                        (x.CustomerId.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                          (x.AreaName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                          getTranslation(x.IsDeactivated? "IsAreaDeactivated" : "IsAreaActivated").toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                        ),
                      ) : []}
                      autoHeight={true}
                      columns={columns}
                      pageSize={8}
                      rowsPerPageOptions={[8]}
                      checkboxSelection={false}
                      disableColumnFilter={false}
                      disableColumnMenu={false}
                      disableSelectionOnClick={true}
                      disableVirtualization={false}
                      hideFooter={false}
                      getRowId={(x) => x.CustomerId}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                      }
                      components={{
                        LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{my: 4, mx: 1, mt: 1}}/>,
                      }}
                      loading={isLoading}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <CustomSimpleDialog
                    title="AreYouSure"
                    content={`DialogContentDeleteCockpitArea`}
                    open={openDialog}
                    cancelAction={() => setOpenDialog(false)}
                    successAction={() => deleteMutation.mutate(selectedAreaName)}
                    handleClose={() => setOpenDialog(false)}
                    cancelText="CommonCancel"
                    successText="CommonDelete" />
          </>
        }
      </LanguageConsumer>
    );
  };
  