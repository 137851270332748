
import { createSlice } from '@reduxjs/toolkit';

interface DeletePopup {
  visible: boolean,
  deleteEmail: string,
  deleteUserId: number
}

interface ResendPopup {
  visible: boolean,
  resendUserId: number,
  resendEmail: string
}

export type WaitingForEmailVerificationState = {
  showAllUsers: boolean,
  deletePopup: DeletePopup,
  resendPopup: ResendPopup
}

export const initialStateWaitingForEmailVerification: WaitingForEmailVerificationState = {
    showAllUsers: false,
  deletePopup: {
    visible: false,
    deleteEmail: "",
    deleteUserId: 0
  },
  resendPopup: {
    visible: false,
    resendUserId: 0,
    resendEmail: ""
  }
};

export const waitingForEmailVerificationsSlice = createSlice({
  name: 'WaitForEmailVerification',
  initialState: initialStateWaitingForEmailVerification,
  reducers: {
    toggleShowAllWaitForEmailVerifications: (state) => {
      state.showAllUsers = !Boolean(state.showAllUsers)
    },
    showDeletePopup: (state, action) => {
      state.deletePopup.visible = true;
      state.deletePopup.deleteEmail = action.payload.email;
      state.deletePopup.deleteUserId = action.payload.userId;
    },
    closeDeletePopup: (state) => {
      state.deletePopup.visible = false;
      state.deletePopup.deleteEmail = "";
      state.deletePopup.deleteUserId = 0;
    },
    showResendPopup: (state, action) => {
      state.resendPopup.visible = true;
      state.resendPopup.resendUserId = action.payload.userId;
      state.resendPopup.resendEmail = action.payload.email;
    },
    closeResendPopup: (state) => {
      state.resendPopup.visible = false;
      state.resendPopup.resendUserId = 0;
      state.resendPopup.resendEmail = "";
    }
  }
});

export const { showResendPopup, closeResendPopup, showDeletePopup, closeDeletePopup, toggleShowAllWaitForEmailVerifications } = waitingForEmailVerificationsSlice.actions;

export default waitingForEmailVerificationsSlice.reducer;
