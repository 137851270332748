import { Controller, FieldValues, RegisterOptions } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import { FormHelperText, Typography } from "@mui/material";
import { ControlledInputProps } from "./Models/ControlledInputProps";
import { Moment } from "moment";


interface ControlledDatePickerProps<T extends FieldValues> extends ControlledInputProps<T> {
  rules?: Pick<
    RegisterOptions<FieldValues>,
    "maxLength" | "minLength" | "validate" | "required"
  >; //https://react-hook-form.com/docs/useform/register#options
  disabled?: boolean;
  minDate?: any;
  maxDate?: any;
}

export const ControlledDatePicker = <T extends FieldValues>(
  {
    name,
    control,
    label,
    rules,
    disabled,
    minDate,
    maxDate,
    ...restProps
  }: ControlledDatePickerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <>
          <DatePicker value={value}
            onChange={onChange}
            label={label}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
            sx={{ width: "100%" }}
            {...restProps} />
          {!!error &&
            <FormHelperText>
              <Typography fontSize={11}
                color={"error"}>
                {error.message}
              </Typography>
            </FormHelperText>}
        </>
      )}
    />
  );
};

