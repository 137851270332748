import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type MyGoldAssignState = {
    selectedLicenceIds: number[],
    assignedToCustomerId?: number,
    resetManyAssigned?: boolean
}

export const initialStateMyGoldAssignState: MyGoldAssignState = {
  selectedLicenceIds: [],
  assignedToCustomerId: 0,
  resetManyAssigned: false
};

  export const myGoldAssignSlice = createSlice({
    name: 'myGoldAssign',
    initialState: initialStateMyGoldAssignState,
    reducers: {
      setSelectedLicenceIds: (state, action : PayloadAction<{ myGoldLicenseIds: number[], assignedToCustomerId?: number, resetManyAssigned?: boolean}>) => {
          state.selectedLicenceIds = action.payload.myGoldLicenseIds;
          state.assignedToCustomerId = action.payload.assignedToCustomerId;  
          state.resetManyAssigned = action.payload.resetManyAssigned;           
      },
      resetSelectedLicenceIds: (state) => {
        state.selectedLicenceIds = initialStateMyGoldAssignState.selectedLicenceIds;
        state.assignedToCustomerId = initialStateMyGoldAssignState.assignedToCustomerId;
        state.resetManyAssigned = initialStateMyGoldAssignState.resetManyAssigned;
      }
    },
  });

  export const { setSelectedLicenceIds, resetSelectedLicenceIds } = myGoldAssignSlice.actions;

  export default myGoldAssignSlice.reducer;