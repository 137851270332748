import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LanguageConsumer, getTranslation } from "../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../../Store/AppSlice";
import { Autocomplete, Button, Grid, TextField, useTheme } from "@mui/material";
import { CustomBox } from "../../../../Layout/CustomBox";
import { TextFieldFormControl } from "../../../../Layout/Inputs/TextFieldFormControl";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCustomerDetailFormValidator } from "../AllCustomers/Models/useCustomerDetailFormValidator";
import { EmptyCustomerForm, CustomerDetailForm } from "../AllCustomers/Models/CustomerDetailForm";
import { fetchMyDepragApi } from "../../../../Infrastructure/Api/ApiBaseCall";
import { DefaultResponse } from "../../../../Infrastructure/Api/Model/DefaultResponse";
import { RootState, store } from "../../../../Infrastructure/Store/store";
import { useQuery } from "@tanstack/react-query";
import { CountryResponseDto } from "./Models/CountryResponseDto";
import { clearCreateCustomerDefaultValues, setGeneratedCustomerId } from "../Store/CustomersSlice";
import { CustomerEditResponseDto } from "./Models/CustomerEditResponseDto";
import { StyledPopper, ListboxComponent } from "../../../../Layout/Inputs/AutoCompleteVirtualizationHelper";


interface CustomerCreateProps {
    hideBackButton?: boolean;
}

export const CustomerCreate = (props: CustomerCreateProps) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const defaultValues = useAppSelector(store => store.customers.createCustomerDefaultValues);
    const [selectedCountry, setSelectedCountry] = useState<string>("");
    const [availableCountries, setAvailableCountries] = useState<{ Id: number, CountryName: string }[]>();
    const [countryError, setCountryError] = useState<boolean>(false);
    const {CustomerDetailFormValidator} = useCustomerDetailFormValidator();

    const {
        register, handleSubmit, formState: { errors, isDirty}, clearErrors, trigger, setValue
    } = useForm<CustomerDetailForm>({
        defaultValues: EmptyCustomerForm,
        values: EmptyCustomerForm,
        resolver: zodResolver(CustomerDetailFormValidator),
    });

    useEffect(() => {
        clearErrors();
        isDirty && trigger()
    }, [language])

    const { isLoading, data} = useQuery({
        queryKey: ["customersCountries"],
        queryFn: async () => await fetchMyDepragApi<CountryResponseDto>(
            { preferredLanguageIsoCode: language },
            'basedata/countries',
            'POST',
            dispatch,
            store.getState() as RootState)
    });

    const onSubmit = async (formData: CustomerDetailForm) => {
        if (selectedCountry.trim().length === 0) {
            setCountryError(true)
        } else {
            var dataToPost = {
                CustomerId: null,
                CompanyName: formData.companyName,
                InternalId: formData.internalId,
                ZipCode: formData.zipCode,
                CountryId: availableCountries?.filter(x => x.CountryName === selectedCountry)[0].Id || null
            }
            var response = await fetchMyDepragApi<DefaultResponse>(dataToPost, "customers", "POST", dispatch, store.getState() as RootState, "SuccessfullyCreatedCustomer", true)

            if (response.Success) {
                var customerId = (response.Payload as CustomerEditResponseDto).CustomerId;
                dispatch(setGeneratedCustomerId(customerId))
                navigate(-1);
            }
        }

    }

    useEffect(() => {
        if (data && data.Items && data.Items.length > 0) {
            setAvailableCountries(data.Items);
        }
    }, [data])

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuCustomersCreateBreadcrumb')));
    }, [location, language, languageFileWasLoaded]);

    useEffect(() => {
        if (!defaultValues) return;
        const { countryName, companyName, postalCode } = defaultValues;
    
        if (countryName) setSelectedCountry(countryName);
        if (companyName) setValue("companyName", companyName);
        if (postalCode) setValue("zipCode", postalCode);

        dispatch(clearCreateCustomerDefaultValues());
    }, [defaultValues, setValue, dispatch]);

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
                        <Grid item xs={12} mx={3}>
                            <Grid container direction={'row'} spacing={2}>
                                {props.hideBackButton ? <></> : <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Button color="darky" onClick={() => navigate(-1)} variant="outlined">{getTranslatedText("Prev")}</Button>
                                </Grid>}
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <CustomBox elevation={0} sx={{
                                        backgroundSize: 'cover',
                                        backgroundBlendMode: 'multiply',
                                        backgroundColor: 'transparent',
                                        border: `1px solid ${theme.palette.cardBorder.main}`
                                    }}>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextFieldFormControl
                                                        disabled={isLoading || !data}
                                                        inputProps={{ "data-testid": "customerDetail-CompanyName" }}
                                                        hasEndAdornment={false}
                                                        labelText={getTranslatedText('CustomerCompanyName')}
                                                        propertyName='companyName'
                                                        register={register} registerOptions={{ valueAsNumber: false }} errors={errors} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextFieldFormControl
                                                      disabled={isLoading || !data}
                                                      inputProps={{ "data-testid": "customerDetail-InternalId" }}
                                                      hasEndAdornment={false}
                                                      labelText={getTranslatedText('CustomerInternalId')}
                                                      propertyName='internalId'
                                                      register={register} registerOptions={{ valueAsNumber: false }} errors={errors} />
                                                </Grid>                                                <Grid item xs={12}>
                                                    <TextFieldFormControl
                                                      disabled={isLoading || !data}
                                                      inputProps={{ "data-testid": "customerDetail-zipCode" }}
                                                      hasEndAdornment={false}
                                                      labelText={getTranslatedText('ZipCode')}
                                                      propertyName='zipCode'
                                                      register={register} registerOptions={{ valueAsNumber: false }}  errors={errors} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        disableListWrap
                                                        PopperComponent={StyledPopper}
                                                        ListboxComponent={ListboxComponent}
                                                        renderOption={(props, option, state) =>
                                                            [props, option, state.index] as React.ReactNode
                                                        }
                                                        disabled={isLoading || !data}
                                                        noOptionsText={getTranslatedText("NoCountriesAvailable")}
                                                        fullWidth
                                                        disablePortal
                                                        id="country"
                                                        options={availableCountries ? availableCountries.map(option => option.CountryName) : []}
                                                        value={selectedCountry}
                                                        ListboxProps={{
                                                            style: { zIndex: 10002 }
                                                        }}
                                                        onChange={(event: any, newValue: string | null) => {
                                                            if (newValue) {
                                                                setCountryError(false)
                                                                setSelectedCountry(newValue)
                                                            }
                                                        }}
                                                        renderInput={(params) => <TextField
                                                            {...params}
                                                            fullWidth
                                                            error={countryError}
                                                            helperText={countryError ? getTranslatedText("PleaseProvideACountry") : ''}
                                                            label={getTranslatedText("RegistrationCountry")}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button color="success" disabled={!!errors["companyName"] || isLoading || !data} variant="contained" type="submit" fullWidth>{getTranslatedText("CreateCustomer")}</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CustomBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}