import { useNavigate } from "react-router-dom";
import { MyDepragClaim } from "../../../Features/MainMenus/Highlevel/Claims/Details/Models/ClaimsEditResponseDto";
import { useAppSelector } from "../../Store/hooks";
import { useEffect } from "react";

export const usePermissionByClaim = (claim: MyDepragClaim, fallbackRoute: string = '/') => {
    const claims = useAppSelector(store => store.authentication.myDepragClaims);
    const navigate = useNavigate();
    
    const isHidden = (necessaryClaim: MyDepragClaim): boolean => {

        if (claims === undefined || claims.length === 0)
          return true;
        const hasCorrectClaim = Object.values(claims).includes(necessaryClaim);
    
        return !hasCorrectClaim;
    }

    useEffect(() => {
        if(isHidden(claim)){
          navigate(fallbackRoute)
        }
      }, [])


}