import { NavLink, useLocation } from "react-router-dom"
import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { PrereleaseCreateContent } from "./Components/PrereleaseCreateContent";
import { getTranslation, LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../../../Store/AppSlice";
import { PrereleaseCreateDone, PrereleaseCreateDoneProps } from "./Components/PrereleaseCreateDone";

export const SoftwarePrereleaseCreate = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const [isPrereleaseCreateDone, setIsPrereleaseCreateDone] = useState<boolean>(false);
    const [prereleaseCreateDoneProps, setPrereleaseCreateDoneProps] = useState<PrereleaseCreateDoneProps>({} as PrereleaseCreateDoneProps);


    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuSoftwarePrereleaseCreateBreadcrumb')));
    }, [location, language, languageFileWasLoaded, dispatch]);


    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
                    <Grid item xs={12} mx={3}>
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Button color="darky" sx={{ zIndex: 9999999 }} component={NavLink} to={'/software/prerelease'} variant="outlined">{getTranslatedText("Prev")}</Button>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                {isPrereleaseCreateDone ?
                                    <PrereleaseCreateDone
                                        DownloadUrl={prereleaseCreateDoneProps.DownloadUrl}
                                        DownloadValidUntil={prereleaseCreateDoneProps.DownloadValidUntil} />
                                    :
                                    <PrereleaseCreateContent
                                        onPrereleaseCreateDone={(prereleaseCreateDoneProps: PrereleaseCreateDoneProps) => {
                                            setIsPrereleaseCreateDone(true);
                                            setPrereleaseCreateDoneProps(prereleaseCreateDoneProps);
                                        }} />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </LanguageConsumer>
    )
}