import { Grid, Typography, Chip, useTheme } from "@mui/material"
import { LanguageConsumer } from "../../../../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBox } from "../../../../../../../Layout/CustomBox"
import { UserDetailResponseDto } from "../../../../UserEdit/Models/UserDetailResponseDto"

interface OpenRegistrationAcceptUserData {
    user: UserDetailResponseDto
}

export const OpenRegistrationAcceptUserData = (props: OpenRegistrationAcceptUserData) => {
    const theme = useTheme();

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <CustomBox elevation={0} sx={{
                        height: '100%',
                        backgroundSize: 'cover',
                        backgroundBlendMode: 'multiply',
                        backgroundColor: "transparent",
                        border: `1px solid ${theme.palette.cardBorder.main}`
                    }}>
                        <>
                            <Grid container direction={'row'} spacing={2} mt={-2}>
                                <Grid item xs={4} textAlign={'left'} my={'auto'}><Typography fontSize={13} fontWeight={700}>{getTranslatedText("RegistrationFirstName")}</Typography></Grid>
                                <Grid item xs={8} textAlign={'left'} my={'auto'}><Chip size="small" label={props.user.FirstName} /></Grid>
                            </Grid>

                            <Grid container direction={'row'} spacing={2} mt={0}>
                                <Grid item xs={4} textAlign={'left'} my={'auto'}><Typography fontSize={13} fontWeight={700}>{getTranslatedText("RegistrationLastName")}</Typography></Grid>
                                <Grid item xs={8} textAlign={'left'} my={'auto'}><Chip size="small" label={props.user.LastName} /></Grid>
                            </Grid>

                            <Grid container direction={'row'} spacing={2} mt={0}>
                                <Grid item xs={4} textAlign={'left'} my={'auto'}><Typography fontSize={13} fontWeight={700}>{getTranslatedText("RegistrationCompanyName")}</Typography></Grid>
                                <Grid item xs={8} textAlign={'left'} my={'auto'}><Chip size="small" label={props.user.CompanyName} /></Grid>
                            </Grid>

                            <Grid container direction={'row'} spacing={2} mt={0}>
                                <Grid item xs={4} textAlign={'left'} my={'auto'}><Typography fontSize={13} fontWeight={700}>{getTranslatedText("RegistrationStreet")}</Typography></Grid>
                                <Grid item xs={8} textAlign={'left'} my={'auto'}><Chip size="small" label={props.user.Street} /></Grid>
                            </Grid>

                            <Grid container direction={'row'} spacing={2} mt={0}>
                                <Grid item xs={4} textAlign={'left'} my={'auto'}><Typography fontSize={13} fontWeight={700}>{getTranslatedText("RegistrationPostalCode")}</Typography></Grid>
                                <Grid item xs={8} textAlign={'left'} my={'auto'}><Chip size="small" label={props.user.PostalCode} /></Grid>
                            </Grid>

                            <Grid container direction={'row'} spacing={2} mt={0}>
                                <Grid item xs={4} textAlign={'left'} my={'auto'}><Typography fontSize={13} fontWeight={700}>{getTranslatedText("RegistrationEmail")}</Typography></Grid>
                                <Grid item xs={8} textAlign={'left'} my={'auto'}><Chip size="small" label={props.user.Email} /></Grid>
                            </Grid>
                        </>
                    </CustomBox>
                </>
            }
        </LanguageConsumer>
    )
}