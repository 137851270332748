import { Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService"
import { NavLink } from "react-router-dom"
import { Facebook, Instagram, LinkedIn, YouTube } from "@mui/icons-material"

export const SocialMediaLinks = () => {

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
                <Grid container direction={'row'} spacing={0}>
                    <Grid item xs={12}>
                            <Tooltip title="LinkedIn" sx={{mr: 1}}>
                                <IconButton color={'footer'} component={NavLink} to='https://de.linkedin.com/company/deprag' target="_blank">
                                    <LinkedIn />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Instagram" sx={{mr: 1}}>
                                <IconButton color={'footer'} component={NavLink} to='https://www.instagram.com/deprag_amberg/' target="_blank">
                                    <Instagram />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Facebook" sx={{mr: 1}}>
                                <IconButton color={'footer'} component={NavLink} to='https://www.facebook.com/people/DEPRAG/100057522254996/' target="_blank">
                                    <Facebook />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Youtube" sx={{mr: 1}}>
                                <IconButton color={'footer'} component={NavLink} to='https://www.youtube.com/DEPRAGInternational' target="_blank">
                                    <YouTube />
                                </IconButton>
                            </Tooltip>
                    </Grid>
                </Grid>
            </>
            }
        </LanguageConsumer>
    )
}