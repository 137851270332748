import { Box, Typography, Grid, TextField, Divider, Button, useTheme } from "@mui/material"
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LanguageConsumer, getTranslation } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks";
import { logIn, resetLoading } from "./Store/LoginSlice";
import './Style/Login.css';
import { AuthenticationRequestDto } from "../../../Api/Authentication/Models/AuthenticationRequestDto";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-hot-toast";
import { validate } from "./EmailValidator/EmailValidator";
import { Helmet } from "react-helmet";
import { setAppTitle } from "../../../Store/AppSlice";
import { PasswordTextField } from "../../../Layout/Inputs/PasswordTextField";

const style = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    minWidth: '100%',
    minHeight: '100%',
    height: '100%',
    bgcolor: 'background.paper',
    borderRadius: 0,
    boxShadow: 24,
    p: 0,
    outline: 'none',
    zIndex: 999999,
    overflow: 'hidden',
    overflowY: 'auto'
};


export const Login = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const { token, loading } = useAppSelector((store) => store.authentication);
    const navigate = useNavigate();
    const isDarkMode = useAppSelector(store => store.settings.theme === 'dark');
    const theme = useTheme();

    const validateAndSubmitLoginForm = () => {
        var trimmedEmail = email.trim();
        if (validate(trimmedEmail) && password.trim().length > 0) {
            dispatch(logIn({ Email: trimmedEmail, Password: password } as AuthenticationRequestDto))
        } else {
            !validate(trimmedEmail) && toast.error(getTranslation("NoValidEmailAddress"));
            password.trim().length === 0 && toast.error(getTranslation("PasswordEmpty"))
        }
    }

    useEffect(() => {
        if(loading){
            dispatch(resetLoading());
        }
    }, [])

    useEffect(() => {
        if (token) {
            navigate('../');
        }
    }, [token])

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            validateAndSubmitLoginForm()
        }
    }

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuLoginBreadcrumb')));
    }, [location, language, languageFileWasLoaded]);

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>
                <Helmet>
                    <title>myDEPRAG ADMIN Login</title>
                </Helmet>
                <Box component={'div'} sx={style}>
                    <Grid container direction="row" sx={{ height: '100%' }} >
                        <Grid item xs={12} md={6} sx={{ px: { xs: 10, md: 10, lg: 30 }, pt: { xs: 10, md: 10, lg: 25 } }}>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'center', mb: 5 }}>

                                    <Typography fontFamily={'Dancing Script'} fontSize={40} sx={{ position: 'relative', ml: -22, }}>my</Typography>
                                    <Typography variant="h3" fontFamily={'Fira Sans'} fontWeight={900} sx={{ color: '#32992c', mt: -3 }} color='success'>DEPRAG</Typography>
                                    <Typography variant="h4" fontFamily={'Fira Sans'} fontWeight={900} sx={{ color: theme.palette.darky.main, mt: -1 }} color='success'>ADMIN</Typography>


                                </Grid>
                                <Grid item xs={12} sx={{ height: '100%' }}>
                                    <TextField
                                        inputProps={{ "data-testid": "login-email" }}
                                        autoFocus
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        label={getTranslatedText("Email")}
                                        onKeyPress={onKeyPress}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <PasswordTextField
                                        inputProps={{ "data-testid": "login-password" }}
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        label={getTranslatedText("Password")}
                                        onKeyPress={onKeyPress}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <LoadingButton color='success' loading={loading} onClick={validateAndSubmitLoginForm}
                                        fullWidth variant='contained'>{getTranslatedText("LoginButton")}</LoadingButton>
                                </Grid>

                                <Grid item xs={12}>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item justifyContent="center"
                            xs={false}
                            md={6}
                            sx={{
                                minHeight: '100%',
                                position: 'relative',
                                textAlign: 'center',
                                background: `url(./login_bg2_light.png)`,
                                backgroundSize: '100% 100%, cover',
                                backgroundPosition: 'center center',
                                backgroundColor: isDarkMode ? '#313131' : '#f8f8f8',
                                transition: 'all 0.3s ease-in-out',
                                WebkitTransition: 'all 0.3s ease-in-out',
                                overflow: 'hidden',
                            }}>

                            <Box component={'div'} sx={{ display: { xs: 'none', md: 'block' } }} className="login-banner-deprag">
                                <img alt="DEPRAG SCHULZ GMBH u. CO KG" style={{ height: 40 }} src="./deprag_b.png"></img>
                            </Box>

                        </Grid>
                    </Grid>
                </Box>
            </>
        }
    </LanguageConsumer>)
}