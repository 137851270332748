import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { setDeleteTitleAndIdExternalHelp, toggleDeletePopupExternalHelp } from "../Store/HelpOverviewSlice";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { DefaultResponse } from "../../../../../Infrastructure/Api/Model/DefaultResponse";
import { RootState, store } from "../../../../../Infrastructure/Store/store";

export const HelpOverviewDelete = () => {
    const dispatch = useAppDispatch();
    const { deletePopupVisible, deleteId, deleteTitle} = useAppSelector(store => store.externalHelp);

    const closeModal = () => {
        dispatch(setDeleteTitleAndIdExternalHelp({title: "", id: 0}));
        dispatch(toggleDeletePopupExternalHelp());
    }

    const deleteHelp = async () => {
        await fetchMyDepragApi<DefaultResponse>(null, `help/externalproducts/${deleteId}`, 'DELETE', dispatch, store.getState() as RootState, "SuccessfullyDeletedHelp");
    }

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
            <Dialog
                open={deletePopupVisible}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                   {getTranslatedText("AreYouSure")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography><span dangerouslySetInnerHTML={{__html: getTranslatedText("DoYouReallyWantToDeleteHelp", [deleteTitle])}}></span></Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="darky" onClick={closeModal}>{getTranslatedText("CommonCancel")}</Button>
                    <Button variant="outlined" color="error" onClick={async () => {
                        await deleteHelp();
                        closeModal();
                    }} autoFocus>{getTranslatedText("DeleteHelp")}</Button>
                </DialogActions>
            </Dialog>
            </>
            }
        </LanguageConsumer>
    )
}