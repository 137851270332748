import { Grid, Button } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { getTranslation, LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../../Infrastructure/Store/store";
import { DataGridSearch } from "../../../../../../Layout/DataGrid/DataGridSearch";
import { StripedDataGrid } from "../../../../../../Layout/DataGrid/StripedDataGrid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { ExpertSetting } from "../Models/ExpertSetting";
import { LoadingButton } from "@mui/lab";
import { CustomSimpleDialog } from "../../../../../../Layout/Dialogs/CustomSimpleDialog";
import toast from "react-hot-toast";

export const ExpertSettingsOverview = () => {
    const dispatch = useAppDispatch();
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const [searchValue, setSearchValue] = useState<string>("");

    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [selectedExpertSettings, setSelectedExpertSettings] = useState<ExpertSetting>()

    const { data, refetch, isFetching } = useQuery({
        queryKey: ["expertSettings"],
        queryFn: async () => await fetchMyDepragApi<ExpertSetting[]>(
            null,
            "highlevel/expertSettings",
            'GET',
            dispatch,
            store.getState() as RootState),
    });

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const columns: GridColDef[] = [
        {
            field: 'ExpertSettingKey',
            headerName: getTranslation('HighLevelMaintenanceExpertSettingsKey'),
            flex: 3,
            minWidth: 150,
        },
        {
            field: 'ExpertSettingValue',
            headerName: getTranslation("HighLevelMaintenanceExpertSettingsValue"),
            flex: 3,
            minWidth: 150,
        },
        {
            field: '',
            headerName: '',
            flex: 1.5,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<any, ExpertSetting, any>) => {
                return <LoadingButton fullWidth loading={mutation.isLoading} variant="contained" color="success" onClick={() => { setOpenDialog(true); setSelectedExpertSettings(params.row) }}>
                    {getTranslation(`${params.row.ExpertSettingKey}ButtonText`)}
                </LoadingButton>
            }
        },

    ]

    const mutation = useMutation({
        mutationFn: (expertSetting: ExpertSetting) => {
            return fetchMyDepragApi<any>
                (
                    expertSetting,
                    "highlevel/expertSettings/edit",
                    "POST",
                    dispatch,
                    store.getState() as RootState
                )
        },
        onError: () => {
            setOpenDialog(false)
            toast.error(getTranslation("ExecutionDidNotWork"));
        },
        onSuccess: () => {
            setOpenDialog(false)
            toast.success(getTranslation("ExecutionWorked"))
        }
    })

    useEffect(() => {
        refetch()
    }, [mutation.isLoading])

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12} textAlign='center'>
                <Grid container spacing={2}>
                    <Grid item xs={4} my={'auto'} sx={{ textAlign: 'left', overflowX: 'hidden', mb: 1 }}>
                        <DataGridSearch searchValue={searchValue} search={search} />
                    </Grid>
                </Grid>

                <StripedDataGrid
                    localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                    rows={data ? data.filter(x =>
                    (x.ExpertSettingKey.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                        (x.ExpertSettingValue.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                        ))) : []}
                    rowHeight={50}
                    autoHeight={true}
                    columns={columns}
                    disableColumnSelector
                    getRowId={(x) => x.ExpertSettingKey}
                    loading={isFetching || mutation.isLoading}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                />

                <CustomSimpleDialog
                    title="AreYouSure"
                    content={`Dialog${selectedExpertSettings?.ExpertSettingKey}`}
                    open={openDialog}
                    cancelAction={() => setOpenDialog(false)}
                    successAction={() => mutation.mutate(selectedExpertSettings!)}
                    handleClose={() => setOpenDialog(false)}
                    cancelText="CommonCancel"
                    successText="ExecuteExpertSetting" />
            </Grid>
        </Grid>
    )
}