import { LoadingButton } from "@mui/lab";
import { Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Divider, TextField, InputAdornment } from "@mui/material";
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBoxHeader } from "../../../../../../Layout/CustomBox";
import { TextFieldFormControl } from "../../../../../../Layout/Inputs/TextFieldFormControl";
import { CustomSwitch } from "../../../../../../Layout/Switch/CustomSwitch";
import { UserState } from "../../../Claims/Overview/Models/UserOverviewItem";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { EmailTriggerRuleForm } from "../Models/EmailTriggerRuleForm";

interface EmailTriggerRulesGeneralInformationProps {
    isSaving: boolean,
    customerShouldBeInState: UserState,
    register: UseFormRegister<EmailTriggerRuleForm>,
    errors: FieldErrors<EmailTriggerRuleForm>,
    isRuleActive: boolean,
    setIsRuleActive: React.Dispatch<React.SetStateAction<boolean>>,
    setCustomerShouldBeInState: React.Dispatch<React.SetStateAction<UserState>>,
    shouldResentMailEveryXDays: number | null,
    setShouldResentMailEveryXDays: React.Dispatch<React.SetStateAction<number | null>>
}

export const EmailTriggerRulesGeneralInformation = (props: EmailTriggerRulesGeneralInformationProps) => {
    const { isSaving, customerShouldBeInState, register, errors, isRuleActive, setIsRuleActive, setCustomerShouldBeInState, shouldResentMailEveryXDays, setShouldResentMailEveryXDays } = props;

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <CustomBoxHeader text={getTranslatedText("EmailTriggerRulesGeneralInformation")} />
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldFormControl
                                disabled={isSaving}
                                inputProps={{ "data-testid": "helpCreateOrEdit-Title" }}
                                hasEndAdornment={false}
                                labelText={getTranslatedText('RuleName')}
                                propertyName='RuleName'
                                register={register} registerOptions={{ valueAsNumber: false }} errors={errors} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>{getTranslatedText("CustomerShouldBeInState")}</InputLabel>
                                <Select
                                    label={getTranslatedText("CustomerShouldBeInState")}
                                    value={customerShouldBeInState}
                                    onChange={(e) => setCustomerShouldBeInState(e.target.value as UserState)}
                                >
                                    <MenuItem value={UserState.IsApproved}>{getTranslatedText(UserState.IsApproved)}</MenuItem>
                                    <MenuItem value={UserState.IsDeleted}>{getTranslatedText(UserState.IsDeleted)}</MenuItem>
                                    <MenuItem value={UserState.VerifyEmail}>{getTranslatedText(UserState.VerifyEmail)}</MenuItem>
                                    <MenuItem value={UserState.WaitingApproval}>{getTranslatedText(UserState.WaitingApproval)}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={getTranslatedText("ShouldResentMailEveryXDays")}
                                value={shouldResentMailEveryXDays || ''}
                                type="number"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setShouldResentMailEveryXDays(parseInt(event.target.value));
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{getTranslatedText("Days")}</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel sx={{ ml: 0 }} control={<CustomSwitch />} checked={isRuleActive} onChange={(e, value) => setIsRuleActive(value)} label={getTranslatedText("EmailTriggerIsRuleActive")} />
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}