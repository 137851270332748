import { createSlice } from '@reduxjs/toolkit';


export type DeleteLogsPopupState = {
  deletePopupVisible: boolean,
}

export const initialDeleteLogsPopup: DeleteLogsPopupState = {
  deletePopupVisible: false,
};

export const deleteLogsPopupSlice = createSlice({
  name: 'deleteLogs',
  initialState: initialDeleteLogsPopup,
  reducers: {
    toggleDeleteLogsPopup: (state) => {
      state.deletePopupVisible = !Boolean(state.deletePopupVisible)
    },
  }
});

export const { toggleDeleteLogsPopup } = deleteLogsPopupSlice.actions;

export default deleteLogsPopupSlice.reducer;
