import dayjs from "dayjs";
import * as z from "zod";
import { getTranslation } from "../../../../../../../Infrastructure/Internationalisation/TranslationService";

export const usePrereleaseCreateFormValidator = () => {

  
    const PrereleaseCreateFormValidator = z.object({
        description: z.string().max(255).nonempty(),
        validUntil:z.custom((value) => dayjs.isDayjs(value) && value.isValid())
          .refine(
            (date) => {
               const parsedDate= date as any
              const now = dayjs();
              const maxFutureDate = now.add(30, 'days');
              return !parsedDate.isBefore(now, 'day') && !parsedDate.isAfter(maxFutureDate, 'day');
            },
            {
              message:
                getTranslation("InvalidValidUntilPrereleaseDate"),
            }
          ),
    });
  
    return {
        PrereleaseCreateFormValidator
    }
  }