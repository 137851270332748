import React, { useRef } from "react";
import { Controller, FieldValues, RegisterOptions } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import { ControlledInputProps } from "./Models/ControlledInputProps";
import { lightGreen, teal, lime, orange, indigo, blue, deepPurple, red, purple } from "@mui/material/colors";
import { Typography } from "@mui/material";


const modules = {
  toolbar: {
    container: [
      [{"header": [2, 3, 4, 5, 6, false]}],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{"list": "ordered"}, {"list": "bullet"}, {"indent": "-1"}, {"indent": "+1"}],
      ["link", "image"],
      ["clean"],
      [
        {
          color: ["#32992c", "#54ac51", lightGreen[500], teal[500], lime[500], orange[500], indigo[500], blue[500], deepPurple[500], red[500], purple[500]],
        },
      ],
    ],
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
};

const formats = [
  "header",
  "bold", "italic", "underline", "strike", "blockquote",
  "list", "bullet", "indent",
  "link", "image", "width", "color",
];

interface ControlledReactQuillProps<T extends FieldValues> extends ControlledInputProps<T> {
  rules?: Pick<
    RegisterOptions<FieldValues>,
    "maxLength" | "minLength" | "validate" | "required"
  >; //https://react-hook-form.com/docs/useform/register#options
  disabled?: boolean;
  minHeight?: number; //in px
}

const ControlledReactQuill = <T extends FieldValues>(
  {
    name,
    control,
    label = "",
    rules,
    disabled,
    minHeight = 120,
  }: ControlledReactQuillProps<T>) => {
  const contentRef = useRef(null);

  return (
    <>
      {label.length !== 0 && <Typography paddingLeft={1} fontWeight={700} mb={1}>
        {label}
      </Typography>}

      <Controller
        name={name}
        control={control}
        render={({
                   field: {onChange, value, onBlur, name, ref},
                   fieldState: {invalid, isTouched, isDirty, error},
                   formState,
                 }) => (
          <ReactQuill ref={contentRef}
                      modules={modules}
                      formats={formats}
                      theme="snow"
                      value={value}
                      onChange={onChange}
                      className={"react-quill"}/>
        )}/>

      <style>{`
        .react-quill .ql-editor {
          min-height: ${minHeight}px;
        }
      `}</style>
    </>
  );
};

export default ControlledReactQuill;
