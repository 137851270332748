import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { ProductSearchResponseDto } from "../Models/ProductSearchResponseDto";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { Grid, Divider, Button, Box, Skeleton, Chip, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { LoadingButton } from "@mui/lab";
import { Download } from "@mui/icons-material";
import { ProductSearchDownloadResponseDto } from "../Models/ProductSearchDownloadResponseDto";
import { globalDateFormatOptions } from "../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";

export const ProductSearchTable = () => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(store => store.settings.language);
    const [searchValue, setSearchValue] = useState<string>("");
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const theme = useTheme()

    const { isLoading, data } = useQuery({
        queryKey: ["productSearch"],
        queryFn: async () => await fetchMyDepragApi<ProductSearchResponseDto[]>(
            null,
            "productsearch",
            'POST',
            dispatch,
            store.getState() as RootState),
    });

    const getDownloadFile = async () => {
        var response = await fetchMyDepragApi<ProductSearchDownloadResponseDto>(null, `productsearch/download`, 'GET', dispatch, store.getState() as RootState,
            "SuccessfullyDownloadedAllProductSearches");
        setIsDownloading(true);
        if (!response || !response.Content || !response.FileName) {
            setIsDownloading(false)
        }
        else {
            await downloadAttachmentFile(response.Content, response.FileName);
        }
    }

    const downloadAttachmentFile = async (fileAsBase64: string, fileName: string) => {
        var base64String = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${fileAsBase64}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = base64String;
        downloadLink.download = fileName;
        downloadLink.click();
        setIsDownloading(false);
    }

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const columns: GridColDef[] = [
        {
            field: 'Id',
            headerName: getTranslation("ProductSearchId"),
            flex: 0.1,
            minWidth: 50,
            valueGetter: (params: GridRenderCellParams<any, ProductSearchResponseDto, any>) => params.row.Id,
            renderCell: (params: GridRenderCellParams<any, ProductSearchResponseDto, any>) => (
                <Chip label={params.row.Id} />
            ),
        },
        {
            field: 'SearchValue',
            headerName: getTranslation("ProductSearchValue"),
            flex: 0.4,
            minWidth: 75,
            valueGetter: (params: GridRenderCellParams<any, ProductSearchResponseDto, any>) => params.row.SearchValue,
        },
        {
            field: 'ShownCategory',
            headerName: getTranslation("ProductSearchShownCategory"),
            flex: 0.4,
            minWidth: 140,
            valueGetter: (params: GridRenderCellParams<any, ProductSearchResponseDto, any>) => getTranslation(`ProductSearch${params.row.ShownCategory.toString()}`),
        },
        {
            field: 'Date',
            headerName: getTranslation("ProductSearchDate"),
            flex: 0.3,
            minWidth: 75,
            valueGetter: (params: GridRenderCellParams<any, ProductSearchResponseDto, any>) => new Date(params.row.Date).toLocaleDateString(language, globalDateFormatOptions)
        },
        {
            field: 'IsResellerOrAmberg',
            headerName: getTranslation("ProductSearchIsResellerOrAmberg"),
            flex: 0.5,
            minWidth: 100,
            valueGetter: (params: GridRenderCellParams<any, ProductSearchResponseDto, any>) => params.row.IsResellerOrAmberg,
            renderCell: (params: GridRenderCellParams<any, ProductSearchResponseDto, any>) => {
                return params.row.IsResellerOrAmberg ? <Chip label={getTranslation("ProductSearchIsResellerOrAmbergTrue")} sx={{ bgcolor: theme.palette.success.main }} />
                    : <Chip label={getTranslation("ProductSearchIsResellerOrAmbergFalse")} />
            }
        },
    ];


    return (
        <>
            <LanguageConsumer>
                {({ getTranslatedText }) =>
                    <>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} textAlign='center'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{ textAlign: 'left' }}>

                                        <div
                                            dangerouslySetInnerHTML={{ __html: getTranslatedText("ProductSearchText") }}
                                        />

                                        <Divider sx={{ my: 3, mb: 2 }} />
                                    </Grid>

                                    <Grid item xs={4} my={'auto'} sx={{ textAlign: 'left', overflowX: 'hidden' }}>
                                        <DataGridSearch searchValue={searchValue} search={search} />
                                    </Grid>

                                    <Grid item xs={8} my={'auto'} sx={{ textAlign: 'right', overflowX: 'hidden' }}>
                                        <LoadingButton variant="contained" color="success" startIcon={<Download />} sx={{ ml: 1 }} loading={isDownloading}
                                            onClick={() => getDownloadFile()}>
                                            {getTranslatedText("DownloadProductSearch")}
                                        </LoadingButton>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <Box component={'div'} sx={{ width: '100%' }}>
                                        <StripedDataGrid
                                            localeText={language === "de" ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                            rows={data ? data.filter(x =>
                                            (x.SearchValue.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                x.Id.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                x.ShownCategory.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                x.Date.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
                                            ) : []}
                                            autoHeight={true}
                                            columns={columns}
                                            pageSize={8}
                                            rowsPerPageOptions={[8]}
                                            checkboxSelection={false}
                                            disableColumnFilter={false}
                                            disableColumnMenu={false}
                                            disableSelectionOnClick={true}
                                            disableVirtualization={false}
                                            hideFooter={false}
                                            getRowId={(x) => x.Id}
                                            getRowClassName={(params) =>
                                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                            }
                                            components={{
                                                LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                            }}
                                            loading={isLoading}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
            </LanguageConsumer>
        </>
    )
}