import { Button, Grid, IconButton, SwipeableDrawer, Typography, useTheme } from "@mui/material"
import { DropzoneArea } from "react-mui-dropzone";
import { Close, Upload } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { licenseDeactivate, licenseDeactivateHighlevel, resetDeactivationBooleans } from "./Store/LicenseDeactivationSlice";
import { getTranslation, LanguageConsumer } from "../../../../../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../Infrastructure/Store/hooks";
import { fileToBase64 } from "../../../../../../../../../Infrastructure/Utils/FileToBase64";
import "./Style/LicenseDeactivationDrawer.css"
import { MyDepragClaim } from "../../../../../../../Highlevel/Claims/Details/Models/ClaimsEditResponseDto";

interface LicenseDeactivationDrawerProps {
    open: boolean;
    onClose: () => void;
    usedLicenceId: number;
}

export const LicenseDeactivationDrawer = (props: LicenseDeactivationDrawerProps) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const [deactivationFile, setDeactivationFile] = useState<File | null>(null);
    const isDeactivating = useAppSelector(store => store.licenseDeactivation.loading);
    const success = useAppSelector(store => store.licenseDeactivation.success);    
    const claims = useAppSelector(store => store.authentication.myDepragClaims);
    const shouldShowManualDeactivation = claims.some(c => MyDepragClaim[c] === MyDepragClaim.HighlevelClaim);
    const [dropKey, setDropKey] = useState<number>(0);

    const handleFileDrop = (files: File[]) => {
        setDeactivationFile(files[0]);
    }

    const closeAndReset = () => {
        dispatch(resetDeactivationBooleans())
        setDeactivationFile(null);
        setDropKey(oldKey => oldKey + 1);
        props.onClose();
    }

    const uploadDeactivationKeyHighlevel = async () => {
            dispatch(licenseDeactivateHighlevel({UsedLicenceId: props.usedLicenceId, RequestFile: "", RequestFileName: ""}))
    } 

    const uploadDeactivationKey = async () => {
        if(deactivationFile) {
            var fileAsBase64 = await fileToBase64(deactivationFile);
            var onlyBase64 = fileAsBase64.split(",")[1] ? fileAsBase64.split(",")[1] : "";

            dispatch(licenseDeactivate({UsedLicenceId: props.usedLicenceId, RequestFile: onlyBase64, RequestFileName: deactivationFile?.name}))
        }
    } 

    useEffect(() => {
        if(success && props.open){
            dispatch(resetDeactivationBooleans())
            setDeactivationFile(null);
            setDropKey(oldKey => oldKey + 1);
            props.onClose();
            toast.success(getTranslation("SuccessfullyDeactivatedLicense"))
        }
    }, [success, props.open])

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <SwipeableDrawer
                        sx={{ zIndex: 100003 }}
                        PaperProps={{ sx: { p: 3, pt: 3, width: { xs: '95%', md: '70%', lg: '40%' }, maxWidth: '100%', background: theme.palette.background.default, height: '100%', borderTopLeftRadius: 20, borderBottomLeftRadius: 20 } }}
                        anchor={'right'}
                        open={props.open}
                        onOpen={console.log}
                        onClose={closeAndReset}
                    >
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={8}>
                                <Typography variant="h5">
                                    {getTranslatedText("DeactivateLicenseTitle")}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} sx={{textAlign: 'right'}}>
                               <IconButton onClick={closeAndReset}>
                                    <Close />
                               </IconButton>
                            </Grid>

                            <Grid item xs={12} sx={{textAlign: 'justify'}}>
                                <Typography>
                                    {getTranslatedText("DeactivateLicenseDescription")}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container direction={'row'} spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sx={{ height: '100%', mt: 2 }}>
                                <DropzoneArea
                                    key={dropKey}
                                    onChange={handleFileDrop}
                                    filesLimit={1}
                                    maxFileSize={524288}
                                    clearOnUnmount={false}
                                    dropzoneText={getTranslatedText("DragAndDropDeactivationKeyHere")}
                                    showPreviews={true}
                                    previewText={getTranslatedText("PreviewDeactivationKey")}
                                    showPreviewsInDropzone={false}
                                    showAlerts={false}
                                    onDropRejected={(rejectedFiles) => {
                                        if(rejectedFiles[0].size > 524288){
                                            toast.error(getTranslatedText("FileSizeExceeded"))
                                        }
                                    }}
                                    useChipsForPreview={true}
                                    dropzoneParagraphClass="dropzone-text"
                                    dropzoneClass="dropzone"
                                    Icon={() => <IconButton color="success" size="large"><Upload /></IconButton>}

                                />
                            </Grid>
                        </Grid>

                        <Grid container direction={'row'} spacing={2} sx={{mt: 2}}>
                            <Grid item xs={12}>
                                <LoadingButton loading={isDeactivating} onClick={uploadDeactivationKey} variant="contained" fullWidth color="error" disabled={!deactivationFile}>{getTranslatedText("DeactivateLicense")}</LoadingButton>
                            </Grid>
                        </Grid>

                        {shouldShowManualDeactivation &&                        
                              <Grid container direction={'row'} spacing={2} sx={{mt: 2}}>
                              <Grid item xs={12}>
                                  <LoadingButton loading={isDeactivating} onClick={uploadDeactivationKeyHighlevel} variant="contained" fullWidth color="error" 
                                  >{getTranslatedText("DeactivateLicenseManual")}</LoadingButton>
                              </Grid>
                          </Grid>
                        }
                        
                    </SwipeableDrawer>
                </>
            }
        </LanguageConsumer>
    )
}