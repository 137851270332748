import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useAppDispatch } from "../../../../../Infrastructure/Store/hooks";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { DefaultResponse } from "../../../../../Infrastructure/Api/Model/DefaultResponse";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { CustomerDeleteModel } from "../Models/CustomerDeleteModel";
import { useEffect } from "react";


interface IAllCustomersDeleteDialog {
    open: boolean;
    onClose: () => void;
    customerId: number;
    refetch: () => void
}

export const AllCustomersDeleteDialog = (props: IAllCustomersDeleteDialog) => {
    const dispatch = useAppDispatch()

    const onDelete= async() => {
        await fetchMyDepragApi<DefaultResponse>({Id: props.customerId} as CustomerDeleteModel, "customers/delete", "POST", 
        dispatch, store.getState() as RootState, "SuccessfullyDeletedCustomerVerification")

        props.onClose()
        props.refetch()
    }

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>
                <Dialog
                    open={props.open}
                    keepMounted
                    onClose={props.onClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{getTranslatedText("DeletingThisCustomer")}</DialogTitle>
                    <DialogContent>
                        {getTranslatedText("AreYouSure")} 
                        <br></br>
                        {getTranslatedText("WantToDeleteThisCustomerID")}  {props.customerId}
                    </DialogContent>

                    <DialogActions>
                        <Button color="error" variant="contained" fullWidth size="large" onClick={onDelete}>{getTranslatedText("Delete")}</Button>
                    </DialogActions>
                </Dialog>
            </>
        }
    </LanguageConsumer>
    )
}