import { Box, Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LanguageConsumer, getTranslation } from "../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../../Store/AppSlice";
import { CustomSwitch } from "../../../../Layout/Switch/CustomSwitch";
import { CustomBox } from "../../../../Layout/CustomBox";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../Infrastructure/Api/ApiBaseCall";
import { store } from "../../../../Infrastructure/Store/store";
import { ReminderGetResponseDto } from "./Models/ReminderGetResponseDto";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { ReminderSetRequestDto } from "./Models/ReminderSetRequestDto";

export const SettingsReminder = () => {
    const theme = useTheme();
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
    const dispatch = useAppDispatch();
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const [openRegistrationValue, setOpenRegistrationValue] = useState<boolean>(false);

    const { data } = useQuery({
        queryKey: ["reminderSettingsEdit"],
        queryFn: async () => await fetchMyDepragApi<ReminderGetResponseDto>(
            null,
            'settings/remindersettings/load',
            'GET',
            dispatch,
            store.getState())
    });

    useEffect(() => {
        if(data){
            setOpenRegistrationValue(data.ReminderIsActive);
        }
    }, [data]);

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('SettingsReminderTitle')));
    }, [location, language, languageFileWasLoaded, dispatch]);

    const handleSaveSettings = async () => {
        const dataToPost = {ReminderIsActive: openRegistrationValue} as ReminderSetRequestDto; 
        await fetchMyDepragApi(dataToPost, "settings/remindersettings/save", "POST", dispatch, store.getState(), "SuccessfullySavedSettings")
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <CustomBox elevation={0} sx={{
                            mx: 3,
                            height: '100%',
                            backgroundSize: 'cover',
                            backgroundBlendMode: 'multiply',
                            backgroundColor: isDarkMode ? "transparent" : "#fff",
                            border: `1px solid ${theme.palette.cardBorder.main}`
                        }}>
                            <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                <div dangerouslySetInnerHTML={{ __html: getTranslatedText("SettingsReminderInfo") }} />
                                <Divider sx={{ my: 3, mb: 2 }} />
                            </Grid>
                            <Box display={"flex"}>
                                <Typography mr={2}>{getTranslatedText("ReminderOpenRegistrationsLabel")}</Typography>
                                <CustomSwitch checked={openRegistrationValue} onClick={() => setOpenRegistrationValue(prevValue => !prevValue)} />
                            </Box>
                        </CustomBox>
                    </Grid>
                    <Grid item xs={12} textAlign={"right"} mr={3}>
                        <Button variant="contained" color="success" startIcon={<SaveOutlinedIcon />} onClick={handleSaveSettings}>{getTranslatedText("SaveSettings")}</Button>
                    </Grid>
                </Grid>
            }
        </LanguageConsumer >
    );
}