import { createSlice } from '@reduxjs/toolkit';

export type CockpitCloudState = {
    showAllAreas: boolean
}

export const initialStateCockpitCloud: CockpitCloudState = {
    showAllAreas: false
};

export const cockpitCloudSlice = createSlice({
  name: 'cockpitCloud',
  initialState: initialStateCockpitCloud,
  reducers: {
    toggleShowAllAreas: (state) => {
      state.showAllAreas = !Boolean(state.showAllAreas)
    }
  }
});

export const { toggleShowAllAreas } = cockpitCloudSlice.actions;

export default cockpitCloudSlice.reducer;
