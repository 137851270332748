import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { RootState, store } from "../../../../../../Infrastructure/Store/store";
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks";
import { toggleDeleteLogsPopup } from "../Store/DeleteLogsPopupSlice";

export const DeleteLogsPopup = () => {
    const dispatch = useAppDispatch();
    const { deletePopupVisible } = useAppSelector(store => store.deleteLogs);

    const handleDelete = async () => {
        await fetchMyDepragApi(null, "highlevel/logs/deleteall", 'DELETE', dispatch, store.getState() as RootState);
        closeDialog();
    }

    const closeDialog = () => {
        dispatch(toggleDeleteLogsPopup());
    }
    
    return(
        <>
            <LanguageConsumer>
            {({getTranslatedText}) => 
                <Dialog open={deletePopupVisible} >
                    <DialogTitle >
                        {getTranslatedText("AreYouSure")}
                    </DialogTitle>

                    <DialogContent >
                        <DialogContentText >
                            {getTranslatedText("HighLevelLogsDeleteAllLogsContent")}
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button variant="outlined" color="darky" onClick={closeDialog}>{getTranslatedText("CommonCancel")}</Button>
                        <Button variant="outlined" color="error" onClick={async () => await handleDelete()} autoFocus>
                            {getTranslatedText("DeleteLogs")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            </LanguageConsumer>
        </>
    );
}