import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { toggleResellerPopup } from "../Store/DeleteResellerPopupSlice";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";

export interface DeleteResellerPopupProps{
    idToDelete: number
}

export const DeleteResellerPopup = (props: DeleteResellerPopupProps) => {
    const dispatch = useAppDispatch();
    const { deletePopupVisible } = useAppSelector(store => store.deleteReseller);

    const handleDelete = async () => {
        await fetchMyDepragApi(
        {Id: props.idToDelete},
        "resellers/delete/",
        'POST',
        dispatch,
        store.getState() as RootState);

        closeDialog();
    }

    const closeDialog = () => {
        dispatch(toggleResellerPopup());
    }
    
    return(
        <>
            <LanguageConsumer>
            {({getTranslatedText}) => 
                <Dialog open={deletePopupVisible} >
                    <DialogTitle >
                        {getTranslatedText("AreYouSure")}
                    </DialogTitle>

                    <DialogContent >
                        <DialogContentText >
                            {getTranslatedText("HighLevelMaintenanceDeleteReseller")}
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button variant="outlined" color="darky" onClick={closeDialog}>{getTranslatedText("CommonCancel")}</Button>
                        <Button variant="outlined" color="error" onClick={async () => await handleDelete()} autoFocus>
                            {getTranslatedText("DeleteReseller")}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            </LanguageConsumer>
        </>
    );
}