import { Close } from "@mui/icons-material";
import { IconButton, SwipeableDrawer, useTheme } from "@mui/material"
import { LanguageConsumer } from "../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../Infrastructure/Store/hooks";
import { toggleMobileDrawer } from "../Store/AppSlice";
import { MobileAndDesktopDrawerContent } from "./DrawerContent.tsx/MobileAndDesktopDrawerContent";
import { MobileToolbarInDrawer } from "./DrawerContent.tsx/MobileToolbarInDrawer";

export const MobileDrawerLayout = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const open = useAppSelector(store => store.global.mobileDrawerOpen);

    const closeMobileDrawer = () => {
        dispatch(toggleMobileDrawer())
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <SwipeableDrawer
                        sx={{ zIndex: 1000 }}
                        PaperProps={{ sx: { p: 1, pt: 1, minWidth: '50%', maxWidth: '100%', background: theme.palette.background.default, height: '100%', borderTopLeftRadius: 20, borderBottomLeftRadius: 20 } }}
                        anchor={'right'}
                        open={open}
                        onOpen={console.log}
                        onClose={closeMobileDrawer}
                    >
                        <IconButton onClick={closeMobileDrawer} sx={{ position: 'absolute', top: 30, right: 10, zIndex: 10001 }}>
                            <Close />
                        </IconButton>

                        <MobileAndDesktopDrawerContent />

                       <MobileToolbarInDrawer />

                    </SwipeableDrawer>
                </>
            }
        </LanguageConsumer>
    )
}