import { FormControlLabel, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { useLocation } from "react-router-dom";
import { useRedirect } from "../../../Infrastructure/Hooks/Global/useRedirect";
import { LanguageConsumer, getTranslation } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../Store/AppSlice";
import "./Style/Dashboard.css";
import { HeroTopBanner } from "../../../Layout/HeroTopBanner/HeroTopBanner";
import { StatusCards } from "./Components/StatusCards/StatusCards";
import { CustomSwitch } from "../../../Layout/Switch/CustomSwitch";
import { toggleShowAllDashboard } from "./Store/DashboardSlice";
import { MyDepragClaim } from "../Highlevel/Claims/Details/Models/ClaimsEditResponseDto";
import { ResellerStatistic } from "./Components/ResellerStatistic/ResellerStatistic";
import { ResellerCardsAndStatistic } from "./Components/ResellerCardsAndStatistic/ResellerCardsAndStatistic";


export const Dashboard = () => {
  useRedirect();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const language = useAppSelector(store => store.settings.language);
  const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
  const { showAll } = useAppSelector(store => store.dashboard);
  const { isDepragAmbergMember, isReseller } = useAppSelector(store => store.authentication);
  const claims = useAppSelector(store => store.authentication.myDepragClaims);

  const shouldShowCards = claims.some(c => MyDepragClaim[c] === MyDepragClaim.BackofficeCustomers);
  const showResellerCards = true;

  useEffect(() => {
    dispatch(setAppTitle(getTranslation("MainMenuDashboardBreadcrumb")));
  }, [location, language, languageFileWasLoaded, dispatch]);


  return (
    <LanguageConsumer>
      {({ getTranslatedText }) =>
        <>
          <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
            <Grid item xs={12} sx={{ mx: 3 }}>
              <HeroTopBanner sx={{ mx: 0 }}
                bgImageUrl="./login_bg2_light.png"
                minHeight={250}
                blurIntensity={4}
                headLine={<>
                  <Typography variant="h2" fontFamily={"Fira Sans"} fontWeight={900}
                    sx={{ fontSize: { xs: 40, sm: 70 } }}
                    color="success">BACKOFFICE</Typography>
                </>}                
                topRightComponent={
                  (isDepragAmbergMember && !showResellerCards) && (
                    <FormControlLabel control={<CustomSwitch />} checked={showAll}
                      onChange={(e, value) => dispatch(toggleShowAllDashboard())}
                      label={getTranslatedText("ShowAll")} />)
                }
              />
            </Grid>

            {shouldShowCards &&
              <>
                <Grid item xs={12} sx={{ mx: 3 }}>
                  {showResellerCards ?
                    <ResellerCardsAndStatistic />
                    : <StatusCards />}
                </Grid>
              </>
            }
          </Grid>
        </>
      }
    </LanguageConsumer>
  );
};
