
import { createSlice } from '@reduxjs/toolkit';

export type UsersState = {
    showAllUsers: boolean
}

export const initialStateUsers: UsersState = {
    showAllUsers: false
};

export const usersSlice = createSlice({
  name: 'users',
  initialState: initialStateUsers,
  reducers: {
    toggleShowAllUsers: (state) => {
      state.showAllUsers = !Boolean(state.showAllUsers)
    }
  }
});

export const { toggleShowAllUsers } = usersSlice.actions;

export default usersSlice.reducer;
