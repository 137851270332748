import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateTicketArticleRequestDto } from '../Models/CreateTicketArticleRequestDto';
import { fetchMyDepragApi } from '../../../../../../Infrastructure/Api/ApiBaseCall';
import { RootState } from '../../../../../../Infrastructure/Store/store';
import { TicketActionsRequestDto } from '../Models/TicketActionsAssignRequestDto';


const emptyFormState = {    
    ticketId: 0,
    subject: "",
    body: "",
    attachments: []
};

export interface CreateTicketArticleAttachmentsForm {
    fileName: string,
    data: string,
    mimetype: string
}

export interface CreateTicketArticleForm {
    ticketId: number,
    subject: string,
    body: string,
    attachments: CreateTicketArticleAttachmentsForm[]
}   

export type CreateTicketArticleState = {
    form: CreateTicketArticleForm,
    success: boolean,
    loading: boolean,
    error: boolean,
    errorMessage: string,
    formIsReadyToSend: boolean,
    updateStateLoading: boolean,
}    

export const initialStateTicketArticle: CreateTicketArticleState = {
    form: emptyFormState,
    success: false,
    loading: false,    
    error: false,
    errorMessage:"",
    formIsReadyToSend: false,
    updateStateLoading: false
};

export const requestCreateTicketArticle = createAsyncThunk('requestCreateTicketArticle', async (request: CreateTicketArticleForm, thunkAPI) => {
    var response = await fetchMyDepragApi<CreateTicketArticleRequestDto>(request, "ticketarticles", 'POST', thunkAPI.dispatch, thunkAPI.getState() as RootState);
    return response;
});

export const requestCloseTicket = createAsyncThunk('requestCloseTicket', async (request: TicketActionsRequestDto, thunkAPI) => {
    var response = await fetchMyDepragApi<{}>(request, "ticketactions/close", 'POST', thunkAPI.dispatch, thunkAPI.getState() as RootState);
    return response;
});

export const requestReopenTicket = createAsyncThunk('requestReopenTicket', async (request: TicketActionsRequestDto, thunkAPI) => {
    var response = await fetchMyDepragApi<{}>(request, "ticketactions/reopen", 'POST', thunkAPI.dispatch, thunkAPI.getState() as RootState);
    return response;
});

export const serviceRequestsDetailSlice = createSlice({
    name: 'serviceRequestsDetail',
    initialState: initialStateTicketArticle,
    reducers: {             
        setValidationFormData: (state, action) => {             
            state.form.ticketId = action.payload.ticketId
            state.form.subject = action.payload.subject
            state.form.body = action.payload.body
        },    
        setAttachmentsCallOnlyOnceBeforeSubmit: (state,action) => {
            state.form.attachments = action.payload
            state.formIsReadyToSend = true; 
        }, 
        restFormToInitialState: (state) => {
            state.form = emptyFormState;
            state.error = false;
            state.errorMessage = "";
            state.success = false;
            state.loading = false;    
            state.formIsReadyToSend = false;    
        },
        resetOnlyForm: (state) => {
            state.form = emptyFormState;
        }
    },
    extraReducers: builder => {
      builder.addCase(requestCreateTicketArticle.fulfilled, (state, action) => {
        state.success = true;
        state.loading = false;
        state.error = false;
        state.formIsReadyToSend = false;
        state.errorMessage = "";
      }).addCase(requestCreateTicketArticle.pending, (state) => {
          state.loading = true;
      }).addCase(requestCreateTicketArticle.rejected, (state, action) => {
          state.loading = false;
          state.success = false;
          state.error = true;
          state.formIsReadyToSend = false;
          state.errorMessage = action.error.message as string;
      }).addCase(requestCloseTicket.fulfilled, (state) => {
        state.updateStateLoading = false;
      }).addCase(requestCloseTicket.pending, (state) => {
          state.updateStateLoading = true;
      }).addCase(requestCloseTicket.rejected, (state) => {
          state.updateStateLoading = false;
      }).addCase(requestReopenTicket.fulfilled, (state) => {
        state.updateStateLoading = false;
      }).addCase(requestReopenTicket.pending, (state) => {
          state.updateStateLoading = true;
      }).addCase(requestReopenTicket.rejected, (state) => {
          state.updateStateLoading = false;
      })
    }
  });
  
  export const { 
      resetOnlyForm,      
      setValidationFormData,     
      setAttachmentsCallOnlyOnceBeforeSubmit,
      restFormToInitialState
  } = serviceRequestsDetailSlice.actions;
  
  export default serviceRequestsDetailSlice.reducer;