import { Box, Grid, IconButton, Modal } from "@mui/material";
import { MediaType, toggleVideoAndImageRecorder } from "./Store/VideoAndImageRecorderSlice";
import { Close } from "@mui/icons-material";
import { ImageRecorder } from "./Components/ImageRecorder";
import { VideoRecorderView } from "./Components/VideoRecorder";
import { useAppDispatch, useAppSelector } from "../../../../../../../Infrastructure/Store/hooks";
import { LanguageConsumer } from "../../../../../../../Infrastructure/Internationalisation/TranslationService";

const style = {
    position: 'absolute' as 'absolute',
    minWidth: '90%',
    minHeight: '90%',
    height: '95%',
    width: '90%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    bgcolor: 'background.paper',
    borderRadius: 3,
    boxShadow: 24,
    p: 0,
    outline: 'none',
    zIndex: 9999999,
    overflow: 'hidden',
};

interface VideoAndImageRecorderProps {
    addFileToList: (fileList: File) => void;
}

export const VideoAndImageRecorder = (props: VideoAndImageRecorderProps) => {
    const dispatch = useAppDispatch();
    const { open, type } = useAppSelector(store => store.videoAndImageRecorder);


    const handleClose = () => {
        dispatch(toggleVideoAndImageRecorder())
    }



    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ zIndex: 99999 }}
                    >
                        <Box component={'div'} sx={style}>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                    <IconButton onClick={handleClose} size="large" sx={{ m: 2, zIndex: 999999999 }}>
                                        <Close />
                                    </IconButton>
                                </Grid>

                                <Grid item xs={12}>
                                    {type === MediaType.Image &&
                                        <ImageRecorder addFileToList={props.addFileToList} />
                                    }

                                    {type === MediaType.Video &&
                                        <VideoRecorderView addFileToList={props.addFileToList} />
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                </>
            }
        </LanguageConsumer>
    )
}