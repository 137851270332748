import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom"
import { LanguageConsumer, getTranslation } from "../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Store/hooks";
import { useEffect, useState } from "react";
import { setAppTitle } from "../../../../Store/AppSlice";
import { Button, Grid, Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { RootState, store } from "../../../../Infrastructure/Store/store";
import { fetchMyDepragApi } from "../../../../Infrastructure/Api/ApiBaseCall";
import { CourseHeader } from "./Components/CourseHeader";
import { OnlineTrainingDetailResponseDto } from "./Models/OnlineTrainingDetailResponseDto";
import { CourseInformation } from "./Components/CourseInformation";
import { CourseDownload } from "./Components/CourseDownload";
import "./Style/CourseEdit.css";
import { CourseUpload } from "./Components/CourseUpload";

export const CourseEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const [idToHandle, setIdToHandle] = useState<number>(id !== undefined ? parseInt(id) : 0);

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuVideoCourseEditBreadcrumb')));
    }, [location, language, languageFileWasLoaded, dispatch]);

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["courseUserEdit"],
        queryFn: async () => await fetchMyDepragApi<OnlineTrainingDetailResponseDto>(
             null,
            'onlinetraining/courses/' + idToHandle,
            'GET',
            dispatch,
            store.getState() as RootState),
        enabled: idToHandle > 0,
        cacheTime: 0
    });

    useEffect(() => {
        if(idToHandle > 0 && id === '0'){
            navigate(`/videoCourses/edit/${idToHandle}`)
        }
    },[idToHandle])


    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
                  <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
                        <Grid item xs={12} mx={3}>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Button color="darky" sx={{zIndex: 9999999}} component={NavLink} to={'/videoCourses/overview'} variant="outlined">{getTranslatedText("Prev")}</Button>
                                </Grid>
                               {idToHandle > 0 && <>
                                <Grid item xs={12} md={8} sx={{ mt: 1 }}>
                                    {data && !isLoading ? <CourseHeader 
                                    title={data.Title}
                                    description={data.Description} 
                                    thumbnail={data.ThumbnailUrl}
                                    moduleCount={data.ModuleCount}
                                    chapterCount={data.ChapterCount}
                                    additionalContentCount={data.AdditionalContentCount} /> : <Skeleton sx={{mt: -8}} width={'100%'} height={400} />}
                                </Grid>

                                <Grid item xs={12} md={4} sx={{mt: 1}}>
                                {data && !isLoading ? <CourseInformation informations={data} /> : <Skeleton sx={{mt: -8}} width={'100%'} height={400} />}
                                </Grid>
                                </>}

                                <Grid item xs={12} md={6} sx={{mt: 1}}>
                                   <CourseDownload courseId={idToHandle} /> 
                                </Grid>
                                

                                <Grid item xs={12} md={6} sx={{mt: 1}}>
                                    <CourseUpload courseId={idToHandle} setIdToHandle={setIdToHandle} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            </>
            }
        </LanguageConsumer>
    )
}