import { whitelistToPersist } from "../../Store/persist";
import { RESET_STORE_ACTION, persistStoreKey, store } from "../../Store/store";
import { persistor } from "../../Store/store";

const usePersistStore = () => {    
    const getStoreElementByKey = (key: string, newStoreAsJson: {}) : {} => {        
        return JSON.parse(newStoreAsJson[key]);         
    }

    const repairPersistStoreIfNeeded = () => {
        if (!localStorage[`persist:${persistStoreKey}`]) {
            return;
        }

        let newStoreAsJson = JSON.parse({ ...localStorage }[`persist:${persistStoreKey}`]);
        let hasInvalidStoreData = false;

        whitelistToPersist.forEach(persistedElement => {

            const storeElement = getStoreElementByKey(persistedElement.key, newStoreAsJson);

            const allPropNamesFromInitialState = Object.getOwnPropertyNames(persistedElement.initialState);

            allPropNamesFromInitialState.forEach(propName => {
                if (Object.hasOwn(storeElement, propName)) {
                    return;
                }
                hasInvalidStoreData = true;
            });
        });

        if (hasInvalidStoreData) {
            store.dispatch(RESET_STORE_ACTION);
        }
    }

    return { repairPersistStoreIfNeeded }
}

export default usePersistStore;