import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

type CustomBorderLinearProgressProps = {
  customColor: string,
  customBackgroundColor?: string
}


export const CustomBorderLinearProgress = styled(LinearProgress, { shouldForwardProp: (prop) => prop !== 'customColor' && prop !== 'customBackgroundColor' })<CustomBorderLinearProgressProps>(({ theme, customColor, customBackgroundColor }) => ({
    height: 20,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: customBackgroundColor ? customBackgroundColor : theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundColor: customColor,
    },
  }));