import { Close } from "@mui/icons-material";
import { Chip, Grid, IconButton, SwipeableDrawer, Typography, useTheme } from "@mui/material"
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBox } from "../../../../../Layout/CustomBox";
import { UserMailHistoryItem } from "../Models/UserDetailResponseDto"
import { UserEditOutgoingMailHistoryTable } from "./UserEditOutgoingMailHistoryTable"

export interface UserEditOutgoingMailSideDrawerProps
{
    open: boolean;
    onClose: () => void;
    outgoingMailHistory: UserMailHistoryItem[] | undefined,
    hasDeniedToReceiveMails: boolean | undefined
}

export const UserEditOutgoingMailSideDrawer = (props: UserEditOutgoingMailSideDrawerProps) => {

    const theme = useTheme();

    return(    
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>

                    <SwipeableDrawer
                        sx={{ zIndex: 100003 }}
                        PaperProps={{ sx: { p: 3, pt: 3, width: { xs: '95%', md: '70%', lg: '40%' }, maxWidth: '100%', background: theme.palette.background.default, height: '100%', borderTopLeftRadius: 20, borderBottomLeftRadius: 20 } }}
                        anchor={'right'}
                        open={props.open}
                        onOpen={console.log}
                        onClose={props.onClose}
                    >                

                    <Grid container direction={'row'} spacing={2}>
                        <Grid item xs={8}>
                            <Typography variant="h5">
                                {getTranslatedText("OutgoingMailOverview")}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{textAlign: 'right'}}>
                            <IconButton onClick={props.onClose}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>   

                    <CustomBox elevation={0} sx={{
                        mt: 2,
                        border: `1px solid ${theme.palette.cardBorder.main}`
                    }}>
                        <Grid item xs={12}>
                            <Chip size="medium" color={props.hasDeniedToReceiveMails ? "error" : "success"} label={props.hasDeniedToReceiveMails ? getTranslatedText('HasDeniedToReceiveMails') : getTranslatedText('HasAllowedToReceiveMails')} />          
                        </Grid>              

                        <Grid item xs={12} mt={4}>
                            <Typography variant="h6">{getTranslatedText('UserEditOutgoingMailHistoryTableTitle')}</Typography>                                                
                        </Grid>              
                        <Grid item xs={12} >
                            <UserEditOutgoingMailHistoryTable data={props.outgoingMailHistory} />
                        </Grid>
                    </CustomBox>                        
                    </SwipeableDrawer>                    
                </>
            }
        </LanguageConsumer>
    )
}