import { useRef, useState } from "react";
import { Box, IconButton, TextField, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { AddCircle, ReplayRounded } from "@mui/icons-material";
import { FiCircle } from "react-icons/fi";
import { closeVideoAndImageRecorder, toggleVideoAndImageRecorder } from "../Store/VideoAndImageRecorderSlice";
import Webcam from "react-webcam";
import { toast } from "react-hot-toast";
import { useAppDispatch } from "../../../../../../../../Infrastructure/Store/hooks";
import { base64ToFile } from "../../../../../../../../Infrastructure/Utils/Base64ToFile";
import { LanguageConsumer } from "../../../../../../../../Infrastructure/Internationalisation/TranslationService";

interface ImageRecorderProps {
    addFileToList: (fileList: File) => void;
}

export const ImageRecorder = (props: ImageRecorderProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const webcamRef = useRef(null);
    const [image, setImage] = useState<string | null>(null);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [fileName, setFileName] = useState<string>("");

    const addImageToList = async () => {
        if (image) {
            let newDate = new Date();
            let name = fileName.trim().length > 0 ? fileName : `Image_${(newDate.toJSON().slice(0, 10))}-${newDate.getHours()}_${newDate.getMinutes()}_${newDate.getSeconds()}`;
            let file = await base64ToFile(image, `${name}.png`, true);
            props.addFileToList(file);
            handleClose();
        }
    }

    const handleClose = () => {
        setImage(null)
        setFileName("");
        dispatch(toggleVideoAndImageRecorder())
    }

    const videoConstraints = {
        facingMode: "enviroment",
    };

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    {!image ? <>
                        <Webcam height={'100%'} className="my-webcam" onUserMediaError={() => {
                            setImage(null);
                            dispatch(closeVideoAndImageRecorder())
                            toast.error(getTranslatedText("NoCameraFoundOrNoPermissions"), { id: "NoCameraFoundOrNoPermissions" });
                        }} ref={webcamRef} videoConstraints={videoConstraints}  style={{
                            position: "absolute",
                              textAlign: "center",
                              zIndex: 8,
                              right:0,
                                height: "calc(100vh - 260px)",
                               width: "100%",
                               objectFit: "contain",
                            }} />

                        <IconButton onClick={() => {
                            if (webcamRef && webcamRef.current) {
                                setImage((webcamRef.current as any).getScreenshot())
                                let newDate = new Date();
                                setFileName(`Image_${(newDate.toJSON().slice(0, 10))}-${newDate.getHours()}_${newDate.getMinutes()}_${newDate.getSeconds()}`)
                            }
                        }} size="large" sx={{ zIndex: 999999999, position: 'absolute', bottom: 30, left: '50%', transform: 'translate(-50%, 0)', backgroundColor: 'rgba(0,0,0,0.3)' }}>
                            <FiCircle color="#f03e3e" size={60} />
                        </IconButton>
                    </>
                        :
                        <>
                            <Box component={'div'} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 100, textAlign: 'left', pt: 1, pl: 3 }}>
                                <TextField sx={{width: '90%'}} InputProps={{ disableUnderline: true, sx: { fontSize: 32, p: 1 } }} size="medium" variant="standard" value={fileName} onChange={(e) => setFileName(e.target.value)}></TextField>
                            </Box>

                            <img src={image} style={{ borderRadius: 3, position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', maxHeight: '100%', width: isMobile ? '100%' : '50%', height: 'auto', }} alt='Taken photo' />
                            
                            <Box component={'div'} sx={{position: 'absolute', bottom: 0, left: 0, width: '100%', height: 150, textAlign: 'center', pt: 6}}>
                                <Tooltip PopperProps={{sx: {zIndex: 9999999}}} title={getTranslatedText("RecordNewImage")}>
                                <IconButton size="large" sx={{background: theme.palette.footer.light}} onClick={() => {
                                    setImage(null)
                                }}>
                                    <ReplayRounded sx={{fontSize: 40, color:'#444'}} />
                                </IconButton>
                                </Tooltip>

                                <Tooltip PopperProps={{sx: {zIndex: 9999999}}} title={getTranslatedText("AddImage")}>
                                <IconButton size="large" sx={{ml: 2, background: theme.palette.footer.light}} onClick={addImageToList}>
                                    <AddCircle color="success" sx={{fontSize: 40}} />
                                </IconButton>
                                </Tooltip>
                            </Box>
                        </>
                    }
                </>
            }
        </LanguageConsumer>
    )
}