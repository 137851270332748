import { createTheme } from '@mui/material';
import { ColorScheme } from '../Features/MainMenus/Settings/Store/SettingsSlice';

const getColorsFromScheme = (colorScheme: ColorScheme) => {
  switch (colorScheme) {
    case ColorScheme.depragStandard:
      return { primaryColor: '#32992c', secondaryColor: '#54ac51'}
    case ColorScheme.depragIndustrial:
      return { primaryColor: '#2d9a9a', secondaryColor: '#999'}

    default:
      return { primaryColor: '#32992c', secondaryColor: '#54ac51'}
  }
}


export const themeLight = (colorScheme: ColorScheme) => {

  const { primaryColor, secondaryColor} = getColorsFromScheme(colorScheme)

  return createTheme({
  palette: {
    mode: 'light',
    common: {
      white: '#f4f4f4'
    },
    primary: {
      main: primaryColor,
      contrastText: '#1E1E24',
    },
    secondary: {
      main: secondaryColor
    },
    success: {
      main: '#54ac51',
      contrastText: '#fff'
    },
    whitey: {
      main: '#fff'
    },
    background: {
      default: '#fff',
      paper: '#fff'
    },
    darky: {
      main: '#444',
      dark: '#323232',
      light: '#faf9f9',
      contrastText: '#fff'
    },
    footer: {
      main: '#888',
      dark: '#5e5e5e',
      light: '#e3e3e3'
    },
    schraubtechnik: {
      main: '#8fb9a8'
    },
    druckluft: {
      main: '#5d6676'
    },
    softwareDesktop: {
      main: '#765d69'
    },
    softwareCloud: {
      main: '#8fb9a8'
    },
    softwareOnPremise: {
      main: '#5d6676'
    },
    softwareControler: {
      main: '#979797'
    },
  gold: {
      main: '#f7cd46',
      light: '#e0c879',
      dark: '#81670f'
    },
    grayey: {
      main: '#f3f3f3',
      light: '#fcfcfc'
    },
    cardBorder: {
      main: '#e0e0e0'
    },
    cardBackground: {
      main: "#e6ede7"
    },
    buttonBackgroundGold: {
      main: "linear-gradient(126deg, #FDD792 0%, #FDD792 60%, #F5F5F5 65%, #F9C475 70%, #D68E43 100%)",
      contrastText: "#444",
  },
  },
  typography: {
    fontFamily: `"Open Sans", "News Cycle", "Nunito Sans", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 12,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h2: {
      fontFamily: "Open Sans"
    },
    h3: {
      fontFamily: "Open Sans"
    },
    h1: {
      fontFamily: "Open Sans"
    },
    h5: {
      fontFamily: "Open Sans"
    },
    h6: {
      fontFamily: "Open Sans"
    }
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#e6ede7",
            color: '#111'
          },
          "&.Mui-selected .MuiListItemIcon-root": {
            color: '#111'
          },
          "&.MuiListItemText-root":{
            color: '#111'
          },
          "& .MuiListItemIcon-root": {
            color: '#111'
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#e6ede7",
          },
          "&.Mui-selected:hover .MuiListItemIcon-root": {
            color: "#111",
          },
          '&:hover': {
            color: '#111',
          },
          '&.Mui-disabled': {
            color: '#000'
          }
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#e6ede7"
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#e6ede7",
          },
          '&:hover': {
            color: '#444',
          },
          '&.Mui-disabled': {
            color: '#000',
            opacity: 1
          }
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: 'transparent',
          borderWidth: "1px",
          borderStyle: 'solid',
          boxShadow: 'none',
          "&.Mui-disabled":{
            background: 'rgb(119, 142, 119)',
            boxShadow: 'none',
            borderWidth: "1px",
            borderStyle: 'solid',
            opacity: 1,
            color: '#fff',
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 1
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        colorPrimary: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          //backgroundColor: '#f3f3f3',
          backgroundColor: '#f3f3f3',
          border: 'none',
          borderRight: '1px solid #f3f3f3'
        },
      },
    },
  },
})
};

export const themeDark = (colorScheme: ColorScheme) => {

  const { primaryColor, secondaryColor} = getColorsFromScheme(colorScheme)

  return createTheme({
  palette: {
    mode: 'dark',
    common: {
      white: '#f4f4f4'
    },
    primary: {
      main: primaryColor,
      contrastText: '#1E1E24',
    },
    secondary: {
      main: secondaryColor,
    },
    success: {
      main: '#658864',
    },
    whitey: {
      main: '#fff'
    },
    background: {
      default: '#111',
      paper: '#222'
    },
    darky: {
      main: '#f0f0f0',
      light: '#f0f0f0',
      dark: '#f0f0f0',
      contrastText: '#444'
    },
    footer: {
      main: '#f8f8f8',
      dark: '#cecece',
      light: '#f1f1f1'
    },
    schraubtechnik: {
      main: '#8fb9a8'
    },
    druckluft: {
      main: '#5d6676'
    },
    softwareDesktop: {
      main: '#765d69'
    },
    softwareCloud: {
      main: '#8fb9a8'
    },
    softwareOnPremise: {
      main: '#5d6676'
    },
    softwareControler: {
      main: '#979797'
    },
  gold: {
      main: '#d4af37',
      light: '#e0c879',
      dark: '#b68d05'
    },
    grayey: {
      main: '#333',
      light: '#444'
    },
    cardBorder: {
      main: '#6b6b6b'
    },
    cardBackground: {
      main: "#455345"
    },
    buttonBackgroundGold: {
      main: "linear-gradient(126deg, #FDD792 0%, #FDD792 60%, #F5F5F5 65%, #F9C475 70%, #D68E43 100%)",
      contrastText: "#444",
    },
  },
  typography: {
    fontFamily: `"Open Sans", "News Cycle", "Nunito Sans", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 12,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h2: {
      fontFamily: "Open Sans"
    },
    h3: {
      fontFamily: "Open Sans"
    },
    h1: {
      fontFamily: "Open Sans"
    },
    h5: {
      fontFamily: "Open Sans"
    },
    h6: {
      fontFamily: "Open Sans"
    }
  },
  components: {
  MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#e6ede7",
            color: '#000',
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#e6ede7",
            color: '#000',
          },
          '&:hover': {
            color: '#000',
          },
          '&.Mui-disabled': {
            color: '#000',
            opacity: 1
          }
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: '#444',

          "&.Mui-selected": {
            backgroundColor: "#e6ede7",
            color: '#000',
          },
          "&.Mui-selected .MuiListItemIcon-root": {
            color: '#000 !important',
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#e6ede7",
            color: '#000',
          },
          '&:hover': {
            color: '#fff',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        colorPrimary: {
          backgroundColor: 'transparent',
          opacity: 1,
          backgroundImage: 'none',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: 'transparent',
          borderWidth: "1px",
          borderStyle: 'solid',
          boxShadow: 'none',
          "&.Mui-disabled":{
            background: 'rgb(119, 142, 119)',
            boxShadow: 'none',
            borderWidth: "1px",
            borderStyle: 'solid',
            opacity: 1,
            color: '#fff',
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 1
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#333',
          border: 'none',
        },
      },
    },
  },
})
};

declare module '@mui/material/styles' {

  interface Palette {
    whitey: Palette['primary'];
    darky: Palette['primary'];
    schraubtechnik: Palette['primary'];
    druckluft: Palette['primary'];
    gold: Palette['primary'];
    softwareDesktop: Palette['primary'];
    softwareCloud: Palette['primary'];
    softwareOnPremise: Palette['primary'];
    softwareControler: Palette['primary'];
    grayey: Palette['primary'];
    footer: Palette['primary'];
    cardBorder: Palette['primary'];
    cardBackground: Palette['primary'];
    buttonBackgroundGold: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    whitey?: PaletteOptions['primary'];
    darky?: PaletteOptions['primary'];
    schraubtechnik?: PaletteOptions['primary'];
    druckluft?: PaletteOptions['primary'];
    gold?: PaletteOptions['primary'];
    softwareDesktop?: PaletteOptions['primary'];
    softwareCloud?: PaletteOptions['primary'];
    softwareOnPremise?: PaletteOptions['primary'];
    softwareControler?: PaletteOptions['primary'];
    grayey?: PaletteOptions['primary'];
    footer?: PaletteOptions['primary'];
    cardBorder?: PaletteOptions['primary'];
    cardBackground?: PaletteOptions['primary'];
    buttonBackgroundGold?: PaletteOptions["primary"];
  }
}


// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    whitey: true;
    darky: true;
    schraubtechnik: true;
    druckluft: true;
    gold: true;
    softwareDesktop: true;
    softwareCloud: true;
    softwareOnPremise: true;
    softwareControler: true;
    footer: true;
    cardBorder: true;
    cardBackground: true;
  }
}


declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    whitey: true;
    darky: true;
    schraubtechnik: true;
    druckluft: true;
    gold: true;
    softwareDesktop: true;
    softwareCloud: true;
    softwareOnPremise: true;
    softwareControler: true;
    footer: true;
    cardBorder: true;
    cardBackground: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    whitey: true;
    darky: true;
    schraubtechnik: true;
    druckluft: true;
    gold: true;
    softwareDesktop: true;
    softwareCloud: true;
    softwareOnPremise: true;
    softwareControler: true;
    footer: true;
    cardBorder: true;
    cardBackground: true;
    buttonBackgroundGold: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    whitey: true;
    darky: true;
    schraubtechnik: true;
    druckluft: true;
    gold: true;
    softwareDesktop: true;
    softwareCloud: true;
    softwareOnPremise: true;
    softwareControler: true;
    footer: true;
    cardBorder: true;
    cardBackground: true;
  }
}

declare module '@mui/material/Icon' {
  interface IconPropsColorOverrides {
    whitey: true;
    darky: true;
    schraubtechnik: true;
    druckluft: true;
    gold: true;
    softwareDesktop: true;
    softwareCloud: true;
    softwareOnPremise: true;
    softwareControler: true;
    footer: true;
    cardBorder: true;
    cardBackground: true;
  }
}

  declare module '@mui/material/TextField' {
    interface TextFieldPropsColorOverrides {
      whitey: true;
      darky: true;
      schraubtechnik: true;
      druckluft: true;
      gold: true;
      softwareDesktop: true;
      softwareCloud: true;
      softwareOnPremise: true;
      softwareControler: true;
      footer: true;
      cardBorder: true;
      cardBackground: true;
    }
}
