import { createSlice } from '@reduxjs/toolkit';

export enum MediaType {
    Image = "Image",
    Video = "Video"
}

export type VideoAndImageRecorderState = {
    open: boolean,
    type: MediaType
}     

export const initialStateVideoAndImageRecorder: VideoAndImageRecorderState = {
    open: false,
    type: MediaType.Image
};

export const videoAndImageRecorderSlice = createSlice({
  name: 'videoAndImageRecorder',
  initialState: initialStateVideoAndImageRecorder,
  reducers: {
    toggleVideoAndImageRecorder: (state) => {
       state.open = !Boolean(state.open);
    },
    closeVideoAndImageRecorder: (state) => {
      state.open = false;
    },
    setMediaType: (state,action) => {
        state.type = action.payload;
    }
  }
});

export const { closeVideoAndImageRecorder, toggleVideoAndImageRecorder, setMediaType } = videoAndImageRecorderSlice.actions;

export default videoAndImageRecorderSlice.reducer;
