import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RedirectModel {
  tempRedirectPathName: string,
  savePath: boolean,
}

export interface AppState {
  status: 'idle' | 'loading' | 'failed',
  drawerOpen: boolean,
  appTitle: string,
  mobileDrawerOpen: boolean,
  hasFavoriteAppDrawer: boolean,
  favoriteAppsOpen: boolean,
  redirect: RedirectModel,
}

export const initialAppState: AppState = {
  status: 'idle',
  drawerOpen: true,
  appTitle: 'Process',
  mobileDrawerOpen: false,
  favoriteAppsOpen: false,
  hasFavoriteAppDrawer: false,
  redirect: {
    tempRedirectPathName: '',
    savePath: true,
  },
};


export const appSlice = createSlice({
  name: 'global',
  initialState: initialAppState,
  reducers: {
    setAppTitle: (state, action) => {
      state.appTitle = action.payload;
    },
    toggleDrawer: (state) => {
      state.drawerOpen = !Boolean(state.drawerOpen);
    },
    toggleMobileDrawer: (state) => {
      state.mobileDrawerOpen = !Boolean(state.mobileDrawerOpen);
    },
    toggleFavoriteAppDrawer: (state, action) => {
      state.favoriteAppsOpen = action.payload;
    },
    setRedirectPathName: (state, action) => {
      state.redirect.tempRedirectPathName = action.payload;
    },
    setSavePathAllowed: (state, action) => {
      state.redirect.savePath = action.payload;
    },
    setRedirect: (state, action) => {
      state.redirect = action.payload;
    },
    resetRedirect: (state) => {
      state.redirect = initialAppState.redirect;
    }
  },
});

export const { setRedirectPathName, setSavePathAllowed, toggleFavoriteAppDrawer, toggleMobileDrawer, setAppTitle, toggleDrawer, setRedirect, resetRedirect } = appSlice.actions;


export default appSlice.reducer;
