import { Chip, IconButton, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { IoPencilSharp } from "react-icons/io5";
import { PiCheckCircle, PiTrash } from "react-icons/pi";
import { NavLink } from "react-router-dom";
import { getTranslation } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks";
import { setDeleteTitleAndIdEmailTrigger, toggleDeletePopupEmailTrigger } from "../../Store/EmailTriggerRulesSlice";
import { AffectedItemPreview } from "../Models/AffectedItemPreview";
import { globalDateFormatOptions } from "../../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";
import { getStateColor } from "../../../../Users/AllUsers/Components/AllUsersTable";

export const useEmailTriggerRulesPreviewColumns = () => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(store => store.settings.language)

    const columns: GridColDef[] = [
        {
            field: 'UserId',
            headerName: "Id",
            flex: 0,
            minWidth: 0,
        },
        {
            field: 'RuleName',
            headerName: getTranslation("RuleName"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'Email',
            headerName: getTranslation("Email"),
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, AffectedItemPreview, any>) => {
                return <Chip label={params.row.Email} component={NavLink} to={`mailto:${params.row.Email}`} sx={{cursor: 'pointer'}} />
            },
        },
        {
            field: 'State',
            headerName: getTranslation("UserState"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, AffectedItemPreview, any>) => {
                return <Chip label={getTranslation(params.row.State)} color={getStateColor(params.row.State)} />
            },
            valueGetter: (params: GridRenderCellParams<any, AffectedItemPreview, any>) => getTranslation(params.row.State)
        },
        {
            field: 'CountryIso',
            headerName: getTranslation("CountryIso"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'IsGoldStatus',
            headerName: getTranslation("IsGoldStatus"),
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, AffectedItemPreview, any>) => {
                return params.row.IsGoldStatus ? <PiCheckCircle size={26} /> : <></>
            },
        },
        {
            field: 'RegistratedSince',
            headerName: getTranslation("RegistratedSince"),
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, AffectedItemPreview, any>) => {
                return <Chip variant="outlined" label={new Date(params.row.RegistratedSince).toLocaleDateString(language, globalDateFormatOptions)} />
            },
        },
        {
            field: 'FreeTrailStartedOn',
            headerName: getTranslation("FreeTrialStartedOn"),
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, AffectedItemPreview, any>) => {
                return params.row.FreeTrailStartedOn ? <Chip variant="outlined" label={new Date(params.row.FreeTrailStartedOn).toLocaleDateString(language, globalDateFormatOptions)} /> : <></>
            },
        },
        {
            field: 'FreeTrailExpiresOn',
            headerName: getTranslation("FreeTrialExpiresOn"),
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
            flex: 1,
            renderCell: (params: GridRenderCellParams<any, AffectedItemPreview, any>) => {
                return params.row.FreeTrailExpiresOn ? <Chip variant="outlined" label={new Date(params.row.FreeTrailExpiresOn).toLocaleDateString(language, globalDateFormatOptions)} /> : <></>
            },
        }
    ];

    return {
        columns
    }
}