
import { Action, combineReducers, configureStore, PreloadedState, ThunkAction } from '@reduxjs/toolkit';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appReducer from '../../Store/AppSlice';
import settingsReducer from '../../Features/MainMenus/Settings/Store/SettingsSlice';
import languageFileReducer from '../Internationalisation/Store/LanguageFileSlice';
import loginReducer from '../../Features/Infrastructure/Login/Store/LoginSlice';
import navigationReducer from '../../Layout/Store/DrawerSlice';
import customersReducer from '../../Features/MainMenus/Customers/Store/CustomersSlice';
import openRegistrationsReducer from '../../Features/MainMenus/Users/OpenRegistrations/Store/OpenRegistrationsSlice';
import usersReducer from '../../Features/MainMenus/Users/Store/UsersSlice';
import waitingForEmailVerificationsReducer from '../../Features/MainMenus/Users/WaitForEmailVerifications/Store/WaitingForEmailVerificationSlice';
import licenseDeactivationReducer from '../../Features/MainMenus/Licences/ByOrderNumber/Components/PaidLicencesDetails/Components/Drawers/LicenseDeactivationDrawer/Store/LicenseDeactivationSlice';
import licenseActivationReducer from '../../Features/MainMenus/Licences/ByOrderNumber/Components/PaidLicencesDetails/Components/Drawers/LicenseActivationDrawer/Store/LicenseActivationSlice';
import searchByOrderNumberReducer from '../../Features/MainMenus/Licences/ByOrderNumber/Components/Store/SearchByOrderNumberSlice';
import externalHelpReducer from '../../Features/MainMenus/Help/HelpOverview/Store/HelpOverviewSlice';
import dashboardReducer from '../../Features/MainMenus/Dashboard/Store/DashboardSlice';
import deleteLogsReducer from '../../Features/MainMenus/Highlevel/ShowLogs/Overview/Store/DeleteLogsPopupSlice';
import deleteMessageReducer from '../../Features/MainMenus/Highlevel/Maintenance/Overview/Store/DeleteMessagesPopupSlice';
import emailTriggerRulesReducer from '../../Features/MainMenus/Highlevel/EmailTriggerRules/Store/EmailTriggerRulesSlice';
import deleteResellerReducer from '../../Features/MainMenus/Productmanagment/Reseller/Store/DeleteResellerPopupSlice';
import byCompanyAssignReducer from '../../Features/MainMenus/Licences/ByCompany/Assign/Store/ByCompanyAssignSlice';
import myGoldAssignReducer from '../../Features/MainMenus/Licences/MyGold/Assign/Store/MyGoldAssignSlice';
import cockpitCloudReducer from "../../Features/MainMenus/Software/CockpitCloud/Store/CockpitCloudSlice";
import serviceRequestsReducer from "../../Features/MainMenus/ServiceRequests/Store/ServiceRequestsSlice";
import serviceRequestsDetailReducer from "../../Features/MainMenus/ServiceRequests/Components/Details/Store/ServiceRequestsDetailSlice";
import videoAndImageRecorderReducer from "../../Features/MainMenus/ServiceRequests/Components/Details/Components/VideoAndImageRecorder/Store/VideoAndImageRecorderSlice";
import equipmentByCompanyAssignReducer from "../../Features/MainMenus/InstalledBase/ByCompany/Assign/Store/EquipmentByCompanyAssignSlice";
import { whitelistToPersist } from './persist';

export const RESET_STORE_ACTION = {
  type: 'RESET_STORE'
}

const rootReducer2 = combineReducers({ 
  global: appReducer,
  settings: settingsReducer,
  languageFile: languageFileReducer,
  authentication: loginReducer,
  navigation: navigationReducer,
  customers: customersReducer,
  openRegistrations: openRegistrationsReducer,
  users: usersReducer,
  waitingForEmailVerifications:waitingForEmailVerificationsReducer,
  licenseDeactivation: licenseDeactivationReducer,
  licenseActivation: licenseActivationReducer,
  licenceSearchByOrderNumber: searchByOrderNumberReducer,
  externalHelp: externalHelpReducer,
  deleteLogs: deleteLogsReducer,
  dashboard: dashboardReducer,
  deleteMessage: deleteMessageReducer,
  emailTriggerRules: emailTriggerRulesReducer,
  deleteReseller: deleteResellerReducer,
  byCompanyAssign: byCompanyAssignReducer,
  myGoldAssign: myGoldAssignReducer,
  cockpitCloud: cockpitCloudReducer,
  serviceRequests: serviceRequestsReducer,
  serviceRequestsDetail: serviceRequestsDetailReducer,
  videoAndImageRecorder: videoAndImageRecorderReducer,
  equipmentByCompanyAssign: equipmentByCompanyAssignReducer
})

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE_ACTION.type) {
    storage.removeItem(`persist:${persistStoreKey}`)
    return rootReducer2(undefined, action)
  }

  return rootReducer2(state, action)
}

const migrations = {
  1: (state) => {
    return {
      ...state,
      global: {
        ...state.global,
        hasFavoriteAppDrawer: false
      }
    }
  },
  2: (state) => {
    return {
      ...state,
      languageFile: {
        ...state.languageFile,
        languageFileIsLoading: false
      }
    }
  },
  3: (state) => {
    return {
      ...state,
      global: {
        ...state.global,
        redirect: {
          tempRedirectPathName: '',
          savePath: true
        }
      }
    }
  }
}

export const persistStoreKey = "rootAdmin";

const persistConfig = {
  key: persistStoreKey,
  version: 3,
  storage,
  whitelist: whitelistToPersist.map((x) => x.key),
  migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  })
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
