import { NavLink, useLocation, useParams } from "react-router-dom";
import { LanguageConsumer, getTranslation } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../../../../Store/AppSlice";
import { PaidLicenseDetailsStatusCard } from "./Components/StatusCard/PaidLicenseDetailsStatusCard";
import { CustomBox } from "../../../../../../Layout/CustomBox";
import { PaidLicenseDetailsTable } from "./Components/DetailsTable/PaidLicenseDetailsTable";
import { PaidLicenseDetailsStatusGeneral } from "./Components/StatusGeneral/PaidLicenseDetailsStatusGeneral";
import { EasyDecrypt } from "../../../../../../Infrastructure/EasyEncryption/EasyEncryption";

export const PaidLicencesDetails = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
    const theme = useTheme();

    const [decryptedId, setDecryptedId] = useState<string>("");

    useEffect(() => {
        if (id) {
            const decrypted = EasyDecrypt(id)
            !isNaN(+decrypted) && setDecryptedId(decrypted)
        }
    }, [id]);

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuSoftwareLicensingBreadcrumb')));
    }, [location, language, languageFileWasLoaded, dispatch]);

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    {decryptedId && <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
                        <Grid item xs={12} mx={3}>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Button color="darky" component={NavLink} to={'/licences/byOrder'} variant="outlined">{getTranslatedText("Prev")}</Button>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <PaidLicenseDetailsStatusGeneral id={decryptedId} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <PaidLicenseDetailsStatusCard id={decryptedId} />
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <CustomBox elevation={0} sx={{
                                        height: '100%',
                                        backgroundSize: 'cover',
                                        backgroundBlendMode: 'multiply',
                                        backgroundColor: isDarkMode ? "transparent" : "#fff",
                                        border: `1px solid ${theme.palette.cardBorder.main}`
                                    }}>
                                        <PaidLicenseDetailsTable id={decryptedId} />
                                    </CustomBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                </>
            }
        </LanguageConsumer>
    )
}