import { Grid, useTheme } from "@mui/material"
import { LegalDocumentsTable } from "./Components/LegalDocumentsTable"
import { LanguageConsumer, getTranslation } from "../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBox } from "../../../../Layout/CustomBox"
import { useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Store/hooks"
import { setAppTitle } from "../../../../Store/AppSlice"
import { useEffect } from "react"

export const LegalDocuments = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const language = useAppSelector(store => store.settings.language);
  const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
  const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
  const theme = useTheme();

  useEffect(() => {
    dispatch(setAppTitle(getTranslation('MainMenuAllLegalDocumentsBreadcrumb')));
  }, [location, language, languageFileWasLoaded, dispatch]);

  return (
    <>
      <LanguageConsumer>
        {({ getTranslatedText }) =>
          <>
            <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <CustomBox elevation={0} sx={{
                  mx: 3,
                  height: '100%',
                  backgroundSize: 'cover',
                  backgroundBlendMode: 'multiply',
                  backgroundColor: isDarkMode ? "transparent" : "#fff",
                  border: `1px solid ${theme.palette.cardBorder.main}`
                }}>
                  <LegalDocumentsTable />
                </CustomBox>
              </Grid>
            </Grid>
          </>
        }
      </LanguageConsumer>
    </>
  )
}