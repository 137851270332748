import { Chip, Grid, Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useState } from "react";
import { globalDateFormatOptions } from "../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";
import { getTranslation, LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { CustomerEditLicencesResponseDto } from "../Models/CustomerLicencesConsent"
import { CopyChip } from "../../../../../Layout/CopyChip";


interface ICustomerEditLicencesTable {
    data: CustomerEditLicencesResponseDto[]
}

export const CustomerEditLicencesTable = (props: ICustomerEditLicencesTable) => {
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const language = useAppSelector(store => store.settings.language);
    const [searchValue, setSearchValue] = useState<string>("");

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const columns: GridColDef[] = [
        {
            field: 'Id',
            headerName: "Id",
            flex: 0,
            minWidth: 0,
        },
        {
            field: 'SapSalesOrder',
            headerName: getTranslation("PaidLicenceOrderNumber"),
            flex: 0.5,
            minWidth: 150,  
            renderCell: (params: GridRenderCellParams<any, CustomerEditLicencesResponseDto, any>) => {
                return <CopyChip showIconAfterCopy label={params.row.SapSalesOrder} isCopyEnabled  />
            }      
        },
        {
            field: 'ProductName',
            headerName: getTranslation("PaidLicenceProductName"),
            flex: 0.5,
            minWidth: 150,        
        },
        {
            field: 'SapProductNumber',
            headerName: getTranslation("PaidLicenceProductNumber"),
            flex: 0.5,
            minWidth: 150            
        },
        { 
            field: 'Licences', 
            headerName: getTranslation("PaidLicenceLicences"), 
            flex: 0.6,
            minWidth: 100, 
            align: "left",
            renderCell: (params: GridRenderCellParams<any, CustomerEditLicencesResponseDto, any>) =>(
                <strong>
                <Chip label={`${params.row.UsedLicence} / ${params.row.QuantityOrdered}`} />
                </strong>
            ), 
            sortable: false, 
            filterable: false
        },
    ]

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                        
                                <Grid item xs={4} my={'auto'} sx={{ textAlign: 'left', overflowX: 'hidden' }}>
                                    <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        disableColumnSelector
                                        localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={props.data ? props.data.filter(x =>
                                            (x.SapSalesOrder && x.SapSalesOrder?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                (x.Id && x.Id.toString().trim().length > 0 && x.Id.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                                (x.ProductName && x.ProductName.trim().length > 0 && x.ProductName?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                                x.SapProductNumber?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                                            )
                                            ) : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        getRowId={(x) => x.Id}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}