import { Avatar, Divider, Grid, Stack, Typography, useTheme } from "@mui/material"
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks"
import { stringAvatar } from "../../../../../Infrastructure/Utils/UserColorGenerator"
import { MyDepragClaim } from "../../../Highlevel/Claims/Details/Models/ClaimsEditResponseDto"
import { RootState, store } from "../../../../../Infrastructure/Store/store"
import { useQuery } from "@tanstack/react-query"
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall"
import { DashboardCardsResponseDto } from "../../Models/DashboardCardsResponseDto"
import { CustomDashboardCards } from "../../../../../Layout/CustomDashboardCards/CustomDashboardCard"
import { useEffect } from "react"
import { CustomBox } from "../../../../../Layout/CustomBox"
import HtmlParser from "react-html-parser"


export const DashboardHeaderAndCards = () => {
    const { firstName, lastName, company } = useAppSelector(store => store.authentication);
    const claims = useAppSelector(store => store.authentication.myDepragClaims);
    const hasResellerClaim = claims.some(c => MyDepragClaim[c] === MyDepragClaim.DepragReseller);
    const dispatch = useAppDispatch();
    const language = useAppSelector(store => store.settings.language);
    const theme = useTheme()

    const { data, refetch } = useQuery({
        queryKey: ["dashboardHeaderCards"],
        queryFn: async () => await fetchMyDepragApi<DashboardCardsResponseDto>(
            null,
            `dashboard/cards/${language}`,
            "GET",
            dispatch,
            store.getState() as RootState),
        cacheTime: 0,
    });

    useEffect(() => {
        refetch()
    }, [language])

    return (<>
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction={"row"} spacing={2} sx={{ pt: 2 }}>
                        <Grid item xs={6} sx={{ display: "flex" }}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Stack direction={{ sm: 'column', md: 'row' }} spacing={{ xs: 2, sm: 0, md: 4 }} alignItems={'center'}>
                                    <Stack className="stack-left" direction={'column'} spacing={{ xs: 2, md: 1 }} sx={{ maxWidth: { sm: '100%', md: '100%' }, minWidth: { sm: '100%', md: '65%' } }}>
                                        <Stack direction={'row'} spacing={1}>
                                            <Typography className="title" lineHeight={{ xs: 1.2, md: 1 }} fontSize={{ xs: 30, sm: 33, md: 35, lg: 37 }}
                                                fontWeight={700} fontFamily={'Raleway'}>
                                                {getTranslatedText("WelcomeBack")}<span style={{ color: theme.palette.success.main }}> {getTranslatedText("InBackoffice")}</span>
                                            </Typography>
                                        </Stack>

                                        <Typography className="subTitle" fontFamily={'Raleway'} sx={{ opacity: 0.5 }} fontSize={20}>
                                            {HtmlParser(getTranslatedText("BackofficeWelcomeText"))}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid item xs={6}>
                            <Stack className="stack-right" direction={'column'} justifyContent={'flex-end'} spacing={2} sx={{ width: '100%', height: 'auto', pt: { sm: 13, md: 1 }, mt: { xs: 3, sm: 3, md: 0 }, display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }}>
                                <CustomBox elevation={0} sx={{
                                    alignSelf: 'flex-end',
                                    height: '100%',
                                    width: 'auto',
                                    maxWidth: 400,
                                    minWidth: 220,
                                }}>
                                    <Stack direction={'column'} spacing={1} alignItems={'flex-end'}>
                                        <Stack direction={'column'} spacing={1} alignItems={'flex-end'}>
                                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                <Typography fontSize={20} fontWeight={600} sx={{ fontFamily: 'Raleway' }}>{`${firstName} ${lastName}`}</Typography>
                                                <Avatar className="global-toolbar-profile" {...stringAvatar(`${firstName} ${lastName}`, { mr: 2, width: 34, height: 34 })} />
                                            </Stack>
                                            <Typography sx={{ fontFamily: 'Raleway' }}>{company}</Typography>
                                            <Stack direction={'row'} spacing={1}>
                                                <Typography sx={{ fontFamily: 'Raleway', fontSize: "small" }}>
                                                    {getTranslatedText(`ResponsibleFor`)}
                                                    {data && data.CountriesResponsibleFor.map((c, index) => (
                                                        <>
                                                            {c}{index !== data.CountriesResponsibleFor.length - 1 && <>, </>}
                                                        </>
                                                    ))}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </CustomBox>
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider sx={{ mb: 2, opacity: 0.6 }} />
                        </Grid>

                        <Grid item xs={12}>
                            <Stack direction={'column'} spacing={1} alignItems={'center'} justifyContent={'center'} sx={{ mb: 4 }}>
                                <Typography color={'success.main'} sx={{ textAlign: 'center' }} lineHeight={1} fontSize={30} fontWeight={700} fontFamily={'Raleway'}>
                                    {getTranslatedText("DashboardInfo")}
                                </Typography>
                                <Typography className="subTitle" fontFamily={'Raleway'} sx={{ opacity: 0.5, textAlign: 'center', }} fontSize={20}>
                                    {HtmlParser(getTranslatedText("DashboardInfoDescription"))}
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={hasResellerClaim ? 3 : 4}>
                            <CustomDashboardCards
                                title={getTranslatedText("OpenRegistration")}
                                count={data ? data.OpenRegistration : 0}
                                buttonText={"GoToRegistrations"}
                                navigateTo={"/users/openRegistrations"}
                                height={240}
                                colorText="red"
                                positionText={"center"} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={hasResellerClaim ? 3 : 4}>
                            <CustomDashboardCards
                                title={getTranslatedText("CountUser")}
                                count={data ? data.CountUsers : 0}
                                buttonText={"GoToUsers"}
                                navigateTo={"/users/allUsers"}
                                height={240}
                                positionText={"center"} />
                        </Grid>

                    </Grid>
                </>
            }
        </LanguageConsumer>
    </>)
}