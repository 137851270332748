import { FormControlLabel, Grid, TextField } from "@mui/material";
import { CustomSwitch } from "../../../../../Layout/Switch/CustomSwitch";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";

interface EditGoldMemberShip {
    hasGoldStatus: boolean,
    handleCheckedChange: () => void,
}

export const ClaimsEditGoldMember = (props: EditGoldMemberShip) => {
    const { hasGoldStatus, handleCheckedChange,  } = props;

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction={'row'} spacing={2} sx={{ mt: -1 }}>
                        <Grid item xs={12} my={'auto'}>

                            <FormControlLabel sx={{ ml: 2 }} control={<CustomSwitch />}
                                checked={props.hasGoldStatus}
                                disabled={false}
                                label={getTranslatedText("UserEditIsGoldMember")}
                                onChange={() => handleCheckedChange()}
                            />
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}