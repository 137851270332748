import { Chip, Tooltip, IconButton, Grid, Divider, Box, Skeleton, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { IoPencilSharp } from "react-icons/io5";
import { AddCircleOutline } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { getTranslation, LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { ResellerOverviewResponseDto } from "../Models/ResellerOverviewResponseDto";
import { ResellerOverviewItem } from "../Models/ResellerOverviewItem";
import { ResellerOverviewRequestDto } from "../Models/ResellerOverviewRequestDto";
import { PiTrash } from "react-icons/pi";
import { DeleteResellerPopup } from "./DeleteResellerPopup";
import { toggleResellerPopup } from "../Store/DeleteResellerPopupSlice";

export const MAX_YEAR = 9999;

export const ResellerOverview = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const PreferredIsoLanguageCode = useAppSelector(store => store.settings.language);
    const location = useLocation();
    const [searchValue, setSearchValue] = useState<string>("");
    const { deletePopupVisible } = useAppSelector(store => store.deleteReseller);
    const [idToDelete, setIdToDelete] = useState<number>(-1);

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["resellers"],
        queryFn: async () => await fetchMyDepragApi<ResellerOverviewResponseDto>(
            {PreferredIsoLanguageCode: PreferredIsoLanguageCode} as ResellerOverviewRequestDto,
            "resellers/overview",
            'POST',
            dispatch,
            store.getState() as RootState)
    });

    useEffect(() => {
        refetch();
    },[deletePopupVisible, location, refetch])

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const openDetails = (params: GridRowParams) => {
        navigate(`/resellers/edit/${params.row.Id}`)
    }

    const handleDelete = async (itemToDelete: number) => {
        setIdToDelete(itemToDelete);
        dispatch(toggleResellerPopup());
    }

    const columns: GridColDef[] = [
                {
            field: 'Id',
            headerName: getTranslation("ResellerId"),
            flex: 0.5,
            minWidth: 75,
            renderCell: (params: GridRenderCellParams<any, ResellerOverviewItem, any>) => {
                return <Chip label={params.row.Id} />
            }
        },
        {
            field: 'CompanyName',
            headerName: getTranslation("ResellerCompanyName"),
            flex: 1.5,
            minWidth: 150,
            valueGetter: (params: GridRenderCellParams<any, ResellerOverviewItem, any>) => params.row.Name
        },
        {
            field: 'CountryName',
            headerName: getTranslation("ResellerCountryName"),
            flex: 2,
            minWidth: 150,
            valueGetter: (params: GridRenderCellParams<any, ResellerOverviewItem, any>) => {
                return params.row.Reseller2Countries.map(x => ' ' + x)
            }
        },
        {
            field: '',
            headerName: '',
            flex: 0.1,
            minWidth: 100,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, ResellerOverviewItem, any>) => (
                <Grid>
                    <strong>
                        <Tooltip title={getTranslation("CommonDelete")}>
                            <IconButton color={'darky'} onClick={() => handleDelete(params.row.Id)}>
                                <PiTrash/>
                            </IconButton>
                        </Tooltip>
                    </strong>

                    <strong>
                        <Tooltip title={getTranslation("CommonEdit")}>
                            <IconButton color={'darky'} component={NavLink} to={`/resellers/edit/${params.row.Id}`}>
                                <IoPencilSharp />
                            </IconButton>
                        </Tooltip>
                    </strong>
                </Grid>
            ),
            sortable: false,
            filterable: false
        },

    ];

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("ResellerInfoText") }}
                                    />

                                    <Divider sx={{ my: 3, mb: 2 }} />
                                </Grid>

                                <Grid item xs={4} my={'auto'} sx={{textAlign: 'left', overflowX: 'hidden'}}>
                                    <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>

                                <Grid item xs={8} my={'auto'} sx={{ textAlign: 'right' }}>
                                    <Button startIcon={<AddCircleOutline />} sx={{ml: 1}} component={NavLink} to={'/resellers/add'} variant="contained" color="success">{getTranslatedText("CreateReseller")}</Button>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        localeText={PreferredIsoLanguageCode === "de" ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={data && data.Resellers ? data.Resellers.sort((x, y) => x.Id - y.Id).filter(x =>
                                            (x.Name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            (x.Id && x.Id.toString().trim().length > 0 && x.Id.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                            x.Reseller2Countries.find((a) => a.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
                                                )
                                        ) : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        getRowId={(x) => x.Id}
                                        onRowDoubleClick={openDetails}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                          }
                                        components={{
                                            LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                        }}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <DeleteResellerPopup idToDelete={idToDelete}/>
                </>
            }
        </LanguageConsumer>
    )
}
