import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../Infrastructure/Store/hooks'
import { motion } from "framer-motion";
import { isNotTokenDependent } from './Helper/notTokenDependent';
import { isDepragAmbergDependent } from './Helper/depragAmbergDependent';
import { setRedirectPathName } from '../../../../Store/AppSlice';

const ProtectedRoute = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.authentication)
  const navigate = useNavigate();
  const location = useLocation();
  const isDepragAmbergMember = useAppSelector(store => store.authentication.isDepragAmbergMember);

  useEffect(() => {
    if (!token && isNotTokenDependent(location)) {
      if (location.pathname !== '/login') {
        dispatch(setRedirectPathName(location.pathname))
      }
      navigate('/login')
    }

      if (!isDepragAmbergMember && isDepragAmbergDependent(location)) {
          navigate('/');
      }
  }, [token])

  if (!token) {
    return (
      <></>
    )
  }

  return (
    <motion.div
      className="container text-center  bg-black"
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      <Outlet />
    </motion.div>
  )
}
export default ProtectedRoute