import * as z from "zod";
import i18next from "i18next";
import { zodI18nMap } from "zod-i18n-map"
import de from "../../../../../../Infrastructure/Internationalisation/ValidationJSON/de.json";
import en from "../../../../../../Infrastructure/Internationalisation/ValidationJSON/en.json";
import { UserState } from "../../../Claims/Overview/Models/UserOverviewItem";


const isDe = navigator.language.toLowerCase().startsWith("de");

i18next.init({
    lng: isDe ? "de" : "en",
    resources: {
      de: { zod: de },
      en: { zod: en }
    },
  });
  z.setErrorMap(zodI18nMap);

export const EmailTriggerRuleCreateOrEditValidator =  z.object({
  RuleName: z.string().max(255).nonempty(),
  MailHeaderDe: z.string().nonempty(),
  MailHeaderEn: z.string().nonempty(),
});