import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { UserOverviewResponseDto } from "../../../Users/AllUsers/Models/UserOverviewResponseDto";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { getTranslation, LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { Box, Chip, Divider, Grid, Skeleton, TextField, Typography, useTheme } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { LicencesBySerialNumberResponseItem } from "../Model/LicencesBySerialNumberResponseItem";
import { globalDateFormatOptions } from "../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";
import { LoadingButton } from "@mui/lab";
import { Delete } from "@mui/icons-material";
import { YesNoDialog } from "../../../../Infrastructure/Dialogs/YesNoDialog";
import { ConfirmDialog } from "../../../../Infrastructure/Dialogs/ConfirmDialog";
import { DefaultResponse } from "../../../../../Infrastructure/Api/Model/DefaultResponse";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { CopyChip } from "../../../../../Layout/CopyChip";

import { MyDepragClaim } from '../../../Highlevel/Claims/Details/Models/ClaimsEditResponseDto';

export const SearchByDeviceSerialNumber = () => {
    const language = useAppSelector(store => store.settings.language);
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const theme = useTheme();

    const [filter, setFilter] = useState<string>("")

    const dispatch = useAppDispatch();

    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
    const [deactivatedSapOrderNumbers, setDeactivatedSapOrderNumbers] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const claims = useAppSelector(store => store.authentication.myDepragClaims);

    const { data, isLoading } = useQuery({
        queryKey: ['LicencesByDevice', filter],
        queryFn: async () => await fetchMyDepragApi<UserOverviewResponseDto>(
            {
                deviceSerialNumber: filter
            },

            "licences/serialnumber",
            'POST',
            dispatch,
            store.getState() as RootState),
        enabled: !!filter

    });

    function CanDeactivate(): boolean {
        const requiredDetailClaim: any = MyDepragClaim.BackofficeLicencesByDeviceDeactivate;
        const hasCorrectClaim = claims.some(c => MyDepragClaim[c] === requiredDetailClaim);
        return hasCorrectClaim;
    }


    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const deactivateLicences = async () => {
        if (filter && data && data.Items.length > 0) {
            setIsDeactivating(true);

            var response = await fetchMyDepragApi<DefaultResponse>({ DeviceSerialNumber: filter }, `licences/deactivate`, 'POST', dispatch, store.getState() as RootState, "SuccessfullyDeactivated", true);
            if(response && response.Success && response.Payload){
                console.log(response)
                navigator.clipboard.writeText((response.Payload.DeactivatedSapOrderNumbers as string[]).join(','));
                setDeactivatedSapOrderNumbers(response.Payload.DeactivatedSapOrderNumbers);
            }
        
            setIsDeactivating(false);

        }
    }


    const columns: GridColDef[] = [
        {
            field: 'RetrievedLicenceId',
            headerName: "Id",
            flex: 0,
            minWidth: 0,
        },
        {
            field: 'OrderCreatedOn',
            headerName: getTranslation("LicenceByDeviceGridColumnOrderCreatedOn"),
            flex: 0,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, LicencesBySerialNumberResponseItem, any>) => {
                const dateObject = new Date(params.row.OrderCreatedOn ?? '');
                const formattedDateTime = dateObject.toLocaleDateString(language, globalDateFormatOptions);
                return <Chip label={formattedDateTime} />
            }
        },
        {
            field: 'SapOrderNumber',
            headerName: getTranslation("LicenceByDeviceGridColumnSapOrderNumber"),
            flex: 0,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, LicencesBySerialNumberResponseItem, any>) => {
                return <CopyChip showIconAfterCopy label={params.row.SapOrderNumber} isCopyEnabled />
            }
        },
        {
            field: 'ProductName',
            headerName: getTranslation("LicenceByDeviceGridColumnProductName"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'CompanyName',
            headerName: getTranslation("LicenceByDeviceGridColumnCompanyName"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'RetrievedByUser',
            headerName: getTranslation("RetrievedByUser"),
            flex: 1,
            minWidth: 150,
        },
    ];

    const handleConfirmDeactivationNoClick = () => {
        setConfirmDialogOpen(false);
    }

    const handleConfirmDeactivationYesClick = async () => {
        await deactivateLicences();
        setConfirmDialogOpen(false);
    }

    const handleCloseDeactivatedSapOrderNumberDialog = () => {
        setDeactivatedSapOrderNumbers([]);
        setFilter("");
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("LicenceByDeviceInfoText") }}
                                    />
                                    <Divider sx={{ my: 3, mb: 2 }} />
                                </Grid>



                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6">{getTranslatedText("SearchLicenceBySerialNumberInfoText")}</Typography>
                                </Grid>

                                <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
                                <Grid item xs={12} md={6} my={'auto'}>
                                    <TextField
                                        hiddenLabel={true}
                                        variant="standard"
                                        color="success"
                                        fullWidth autoFocus
                                        placeholder={getTranslatedText("PlaceHolderForSerialNumber")}
                                        InputProps={{ disableUnderline: true }}
                                        inputProps={{ sx: { textAlign: 'center', fontSize: 23, fontWeight: 700, border: 0, outline: 0, '&::placeholder': { fontWeight: 400, opacity: 0.1 } } }}
                                        sx={{ border: `1px solid ${theme.palette.footer.main}`, padding: 1, borderRadius: 8, '&:has(.Mui-focused)': { border: `2px solid ${theme.palette.success.main}` } }}
                                        type={'search'}
                                        value={filter}
                                        onChange={(e) => setFilter(e.target.value)}
                                    />
                                </Grid>
                                <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>

                                <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                    <Divider sx={{ my: 3 }} />
                                </Grid>

                            </Grid>
                            {data !== undefined && data.Items.length > 0 &&
                                <>
                                    <Grid item xs={12} sx={{ mt: 1 }}>
                                        <Box component={'div'} sx={{ width: '100%' }}>
                                            <StripedDataGrid
                                                localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                                rows={data ? data.Items : []}
                                                autoHeight={true}
                                                columns={columns}
                                                pageSize={8}
                                                rowsPerPageOptions={[8]}
                                                checkboxSelection={false}
                                                disableColumnFilter={false}
                                                disableColumnMenu={false}
                                                disableSelectionOnClick={true}
                                                disableVirtualization={false}
                                                hideFooter={false}
                                                getRowClassName={(params) =>
                                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                                }
                                                getRowId={(x) => x.RetrievedLicenceId}

                                                components={{
                                                    LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                                }}
                                                loading={isLoading}
                                            />
                                        </Box>

                                    </Grid></>
                            }
                        </Grid>

                    </Grid>

                    {data !== undefined && data.Items.length > 0 && CanDeactivate() &&
                        <>
                            <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                <Divider sx={{ my: 3 }} />
                            </Grid>

                            <Grid item xs={10} sx={{ textAlign: 'left' }}>
                                <div
                                    dangerouslySetInnerHTML={{ __html: getTranslatedText("DeactivateLicenceByDeviceButtonInfoText") }}
                                />
                            </Grid>
                            <Grid item xs={2} my={'auto'}>
                                <LoadingButton
                                    disabled={(!data || !data.Items) || data?.Items?.length === 0}
                                    loading={isDeactivating}
                                    onClick={() => setConfirmDialogOpen(true)}
                                    startIcon={<Delete />}
                                    fullWidth
                                    color="error"
                                    variant="contained">{getTranslatedText("DeactiveLicence")}</LoadingButton>
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                <Divider sx={{}} />
                            </Grid>
                        </>
                    }

                    <YesNoDialog
                        title={getTranslatedText("DeactivateLicencesByDeviceConfirmationDialogTitle")}
                        content={getTranslatedText("DeactivateLicencesByDeviceConfirmationDialogContent")}
                        titleYesButton={getTranslatedText("TEXT_YES")}
                        titleNoButton={getTranslatedText("TEXT_NO")}
                        open={confirmDialogOpen}
                        onNoClick={handleConfirmDeactivationNoClick}
                        onYesClick={handleConfirmDeactivationYesClick}
                    />
                    <ConfirmDialog
                        open={deactivatedSapOrderNumbers.length > 0}
                        onOkClick={handleCloseDeactivatedSapOrderNumberDialog}
                        titleOkButton={getTranslatedText("TEXT_OK")}
                        title={getTranslatedText("DeactivatedSapOrderNumbersDialogTitle")}
                        contentIsElement
                        content={<>
                            <Typography mb={2}>{getTranslatedText("DeactivatedSapOrderNumbersDialogContent")}</Typography>
                        <CopyChip isCopyEnabled label={deactivatedSapOrderNumbers.join(', ')} />
                        </>
                    }
                    />
                </>
            }
        </LanguageConsumer>
    )
}