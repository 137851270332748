import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { ImportantLinks } from "./Components/ImportantLinks";
import { SocialMediaLinks } from "./Components/SocialMediaLinks";

export const DepragFooter = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:1200px)');
    const location = useLocation();

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                {location.pathname !== '/login' &&    <Box component={'div'} sx={{
                        background: theme.palette.grayey.light,
                        maxWidth: isMobile ? '100vw' : 'calc(100vw + 20px)',
                        m: isMobile ? -1 : -4,
                        mr: isMobile ? -1 : -3,
                        mb: -5,
                        minHeight: 100,
                        p: 0,
                        pb: 5,
                        mt: 0,
                        boxSizing: 'content-box',
                        overflowX: 'hidden',
                        color: theme.palette.footer.main,
                        borderTop: `1px solid ${theme.palette.grayey.main}`
                    }}>
                        <>
                            <Grid container direction={'row'} spacing={2} sx={{ mt: 3, ml: 0, justifyContent: "space-evenly"}}>
                                <Grid item xs={12} md={4} my={'auto'} sx={{ textAlign: 'center' }}>
                                    <ImportantLinks />
                                </Grid>
                                <Grid item xs={12} md={4} my={'auto'} sx={{ textAlign: 'center', mt: isMobile ? 3 : 0, }}>
                                    
                                    <SocialMediaLinks />
                                </Grid>
                                <Grid item xs={12} md={4} my={'auto'}  sx={{ mt: isMobile ? 3 : 0, textAlign: 'center', mr: isMobile ? 0 : -5, alignSelf: isMobile ? 'auto' : 'center' }}>
                                   <img alt="DEPRAG LOGO" style={{maxHeight: 40}} src={'deprag_b.png'} />
                                </Grid>
                            </Grid>
                        </>
                    </Box>}
                </>
            }
        </LanguageConsumer>
    )
}