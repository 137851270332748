import { Chip, Tooltip, IconButton, Grid, Divider, TextField, FormControlLabel, Box, Skeleton, Button, Rating, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { globalDateFormatOptions } from "../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { CustomSwitch } from "../../../../../Layout/Switch/CustomSwitch";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { IoPencilSharp } from "react-icons/io5";
import { AddCircle, AddCircleOutline } from "@mui/icons-material";
import { OnlineTrainingOverviewResponseDto } from "../Models/OnlineTrainingOverviewResponseDto";
import { OnlineTrainingOverviewItem } from "../Models/OnlineTrainingOverviewItem";
import Flag from 'react-world-flags'
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";

export const MAX_YEAR = 9999;

export const AllCoursesTable = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const language = useAppSelector(store => store.settings.language);
    const location = useLocation();
    const [searchValue, setSearchValue] = useState<string>("");

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["allCourses"],
        queryFn: async () => await fetchMyDepragApi<OnlineTrainingOverviewResponseDto>(
             null,
            "onlinetraining/courses/overview",
            'GET',
            dispatch,
            store.getState() as RootState)
    });

    useEffect(() => {
        refetch();
    }, [location, refetch]);


    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const openDetails = (params: GridRowParams) => {
        navigate(`/videoCourses/edit/${params.row.CourseId}`)
    }

    const columns: GridColDef[] = [
        {
            field: 'CourseId',
            headerName: "Id",
            flex: 0,
            minWidth: 0,
        },
        {
            field: 'Title',
            headerName: getTranslation("OnlineTutorialsTitle"),
            flex: .5,
            minWidth: 200,
        },
        {
            field: 'Updated',
            headerName: getTranslation("OnlineTutorialsUpdated"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, OnlineTrainingOverviewItem, any>) => {
                return <Chip variant="outlined" color="success" label={new Date(params.row.Updated).toLocaleDateString(language, globalDateFormatOptions)} />
            }
        },
        {
            field: 'AverageRating',
            headerName: getTranslation("OnlineTutorialsRating"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, OnlineTrainingOverviewItem, any>) => {
                return  <><Rating name="read-only" value={params.row.AverageRating} readOnly /> <Typography sx={{ml: 1}}>({params.row.RatingsCount})</Typography></>
            }
        },
        {
            field: 'LanguageIsoCode',
            headerName: getTranslation("OnlineTutorialsLanguage"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, OnlineTrainingOverviewItem, any>) => {
                return  <Flag code={ params.row.LanguageIsoCode === 'de' ? 'de' : 'us' } height={14}  />
            },
            valueGetter: (params: GridRenderCellParams<any, OnlineTrainingOverviewItem, any>) => getTranslation(`Language_${params.row.LanguageIsoCode.toLocaleUpperCase()}`)
        },
        {
            field: 'DurationInMinutes',
            headerName: getTranslation("OnlineTutorialsDurationInMinutes"),
            flex: 1,
            minWidth: 130,
            renderCell: (params: GridRenderCellParams<any, OnlineTrainingOverviewItem, any>) => {
                return <Chip label={`${params.row.DurationInMinutes} min`} />
            }
        },
        {
            field: '',
            headerName: '',
            flex: 0.1,
            minWidth: 100,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, OnlineTrainingOverviewItem, any>) => (
                <strong>
                    <Tooltip title={getTranslation("CommonEdit")}>
                        <IconButton color={'darky'} component={NavLink} to={`/videoCourses/edit/${params.row.CourseId}`}>
                            <IoPencilSharp />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },
    ];

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("VideoCoursesInfoText") }}
                                    />

                                    <Divider sx={{ my: 3, mb: 2 }} />
                                </Grid>

                                <Grid item xs={4} my={'auto'} sx={{textAlign: 'left', overflowX: 'hidden'}}>
                                  <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>
                                <Grid item xs={8} my={'auto'} sx={{textAlign: 'right'}}>
                                    <Button startIcon={<AddCircleOutline />} component={NavLink} to={`/videoCourses/edit/0`} variant="contained" color="success">{getTranslatedText("CreateVideoCourse")}</Button>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        localeText={language === "de" ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={data ? data.Items.filter(x =>
                                            (x.Title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            x.CourseId.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                               x.Description.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                x.DurationInMinutes.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) || 
                                                getTranslatedText(`Language_${x.LanguageIsoCode.toLocaleUpperCase()}`).toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                new Date(x.Updated).toLocaleDateString(language, globalDateFormatOptions).includes(searchValue.toLocaleLowerCase()) 
                                                )
                                        ) : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                          }
                                        getRowId={(x: any) => x.CourseId}
                                        onRowDoubleClick={openDetails}
                                        components={{
                                            LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                        }}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}