import { useLocation } from "react-router-dom";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { Box, Divider, FormControlLabel, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, TextField} from "@mui/material";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { DataGrid } from "@mui/x-data-grid";
import { WaitingForEmailVerificationResponseDto } from "../Models/WaitingForEmailVerificationResponseDto";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { CustomSwitch } from "../../../../../Layout/Switch/CustomSwitch";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { useWaitForEmailVerificationTableColumnDefinitions } from "./useWaitForEmailVerificationTableColumnDefinations";
import { showResendPopup, showDeletePopup, toggleShowAllWaitForEmailVerifications } from "../Store/WaitingForEmailVerificationSlice";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { WaitForEmailVerificationDeletePopup } from "./Popups/WaitForEmailVerificationDeletePopup";
import { WaitForEmailVerificationResendPopup } from "./Popups/WaitForEmailVerificationResendPopup";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import { StepsForRegistration } from "../../../../../Layout/StepsForRegistration/StepsForRegistration";


export const WaitForEmailVerificationsTable = () => {
    const dispatch = useAppDispatch();    
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const isDepragAmbergMember = useAppSelector(store => store.authentication.isDepragAmbergMember);
    const [searchValue, setSearchValue] = useState<string>("");
    const showAllUsers = useAppSelector(store => store.waitingForEmailVerifications.showAllUsers);
    const {visible: visibleDelete} = useAppSelector(store => store.waitingForEmailVerifications.deletePopup);
    const {visible: visibleResend} = useAppSelector(store => store.waitingForEmailVerifications.resendPopup);

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["WaitForEmailVerification"],
        queryFn: async () => await fetchMyDepragApi<WaitingForEmailVerificationResponseDto>(
            {showAll: isDepragAmbergMember ? showAllUsers : false},
            "users/WaitingForEmailVerification/overview",
            'POST',
            dispatch,
            store.getState() as RootState),
        cacheTime: 0
    });

    const { columns, selectedUserId, selectedUserEmail, anchorEl, openMenu, handleClose } = useWaitForEmailVerificationTableColumnDefinitions(language);

    useEffect(() => {
        refetch();
    }, [location, refetch, showAllUsers, visibleDelete, visibleResend]);


    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const showAllCustomersChanged = (value: boolean) => {
        dispatch(toggleShowAllWaitForEmailVerifications());
    }
  
    
    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("WaitForEmailVerificationsInfoText") }}
                                    />


                                    
                                </Grid>

                                <StepsForRegistration maxStepKeyForSuccessColor={1}  sx={{mb: 4, mt: 2}} />

                                <Grid item xs={4} my={'auto'} sx={{textAlign: 'left', overflowX: 'hidden'}}>
                                  <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>
                            {isDepragAmbergMember &&  <Grid item xs={8} my={'auto'} sx={{ textAlign: 'right' }}>
                                    <FormControlLabel control={<CustomSwitch />} checked={showAllUsers} onChange={(e, value) => showAllCustomersChanged(value)} label={getTranslatedText("ShowAllUsers")} />
                                </Grid>}

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={data ? data.Items.filter(x =>
                                            (x.CompanyName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            (x.UserId.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                                x.UserLastName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                x.UserFirstName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                x.Email.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                getTranslation(x.State).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                                                )
                                        ) : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                          }
                                        getRowId={(x) => x.UserId}                                       
                                        components={{
                                            LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                        }}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        elevation={3}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ borderRadius: 3 }}
                    >
                      <MenuItem onClick={() => {
                            handleClose();
                            dispatch(showResendPopup({email: selectedUserEmail, userId: selectedUserId}))
                        }}>
                            <ListItemIcon>
                             <CheckCircle color="success" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{getTranslatedText("WaitForEmailVerificationResendUser")}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleClose();
                            dispatch(showDeletePopup({email: selectedUserEmail, userId: selectedUserId}))
                        }}>
                            <ListItemIcon>
                             <Cancel color="error" fontSize="small" /> 
                            </ListItemIcon>
                            <ListItemText>{getTranslatedText("WaitForEmailVerificationDeleteUser")}</ListItemText>
                        </MenuItem>
                    </Menu>

                    <WaitForEmailVerificationDeletePopup />
                    <WaitForEmailVerificationResendPopup />
                </>
            }
        </LanguageConsumer>
    )

}