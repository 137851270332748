import { IconButton, Tooltip, useTheme } from "@mui/material"
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService"
import { FiSun, FiMoon } from "react-icons/fi"
import { setAppTheme } from "../../../Features/MainMenus/Settings/Store/SettingsSlice"
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks"

export const ThemeButton = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
            <Tooltip title={getTranslatedText(isDarkMode ? "SetLightMode" : "SetDarkMode")} className={'global-toolbar-theme'}>
                <IconButton
                    aria-label="open drawer"
                    onClick={() => dispatch(setAppTheme(isDarkMode ? 'light' : 'dark'))}
                    edge="start"
                    sx={{
                        background: isDarkMode ? '#222' : theme.palette.darky.light,
                        color: theme.palette.darky.main,
                        ml: 2
                    }}
                >
                    {isDarkMode ? <FiSun /> : <FiMoon />}
                </IconButton>
            </Tooltip>
            </>
            }
        </LanguageConsumer>
    )
}
