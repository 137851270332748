import {
  Backdrop,
  Box,
  BoxProps,
  Button,
  Grid,
  Paper,
  Slide,
  Stack,
  StyledComponentProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { ReactNode, useState } from "react";
import { useAppSelector } from "../../Infrastructure/Store/hooks";
import { Link } from "react-router-dom";
import { UpCounter } from "../UpCounter/UpCounter";


interface CustomImageProps {
  src: string;
  overlayEnabled?: boolean,
  borderCustomRadius?: number[],
  hasOverlay?: boolean
  overlayTitle?: string,
  overlayDescription?: string,
  initialDescription?: string,
  toLink?: string,
  clipPath?: "right" | "left",
  centerOverlayText?: boolean,
  blurIntensity?: number,
  slideDirection?: "left" | "up" | "down" | "right",
  slidePosition?: "left" | "right",
  hasNoHoverEffect?: boolean,
  icon?: ReactNode,
  iconSide?: "left" | "right",
  count?: number,
  brightness?: number,
  gradientColor?: string
}

interface Props extends BoxProps, CustomImageProps, StyledComponentProps {
}

/**
 * Custom image component by DEPRAG
 * @param {string} props.src Path to image file
 * @param {boolean} props.hasOverlay Use overlay on image (optional)
 * @param {boolean} props.overlayEnabled Is overlay active by default (optional)
 * @param {number[]} props.borderCustomRadius Border radius for component [topLeft,topRight,bottomLeft,bottomRight] (optional)
 * @param {string} props.overlayTitle Title of overlay (optional)
 * @param {string} props.initialDescription Description before hovering over element (optional)
 * @param {string} props.overlayDescription Description after hovering over element (optional)
 * @param {string} props.toLink NavLink to route on details button (optional)
 * @param {"right" | "left"} props.clipPath Clip the image on left or right side (optional)
 * @param {boolean} props.centerOverlayText Center all overlay texts (optional)
 * @param {number} props.blurIntensity Intensity of the blur (optional)
 * @param {number} props.slideDirection Direction of Slide 'left, right, up, down' (optional)
 * @param {number} props.slidePosition Slideposition after hover 'left, right' (optional)
 *
 * @visibleName The Best Status Card Component by DEPRAG🐙
 */

export const CustomStatusCard = (props: Props) => {
  const [overlayActive, setOverlayActive] = useState<boolean>(true);
  const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
  const isMobile = useMediaQuery("(max-width:500px)");

  return (
    <LanguageConsumer>
      {({getTranslatedText}) =>
        <>
          <Box elevation={0}
               className={props.className}
               onClick={props.onClick}
               onMouseOver={() => setOverlayActive(old => !Boolean(old))}
               onMouseOut={() => setOverlayActive(old => !Boolean(old))}
               onContextMenu={props.onContextMenu}
               component={Paper}
               sx={{
                 borderTopLeftRadius: props.borderCustomRadius !== undefined ? props.borderCustomRadius[0] : 0,
                 borderBottomLeftRadius: props.borderCustomRadius !== undefined ? props.borderCustomRadius[2] : 0,
                 borderTopRightRadius: props.borderCustomRadius !== undefined ? props.borderCustomRadius[1] : 0,
                 borderBottomRightRadius: props.borderCustomRadius !== undefined ? props.borderCustomRadius[3] : 0,
                 background: props.gradientColor ? `radial-gradient(at 70%, #f0f0f0 0%, ${props.gradientColor} 49%)` : `url(${props.src})`,
                 cursor: props.hasNoHoverEffect && props.toLink && overlayActive ? "pointer" : "default",
                 backgroundSize: "cover",
                 width: "100%",
                 height: props.height,
                 display: "flex",
                 overflow: "hidden",
                 position: "relative",
                 alignItems: "flex-end",
                 justifyContent: props.slidePosition === undefined ? "flex-end" : (props.slidePosition === "right" ? "flex-start" : "flex-end"),
                 alignContent: "center",
                 transition: "all 0.3s ease-in-out",
                 clipPath: props.clipPath && !isMobile ? "circle(100% at 50% 50%)" : "none",
                 "&:hover": {
                   clipPath: props.clipPath && !isMobile ? (props.clipPath === "right" ? "circle(83.6% at 16% 50%)" : "circle(86.0% at 86% 50%)") : "none",
                 },
                 ...props.sx,
               }}
          >
            {props.hasOverlay && (props.hasNoHoverEffect === undefined || !props.hasNoHoverEffect) &&
              <Slide direction={props.slideDirection ? props.slideDirection : "up"} in={!overlayActive}>
                <Box elevation={0} component={Paper} sx={{
                  display: "flex",
                  alignItems: "center",
                  height: props.slideDirection === "left" || props.slideDirection === "right" ? "100%" : (isMobile ? "100%" : "auto"),
                  width: props.slideDirection === "left" || props.slideDirection === "right" ? (isMobile ? "100%" : "50%") : "100%",
                  borderRadius: 0,
                  background: isDarkMode ? "rgba(0,0,0,0.9)" : "rgba(255,255,255,0.9)",
                  p: 2,
                  textAlign: "left",
                }}>
                  <Grid container direction={"row"} spacing={2}
                        sx={{textAlign: props.centerOverlayText ? "center" : "left"}}>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight={700}>{props.overlayTitle}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{mt: -1}}>
                      <Typography>{props.overlayDescription}</Typography>
                    </Grid>
                    {props.toLink && <Grid item xs={12}>
                      <Button component={Link} to={props.toLink}
                              variant="outlined">{getTranslatedText("CommonMore")}</Button>
                    </Grid>}
                  </Grid>
                </Box>
              </Slide>}


            {props.hasOverlay &&
              <Backdrop sx={{
                backdropFilter: `blur(${props.blurIntensity ? props.blurIntensity : 0}px)`,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                p: 0,
                m: 0,
                background: isDarkMode ? "rgba(0, 0, 0, 0.301)" : "rgba(255, 255, 255, 0.301)",
                alignItems: "flex",
                justifyContent: "center",
                textAlign: "center",
                cursor: props.hasNoHoverEffect && props.toLink && !overlayActive ? "pointer" : "default",
              }}
                        open={overlayActive || (!overlayActive && props.hasNoHoverEffect !== undefined && props.hasNoHoverEffect)}>
                <Grid container direction={"row"} spacing={2}>
                  {props.icon !== undefined && <Grid item xs={12} md={5} my={"auto"}>
                    {props.icon}
                  </Grid>}
                  <Grid item xs={12} md={props.icon !== undefined ? 7 : 12} my={"auto"}>
                    <Stack direction={"column"}>
                      {props.count !== undefined && <Typography variant={isMobile ? "h6" : "h2"}><UpCounter speed={20}
                                                                                                            maxValue={props.count ? props.count : 0}/></Typography>}
                      <Typography sx={{textAlign: "center"}} variant={isMobile ? "h6" : "h5"} fontFamily={"Fira Sans"}
                                  fontWeight={400}>{props.overlayTitle}</Typography>
                    </Stack>
                  </Grid>
                  {props.initialDescription && <Grid item xs={12} sx={{mt: -1, mx: 3}}>
                    <Typography>{props.initialDescription}</Typography>
                  </Grid>}
                </Grid>
              </Backdrop>}
          </Box>
        </>
      }
    </LanguageConsumer>
  );
};
