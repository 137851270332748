import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  TextField,
  useTheme,
} from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../../../Store/AppSlice";
import {
  LanguageConsumer,
  getTranslation,
} from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { CustomBox } from "../../../../../Layout/CustomBox";
import {
  ListboxComponent,
  StyledPopper,
} from "../../../../../Layout/Inputs/AutoCompleteVirtualizationHelper";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { CustomerOverviewResponseDto } from "../../../Customers/AllCustomers/Models/CustomerOverviewResponseDto";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { DefaultResponse } from "../../../../../Infrastructure/Api/Model/DefaultResponse";
import { CustomerOverviewItemDto } from "../../../Customers/AllCustomers/Models/CustomerOverviewItemDto";
import { EquipmentByCompanyAssignRequestDto } from "./Model/EquipmentByCompanyAssignRequestDto";
import { resetSelectedEquipmentIds } from "./Store/EquipmentByCompanyAssignSlice";

export const EquipmentByCompanyAssign = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isDepragAmbergMember, isReseller } = useAppSelector(store => store.authentication);
  const language = useAppSelector((store) => store.settings.language);
  const languageFileWasLoaded = useAppSelector(
    (store) => store.languageFile.languageFileWasLoaded
  );
  const { selectedEquipmentIds, assignedToCustomerId, resetManyAssigned } = useAppSelector(store => store.equipmentByCompanyAssign);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const customerId = useAppSelector(store => store.authentication.customerId);

  const {
    isLoading: customerIsLoading,
    data: customerData,
    refetch: customerRefetch,
  } = useQuery({
    queryKey: ["customers"],
    queryFn: async () =>
      await fetchMyDepragApi<CustomerOverviewResponseDto>(
        { showAll: false, preferredLanguageIso: language },
        "customers/overview/" + customerId,
        "POST",
        dispatch,
        store.getState() as RootState
      ),
  });

  const customerFind = customerData?.Items.find(item => assignedToCustomerId === item.Id); 
  
  useEffect(() => {
    customerRefetch();
  }, [location, customerRefetch]);

  const mapCompanyNameOption = (option: CustomerOverviewItemDto) => {
    return `${option.CompanyName} ${(option.SapCustomerId && option.SapCustomerId.trim().length > 0)
      ? `(SAP: ${option.SapCustomerId})` : ""} ${option.InternalId ? `| ${option.InternalId}` : ""}`;
  };

  const onReset = ()=>{
    onSubmit(true)
  }

  const onSubmit = async (isReset?: boolean) => {
    const selectedCustomerId = customerData?.Items.find(x => mapCompanyNameOption(x).includes(selectedCustomer!))?.Id;

    const dataToPost: EquipmentByCompanyAssignRequestDto = {
      CustomerId: isReset ? customerId : !selectedCustomerId ? 0 : selectedCustomerId,
      EquipmentIds: selectedEquipmentIds,
    };

    const response = await fetchMyDepragApi<DefaultResponse>(
      dataToPost,
      "installedbase/equipment/byCompany/assign",
      "POST",
      dispatch,
      store.getState() as RootState,
      isReset ? "SuccessfullyResetEquipment" : "SuccessfullyAssignedEquipment",
      true);

    if(response.Success){
        navigate(-1);
        dispatch(resetSelectedEquipmentIds());
    }
  };

  useEffect(() => {
    dispatch(setAppTitle(getTranslation("MainMenuEquipmentByCompanyAssignBreadcrumb")));
  }, [location, language, languageFileWasLoaded, dispatch]);

  useEffect(() => {
    if (customerFind && customerFind.Id !== customerId) setSelectedCustomer(mapCompanyNameOption(customerFind).trim());
  }, [customerData, customerFind, customerId])


  return (
    <LanguageConsumer>
      {({ getTranslatedText }) => (
        <>
          <Grid
            container
            direction="row"
            spacing={2}
            className="main-content"
            sx={{ mt: 1 }}
          >
            <Grid item xs={12} mx={3}>
              <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Button
                    color="darky"
                    onClick={() => navigate(-1)}
                    variant="outlined"
                  >
                    {getTranslatedText("Prev")}
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <CustomBox
                    elevation={0}
                    sx={{
                      backgroundSize: "cover",
                      backgroundBlendMode: "multiply",
                      backgroundColor: "transparent",
                      border: `1px solid ${theme.palette.cardBorder.main}`,
                    }}
                  >
                      <Grid container direction={"row"} spacing={2}>
                        <Grid item xs={12} sx={{ textAlign: "left" }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: getTranslatedText("EquipmentByCompanyAssignInfoText", [selectedEquipmentIds.length.toString()]),
                            }}
                          />
                          <Divider sx={{ my: 3, mb: 2 }} />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            disableListWrap
                            PopperComponent={StyledPopper}
                            ListboxComponent={ListboxComponent}
                            renderOption={(props, option, state) =>
                              [props, option, state.index] as React.ReactNode
                            }
                            disabled={customerIsLoading || !customerData}
                            noOptionsText={getTranslatedText(
                              "NoCustomersAvailable"
                            )}
                            fullWidth
                            id="customers"
                            options={
                              customerData
                                ? customerData.Items.map((option) => mapCompanyNameOption(option).trim())
                                : []
                            }
                            value={selectedCustomer}
                            ListboxProps={{
                              style: { zIndex: 10002 },
                            }}
                            onChange={(event: any, newValue: string | null) => {
                              if (newValue) {
                                setSelectedCustomer(newValue);
                              } else {
                                setSelectedCustomer(null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label={getTranslatedText(
                                  "AssignCustomerName"
                                )}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "right",
                            gap: 2,
                          }}
                        >
                          {((customerFind &&  customerFind?.Id !== customerId) || resetManyAssigned ) &&
                          <Button color="error"
                                                                                     variant="contained"
                                                                                     onClick={onReset}>
                            {getTranslatedText("ReturnAssigning")}
                          </Button>}

                          {(isDepragAmbergMember || isReseller) && <Button color="darky"
                                  variant="outlined"
                                  component={NavLink}
                                  to={"/customers/create"}
                          >
                            {getTranslatedText("CreateCustomer")}
                          </Button>}


                          <Button color="success"
                                  disabled={!selectedCustomer || selectedEquipmentIds.length === 0}
                                  variant="contained"
                                  onClick={() => onSubmit()}
                          >
                            {getTranslatedText("AssignLicences")}
                          </Button>
                        </Grid>
                      </Grid>
                  </CustomBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </LanguageConsumer>
  );
};
