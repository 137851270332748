import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../Infrastructure/Store/hooks";

interface UpCounterProps {
    maxValue: number,
    speed: number
}

export const UpCounter = (props: UpCounterProps) => {
    const {maxValue, speed } = props;
    const [numberCount, setNumberCount] = useState<number>(0);
    const showAll = useAppSelector(store => store.dashboard.showAll);

    useEffect(() => {
        setNumberCount(0);
    }, [showAll])
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            if(numberCount < maxValue) {
                setNumberCount(numberCount + 1);
            }
        }, maxValue > 100 ? (maxValue > 5000 ? .2 : 1) : speed);
    
        return () => {
          clearTimeout(timeout);
        };
      }, [numberCount, maxValue, speed])

    return <>{numberCount}</>
}