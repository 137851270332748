export interface UserOverviewItem {
    UserId: number,
    UserLastName: string,
    UserFirstName: string,
    CompanyName: string,
    Email: string,
    State: UserState
}

export enum UserState {
    VerifyEmail = "VerifyEmail",
    WaitingApproval = "WaitingApproval",
    IsApproved = "IsApproved",
    IsDeleted = "IsDeleted"
}