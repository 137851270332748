import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { useEffect } from "react";
import { setAppTitle } from "../../../../../Store/AppSlice";
import { getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { useLocation } from "react-router-dom";
import { LogsTable } from "./Components/LogsTable";
import { Grid, useTheme } from "@mui/material";
import { CustomBox } from "../../../../../Layout/CustomBox";

export const Logs = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
    const theme = useTheme();

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuLogsBreadcrumb')));
    }, [location, language, languageFileWasLoaded, dispatch]);

    return (
        <>
            <Grid container direction="row" spacing={2} className="main-content" sx={{mt: 1}}>
                <Grid item xs={12}>
                    <CustomBox elevation={0} sx={{mx: 3,
                        height: '100%',
                        backgroundSize: 'cover', 
                        backgroundBlendMode: 'multiply',
                        backgroundColor: isDarkMode ? "transparent" : "#fff", 
                        border: `1px solid ${theme.palette.cardBorder.main}` }}>
                        <LogsTable />
                    </CustomBox>
                </Grid>
            </Grid>
        </>
    )
}