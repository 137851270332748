import { Grid, Divider, TextField, FormControlLabel, Box, Skeleton, Menu, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { CustomSwitch } from "../../../../../Layout/Switch/CustomSwitch";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { OpenRegistrationOverview } from "../Models/OpenRegistrationOverview";
import { showAcceptPopup, showDeclinePopup, toggleShowAllOpenRegistrations } from "../Store/OpenRegistrationsSlice";
import { OpenRegistrationOverviewItem } from "../Models/OpenRegistrationOverviewItem";
import { useOpenRegistrationsTableColumnDefinitions } from "./useOpenRegistrationsTableColumnDefinitions";
import { Cancel, CheckCircle, Check } from "@mui/icons-material";
import { OpenRegistrationDeclinePopup } from "./Popups/OpenRegistrationDeclinePopup";
import { OpenRegistrationAcceptPopup } from "./Popups/OpenRegistrationAcceptPopup";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import { StepsForRegistration } from "../../../../../Layout/StepsForRegistration/StepsForRegistration";

export const MAX_YEAR = 9999;

export const OpenRegistrationsTable = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const language = useAppSelector(store => store.settings.language);
    const location = useLocation();
    const { isDepragAmbergMember } = useAppSelector(store => store.authentication);
    const [searchValue, setSearchValue] = useState<string>("");
    const showAllOpenRegistrations = useAppSelector(store => store.openRegistrations.showAllOpenRegistrations);
    const {visible: visibleDecline} = useAppSelector(store => store.openRegistrations.declinePopup);
    const {visible: visibleAccept} = useAppSelector(store => store.openRegistrations.acceptPopup);

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["openRegistrations"],
        queryFn: async () => await fetchMyDepragApi<OpenRegistrationOverview>(
             {showAll: isDepragAmbergMember ? showAllOpenRegistrations : false, preferredLanguageIso: language},
            "users/openregistrations/overview",
            'POST',
            dispatch,
            store.getState() as RootState),
            cacheTime: 0
    });

    const { columns, selectedUserId, selectedUserEmail, anchorEl, openMenu, handleClose } = useOpenRegistrationsTableColumnDefinitions(language);

    useEffect(() => {
        refetch();
    }, [location, refetch, showAllOpenRegistrations, !visibleDecline, !visibleAccept, dispatch]);

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const showAllCustomersChanged = (value: boolean) => {
        dispatch(toggleShowAllOpenRegistrations())
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("OpenRegistrationsInfoText") }}
                                    />
                                </Grid>

                                <StepsForRegistration maxStepKeyForSuccessColor={2}  sx={{mb: 4, mt: 2}} />


                                <Grid item xs={4} my={'auto'} sx={{textAlign: 'left', overflowX: 'hidden'}}>
                                  <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>
                             {isDepragAmbergMember &&   <Grid item xs={8} my={'auto'} sx={{ textAlign: 'right' }}>
                                    <FormControlLabel control={<CustomSwitch />} checked={showAllOpenRegistrations} onChange={(e, value) => showAllCustomersChanged(value)} label={getTranslatedText("ShowAllOpenRegistrations")} />
                                </Grid>}

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={data ? data.Items.filter(x =>
                                            (x.Email.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            (x.UserId.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                                x.UserRegistration.FirstName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                x.UserRegistration.LastName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                x.UserRegistration.CompanyName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                                                 )
                                        ) : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                          }
                                        getRowId={(x: any) => x.UserId}
                                        components={{
                                            LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                        }}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        elevation={3}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ borderRadius: 3 }}
                    >
                      <MenuItem onClick={() => {
                            handleClose();
                            dispatch(showAcceptPopup({email: selectedUserEmail, userId: selectedUserId}))
                        }}>
                            <ListItemIcon>
                             <CheckCircle color="success" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{getTranslatedText("AcceptUser")}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleClose();
                            dispatch(showDeclinePopup({email: selectedUserEmail, userId: selectedUserId}));
                        }}>
                            <ListItemIcon>
                             <Cancel color="error" fontSize="small" /> 
                            </ListItemIcon>
                            <ListItemText>{getTranslatedText("DeclineUser")}</ListItemText>
                        </MenuItem>
                    </Menu>

                    <OpenRegistrationDeclinePopup />
                    <OpenRegistrationAcceptPopup />
                </>
            }
        </LanguageConsumer>
    )
}