export interface ClaimsEditResponseDto {
    UserId: number,
    CompanyName: string,
    Email: string,
    IsGoldMember: boolean,
    Claims: SingleClaim[]
}

export interface SingleClaim {
    Claim: MyDepragClaim,
    Value: string,
    IsEnabled: boolean,
    CanHasValue: boolean
}

export enum MyDepragClaim {
    AccountOwner = "AccountOwner",
    DepragAmbergMember = "DepragAmbergMember",
    DepragReseller = "DepragReseller",
    BackofficeSimpleUser = "BackofficeSimpleUser",
    BackofficeCustomers = "BackofficeCustomers",
    BackofficeUsers = "BackofficeUsers",
    BackofficeVideoCourses = "BackofficeVideoCourses",
    BackofficeLicencesByOrder = "BackofficeLicencesByOrder",
    BackofficeLicencesByDevice = "BackofficeLicencesByDevice",
    BackofficeLicencesByCompany = "BackofficeLicencesByCompany",
    BackofficeMyGoldLicences = "BackofficeMyGoldLicences",
    BackofficeLicencesByDeviceDeactivate = "BackofficeLicencesByDeviceDeactivate",
    HighlevelClaim = "HighlevelClaim",
    BackofficeHelp = "BackofficeHelp",
    BackofficeProductManagement = "BackofficeProductManagement",
    BackofficeLoginAllowed = "BackofficeLoginAllowed",
    BackofficeServiceRequests = "BackofficeServiceRequests",
    BackofficeEquipmentByCompany = "BackofficeEquipmentByCompany",
    BackofficeSoftwareCockpitCloud = "BackofficeSoftwareCockpitCloud",
    BackofficeSoftwarePreRelease = "BackofficeSoftwarePreRelease"
}
