import { useRef, useState } from "react";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { Box, Icon, InputAdornment, Slide, TextField, Tooltip } from "@mui/material";
import { FiSearch } from "react-icons/fi";

interface DataGridSearchProps {
    searchValue: string,
    search: (value: string) => void,
    label?: string
}

export const DataGridSearch = (props: DataGridSearchProps) => {
    const [iconOnly, setIconOnly] = useState<boolean>(true);
    const containerRef = useRef(null)

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <Box component={'div'} ref={containerRef} >
            {iconOnly ? 
            <Box component="div" sx={{height: 55, transition: 'all 0.3s ease-in-out'}}><Tooltip title={getTranslatedText("CommonSearch")}><Icon sx={{cursor: 'pointer', mt: 1.5, fontSize: 30}} onClick={() => setIconOnly(old => !Boolean(old))}> <FiSearch /></Icon></Tooltip></Box>
            :
            <Slide in={!iconOnly} direction="right" mountOnEnter unmountOnExit container={containerRef.current}>
            <TextField
                onBlur={() => props.searchValue.trim().length === 0 && setIconOnly(old => !Boolean(old))}
                autoFocus={!iconOnly}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <FiSearch />
                        </InputAdornment>
                    )
                }}
                variant="outlined"
                color="success"
                fullWidth
                type={'search'}
                value={props.searchValue}
                onChange={(e) => props.search(e.target.value)}
                label={props.label ? props.label : getTranslatedText("CommonSearch")}
            /></Slide>}
        
            </Box>
            }
        </LanguageConsumer>
    )
}