import { Button, Chip, Collapse, Divider, Grid, IconButton, Paper, Stack, TextField, Typography, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { DateTimePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { NavLink, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks"
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBox } from "../../../../../Layout/CustomBox"
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall"
import { useQuery } from "@tanstack/react-query"
import { RootState, store } from "../../../../../Infrastructure/Store/store"
import { LegalDocumentsDetailResponse } from "../Models/LegalDocumentsDetailResponse"
import HtmlParser from "react-html-parser"
import { ArrowDropDown, ArrowDropUp, Download } from "@mui/icons-material"
import "./Style/style.css"
import { DocumentType } from "../Models/DocumentType"
import { isDocumentTypeWithHtmlInput } from "../Logic/LegalDocumentsLogic"

export const LegalDocumentsDetail = () => {
    const { id } = useParams();
    const theme = useTheme();
    const isDarkMode = useAppSelector((store) => store.settings.theme === "dark");
    const dispatch = useAppDispatch();
    const [downloadUrl, setDownloadUrl] = useState('')
    const [openContent, setOpenContent] = useState(true);

    const { data } = useQuery({
        queryKey: ["legalDocumentsDetail"],
        queryFn: async () => await fetchMyDepragApi<LegalDocumentsDetailResponse>(
            null,
            "legalDocuments/detail/" + id,
            'POST',
            dispatch,
            store.getState() as RootState),
    });

    useEffect(() => {
        if (data) {
            let url;
            if (data.DocumentType === 'LegalInformationForDepragGoldPurchase') {
                url = 'data:application/pdf;base64,' + data.FileAsBase64String;
            }
            setDownloadUrl(url);
        }
    }, [data])

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
                    <Grid item xs={12} sx={{ mb: 2, mx: 1 }}>
                        <Button color="darky" component={NavLink} to={'/legalDocuments'} variant="outlined">
                            {getTranslatedText("Prev")}
                        </Button>
                    </Grid>

                    <CustomBox
                        elevation={0}
                        sx={{
                            m: 3,
                            pt: 2,
                            height: "100%",
                            width: "100%",
                            backgroundSize: "cover",
                            backgroundBlendMode: "multiply",
                            backgroundColor: isDarkMode ? "transparent" : "#fff",
                            border: `1px solid ${theme.palette.cardBorder.main}`,
                        }}
                    >

                        <Grid sx={{ mt: 1 }}>
                            <Grid mt={2}>
                                <TextField label={getTranslatedText('LegalDocumentsDocumentType')} value={data && data.DocumentType}
                                    disabled fullWidth InputLabelProps={{ shrink: true }} />
                            </Grid>

                            <Grid mt={4}>
                                <TextField label={getTranslatedText('LegalDocumentsLanguageIsoCode')} value={data && data.LanguageIsoCode}
                                    disabled fullWidth InputLabelProps={{ shrink: true }} />
                            </Grid>
                        </Grid>


                        <Grid container direction={"row"} spacing={2} sx={{ my: 2 }}>
                            <Typography paddingLeft={2} fontWeight={700}>
                                {getTranslatedText("LegalDocumentsTiming")}
                            </Typography>
                            <Grid item xs={12} container direction={"row"} spacing={3}>
                                <Grid id={`DateTimePicker_${isDarkMode ? "Dark" : "Light"}`} item xs={6}>
                                    <DateTimePicker
                                        label={getTranslatedText("LegalDocumentsValidFrom")}
                                        format="HH:mm DD/MM/YYYY"
                                        value={data ? moment(new Date(data.ValidFrom)) : moment()}
                                        readOnly
                                    />
                                </Grid>

                                <Grid id={`DateTimePicker_${isDarkMode ? "Dark" : "Light"}`} item xs={6}>
                                    <DateTimePicker
                                        label={getTranslatedText("LegalDocumentsValidUntil")}
                                        format="HH:mm DD/MM/YYYY"
                                        value={data ? moment(new Date(data.ValidUntil)) : moment()}
                                        readOnly
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {data && !isDocumentTypeWithHtmlInput(data.DocumentType as DocumentType) && <Grid container direction={"row"} spacing={2} sx={{ mt: 1 }}>
                            <Typography paddingLeft={2} fontWeight={700}>
                                {getTranslatedText("LegalDocumentsFileAttachment")}
                            </Typography>
                            <Grid item xs={12} container direction={"row"} spacing={3}>
                                <Chip id={'LegalDocumentsAttachment'} icon={<Download />} component={NavLink} to={downloadUrl} sx={{ ml: 3, mt: 2, cursor: 'pointer' }}
                                    download={getTranslatedText('LegalDocumentsGold')}
                                    label={getTranslatedText('LegalDocumentsGold')} />
                            </Grid>
                        </Grid>}



                        {data && isDocumentTypeWithHtmlInput(data.DocumentType as DocumentType) &&
                            <>
                                <Divider />

                                <Grid container direction={"column"} pl={0} spacing={2} sx={{ mt: 1 }}>
                                    <Stack direction={'row'}>
                                        <Typography paddingLeft={2} fontWeight={700} mt={1} mr={2}>
                                            {getTranslatedText("LegalDocumentsContent")}
                                        </Typography>

                                        <IconButton onClick={() => setOpenContent(!openContent)} >
                                            {openContent ? <ArrowDropUp /> : <ArrowDropDown />}
                                        </IconButton>
                                    </Stack>

                                    <Collapse in={openContent}>
                                        <Grid item xs={11} container direction={"row"} spacing={3} m={3}>
                                            <Typography component={Paper} sx={{}} elevation={0}>
                                                {data && HtmlParser(data.TextContent)}
                                            </Typography>
                                        </Grid>
                                    </Collapse>
                                </Grid>
                            </>}

                    </CustomBox>
                </Grid>
            }
        </LanguageConsumer>
    )
}