import { Controller, FieldValues, RegisterOptions } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select, FormHelperText, Box, Typography } from "@mui/material";
import { ControlledInputProps } from "./Models/ControlledInputProps";
import { getTranslation } from "../../Infrastructure/Internationalisation/TranslationService";


interface ControlledSelectProps<T extends FieldValues> extends ControlledInputProps<T> {
  rules?: Pick<
    RegisterOptions<FieldValues>,
    "maxLength" | "minLength" | "validate" | "required"
  >; //https://react-hook-form.com/docs/useform/register#options
  disabled?: boolean;
}

export const ControlledSelect = <T extends FieldValues>(
  {
    name,
    control,
    label,
    options,
    rules,
    disabled,
  }: ControlledSelectProps<T>) => {
  const generateSingleOptions = () => {
    return options?.map((option: any) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {getTranslation(option.label)}
        </MenuItem>
      );
    });
  };


  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel id={`${name}_form-input-dropdown-label`}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
                   field: {onChange, value, onBlur, name, ref},
                   fieldState: {invalid, isTouched, isDirty, error},
                   formState,
                 }) => (
          <>
            <Select value={value}
                    onChange={onChange}
                    labelId={`${name}_form-input-dropdown-label`}
                    label={label}
                    disabled={disabled}
                    error={!!error}>
              {generateSingleOptions()}
            </Select>
            {!!error &&
              <FormHelperText>
                <Typography fontSize={11}
                            color={"error"}>
                  {error.message}
                </Typography>
              </FormHelperText>}
          </>
        )}
      />

    </FormControl>
  );
};
