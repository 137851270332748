import { Box, Chip, Grid, IconButton, Skeleton, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { IoPencilSharp } from "react-icons/io5";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { LanguageConsumer, getTranslation } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks";
import { RootState, store } from "../../../../../../Infrastructure/Store/store";
import { DataGridSearch } from "../../../../../../Layout/DataGrid/DataGridSearch";
import { StripedDataGrid } from "../../../../../../Layout/DataGrid/StripedDataGrid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { UserOverviewItem, UserState } from "../Models/UserOverviewItem";
import { UserOverviewResponseDto } from "../Models/UserOverviewResponseDto";

export const MAX_YEAR = 9999;

const getStateColor = (state: UserState) => {
    switch (state) {
        case UserState.IsApproved:
            return 'success';
        case UserState.IsDeleted:
            return 'error';
        case UserState.WaitingApproval:
            return 'info';
        case UserState.VerifyEmail:
            return 'default';
        default:
            return 'default';
    }
}

export const AllClaimsTable = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const location = useLocation();
    const [searchValue, setSearchValue] = useState<string>("");

    const { isLoading, data, refetch } = useQuery({
        queryKey: ["users"],
        queryFn: async () => await fetchMyDepragApi<UserOverviewResponseDto>(null, 
            "highlevel/claimseditor/overview",
            'POST',
            dispatch,
            store.getState() as RootState)
    });

    useEffect(() => {
        refetch();
    }, [location, refetch]);

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const openDetails = (params: GridRowParams) => {
        navigate(`/highlevel/claims/edit/${params.row.UserId}`)
    }

    const columns: GridColDef[] = [
        {
            field: 'UserId',
            headerName: "Id",
            flex: 0,
            minWidth: 0,
        },
        {
            field: 'UserLastName',
            headerName: getTranslation("UserLastName"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'UserFirstName',
            headerName: getTranslation("UserFirstName"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'Email',
            headerName: getTranslation("UserEmail"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, UserOverviewItem, any>) => {
                return <Chip label={params.row.Email} />
            }
        },
        {
            field: 'CompanyName',
            headerName: getTranslation("UserCompanyName"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'State',
            headerName: getTranslation("UserState"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, UserOverviewItem, any>) => {
                return <Chip label={getTranslation(params.row.State)} color={getStateColor(params.row.State)} />
            },
            valueGetter: (params: GridRenderCellParams<any, UserOverviewItem, any>) => getTranslation(params.row.State)
        },
        {
            field: '',
            headerName: '',
            flex: 0.1,
            minWidth: 100,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, UserOverviewItem, any>) => (
                <strong>
                    <Tooltip title={getTranslation("CommonEdit")}>
                        <IconButton color={'darky'} component={NavLink} to={`/highlevel/claims/edit/${params.row.UserId}`}>
                            <IoPencilSharp />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },
    ];

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={4} my={'auto'} sx={{textAlign: 'left', overflowX: 'hidden'}}>
                                  <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={data ? data.Items.filter(x =>
                                            ((x.CompanyName && x.CompanyName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                               (x.UserId && x.UserId.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                               (x.UserLastName && x.UserLastName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                               (x.UserFirstName && x.UserFirstName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                               (x.Email && x.Email.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                                (getTranslation(x.State) && getTranslation(x.State).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
                                                )
                                        ) : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        getRowId={(x) => x.UserId}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                          }
                                        onRowDoubleClick={openDetails}
                                        components={{
                                            LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                        }}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}