import { Tooltip, IconButton, Menu, MenuItem, Typography, useTheme } from "@mui/material"
import { FiGlobe } from "react-icons/fi"
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks"
import { useState } from "react"
import Flag from 'react-world-flags'
import { setLanguage } from "../../../Features/MainMenus/Settings/Store/SettingsSlice"

export const LanguageButton = () => {
    const dispatch = useAppDispatch();
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
    const isLangDe = useAppSelector((store) => store.settings.language === 'de');
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setLanguageFromPopup = (lang: string) => {
        dispatch(setLanguage(lang));
        handleClose();
      }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Tooltip title={getTranslatedText("ChangeLanguage")} className={'global-toolbar-language'}>
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleClick}
                            edge="start"
                            sx={{
                                background: isDarkMode ? '#222' : theme.palette.darky.light,
                                color: theme.palette.darky.main,
                                ml: 2,
                            }}
                        >
                            <FiGlobe />
                        </IconButton>
                    </Tooltip>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ borderRadius: 3 }}
                    >
                        <MenuItem onClick={() => setLanguageFromPopup('de')}>
                            <Flag code={'de'} height={16} />
                            <Typography sx={{ ml: 1, fontWeight: isLangDe ? 800 : 400 }}>{getTranslatedText("German")}</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => setLanguageFromPopup('en')}>
                            <Flag code={'us'} height={14} />
                            <Typography sx={{ ml: 1, fontWeight: !isLangDe ? 800 : 400 }}>{getTranslatedText("English")}</Typography>
                        </MenuItem>
                    </Menu>
                </>
            }
        </LanguageConsumer>
    )
}
