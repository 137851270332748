import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Grid, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { DefaultResponse } from "../../../../../Infrastructure/Api/Model/DefaultResponse";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { CustomBox } from "../../../../../Layout/CustomBox";
import { setAppTitle } from "../../../../../Store/AppSlice";
import { UserState } from "../../Claims/Overview/Models/UserOverviewItem";
import { MailTriggerItem } from "../Overview/Models/MailTriggerItem";
import { EmailTriggerRulesEmailContent } from "./Components/EmailTriggerRulesEmailContent";
import { EmailTriggerRulesFilters } from "./Components/EmailTriggerRulesFilters";
import { EmailTriggerRulesGeneralInformation } from "./Components/EmailTriggerRulesGeneralInformation";
import { EmailTriggerRuleCreateOrEditValidator } from "./Models/EmailTriggerRuleCreateOrEditValidator";
import { EmailTriggerRuleForm, EmptyEmailTriggerRuleForm } from "./Models/EmailTriggerRuleForm";
import { EmailTriggerRulesPreview } from "./Components/EmailTriggerRulesPreview";
import { LoadingButton } from "@mui/lab";

export const EmailTriggerRulesCreateOrEdit = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const isDepragAmbergMember = useAppSelector(store => store.authentication.isDepragAmbergMember);
    const theme = useTheme();
    const [currentLanguageToEdit, setCurrentLanguageToEdit] = useState<'de' | 'en'>('de');
    const contentDeRef = useRef(null);
    const contentEnRef = useRef(null);

    const [contentDe, setContentDe] = useState<string>("");
    const [contentEn, setContentEn] = useState<string>("");
    const [customerShouldBeInState, setCustomerShouldBeInState] = useState<UserState>(UserState.IsApproved);
    const [isRuleActive, setIsRuleActive] = useState<boolean>(false);

    const [filterIncludeDepragMembers, setFilterIncludeDepragMembers] = useState<boolean | null>(null);
    const [filterForDaysRegisteredGreaterThan, setFilterForDaysRegisteredGreaterThan] = useState<number | null>(null);
    const [filterForDaysNotLoggedInGreaterThan, setFilterForDaysNotLoggedInGreaterThan] = useState<number | null>(null);
    const [filterIfTestPeriodHasStarted, setFilterIfTestPeriodHasStarted] = useState<boolean | null>(null);
    const [filterForTestPeriodHasEnded, setFilterForTestPeriodHasEnded] = useState<boolean | null>(null);
    const [filterForGoldMembershipActive, setFilterForGoldMembershipActive] = useState<boolean | null>(null);
    const [shouldResentMailEveryXDays, setShouldResentMailEveryXDays] = useState<number | null>(null);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const { isLoading, data } = useQuery({
        queryKey: [`emailTriggerRule-${id}`],
        queryFn: async () => await fetchMyDepragApi<MailTriggerItem>({},
            `highlevel/mailtriggers/get/${id}`,
            'GET',
            dispatch,
            store.getState() as RootState),
        enabled: id !== undefined && parseInt(id) > 0
    });

    useEffect(() => {
        if (data) {
            setContentDe(data.MailContentDe)
            setContentEn(data.MailContentEn)
            setIsRuleActive(data.IsRuleActive)
            setCustomerShouldBeInState(data.CustomerShouldBeInState)
            setFilterIncludeDepragMembers(data.FilterIncludeDepragMembers)
            setFilterForDaysRegisteredGreaterThan(data.FilterForDaysRegisteredGreaterThan)
            setFilterForDaysNotLoggedInGreaterThan(data.FilterForDaysNotLoggedInGreaterThan)
            setFilterIfTestPeriodHasStarted(data.FilterIfTestPeriodHasStarted)
            setFilterForTestPeriodHasEnded(data.FilterForTestPeriodHasEnded)
            setFilterForGoldMembershipActive(data.FilterForGoldMembershipActive)
            setShouldResentMailEveryXDays(data.ShouldResentMailEveryXDays)
        }
    }, [data])

    const { register, handleSubmit, watch, formState: { errors } } = useForm<EmailTriggerRuleForm>({
        defaultValues: EmptyEmailTriggerRuleForm, values: data ?
            {
                MailTriggerItemId: data.MailTriggerItemId,
                RuleName: data.RuleName,
                MailHeaderDe: data.MailHeaderDe,
                MailHeaderEn: data.MailHeaderEn,
            } : EmptyEmailTriggerRuleForm,
        resolver: zodResolver(EmailTriggerRuleCreateOrEditValidator)
    });

    useEffect(() => {
        dispatch(setAppTitle(getTranslation(id === "0" || id === undefined ? 'MainMenuEmailTriggerCreateBreadcrumb' : 'MainMenuEmailTriggerEditBreadcrumb')));
    }, [location, language, languageFileWasLoaded, dispatch]);

    const onSubmit = async (data) => {
        setIsSaving(true);
        let contentDePost = contentDe;
        let contentEnPost = contentEn;

        if (contentDeRef && contentDeRef.current && (contentDeRef.current as any).unprivilegedEditor.getLength() === 1) {
            contentDePost = "";
        }

        if (contentEnRef && contentEnRef.current && (contentEnRef.current as any).unprivilegedEditor.getLength() === 1) {
            contentEnPost = "";
        }

        let dataToPost = {
            MailTriggerItemId: id !== undefined && parseInt(id) > 0 ? parseInt(id) : 0,
            IsRuleActive: isRuleActive,
            RuleName: data.RuleName,
            CustomerShouldBeInState: customerShouldBeInState,
            FilterIncludeDepragMembers: filterIncludeDepragMembers,
            FilterForDaysRegisteredGreaterThan: filterForDaysRegisteredGreaterThan,
            FilterForDaysNotLoggedInGreaterThan: filterForDaysNotLoggedInGreaterThan,
            FilterIfTestPeriodHasStarted: filterIfTestPeriodHasStarted,
            FilterForTestPeriodHasEnded: filterForTestPeriodHasEnded,
            FilterForGoldMembershipActive: filterForGoldMembershipActive,
            ShouldResentMailEveryXDays: shouldResentMailEveryXDays,
            MailHeaderDe: data.MailHeaderDe,
            MailHeaderEn: data.MailHeaderEn,
            MailContentDe: contentDePost,
            MailContentEn: contentEnPost,
        } as MailTriggerItem

        await fetchMyDepragApi<DefaultResponse>(dataToPost, 'highlevel/mailtriggers/edit', 'POST', dispatch, store.getState() as RootState, id === "0" || id === undefined ? "SuccessfullyCreatedEmailRule" : "SuccessfullySavedEmailRule");
        setIsSaving(false);
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container direction={'row'} spacing={2} mt={1} className="main-content">
                            <Grid item xs={12}>
                                <Grid container direction={'row'} spacing={2}>
                                    <Grid item xs={12}>
                                        <Button color="darky" sx={{ zIndex: 9999999 }} component={NavLink} to={'/highlevel/emailTriggerRules'} variant="outlined">{getTranslatedText("Prev")}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <CustomBox elevation={0} sx={{
                                    border: `1px solid ${theme.palette.cardBorder.main}`
                                }}>
                                    <EmailTriggerRulesGeneralInformation
                                        customerShouldBeInState={customerShouldBeInState}
                                        errors={errors}
                                        register={register}
                                        isRuleActive={isRuleActive}
                                        isSaving={isSaving}
                                        setCustomerShouldBeInState={setCustomerShouldBeInState}
                                        setIsRuleActive={setIsRuleActive}
                                        setShouldResentMailEveryXDays={setShouldResentMailEveryXDays}
                                        shouldResentMailEveryXDays={shouldResentMailEveryXDays}
                                        
                                    />

                                </CustomBox>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <CustomBox elevation={0} sx={{ border: `1px solid ${theme.palette.cardBorder.main}` }}>
                                    <EmailTriggerRulesFilters
                                        filterIncludeDepragMembers={filterIncludeDepragMembers}
                                        filterForDaysRegisteredGreaterThan={filterForDaysRegisteredGreaterThan}
                                        filterForDaysNotLoggedInGreaterThan={filterForDaysNotLoggedInGreaterThan}
                                        filterIfTestPeriodHasStarted={filterIfTestPeriodHasStarted}
                                        filterForTestPeriodHasEnded={filterForTestPeriodHasEnded}
                                        filterForGoldMembershipActive={filterForGoldMembershipActive}
                                        setFilterIncludeDepragMembers={setFilterIncludeDepragMembers}
                                        setFilterForDaysRegisteredGreaterThan={setFilterForDaysRegisteredGreaterThan}
                                        setFilterForDaysNotLoggedInGreaterThan={setFilterForDaysNotLoggedInGreaterThan}
                                        setFilterIfTestPeriodHasStarted={setFilterIfTestPeriodHasStarted}
                                        setFilterForTestPeriodHasEnded={setFilterForTestPeriodHasEnded}
                                        setFilterForGoldMembershipActive={setFilterForGoldMembershipActive}
                                    />

                                </CustomBox>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <CustomBox elevation={0} sx={{
                                    border: `3px solid ${theme.palette.cardBorder.main}`,
                                    background: theme.palette.cardBorder.main
                                }}>
                                    <EmailTriggerRulesPreview
                                        isRuleActive={isRuleActive}
                                        id={id}
                                        ruleName={watch("RuleName")}
                                        customerShouldBeInState={customerShouldBeInState}
                                        filterIncludeDepragMembers={filterIncludeDepragMembers}
                                        filterForDaysRegisteredGreaterThan={filterForDaysRegisteredGreaterThan}
                                        filterForDaysNotLoggedInGreaterThan={filterForDaysNotLoggedInGreaterThan}
                                        filterIfTestPeriodHasStarted={filterIfTestPeriodHasStarted}
                                        filterForTestPeriodHasEnded={filterForTestPeriodHasEnded}
                                        filterForGoldMembershipActive={filterForGoldMembershipActive}
                                        shouldResentMailEveryXDays={shouldResentMailEveryXDays}
                                        mailContentDe={contentDe}
                                        mailContentEn={contentEn}
                                        mailHeaderDe={watch("MailHeaderDe")}
                                        mailHeaderEn={watch("MailHeaderEn")}
                                    />

                                </CustomBox>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <CustomBox elevation={0} sx={{ width: '100%', typography: 'body1', border: `1px solid ${theme.palette.cardBorder.main}` }}>
                                    <EmailTriggerRulesEmailContent
                                        contentDe={contentDe}
                                        contentEn={contentEn}
                                        setContentDe={setContentDe}
                                        setContentEn={setContentEn}
                                        contentDeRef={contentDeRef}
                                        contentEnRef={contentEnRef}
                                        currentLanguageToEdit={currentLanguageToEdit}
                                        errors={errors}
                                        register={register}
                                        isSaving={isSaving}
                                        setCurrentLanguageToEdit={setCurrentLanguageToEdit}
                                    />
                                </CustomBox>
                            </Grid>

                            <Grid item xs={12} mb={4}>
                                <LoadingButton loading={isSaving} type="submit" variant="contained" fullWidth color="success">
                                    {getTranslatedText("CommonSave")}
                                </LoadingButton>
                            </Grid>

                        </Grid>
                    </form>
                </>
            }
        </LanguageConsumer>
    )
}