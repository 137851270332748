import { Box, Grid, IconButton, Skeleton, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {  useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { ReportingOverviewModel } from "./ReportingOverviewModel";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { FileDownloadApi, fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { FiSave } from "react-icons/fi";
import { DefaultResponse } from "../../../../../Infrastructure/Api/Model/DefaultResponse";

export const ReportingOverview = () => {
    const dispatch = useAppDispatch();
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const [searchValue, setSearchValue] = useState<string>("");

    const { data,  isFetching } = useQuery({
        queryKey: ["reporting"],
        queryFn: async () => await fetchMyDepragApi<ReportingOverviewModel[]>(
            null,
            "highlevel/reporting/overview",
            'GET',
            dispatch,
            store.getState() as RootState),
    });

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const downloadReport = async (id: number) => {
        await fetchMyDepragApi<DefaultResponse>(
            id, 
            "highlevel/reporting/getreport", 
            "POST", 
            dispatch, 
            store.getState() as RootState, 
            undefined, undefined, undefined, 
            {fallbackFileName: "Report.txt", isExcel: false} as FileDownloadApi)
    }

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: getTranslation('Name'),
            flex: 3,
            minWidth: 150,
        }     ,
        {
            field: 'DetailsDrawer',
            headerName: '',
            flex: 0.1,
            minWidth: 60,
            align: 'center',
            renderCell: (params: GridRenderCellParams<any, ReportingOverviewModel, any>) => (
                <strong>
                    <Tooltip title={getTranslation("Details")}>
                        <IconButton color={'darky'} onClick={(e) => downloadReport(params.row.Id)}>
                            <FiSave />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },  
    ]
   
    return (
        <Grid item xs={12} sx={{ mt: 1 }}>
        <Box component={'div'} sx={{ width: '100%' }}>
            <StripedDataGrid
                localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                rows={data ? data.filter(x =>
                (x.Name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                )
                ) : []}
                autoHeight={true}
                columns={columns}
                pageSize={8}
                rowsPerPageOptions={[8]}
                checkboxSelection={false}
                disableColumnFilter={false}
                disableColumnMenu={false}
                disableSelectionOnClick={true}
                disableVirtualization={false}
                hideFooter={false}
                getRowId={(x) => x.Id}
            //    onRowDoubleClick={openDetails}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                components={{
                    LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                }}
                loading={isFetching}
            />
        </Box>
    </Grid>
    )
}