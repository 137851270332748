
import { createSlice } from '@reduxjs/toolkit';


export type HelpOverviewState = {
  deletePopupVisible: boolean,
  deleteTitle: string,
  deleteId: number,
}

export const initialStateHelpOverview: HelpOverviewState = {
  deletePopupVisible: false,
  deleteTitle: "",
  deleteId: 0
};

export const helpOverviewSlice = createSlice({
  name: 'externalHelp',
  initialState: initialStateHelpOverview,
  reducers: {
    toggleDeletePopupExternalHelp: (state) => {
      state.deletePopupVisible = !Boolean(state.deletePopupVisible)
    },
    setDeleteTitleAndIdExternalHelp: (state, action) => {
        state.deleteTitle = action.payload.title;
        state.deleteId = action.payload.id;
    }
  }
});

export const { toggleDeletePopupExternalHelp, setDeleteTitleAndIdExternalHelp } = helpOverviewSlice.actions;

export default helpOverviewSlice.reducer;
