import { initialStateLogin } from "../../Features/Infrastructure/Login/Store/LoginSlice"
import { initialStateDashboard } from "../../Features/MainMenus/Dashboard/Store/DashboardSlice"
import {initialStateSettings} from "../../Features/MainMenus/Settings/Store/SettingsSlice"
import { initialDrawerState } from "../../Layout/Store/DrawerSlice"
import { initialAppState } from "../../Store/AppSlice"


interface PersistObject {
    key: string,
    initialState: {}
}

export const whitelistToPersist = [
    { key: "settings", initialState: initialStateSettings },
    { key: "authentication", initialState: initialStateLogin },
    { key: "global", initialState: initialAppState },
    { key: "navigation", initialState: initialDrawerState},
    { key: "dashboard", initialState: initialStateDashboard }
] as PersistObject[]