import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material"
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks"
import { closeDeclinePopup } from "../../Store/OpenRegistrationsSlice";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { DefaultResponse } from "../../../../../../Infrastructure/Api/Model/DefaultResponse";
import { store, RootState } from "../../../../../../Infrastructure/Store/store";

export const OpenRegistrationDeclinePopup = () => {
    const { visible, declineEmail, declineUserId} = useAppSelector(store => store.openRegistrations.declinePopup);
    const dispatch = useAppDispatch();

    const close = () => {
        dispatch(closeDeclinePopup());
    }

    const declineRegistration = async() => {
        await fetchMyDepragApi<DefaultResponse>({userIdToDeny: declineUserId}, "users/openregistrations/deny", "POST", dispatch, store.getState() as RootState, "SuccessfullyDeclinedUser")
        close();
    }

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
            <Dialog
                open={visible}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                   {getTranslatedText("AreYouSure")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography><span dangerouslySetInnerHTML={{__html: getTranslatedText("OpenRegistrationDeclineBody", [declineEmail])}}></span></Typography>
                        <Typography color={'error'} sx={{mt: 1}}>{getTranslatedText("OpenRegistrationDeclineHint")}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="darky" onClick={close}>{getTranslatedText("CommonCancel")}</Button>
                    <Button variant="outlined" color="error" onClick={() => declineRegistration()} autoFocus>
                        {getTranslatedText("DeclineUser")}
                    </Button>
                </DialogActions>
            </Dialog>
            </>
            }
        </LanguageConsumer>
    )
}