import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LanguageConsumer } from '../../Infrastructure/Internationalisation/TranslationService';

interface CustomSimpleDialogProps {
    cancelAction: () => void,
    successAction: () => void,
    cancelText: string,
    successText: string,
    title: string,
    content: string,
    open: boolean,
    handleClose: () => void,
    successColor?: "success" | "error" | "darky" | "info" | "warning" | "gold"
}

export const CustomSimpleDialog = (props: CustomSimpleDialogProps) => {

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Dialog
                        sx={{zIndex: 999999999}}
                        open={props.open}
                        onClose={props.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {getTranslatedText(props.title)}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            {getTranslatedText(props.content)}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={props.handleClose} variant='outlined' color="darky">{getTranslatedText(props.cancelText)}</Button>
                            <Button onClick={props.successAction} autoFocus variant='outlined' color={props.successColor ? props.successColor : 'error'}>{getTranslatedText(props.successText)}</Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
        </LanguageConsumer>
    );
}