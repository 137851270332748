export const EasyEncrypt = (stringToEncrypt: string) => {
    try {
        const key = process.env.REACT_APP_ENDECRYPTIONKEY as string;

        const textEncoder = new TextEncoder();
        const textDecoder = new TextDecoder();

        const productIdBytes = textEncoder.encode(stringToEncrypt);
        const keyBytes = textEncoder.encode(key);

        for (let i = 0; i < productIdBytes.length; i++) {
            productIdBytes[i] ^= keyBytes[i % keyBytes.length];
        }

        const encrypted = btoa(textDecoder.decode(productIdBytes));
        return encrypted;
    }
    catch {
        throw new Error('Encryption failed')
    }
};

export const EasyDecrypt = (stringToDecrypt: string) => {

    try {
        const key = process.env.REACT_APP_ENDECRYPTIONKEY as string;

        const textEncoder = new TextEncoder();
        const textDecoder = new TextDecoder();

        const encryptedBytes = new Uint8Array(atob(stringToDecrypt).split('').map((char) => char.charCodeAt(0)));
        const keyBytes = textEncoder.encode(key);

        for (let i = 0; i < encryptedBytes.length; i++) {
            encryptedBytes[i] ^= keyBytes[i % keyBytes.length];
        }

        const decrypted = textDecoder.decode(encryptedBytes);
        return decrypted;
    }
    catch {
        throw new Error('Decryption failed')
    }
};