import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks";
import { RootState, store } from "../../../../../../Infrastructure/Store/store";
import { CustomBoxHeader } from "../../../../../../Layout/CustomBox";
import { MailTriggerItem } from "../../Overview/Models/MailTriggerItem";
import { AffectedItemPreview } from "../Models/AffectedItemPreview";
import { EmailTriggerRulesPreviewProps } from "../Models/EmailTriggerRulesPreviewProps";
import { Box, Grid, Skeleton } from "@mui/material";
import { StripedDataGrid } from "../../../../../../Layout/DataGrid/StripedDataGrid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { useEmailTriggerRulesPreviewColumns } from "./useEmailTriggerRulesPreviewColumns";
import { DataGridSearch } from "../../../../../../Layout/DataGrid/DataGridSearch";
import { globalDateFormatOptions } from "../../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";

export const EmailTriggerRulesPreview = (props: EmailTriggerRulesPreviewProps) => {
    const { mailContentDe, mailContentEn, mailHeaderDe, mailHeaderEn, filterForDaysNotLoggedInGreaterThan, shouldResentMailEveryXDays, filterForDaysRegisteredGreaterThan, filterForGoldMembershipActive, filterForTestPeriodHasEnded, filterIfTestPeriodHasStarted, filterIncludeDepragMembers, customerShouldBeInState, id, isRuleActive, ruleName } = props;
    const dispatch = useAppDispatch();
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const language = useAppSelector(store => store.settings.language);
    const {columns} = useEmailTriggerRulesPreviewColumns();
    const [searchValue, setSearchValue] = useState<string>("");

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const { isLoading, data, refetch } = useQuery({
        queryKey: [`emailTriggerRulePreview-${id}`],
        queryFn: async () => await fetchMyDepragApi<AffectedItemPreview[]>(
            buildPostObject(),
            `highlevel/mailtriggers/Preview`,
            'POST',
            dispatch,
            store.getState() as RootState),
        enabled: id !== undefined && parseInt(id) > 0
    });

    useEffect(() => {
        refetch()
    },[customerShouldBeInState, filterIncludeDepragMembers, filterForDaysRegisteredGreaterThan, filterForDaysNotLoggedInGreaterThan, filterIfTestPeriodHasStarted, filterForTestPeriodHasEnded, filterForGoldMembershipActive, shouldResentMailEveryXDays])


    const buildPostObject = useCallback(() => {
        return {
            MailTriggerItemId: id !== undefined && parseInt(id) > 0 ? parseInt(id) : 0,
            IsRuleActive: isRuleActive,
            RuleName: ruleName,
            CustomerShouldBeInState: customerShouldBeInState,
            FilterIncludeDepragMembers: filterIncludeDepragMembers,
            FilterForDaysRegisteredGreaterThan: filterForDaysRegisteredGreaterThan,
            FilterForDaysNotLoggedInGreaterThan: filterForDaysNotLoggedInGreaterThan,
            FilterIfTestPeriodHasStarted: filterIfTestPeriodHasStarted,
            FilterForTestPeriodHasEnded: filterForTestPeriodHasEnded,
            FilterForGoldMembershipActive: filterForGoldMembershipActive,
            ShouldResentMailEveryXDays: shouldResentMailEveryXDays,
            MailHeaderDe: mailHeaderDe,
            MailHeaderEn: mailHeaderEn,
            MailContentDe: mailContentDe,
            MailContentEn: mailContentEn,
        } as MailTriggerItem
    }, [customerShouldBeInState, filterIncludeDepragMembers, filterForDaysRegisteredGreaterThan, filterForDaysNotLoggedInGreaterThan, filterIfTestPeriodHasStarted, filterForTestPeriodHasEnded, filterForGoldMembershipActive, shouldResentMailEveryXDays]);

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <CustomBoxHeader text={getTranslatedText("EmailTriggerRulesPreviewTitle", [data && data !== undefined && data.length > 0 ? `${data.length.toString()} ${getTranslatedText("PreviewHits")}` : getTranslatedText("NoPreviewHits")])} />

                    <Grid container direction={'row'} spacing={2}>
                        <Grid item xs={4} my={'auto'} sx={{textAlign: 'left', overflowX: 'hidden'}}>
                            <DataGridSearch searchValue={searchValue} search={search} />
                        </Grid>
                        <Grid item xs={12}>
                            <Box component={'div'} sx={{ width: '100%' }}>
                                <StripedDataGrid
                                    localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                    rows={data ? data.filter(x =>
                                        (x.UserId && x.UserId.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                        (x.Email && x.Email.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                        (x.RegistratedSince && new Date(x.RegistratedSince).toLocaleDateString(language, globalDateFormatOptions).includes(searchValue.toLocaleLowerCase())) ||
                                        (x.FreeTrailStartedOn && new Date(x.FreeTrailStartedOn).toLocaleDateString(language, globalDateFormatOptions).includes(searchValue.toLocaleLowerCase())) ||
                                        (x.FreeTrailExpiresOn && new Date(x.FreeTrailExpiresOn).toLocaleDateString(language, globalDateFormatOptions).includes(searchValue.toLocaleLowerCase())) ||
                                        (x.CountryIso && x.CountryIso.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
                                            ) : []}
                                    autoHeight={true}
                                    columns={columns}
                                    pageSize={8}
                                    rowsPerPageOptions={[8]}
                                    checkboxSelection={false}
                                    disableColumnFilter={false}
                                    disableColumnMenu={false}
                                    disableSelectionOnClick={true}
                                    disableVirtualization={false}
                                    disableColumnSelector
                                    hideFooter={false}
                                    getRowId={(x) => x.UserId}
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                    }
                                    components={{
                                        LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                    }}
                                    loading={isLoading}
                                />
                            </Box>
                        </Grid>

                    </Grid>

                </>
            }
        </LanguageConsumer>
    )
}