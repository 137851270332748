export interface HelpForm {
    externalProductHelpId: number,
    title: string,
    product: string,
}

export const EmptyHelpForm = {
    externalProductHelpId: 0,
    title: "",
    product: "",
}