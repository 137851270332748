import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type EquipmentByCompanyAssignState = {
    selectedEquipmentIds: number[],
    assignedToCustomerId?: number,
    resetManyAssigned?: boolean
}

export const initialStateEquipmentByCompanyAssignState: EquipmentByCompanyAssignState = {
  selectedEquipmentIds: [],
  assignedToCustomerId: 0,
  resetManyAssigned: false
};

  export const equipmentByCompanyAssignSlice = createSlice({
    name: 'equipmentByCompanyAssign',
    initialState: initialStateEquipmentByCompanyAssignState,
    reducers: {
      setSelectedEquipmentIds: (state, action : PayloadAction<{ equipmentIds: number[], assignedToCustomerId?: number, resetManyAssigned?: boolean}>) => {
          state.selectedEquipmentIds = action.payload.equipmentIds;
          state.assignedToCustomerId = action.payload.assignedToCustomerId;  
          state.resetManyAssigned = action.payload.resetManyAssigned;           
      },
      resetSelectedEquipmentIds: (state) => {
        state.selectedEquipmentIds = initialStateEquipmentByCompanyAssignState.selectedEquipmentIds;
        state.assignedToCustomerId = initialStateEquipmentByCompanyAssignState.assignedToCustomerId;
        state.resetManyAssigned = initialStateEquipmentByCompanyAssignState.resetManyAssigned;
      }
    },
  });

  export const { setSelectedEquipmentIds, resetSelectedEquipmentIds } = equipmentByCompanyAssignSlice.actions;

  export default equipmentByCompanyAssignSlice.reducer;