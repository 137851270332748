import { Tooltip, IconButton, Grid, Button } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { PiArrowRightLight, PiPlusCircle, PiTrash } from "react-icons/pi"
import { Link, useNavigate } from "react-router-dom"
import { getTranslation, LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { CopyChip } from "../../../../../../Layout/CopyChip"
import { DataGridSearch } from "../../../../../../Layout/DataGrid/DataGridSearch"
import { StripedDataGrid } from "../../../../../../Layout/DataGrid/StripedDataGrid"
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_de"
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_en"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall"
import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks"
import { store, RootState } from "../../../../../../Infrastructure/Store/store"
import { toggleMessagePopup } from "../Store/DeleteMessagesPopupSlice"
import { DeleteMessagePopup } from "./DeleteMessagePopup"
import { MaintenanceMessageOverviewResponseDto } from "../Models/MaintenanceMessageOverviewResponseDto";
import { MaintenanceMessageOverviewRequestDto } from "../Models/MaintenanceMessageOverviewRequestDto";

export const MaintenanceMessageOverview = () => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(store => store.settings.language);
    const { deletePopupVisible } = useAppSelector(store => store.deleteMessage);

    const langIsDe = language === "de";

    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState<string>("");
    const [idToDelete, setIdToDelete] = useState<number>(-1);

    const { data, refetch, isFetching } = useQuery({
        queryKey: ["maintenance"],
        queryFn: async () => await fetchMyDepragApi<MaintenanceMessageOverviewResponseDto[]>(
            { IsoCode: language } as MaintenanceMessageOverviewRequestDto,
            "highlevel/maintenance/overview",
            "POST",
            dispatch,
            store.getState() as RootState),
    });

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const convertToDate = (date: Date | string) => {
        return new Date(Date.parse(date.toString())).toLocaleDateString(langIsDe ? 'de-de' : 'en-us', { weekday: "long", year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" })
    }

    const openDeletePopup = (id: number) => {
        setIdToDelete(id);
        dispatch(toggleMessagePopup());
    }

    const columns: GridColDef[] = [
        {
            field: 'Title',
            headerName: getTranslation('HighLevelMaintenanceMessageTitle'),
            flex: 1,
            minWidth: 150,
            valueGetter: (params: GridRenderCellParams<any, MaintenanceMessageOverviewResponseDto, any>) => params.row.Title,
        },
        {
            field: 'MessageContent',
            headerName: getTranslation("HighLevelMaintenanceMessageContent"),
            flex: 2,
            minWidth: 150,
            valueGetter: (params: GridRenderCellParams<any, MaintenanceMessageOverviewResponseDto, any>) => params.row.Description,
        },
        {
            field: 'MessageId',
            headerName: getTranslation("HighLevelMaintenanceMessageId"),
            flex: 0.2,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<any, MaintenanceMessageOverviewResponseDto, any>) => {
                return <CopyChip showIconAfterCopy label={params.row.MaintenanceMessageId.toString().replace(/^0+/, '')} isCopyEnabled />
            }
        },
        {
            field: 'ValidFrom',
            headerName: getTranslation("HighLevelMaintenanceMessageValidFrom"),
            flex: 1,
            minWidth: 90,
            valueGetter: (params: GridRenderCellParams<any, MaintenanceMessageOverviewResponseDto, any>) => new Date(params.row.ValidFrom).toLocaleDateString(langIsDe ? 'DE-de' : 'EN-us'),
        },
        {
            field: 'ValidUntil',
            headerName: getTranslation("HighLevelMaintenanceMessageValidUntil"),
            flex: 1,
            minWidth: 90,
            valueGetter: (params: GridRenderCellParams<any, MaintenanceMessageOverviewResponseDto, any>) => new Date(params.row.ValidUntil).toLocaleDateString(langIsDe ? 'DE-de' : 'EN-us'),
        },
        {
            field: 'DeleteMessage',
            headerName: '',
            flex: 0.01,
            minWidth: 50,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, MaintenanceMessageOverviewResponseDto, any>) => (
                <strong>
                    <Tooltip title={getTranslation("CommonDelete")}>
                        <IconButton color={'darky'} onClick={() => openDeletePopup(params.row.MaintenanceMessageId)}>
                            <PiTrash />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },
        {
            field: '',
            headerName: '',
            flex: 0.01,
            minWidth: 50,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, MaintenanceMessageOverviewResponseDto, any>) => (
                <strong>
                    <Tooltip title={getTranslation("CommonDetails")}>
                        <IconButton color={'darky'} component={Link} to={`/highlevel/maintenance/details/${params.row.MaintenanceMessageId}`}>
                            <PiArrowRightLight />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        }
    ]

    const handleClick = (Maintenance: any) => {
        navigate("/highlevel/maintenance/details/" + Maintenance.toString());
    }

    const handleAddMessage = () => {
        navigate('/highlevel/maintenance/add')
    }

    useEffect(() => {
        refetch();
    }, [deletePopupVisible, refetch, language])

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} textAlign='center'>
                        <Grid container spacing={2}>
                            <Grid item xs={4} my={'auto'} sx={{ textAlign: 'left', overflowX: 'hidden', mb: 1 }}>
                                <DataGridSearch searchValue={searchValue} search={search} />
                            </Grid>

                            <Grid item xs={8} my={'auto'} sx={{ textAlign: 'right', mb: 2 }}>
                                <Button startIcon={<PiPlusCircle />} sx={{ ml: 1 }} variant="contained" color="success" onClick={() => handleAddMessage()}>
                                    {getTranslatedText("HighLevelMaintenanceAddMessage")}
                                </Button>
                            </Grid>
                        </Grid>


                        <StripedDataGrid
                            localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                            rows={data ? data.filter(x =>
                            (x.Description.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                (x.Description.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                    x.Description.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                    x.Title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                    convertToDate(x.ValidFrom).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                    convertToDate(x.ValidUntil).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                                ))) : []}
                            rowHeight={50}
                            autoHeight={true}
                            columns={columns}
                            disableColumnSelector
                            onRowDoubleClick={(e) => handleClick(e.row.MaintenanceMessageId)}
                            getRowId={(x) => x.MaintenanceMessageId}
                            loading={isFetching}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                        />
                    </Grid>

                    <DeleteMessagePopup idToDelete={idToDelete} />
                </Grid>
            }
        </LanguageConsumer>
    )
}
