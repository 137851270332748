import { Close } from "@mui/icons-material";
import { Button, Grid, IconButton, Stack, SwipeableDrawer, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { CustomBox } from "../../../../../Layout/CustomBox";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { ClaimsEditResponseDto, MyDepragClaim, SingleClaim } from "../../../Highlevel/Claims/Details/Models/ClaimsEditResponseDto";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { DefaultResponse } from "../../../../../Infrastructure/Api/Model/DefaultResponse";
import { UserEditClaimsDisplay } from "./UserEditClaimsDisplay";
import { UserPrefabricatedClaimType } from "../Models/UserPrefabricatedClaimType";

interface IUserEditPermissionDrawer {
    userId: string
    open: boolean;
    onClose: () => void;
}

export const UserEditPermissionDrawer = (props: IUserEditPermissionDrawer) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [listOfClaims, setListOfClaims] = useState<SingleClaim[]>([])
    const [isGoldMember, setIsGoldMember] = useState<boolean>(false)

    const { data, refetch } = useQuery({
        queryKey: [`getClaim-${props.userId}`],
        queryFn: async () => await fetchMyDepragApi<ClaimsEditResponseDto>(null,
            'users/claims/get/' + props.userId,
            'POST',
            dispatch,
            store.getState() as RootState),
        enabled: props.userId !== undefined && parseInt(props.userId) > 0
    });

    const postToApi = async (prefabricatedClaimType: UserPrefabricatedClaimType) => {
        var dataToPost = {
            UserId: parseInt(props.userId),
            PrefabricatedClaimType: prefabricatedClaimType
        }
        await fetchMyDepragApi<DefaultResponse>(dataToPost, "users/claims/edit", "POST", dispatch, store.getState() as RootState,
            "SuccessfullyChangedClaim")

        refetch();
    }


    useEffect(() => {
        if (data) {
            setListOfClaims(data.Claims);
            setIsGoldMember(data.IsGoldMember);
        }
    }, [data])

    const close = () => {
        props.onClose();
    }

    const SetClaimsForDepragAmberg = () => {   
        postToApi(UserPrefabricatedClaimType.AmbergClaims)
    }

    const SetClaimsForPartnerOrSubsidiary = () => {
        postToApi(UserPrefabricatedClaimType.PartnerOrSubsidiaryClaims)
    }

    const RemoveClaims = () => {
        postToApi(UserPrefabricatedClaimType.RemoveClaims)
    }


    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <SwipeableDrawer
                        sx={{ zIndex: 100003 }}
                        PaperProps={{ sx: { p: 3, pt: 3, width: { xs: '95%', md: '70%', lg: '40%' }, maxWidth: '100%', background: theme.palette.background.default, height: '100%', borderTopLeftRadius: 20, borderBottomLeftRadius: 20 } }}
                        anchor={'right'}
                        open={props.open}
                        onOpen={console.log}
                        onClose={close}
                    >

                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={8}>
                                <Typography variant="h5">
                                    {getTranslatedText("UserEditAvailableClaims")}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <IconButton onClick={close}>
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 1 }}>
                            <CustomBox elevation={0} sx={{
                                backgroundSize: 'cover',
                                backgroundBlendMode: 'multiply',
                                backgroundColor: 'transparent',
                                border: `1px solid ${theme.palette.cardBorder.main}`
                            }}>
                                {listOfClaims && listOfClaims.length > 0 && listOfClaims.map(item => {
                                    return (
                                        <UserEditClaimsDisplay key={item.Claim} item={item} />
                                    )
                                })}
                            </CustomBox>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 1 }}>
                            <Stack direction={'column'} justifyContent={'space-between'} gap={1} sx={{ height: '100%' }}>
                                <Stack direction={{ xs: 'column', md: 'row' }} gap={1}>
                                    <Button fullWidth color='darky' variant="contained" onClick={SetClaimsForDepragAmberg}   >{getTranslatedText('UserEditDepragAmberg')}</Button>
                                    <Button fullWidth color='darky' variant="contained" onClick={SetClaimsForPartnerOrSubsidiary} >{getTranslatedText('Partner/Tochter')}</Button>
                                </Stack>

                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={RemoveClaims}
                                >
                                    {getTranslatedText('UserEditRemoveAllClaims')}
                                </Button>
                            </Stack>
                        </Grid>
                    </SwipeableDrawer>
                </>
            }
        </LanguageConsumer>
    )
}