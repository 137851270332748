import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LanguageConsumer, getTranslation } from "../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../Store/AppSlice";
import { Grid, Typography } from "@mui/material";
import { CustomBox } from "../../../Layout/CustomBox";


export const Imprint = () => {

    const dispatch = useAppDispatch();
    const location = useLocation();    
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuImprintBreadcrumb')));
    }, [location, language, languageFileWasLoaded, dispatch]);
    

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
            <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
                <Grid item xs={12}>
                    <CustomBox elevation={3}>
                        <Typography><div dangerouslySetInnerHTML={{__html:getTranslatedText('ImprintContent')}}></div></Typography> 
                    </CustomBox>
                </Grid>
            </Grid>
            </>
            }
        </LanguageConsumer>
    )
}