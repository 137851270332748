export interface CustomerDetailForm {
    companyName: string,
    sapCustomerId: string,
    internalId: string,
    zipCode: string 
}

export const EmptyCustomerForm: CustomerDetailForm = {
    companyName: "",
    sapCustomerId: "",
    internalId: "",
    zipCode: ""
} ;