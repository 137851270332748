import { useQueryClient } from "@tanstack/react-query";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { useEffect } from "react";
import { resetRedirect } from "../../../Store/AppSlice";

export const useHandleLogout = () => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const token = useAppSelector(store => store.authentication.token); 

    useEffect(() => {
        if (!token || (token && token.trim().length === 0) || token === undefined) {
            queryClient.removeQueries();   
            dispatch(resetRedirect());       
        }
    }, [token, queryClient])
 
    return {}
}