import {
  Chip,
  Tooltip,
  IconButton,
  Grid,
  Divider,
  TextField,
  FormControlLabel,
  Box,
  Skeleton,
  Stack,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import {
  LanguageConsumer,
  getTranslation,
} from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { CustomSwitch } from "../../../../../Layout/Switch/CustomSwitch";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { IoPencilSharp } from "react-icons/io5";
import { UserOverviewResponseDto } from "../Models/UserOverviewResponseDto";
import { UserOverviewItem, UserState } from "../Models/UserOverviewItem";
import { toggleShowAllUsers } from "../../Store/UsersSlice";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import Flag from "react-world-flags";
import FlagIcon from "@mui/icons-material/Flag";
import { PiCheckCircleLight, PiXCircleLight } from "react-icons/pi";


export const MAX_YEAR = 9999;

export const getStateColor = (state: UserState) => {
  switch (state) {
    case UserState.IsApproved:
      return "success";
    case UserState.IsDeleted:
      return "error";
    case UserState.WaitingApproval:
      return "info";
    case UserState.VerifyEmail:
      return "default";
    default:
      return "default";
  }
};

export const AllUsersTable = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const langIsDe = useAppSelector(store => store.settings.language === "de");
  const location = useLocation();
  const isDepragAmbergMember = useAppSelector(store => store.authentication.isDepragAmbergMember);
  const [searchValue, setSearchValue] = useState<string>("");
  const showAllUsers = useAppSelector(store => store.users.showAllUsers);
  const userId = useAppSelector(store => store.authentication.userId);
  
  const {isLoading, data, refetch} = useQuery({
    queryKey: ["users"],
    queryFn: async () => await fetchMyDepragApi<UserOverviewResponseDto>(
      {},
      `users/overview/${userId}/${isDepragAmbergMember ? showAllUsers : false}` ,
      "GET",
      dispatch,
      store.getState() as RootState),
  });

  useEffect(() => {
    refetch();
  }, [location, refetch, showAllUsers]);


  const search = (inputText: string) => {
    setSearchValue(inputText);
  };

  const showAllCustomersChanged = (value: boolean) => {
    dispatch(toggleShowAllUsers());
  };

  const openDetails = (params: GridRowParams) => {
    navigate(`/users/edit/${params.row.UserId}`);
  };

  const columns: GridColDef[] = [
    {
      field: "UserId",
      headerName: "Id",
      flex: 0,
      minWidth: 0,
    },
    {
      field: "CompanyName",
      headerName: getTranslation("UserCompanyName"),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "UserFirstName",
      headerName: getTranslation("UserFirstName"),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "UserLastName",
      headerName: getTranslation("UserLastName"),
      flex: 1,
      minWidth: 150,
    },
    {
      field: "IsoCode",
      headerName: getTranslation("RegistrationCountry"),
      flex: 1,
      minWidth: 80,
    },
    {
      field: "IsGold",
      headerName: getTranslation("IsGold"),
      flex: 1,
      minWidth: 80,
      renderCell: (params: GridRenderCellParams<any, UserOverviewItem, any>) => {
        return params.row.IsGold ? <PiCheckCircleLight size={26}  color={theme.palette.success.main} /> : <PiXCircleLight size={26} color={theme.palette.error.main} />}
    },
    {
      field: "State",
      headerName: getTranslation("UserState"),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams<any, UserOverviewItem, any>) => {
        return <Chip label={getTranslation(params.row.State)} color={getStateColor(params.row.State)}/>;
      },
      valueGetter: (params: GridRenderCellParams<any, UserOverviewItem, any>) => getTranslation(params.row.State),
    },
    {
      field: "",
      headerName: "",
      flex: 0.1,
      minWidth: 72,
      align: "right",
      renderCell: (params: GridRenderCellParams<any, UserOverviewItem, any>) => (
        <strong>
          <Tooltip title={getTranslation("CommonEdit")}>
            <IconButton color={"darky"} component={NavLink} to={`/users/edit/${params.row.UserId}`}>
              <IoPencilSharp/>
            </IconButton>
          </Tooltip>
        </strong>
      ),
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <LanguageConsumer>
      {({getTranslatedText}) =>
        <>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign: "left"}}>

                  <div
                    dangerouslySetInnerHTML={{__html: getTranslatedText("AllUsersInfoText")}}
                  />


                  <Divider sx={{my: 3, mb: 2}}/>
                </Grid>

                <Grid item xs={4} my={"auto"} sx={{textAlign: "left", overflowX: "hidden"}}>
                  <DataGridSearch searchValue={searchValue} search={search}/>
                </Grid>
                {isDepragAmbergMember && <Grid item xs={8} my={"auto"} sx={{textAlign: "right"}}>
                  <FormControlLabel control={<CustomSwitch/>} checked={showAllUsers}
                                    onChange={(e, value) => showAllCustomersChanged(value)}
                                    label={getTranslatedText("ShowAllUsers")}/>
                </Grid>}

              </Grid>
              <Grid item xs={12} sx={{mt: 1}}>
                <Box component={"div"} sx={{width: "100%"}}>
                  <StripedDataGrid
                    localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                    rows={data ? data.Items.filter(x =>
                      (x.CompanyName && x.CompanyName?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                        (x.UserId && x.UserId.toString().trim().length > 0 && x.UserId?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                        (x.UserLastName && x.UserLastName?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                        (x.UserFirstName && x.UserFirstName?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                        (x.IsoCode && x.IsoCode?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                        (x.Email && x.Email?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                        getTranslation(x.State).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                      ),
                    ) : []}
                    autoHeight={true}
                    columns={columns}
                    pageSize={8}
                    rowsPerPageOptions={[8]}
                    checkboxSelection={false}
                    disableColumnFilter={false}
                    disableColumnMenu={false}
                    disableSelectionOnClick={true}
                    disableVirtualization={false}
                    hideFooter={false}
                    getRowId={(x) => x.UserId}
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                    }
                    onRowDoubleClick={openDetails}
                    components={{
                      LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{my: 4, mx: 1, mt: 1}}/>,
                    }}
                    loading={isLoading}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    </LanguageConsumer>
  );
};
