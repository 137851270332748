import { Component } from "react";
import { ErrorBoundaryDisplay } from "./ErrorBoundaryDisplay";

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, { hasError: boolean }> {
    constructor(props: Readonly<ErrorBoundaryProps>) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) return <ErrorBoundaryDisplay />;
        return this.props.children;
    }
}