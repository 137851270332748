import { createSlice } from '@reduxjs/toolkit';


export type DeleteResellerPopupState = {
  deletePopupVisible: boolean,
}

export const initialDeleteResellerPopup: DeleteResellerPopupState = {
  deletePopupVisible: false,
};

export const DeleteResellerPopupSlice = createSlice({
  name: 'deleteReseller',
  initialState: initialDeleteResellerPopup,
  reducers: {
    toggleResellerPopup: (state) => {
      state.deletePopupVisible = !Boolean(state.deletePopupVisible)
    },
  }
});

export const { toggleResellerPopup } = DeleteResellerPopupSlice.actions;

export default DeleteResellerPopupSlice.reducer;
