import { Routes, Route, useLocation } from "react-router-dom"
import { Login } from "./Features/Infrastructure/Login/Login"
import { AnimatePresence } from "framer-motion";

export const DepragRoutesExternal = () => {
    const location = useLocation();
    
    return (
        <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<></>} />
        </Routes>
        </AnimatePresence>
    )
}