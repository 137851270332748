export interface EmailTriggerRuleForm {
    MailTriggerItemId: number,
    RuleName: string,
    MailHeaderDe: string,
    MailHeaderEn: string,
}

export const EmptyEmailTriggerRuleForm = {
    MailTriggerItemId: 0,
    RuleName: "",
    MailHeaderDe: "",
    MailHeaderEn: "",
}