import { createSlice } from '@reduxjs/toolkit';

const getBrowserLanguage = () => {
  if(navigator.language.toLowerCase().startsWith("de")){
    return "de";
  }else{
    return "en";
  }
}

export enum ColorScheme {
  depragStandard = 'depragStandard',
  depragIndustrial = 'depragIndustrial'
}

export interface SettingsState {
  status: 'idle' | 'loading' | 'failed',
  theme: 'dark' | 'light',
  language: string,
  colorScheme: ColorScheme,
}

export const initialStateSettings: SettingsState = {
  status: 'idle',
  theme: 'light',
  language: getBrowserLanguage(),
  colorScheme: ColorScheme.depragStandard
};


export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialStateSettings,
  reducers: {
    setColorScheme: (state, action) => {
      state.colorScheme = action.payload;
    },
    setLanguage: (state,action) => {
      state.language = action.payload
    },
    setAppTheme: (state, action) => {
      state.theme = action.payload;
    },
  }
});

export const { setLanguage, setAppTheme, setColorScheme } = settingsSlice.actions;


export default settingsSlice.reducer;
