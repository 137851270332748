import { MaintenanceMessage } from "../../Overview/Models/MaintenanceMessage";

export const EmptyMessage: MaintenanceMessage = {
    MaintenanceMessageId: 0,
    TitleDE: "",
    TitleEN: "",
    DescriptionDE: "",
    DescriptionEN: "",
    ValidFrom: new Date(),
    ValidUntil: new Date()
}