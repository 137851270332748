import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom"
import { LanguageConsumer, getTranslation } from "../../../../Infrastructure/Internationalisation/TranslationService"
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../../Store/AppSlice";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../Infrastructure/Api/ApiBaseCall";
import { store, RootState } from "../../../../Infrastructure/Store/store";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Styles/HelpOrCreateEdit.css';
import { Box, Button, Divider, FormControlLabel, Grid, Icon, Tab, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { CustomBox } from "../../../../Layout/CustomBox";
import { useForm } from "react-hook-form";
import { EmptyHelpForm, HelpForm } from "./Models/HelpForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { HelpCreateOrEditValidator } from "./Models/HelpCreateOrEditValidator";
import { TextFieldFormControl } from "../../../../Layout/Inputs/TextFieldFormControl";
import ImageResize from 'quill-image-resize-module-react';
import { DefaultResponse } from "../../../../Infrastructure/Api/Model/DefaultResponse";
import { DetailResponseDto } from "./Models/DetailResponseDto";
import { CustomSwitch } from "../../../../Layout/Switch/CustomSwitch";
import { blue, deepPurple, indigo, lightGreen, lime, orange, purple, red, teal } from "@mui/material/colors";

Quill.register('modules/imageResize', ImageResize)

const modules = {
    toolbar: {
        container: [
            [{ 'header': [2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean'],
            [
                {
                    color: ["#32992c", "#54ac51", lightGreen[500], teal[500], lime[500], orange[500], indigo[500], blue[500], deepPurple[500], red[500], purple[500]],
                },
            ],
        ]
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
};

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'width', 'color'
];


export const HelpCreateOrEdit = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const isDepragAmbergMember = useAppSelector(store => store.authentication.isDepragAmbergMember);
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
    const isMobile = useMediaQuery('(max-width:500px)');
    const theme = useTheme();
    const [currentLanguageToEdit, setCurrentLanguageToEdit] = useState<'de' | 'en'>('de');
    const contentDeRef = useRef(null);
    const contentEnRef = useRef(null);
    const [contentDe, setContentDe] = useState<string>("");
    const [contentEn, setContentEn] = useState<string>("");
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isPublished, setIsPublished] = useState<boolean>(false);

    const { isLoading, data } = useQuery({
        queryKey: [`helpCreateOrEdit-${id}`],
        queryFn: async () => await fetchMyDepragApi<DetailResponseDto>({},
            'help/externalproducts/' + id,
            'GET',
            dispatch,
            store.getState() as RootState),
        enabled: id !== undefined && parseInt(id) > 0
    });


    useEffect(() => {
        if (data) {
            setContentDe(data.ContentDe)
            setContentEn(data.ContentEn)
            setIsPublished(data.IsPublished)
        }
    }, [data])

    const { register, handleSubmit, formState: { errors } } = useForm<HelpForm>({
        defaultValues: EmptyHelpForm, values: data ?
            {
                externalProductHelpId: data.ExternalProductHelpId,
                title: data.Title,
                product: data.Product,
            } : EmptyHelpForm,
        resolver: zodResolver(HelpCreateOrEditValidator)
    });

    useEffect(() => {
        dispatch(setAppTitle(getTranslation(id === "0" || id === undefined ? 'MainMenuHelpCreateBreadcrumb' : 'MainMenuHelpEditBreadcrumb')));

        if (location.pathname.includes('/help/createOrEdit') && !isDepragAmbergMember) {
            navigate('/help/overview')
        }
    }, [location, language, languageFileWasLoaded, dispatch]);

    const handleChange = (event: React.SyntheticEvent, newValue: 'de' | 'en') => {
        setCurrentLanguageToEdit(newValue);
    };

    const validateDe = useMemo(() => {
        if (contentDeRef && contentDeRef.current) {
            let editor = (contentDeRef.current as any).unprivilegedEditor;
            if (editor.getLength() > 10000) {
                return false;
            }
            return true;
        }
        return true;
    }, [contentDe, currentLanguageToEdit])

    const validateEn = useMemo(() => {
        if (contentEnRef && contentEnRef.current) {
            let editor = (contentEnRef.current as any).unprivilegedEditor;
            if (editor.getLength() > 10000) {
                return false;
            }
            return true;
        }
        return true;
    }, [contentEn, currentLanguageToEdit])

    const onSubmit = async (data) => {
        setIsSaving(true);
        let contentDePost = contentDe;
        let contentEnPost = contentEn;

        if (contentDeRef && contentDeRef.current && (contentDeRef.current as any).unprivilegedEditor.getLength() === 1) {
            contentDePost = "";
        }

        if (contentEnRef && contentEnRef.current && (contentEnRef.current as any).unprivilegedEditor.getLength() === 1) {
            contentEnPost = "";
        }

        let dataToPost = {
            externalProductHelpId: id !== undefined && parseInt(id) > 0 ? parseInt(id) : null,
            title: data.title,
            product: data.product,
            contentDe: contentDePost,
            contentEn: contentEnPost,
            isPublished: isPublished,
        }

        await fetchMyDepragApi<DefaultResponse>(dataToPost, 'help/externalproducts', 'POST', dispatch, store.getState() as RootState, id === "0" || id === undefined ? "SuccessfullyCreatedHelp" : "SuccessfullySavedHelp");
        setIsSaving(false);
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container direction={'row'} spacing={2} mt={1} className="main-content">
                            <Grid item xs={12}>
                                <Grid container direction={'row'} spacing={2}>
                                    <Grid item xs={12}>
                                        <Button color="darky" sx={{ zIndex: 9999999 }} component={NavLink} to={'/help/overview'} variant="outlined">{getTranslatedText("Prev")}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ mt: 2 }}>
                                <CustomBox elevation={0} sx={{
                                    border: `1px solid ${theme.palette.cardBorder.main}`
                                }}>
                                    <Grid container direction={'row'} spacing={2}>
                                        <Grid item xs={12}>
                                            <TextFieldFormControl
                                                disabled={isSaving}
                                                inputProps={{ "data-testid": "helpCreateOrEdit-Title" }}
                                                hasEndAdornment={false}
                                                labelText={getTranslatedText('HelpTitle')}
                                                propertyName='title'
                                                register={register} registerOptions={{ valueAsNumber: false }} errors={errors} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextFieldFormControl
                                                disabled={isSaving}
                                                inputProps={{ "data-testid": "helpCreateOrEdit-ProductNumber" }}
                                                hasEndAdornment={false}
                                                labelText={getTranslatedText('HelpProduct')}
                                                propertyName='product'
                                                register={register} registerOptions={{ valueAsNumber: false }} errors={errors} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel sx={{ ml: 0 }} control={<CustomSwitch />} checked={isPublished} onChange={(e, value) => setIsPublished(value)} label={getTranslatedText("HelpPublished")} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ mb: 2 }} />
                                            <LoadingButton loading={isSaving} type="submit" variant="contained" fullWidth color="success">
                                                {getTranslatedText("CommonSave")}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>

                                </CustomBox>
                            </Grid>
                            <Grid item xs={12} md={8} sx={{ mt: 2 }}>
                                <CustomBox elevation={0} sx={{ width: '100%', typography: 'body1', border: `1px solid ${theme.palette.cardBorder.main}` }}>
                                    <TabContext value={currentLanguageToEdit}>
                                        <Box component={'div'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList sx={{ mt: -2 }} orientation={isMobile ? "vertical" : "horizontal"} onChange={handleChange} aria-label="lab API tabs example">
                                                <Tab label={getTranslatedText("German")} value="de" />
                                                <Tab label={getTranslatedText("English")} value="en" />

                                            </TabList>
                                        </Box>
                                        <TabPanel value="de" sx={{ p: 0, pt: 3 }}>
                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item xs={12}>
                                                    <ReactQuill ref={contentDeRef} modules={modules} formats={formats} theme="snow" value={contentDe} onChange={setContentDe} />
                                                </Grid>
                                                {!validateDe && <Grid item xs={12}>
                                                    <Typography color="error">{getTranslatedText("ContentDeCharactersMustBeBetweenTenAndThousand")}</Typography>
                                                </Grid>}
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value="en" sx={{ p: 0, pt: 3 }}>
                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item xs={12}>
                                                    <ReactQuill ref={contentEnRef} modules={modules} formats={formats} theme="snow" value={contentEn} onChange={setContentEn} />
                                                </Grid>
                                                {!validateEn && <Grid item xs={12}>
                                                    <Typography color="error">{getTranslatedText("ContentEnCharactersMustBeBetweenTenAndThousand")}</Typography>
                                                </Grid>}
                                            </Grid>
                                        </TabPanel>
                                    </TabContext>
                                </CustomBox>
                            </Grid>

                        </Grid>
                    </form>
                </>
            }
        </LanguageConsumer>
    )
}