import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ByCompanyAssignState = {
    selectedLicenceIds: number[],
    assignedToCustomerId?: number,
    resetManyAssigned?: boolean
}

export const initialStateByCompanyAssignState: ByCompanyAssignState = {
  selectedLicenceIds: [],
  assignedToCustomerId: 0,
  resetManyAssigned: false
};

  export const byCompanyAssignSlice = createSlice({
    name: 'byCompanyAssign',
    initialState: initialStateByCompanyAssignState,
    reducers: {
      setSelectedLicenceIds: (state, action : PayloadAction<{ payedLicenseIds: number[], assignedToCustomerId?: number, resetManyAssigned?: boolean}>) => {
          state.selectedLicenceIds = action.payload.payedLicenseIds;
          state.assignedToCustomerId = action.payload.assignedToCustomerId;  
          state.resetManyAssigned = action.payload.resetManyAssigned;           
      },
      resetSelectedLicenceIds: (state) => {
        state.selectedLicenceIds = initialStateByCompanyAssignState.selectedLicenceIds;
        state.assignedToCustomerId = initialStateByCompanyAssignState.assignedToCustomerId;
        state.resetManyAssigned = initialStateByCompanyAssignState.resetManyAssigned;
      }
    },
  });

  export const { setSelectedLicenceIds, resetSelectedLicenceIds } = byCompanyAssignSlice.actions;

  export default byCompanyAssignSlice.reducer;