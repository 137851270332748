import { getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService";

export interface UserDetailRequestDto {
    UserId: number,
    Email: string,
    State: string,
    CustomerId: number,
}

export interface UserDetailFormValues {
    lastName: string;
    firstName: string;
    postalCode: string;
    city: string;
    street: string;
    houseNumber: string;
    email: string;
    countryName: string
}

export const EmptyUserForm: UserDetailFormValues = {
    lastName: getTranslation("UserEditLastName"),
    firstName: getTranslation("UserEditFirstName"),
    postalCode: getTranslation("UserEditPostalCode"),
    city: getTranslation("UserEditCity"),
    street: getTranslation("UserEditStreet"),
    houseNumber: getTranslation("UserEditHouseNumber"),
    email: getTranslation("UserEditEmail"),
    countryName: getTranslation("CustomerCountryName")
}