import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Box, Grid, Tooltip, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../Infrastructure/Store/hooks';
import { toggleMobileDrawer } from '../Store/AppSlice';
import { Close, Groups, Logout, Password, Star } from '@mui/icons-material';
import { setAppTheme, setLanguage } from '../Features/MainMenus/Settings/Store/SettingsSlice';
import { LanguageConsumer } from '../Infrastructure/Internationalisation/TranslationService';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { LanguageButton } from './Toolbar/Buttons/LanguageButton';
import { ThemeButton } from './Toolbar/Buttons/ThemeButton';
import { ProfileButton } from './Toolbar/Buttons/ProfileButton';

export const drawerWidth = 320;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  ismobile: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'ismobile',
})<AppBarProps>(({ theme, open, ismobile }) => ({
  left: '70px',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    left: ismobile ? 0 : drawerWidth,
    width: `calc(100% - ${ismobile ? 0 : drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppBarLayout = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:1200px)');
  const navigate = useNavigate();

  const open = useAppSelector((store) => store.global.drawerOpen);
  const appTitle = useAppSelector((store) => store.global.appTitle);
  const isDarkMode = useAppSelector((store) => store.settings.theme === 'dark');
  const isMobileMenuOpen = useAppSelector(store => store.global.mobileDrawerOpen);
  const hasFavoriteAppsDrawer = useAppSelector(store => store.global.hasFavoriteAppDrawer);

  const [appBarBackground, setAppBarBackground] = useState<boolean>(false);
  const [scrollTop, setScrollTop] = useState<number>(10);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElProfile, setAnchorElProfile] = useState<null | HTMLElement>(null);


  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };


  useEffect(() => {
    function onScroll() {
      const currentPosition = window.pageYOffset;
      if (currentPosition > scrollTop) {
        setAppBarBackground(true);
      } else if (currentPosition <= 10) {
        setAppBarBackground(false);
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  const setLanguageFromPopup = (lang: string) => {
    dispatch(setLanguage(lang));
    handleClose();
  }

  return (
    <LanguageConsumer>
      {({getTranslatedText}) =>
       <>
         <Helmet>
          <title>{appTitle}</title>
        </Helmet>
       <AppBar
         position="fixed"
         ismobile={isMobile}
         open={open}
         sx={{
           transition: 'background 0.2s ease-in-out',
           m: 0,
           p: 0,
           zIndex: isMobile ? 1 : 1201,
         }}
       >
         <Toolbar>

              <Grid container direction='row' spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant={isMobile ? 'h6' : 'h5'}
                    fontWeight="bold"
                    noWrap
                    component="div"
                    color="darky"
                    sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: { xs: 150, sm: 250, md: 270, lg: 500, xl: 600 }, transition: 'all 0.2s ease-in-out', mt: appBarBackground ? -10 : (isMobile ? 2 : 0), opacity: appBarBackground ? 0 : 1,
                     ml: isMobile ? 0 : (hasFavoriteAppsDrawer ? 10 : 0)
                   }}
                  >
                    {appTitle.toUpperCase()}

                  </Typography>
                </Grid>
              </Grid>

           <Box component="div" sx={{ ml: 'auto', }}>

           {!isMobile ? <>
            <Box component={'div'} sx={{ transition: 'top 0.3s ease-in-out, right 0.3s ease-in-out', backdropFilter: `blur(${appBarBackground ? 5 : 0}px)`, borderRadius: appBarBackground ? 2 : 0, background: appBarBackground ? 'rgba(122, 122, 122, 0.253)' : 'transparent', p: appBarBackground ? 1 : 0, pl: 3, textAlign: 'center', position: 'fixed', top: appBarBackground ? -10 : -14, right: appBarBackground ? 30 : 10, mt: 4 }}>

                    <LanguageButton />

                    <ThemeButton />

                    <ProfileButton />

             </Box>
             </>
             :
             <Box component={'div'} sx={{ transition: 'all 0.2s ease-in-out',  p: 0, pl: 3, textAlign: 'center', position: 'fixed', top: appBarBackground ? -10 : -14, right: appBarBackground ? 30 : 10,  mt: 4 }}>
                 <Tooltip title={getTranslatedText(isMobileMenuOpen ? "CloseMenu" : "OpenMenu")}>
               <IconButton
                 aria-label="open drawer"
                 onClick={() =>  dispatch(toggleMobileDrawer())}
                 edge="start"
                 size='large'
                 sx={{
                   background: isMobileMenuOpen ? 'transparent' : (isDarkMode ? '#222' : theme.palette.darky.light),
                   color: theme.palette.darky.main,
                   ml: 4,
                   zIndex: 1
                 }}
               >
                {isMobileMenuOpen ? <Close /> : <MenuIcon />}
               </IconButton>
             </Tooltip>
             </Box>
             }

           </Box>

         </Toolbar>
       </AppBar>
     </>

      }
    </LanguageConsumer>

  );
};
