import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService"
import { Brightness5, DarkMode, Groups, Language, Logout, Password, Person, Star } from "@mui/icons-material";
import { Box, Divider, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography, useTheme } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Store/hooks";
import { useState } from "react";
import { setAppTheme, setLanguage } from "../../Features/MainMenus/Settings/Store/SettingsSlice";
import { useNavigate } from "react-router-dom";
import { LanguageButton } from "../Toolbar/Buttons/LanguageButton";
import { ThemeButton } from "../Toolbar/Buttons/ThemeButton";
import { ProfileButton } from "../Toolbar/Buttons/ProfileButton";

export const MobileToolbarInDrawer = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const isDarkMode = useAppSelector(store => store.settings.theme === 'dark');
    const isLangDe = useAppSelector(store => store.settings.language === 'de');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElProfile, setAnchorElProfile] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const openProfileMenu = Boolean(anchorElProfile);
    const navigate = useNavigate();
    const currentUser = useAppSelector(store => store.authentication);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickProfile = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        setAnchorElProfile(event.currentTarget);
    };
    const handleCloseProfile = () => {
        setAnchorElProfile(null);
    };

    const setLanguageFromPopup = (lang: string) => {
        dispatch(setLanguage(lang));
        handleClose();
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Box component={'div'} className={'global-toolbar'} sx={{ width: 'auto', transition: 'all 0.1s ease-in-out', borderRadius: 8, background: isDarkMode ? '#333' : '#f3f3f3', p: 2, bottom: 0, mt: 2 }}>

                        <LanguageButton />


                        <ThemeButton />

                        <ProfileButton />
                    </Box>
                </>
            }
        </LanguageConsumer>
    )
}