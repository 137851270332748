import { Box, Button, Chip, Grid, Skeleton, TextField, Tooltip, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../../../../../Infrastructure/Api/ApiBaseCall";
import { useAppDispatch, useAppSelector } from "../../../../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../../../../Infrastructure/Store/store";
import { LanguageConsumer, getTranslation } from "../../../../../../../../Infrastructure/Internationalisation/TranslationService";
import { useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { globalDateFormatOptions } from "../../../../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";
import { AddCircleOutline, Download } from "@mui/icons-material";
import { base64ToFile, downloadFile } from "../../../../../../../../Infrastructure/Utils/Base64ToFile";
import { LicenseActivationDrawer } from "../Drawers/LicenseActivationDrawer/LicenseActivationDrawer";
import { LicenseDeactivationDrawer } from "../Drawers/LicenseDeactivationDrawer/LicenseDeactivationDrawer";
import { MAX_YEAR } from "../../../SearchByOrderNumber";
import { PaidLicencesDetailItemDto, PaidLicencesDetailOverviewDto } from "../../../../Model/PaidLicencesDetailOverviewDto";
import { StripedDataGrid } from "../../../../../../../../Layout/DataGrid/StripedDataGrid";

interface PaidLicenseDetailsTableProps {
    id: string | undefined;
}

export const PaidLicenseDetailsTable = (props: PaidLicenseDetailsTableProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [searchValue, setSearchValue] = useState<string>("");
    const [deactivationOpen, setDeactivationOpen] = useState<boolean>(false);
    const [activationOpen, setActivationOpen] = useState<boolean>(false);
    const [licenceIdToDeactivate, setLicenceIdToDeactivate] = useState<number>(0);
    const [licenceIdToActivate, setLicenceIdToActivate] = useState<number>(0);
    const language = useAppSelector(store => store.settings.language);

    const { isLoading, data, refetch } = useQuery({ 
        queryKey: ["paidLicenseDetail"], 
        queryFn: async () => await fetchMyDepragApi<PaidLicencesDetailOverviewDto>({ payedLicenceId: parseInt(props.id as string) }, 
                'licences/ordernumber/detail', 'POST', dispatch, store.getState() as RootState) });

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const onDeactivationClose = () => {
        setLicenceIdToDeactivate(0);
        setDeactivationOpen(false);
        refetch();
    }

    const onActivationClose = () => {
        setLicenceIdToActivate(0);
        setActivationOpen(false);
        refetch();
    }

    const columns: GridColDef[] = [
        {
            field: 'SerialNumberOfDevice', 
            headerName: getTranslation("PaidLicenceDetailSerialNumber"), 
            flex: .5,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<any, PaidLicencesDetailItemDto, any>) => {
                return params.row.SerialNumberOfDevice !== null ? <Chip label={params.row.SerialNumberOfDevice} /> : <></>
            }
        },
        {
            field: 'LicenceActiveFrom',
            headerName: getTranslation("PaidLicenceDetailLicenceActiveFrom"),
            flex: .5,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<any, PaidLicencesDetailItemDto, any>) => {
                return <Chip color="success" variant="outlined" label={new Date(params.row.LicenceActiveFrom).toLocaleDateString(language, globalDateFormatOptions)} />
            },
        },
        {
            field: 'LicenceActiveUntil',
            headerName: getTranslation("PaidLicenceDetailLicenceActiveUntil"),
            flex: .5,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<any, PaidLicencesDetailItemDto, any>) => {
                return <Chip color="error" variant="outlined" label={MAX_YEAR === new Date(params.row.LicenceActiveUntil).getFullYear() ? getTranslation("Unlimited") : new Date(params.row.LicenceActiveUntil).toLocaleDateString(language, globalDateFormatOptions)} />
            },
        },
        {
            field: 'ReleaseFileName',
            headerName: getTranslation("PaidLicenceDetailLicenceReleaseFileName"),
            flex: 1,
            minWidth: 300,
            renderCell: (params: GridRenderCellParams<any, PaidLicencesDetailItemDto, any>) => {
                return <Tooltip title={getTranslation("DownloadReleaseKey")}>
                    <Chip onClick={() => downloadReleaseFile(params.row.ReleaseFile, params.row.ReleaseFileName)} icon={<Download />} sx={{ cursor: 'pointer', textAlign: 'left' }} label={params.row.ReleaseFileName} />
                </Tooltip>

            },
        },       
         {
            field: 'RetrievedByUser', 
            headerName: getTranslation("RetrievedByUser"), 
            flex: .5,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<any, PaidLicencesDetailItemDto, any>) => {
                return params.row.RetrievedByUser !== null ? <Chip label={params.row.RetrievedByUser} /> : <></>
            }
        },
        {
            field: '',
            headerName: '',
             flex: .5,
            minWidth: 200,
            renderCell: (params: GridRenderCellParams<any, PaidLicencesDetailItemDto, any>) => {
                return <Button fullWidth variant="contained" color="error" onClick={() => {
                    setLicenceIdToDeactivate(params.row.UsedLicenceId);
                    setDeactivationOpen(true);
                }}>{getTranslation("DeactivateLicense")}</Button>
            },
        },
    ];

    const downloadReleaseFile = async (fileAsBase64: string, fileName: string) => {
        var isTxt = fileName.includes(".txt");
        var fileToDownload = await base64ToFile(`${isTxt ? 'data:text/plain' : 'data:application/octet-stream'};base64,${fileAsBase64}`, fileName);
        downloadFile(fileToDownload, fileName, isTxt);
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={4} my={'auto'}>
                            <TextField
                                variant="outlined"
                                color="success"
                                fullWidth
                                type={'search'}
                                value={searchValue}
                                onChange={(e) => search(e.target.value)}
                                label={getTranslatedText("CommonSearch")}
                            />
                        </Grid>
                        {data && data.AvailableLicencesForUnlock > 0 && <Grid item xs={8} my={'auto'} sx={{ textAlign: 'right' }}>
                            <Button variant="contained" onClick={() => {
                                setLicenceIdToActivate(data.PayedLicenceId);
                                setActivationOpen(true);
                            }} startIcon={<AddCircleOutline />} color="success">{getTranslatedText("AddNewActivation")}</Button>
                        </Grid>}

                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <Box component={'div'} sx={{ width: '100%' }}>
                            <StripedDataGrid
                                localeText={language === "de" ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                rows={data ? data.AlreadyUnlockedLicences.filter(x =>
                                    ( x.ReleaseFileName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                    ( new Date(x.LicenceActiveFrom).toLocaleDateString(language, globalDateFormatOptions).includes(searchValue.toLowerCase())) ||
                                    ( new Date(x.LicenceActiveUntil).toLocaleDateString(language, globalDateFormatOptions).includes(searchValue.toLowerCase())) ||
                                    (x.SerialNumberOfDevice && x.SerialNumberOfDevice.toLowerCase().includes(searchValue.toLowerCase())))
                                ) : []}
                                autoHeight={true}
                                columns={columns}
                                pageSize={8}
                                rowsPerPageOptions={[8]}
                                checkboxSelection={false}
                                disableColumnFilter={false}
                                disableColumnMenu={false}
                                disableSelectionOnClick={true}
                                disableVirtualization={false}
                                hideFooter={false}
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                  }
                                getRowId={(x: any) => x.UsedLicenceId}
                                components={{
                                    LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />
                                }}
                                loading={isLoading}
                            />
                        </Box>
                    </Grid>

                    <LicenseDeactivationDrawer open={deactivationOpen} onClose={onDeactivationClose} usedLicenceId={licenceIdToDeactivate} />

                    <LicenseActivationDrawer open={activationOpen} onClose={onActivationClose} paidLicenceId={licenceIdToActivate} />
                </>
            }
        </LanguageConsumer>
    )
}