import { Avatar, Box, Chip, Divider, Grid, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { TicketArticleResponseItemDto } from "../Models/TicketArticleResponseDto";
import { useCallback, useMemo, useState } from "react";
import { Download } from "@mui/icons-material";
import "../Style/ServiceRequestsDetail.css";
import ReactHtmlParser from 'react-html-parser'
import { NavLink } from "react-router-dom";
import { PiArrowRightLight, PiArrowLeftLight } from "react-icons/pi";
import { TfiClose } from "react-icons/tfi";
import Lightbox from "react-spring-lightbox";
import { useAppSelector } from "../../../../../../Infrastructure/Store/hooks";
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { stringAvatar } from "../../../../../../Infrastructure/Utils/UserColorGenerator";
import { CustomBox } from "../../../../../../Layout/CustomBox";
import { globalDateFormatOptions } from "../../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";

interface TicketArticleProps {
    item: TicketArticleResponseItemDto;
    index: number;
    title: string;
    owner: string;
    showImages?: boolean
}

const MAX_LENGTH_MESSAGE = 2000;

export const TicketArticle = (props: TicketArticleProps) => {
    const { showImages = false } = props;
    const theme = useTheme();
    const language = useAppSelector(store => store.settings.language);
    const [showMore, setShowMore] = useState<boolean>(false);
    const isCustomer = props.item.Sender === "Customer";
    const { firstName, lastName } = useAppSelector(store => store.authentication);
    const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);
    const [currentImage, setCurrentImage] = useState<number>(0);

    const splitBody = (body: string) => {
        return body.split("\r\n### Informationen vom Portal ###")[0];
    }

    const attachamentsDocuments = useMemo(() => {
        if (props.item.Attachments && props.item.Attachments.length > 0) {
            return props.item.Attachments.filter(x => !x.MimeType.includes("image"));
        }
        return []
    }, [props.item])

    const attachamentsImages = useMemo(() => {
        if (props.item.Attachments && props.item.Attachments.length > 0) {
            return props.item.Attachments.filter(x => x.MimeType.includes("image"));
        }
        return []
    }, [props.item])


    const removeImages = (node) => {
        if (node.type === 'tag' && node.name === 'img') {
            return null;
        }
    }

    const maxSteps = attachamentsImages && attachamentsImages.length > 0 ? attachamentsImages.length : 1;


    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setIsViewerOpen(false);
    };

    const gotoPrevious = () =>
        currentImage > 0 && setCurrentImage(currentImage - 1);

    const gotoNext = () =>
        currentImage + 1 < maxSteps &&
        setCurrentImage(currentImage + 1);

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" sx={{ mt: 5, mb: 1 }}>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography fontSize={12} fontWeight={700} color="footer.dark">{new Date(props.item.CreatedAt).toLocaleTimeString(language, globalDateFormatOptions)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item md={1} display={{ xs: 'none', md: 'flex' }} justifyContent={'flex-end'}>
                            {isCustomer &&
                                <Tooltip title={`${props.item.From}`}>
                                    <Avatar  {...stringAvatar(`${props.item.From.split(" ")[0] || ""} ${props.item.From.split(" ")[1] || ""}`, { mr: 2 })} />
                                </Tooltip>
                            }
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <CustomBox elevation={0} sx={{
                                position: 'relative',
                                height: '100%',
                                width: '100%',
                                backgroundSize: 'cover',
                                backgroundBlendMode: 'multiply',
                                backgroundColor: isCustomer ? theme.palette.cardBorder.light : theme.palette.cardBackground.main,
                                border: isCustomer ? `3px solid ${theme.palette.cardBorder.main}` : `3px solid #32992c`,
                                overflowX: 'auto'
                            }}>
                                <Grid container direction="row" sx={{ mt: 0 }} >
                                    <Grid item xs={12} key={`TicketArticle-${props.index}`}>
                                        <Grid container direction="row" sx={{ mt: 0 }}>

                                            <Grid item xs={12} sx={{ "& span, & div": { color: `${theme.palette.darky.main} !important` } }}>
                                                <Typography sx={{ whiteSpace: 'pre-wrap' }}>{showMore ? ReactHtmlParser(splitBody(props.item.Body), { transform: removeImages }) : ReactHtmlParser(splitBody(props.item.Body).substring(0, MAX_LENGTH_MESSAGE), { transform: removeImages })}</Typography>
                                                {!showMore && splitBody(props.item.Body).length > MAX_LENGTH_MESSAGE ? "..." : ""}
                                            </Grid>

                                            {props.item.Body.length > MAX_LENGTH_MESSAGE && <Grid item xs={12} sx={{ mt: 1 }}>
                                                <Chip label={getTranslatedText(showMore ? "ShowLess" : "ShowMore")} onClick={() => setShowMore(!Boolean(showMore))} />
                                            </Grid>}

                                            {attachamentsImages.length > 0 &&
                                                <Grid item xs={12} sx={{ mt: 2, my: 'auto' }}>
                                                    <Divider sx={{ my: .5 }}><Chip variant="outlined" label={getTranslatedText("TicketImages")} /></Divider>
                                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                                        {attachamentsImages.map((attachment, index) => {
                                                            return showImages ?
                                                                <img onClick={() => openImageViewer(index)} src={attachment.DownloadUrl} alt={attachment.FileName} title={attachment.FileName} style={{ height: 150, width: 'auto', cursor: 'pointer' }} /> :
                                                                <Chip key={`attachment-${index}`} deleteIcon={<Download />} onDelete={() => null} component={NavLink} to={attachment.DownloadUrl} sx={{ mr: 1, my: 1, cursor: 'pointer' }} label={attachment.FileName} />
                                                        })}
                                                    </Stack>
                                                </Grid>}

                                            {attachamentsDocuments.length > 0 &&
                                                <Grid item xs={12} sx={{ mt: 2, my: 'auto' }}>
                                                    <Divider sx={{ my: .5 }}><Chip variant="outlined" label={getTranslatedText("TicketDocuments")} /></Divider>
                                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                                        {attachamentsDocuments.map((attachment, index) => {
                                                            return <Chip key={`attachment-${index}`} deleteIcon={<Download />} onDelete={() => null} component={NavLink} to={attachment.DownloadUrl} sx={{ mr: 1, my: 1, cursor: 'pointer' }} label={attachment.FileName} />
                                                        })}
                                                    </Stack>
                                                </Grid>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomBox>
                        </Grid>
                        <Grid item md={1} display={{ xs: 'none', md: 'flex' }} justifyContent={'flex-start'}>
                            {!isCustomer &&
                                <Tooltip title={getTranslatedText("Deprag")}>
                                    <Avatar sx={{ ml: 2 }} src={'deprag_avatar.webp'}></Avatar>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>

                    {
                        
                                            <Lightbox
                        isOpen={isViewerOpen}
                        onPrev={gotoPrevious}
                        onNext={gotoNext}
                        images={attachamentsImages && attachamentsImages.length > 0 ? attachamentsImages.map(item => {
                            return {
                                src: item.DownloadUrl,
                                loading: 'lazy',
                                alt: item.FileName
                            }
                        }) : []}
                        currentIndex={currentImage}
                        pageTransitionConfig={
                            {
                                from: { transform: "scale(1)", opacity: 0 },
                                enter: { transform: "scale(1)", opacity: 1 },
                                leave: { transform: "scale(1)", opacity: 0 },
                                config: { mass: 1, tension: 320, friction: 32 }
                            }
                        }
                        onClose={closeImageViewer}
                        className="lightbox-img"
                        style={{ background: 'rgba(0,0,0,0.7)', zIndex: 999999999 }}
                        renderHeader={() => <Box component={'div'} sx={{ width: '100%', textAlign: 'right', pr: 3, pt: 3 }}><TfiClose onClick={closeImageViewer} style={{ cursor: 'pointer' }} color="white" size={40} /></Box>}
                        renderNextButton={() => currentImage < (attachamentsImages ? attachamentsImages.length - 1 : 1) ? <PiArrowRightLight onClick={gotoNext} style={{ marginRight: 10, zIndex: 9999999999, cursor: 'pointer', display: 'flex', alignSelf: 'center' }} color={'#fff'} size={60} /> : <></>}
                        renderPrevButton={() => currentImage > 0 ? <PiArrowLeftLight onClick={gotoPrevious} style={{ marginLeft: 10, zIndex: 9999999999, cursor: 'pointer', display: 'flex', alignSelf: 'center' }} color={'#fff'} size={60} /> : <></>}
                    />
                        
                    }

                </>
            }
        </LanguageConsumer>
    )

}
