
import { createSlice } from '@reduxjs/toolkit';

export type ServiceRequestsState = {
    showOnlyNotClosedTickets: boolean
}

export const initialStateServiceRequests: ServiceRequestsState = {
    showOnlyNotClosedTickets: true
};

export const serviceRequestsSlice = createSlice({
  name: 'serviceRequests',
  initialState: initialStateServiceRequests,
  reducers: {
    toggleShowOnlyNotClosedTickets: (state) => {
      state.showOnlyNotClosedTickets = !Boolean(state.showOnlyNotClosedTickets)
    }
  }
});

export const { toggleShowOnlyNotClosedTickets } = serviceRequestsSlice.actions;

export default serviceRequestsSlice.reducer;
