
import { createSlice } from '@reduxjs/toolkit';


export type EmailTriggerRulesState = {
  deletePopupVisible: boolean,
  deleteTitle: string,
  deleteId: number,
}

export const initialStateEmailTriggerRules: EmailTriggerRulesState = {
  deletePopupVisible: false,
  deleteTitle: "",
  deleteId: 0
};

export const emailTriggerRulesSlice = createSlice({
  name: 'emailTriggerRules',
  initialState: initialStateEmailTriggerRules,
  reducers: {
    toggleDeletePopupEmailTrigger: (state) => {
      state.deletePopupVisible = !Boolean(state.deletePopupVisible)
    },
    setDeleteTitleAndIdEmailTrigger: (state, action) => {
        state.deleteTitle = action.payload.title;
        state.deleteId = action.payload.id;
    }
  }
});

export const { toggleDeletePopupEmailTrigger, setDeleteTitleAndIdEmailTrigger } = emailTriggerRulesSlice.actions;

export default emailTriggerRulesSlice.reducer;
