import { Chip, IconButton, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { IoPencilSharp } from "react-icons/io5";
import { PiCheckCircle, PiTrash } from "react-icons/pi";
import { NavLink } from "react-router-dom";
import { getTranslation } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch } from "../../../../../../Infrastructure/Store/hooks";
import { setDeleteTitleAndIdEmailTrigger, toggleDeletePopupEmailTrigger } from "../../Store/EmailTriggerRulesSlice";
import { MailTriggerItem } from "../Models/MailTriggerItem";
import { getStateColor } from "../../../../Users/AllUsers/Components/AllUsersTable";

export const useEmailTriggerRulesOverviewTableColumnDefinitions = () => {
    const dispatch = useAppDispatch();

    const columns: GridColDef[] = [
        {
            field: 'MailTriggerItemId',
            headerName: "Id",
            flex: 0,
            minWidth: 0,
        },
        {
            field: 'RuleName',
            headerName: getTranslation("RuleName"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'State',
            headerName: getTranslation("ConditionUserState"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, MailTriggerItem, any>) => {
                return <Chip label={getTranslation(params.row.CustomerShouldBeInState)} color={getStateColor(params.row.CustomerShouldBeInState)} />
            },
            valueGetter: (params: GridRenderCellParams<any, MailTriggerItem, any>) => getTranslation(params.row.CustomerShouldBeInState)
        },
        {
            field: 'MailHeaderDe',
            headerName: getTranslation("MailHeaderDe"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'MailHeaderEn',
            headerName: getTranslation("MailHeaderEn"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'IsRuleActive',
            headerName: getTranslation("IsRuleActive"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, MailTriggerItem, any>) => {
                return params.row.IsRuleActive ? <PiCheckCircle size={26} /> : <></>
            }
        },
        {
            field: '',
            headerName: '',
            flex: 0.1,
            minWidth: 100,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, MailTriggerItem, any>) => (
                <strong>
                    <Tooltip title={getTranslation("CommonEdit")}>
                        <IconButton color={'darky'} component={NavLink} to={`/highlevel/emailTriggerRules/createOrEdit/${params.row.MailTriggerItemId}`}>
                            <IoPencilSharp />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={getTranslation("CommonDelete")}>
                        <IconButton sx={{ ml: 1 }} color={'darky'} onClick={() => {
                            dispatch(setDeleteTitleAndIdEmailTrigger({ title: params.row.RuleName, id: params.row.MailTriggerItemId }));
                            dispatch(toggleDeletePopupEmailTrigger())
                        }}>
                            <PiTrash />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },
    ];

    return {
        columns
    }
}