import { Box, Button, Chip, Grid, Typography, useTheme } from "@mui/material"
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBox } from "../../../../../Layout/CustomBox"
import HtmlParser from "react-html-parser"

interface CourseHeaderProps {
    title: string,
    description: string,
    thumbnail: string,
    chapterCount: number,
    moduleCount: number,
    additionalContentCount: number
}

export const CourseHeader = (props: CourseHeaderProps) => {
    const theme = useTheme();
    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <CustomBox elevation={0} sx={{
                        minHeight: 200,
                        height: '100%',
                        border: `1px solid ${theme.palette.cardBorder.main}`,
                        backgroundSize: 'cover',
                        position: 'relative',
                        maxWidth: '100%'
                    }}>

                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={12} sm={7} md={6} lg={6}>
                                <Grid container direction={'row'} spacing={2}>
                                    <Grid item xs={12} sx={{ textAlign: 'left', position: 'relative' }}>
                                        <Box component={'div'} sx={{ borderRadius: 3, position: 'relative', width: '100%', height: 250, backgroundImage: `url(${props.thumbnail})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}>

                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={5} md={6} lg={6}>
                                <Grid container direction={'row'} spacing={2}>
                                    <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                        <Typography variant="h5" fontWeight={600}>{props.title} </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ textAlign: 'left', mt: -1.5, }}>
                                        <Typography fontSize={14} fontWeight={400} sx={{ color: theme.palette.footer.main }}>{HtmlParser(props.description)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}><Typography fontSize={12}>{getTranslatedText("ModuleCount")}</Typography></Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}><Chip size={'small'} label={`${props.moduleCount}`} /></Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}><Typography fontSize={12}>{getTranslatedText("ChapterCount")}</Typography></Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}><Chip size={'small'} label={`${props.chapterCount}`} /></Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}><Typography fontSize={12}>{getTranslatedText("AdditionalContentCount")}</Typography></Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} my={'auto'}><Chip size={'small'} label={`${props.additionalContentCount}`} /></Grid>
                                </Grid>
                            </Grid>

                        </Grid>

                    </CustomBox>
                </>
            }
        </LanguageConsumer>
    )
}