import { Location, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Store/hooks"
import { useEffect } from "react";
import { NavigationItem, toggleNaviagtionItemOpen } from "../../../Layout/Store/DrawerSlice";
import { useNavigationItems } from "../../../Layout/DrawerContent.tsx/NavigationItems/useNavigationItems";

export const useOpenedMenuItem = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const currentlyOpen = useAppSelector(store => store.navigation.openedMenuItem);
    const { navigationItems } = useNavigationItems();

    const checkIfLocationHasToBeOpened = (location: Location) => {
        let pathName = location.pathname;

        var list = navigationItems;

        for(var item of list){
            var itemId = item.id;
            var wasFound = childWasFound(item.children, pathName);
            if(wasFound){
                currentlyOpen !== itemId && dispatch(toggleNaviagtionItemOpen({id: itemId, isHookCall: true }));
                break;
            }
        }
    }

    const childWasFound = (list: NavigationItem[] | null, pathName:string): boolean => {
        if(list && list.length > 0){
            var tempList = [] as number[];

            for(var item of list){
                if(item.route === pathName){
                    tempList.push(item.id);
                    break;
                }
            }
    
            return tempList.length > 0;
        }
      
        return false;
    }

    useEffect(() => {
            checkIfLocationHasToBeOpened(location)
    }, [location])
}