import * as z from "zod";
import i18next from "i18next";
import { zodI18nMap } from "zod-i18n-map"
import de from "../../../../../Infrastructure/Internationalisation/ValidationJSON/de.json";
import en from "../../../../../Infrastructure/Internationalisation/ValidationJSON/en.json";
import { useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { useEffect } from "react";


const isDe = navigator.language.toLowerCase().startsWith("de");

i18next.init({
    lng: isDe ? "de" : "en",
    resources: {
      de: { zod: de },
      en: { zod: en }
    },
  });

  z.setErrorMap(zodI18nMap);

export const useCustomerDetailFormValidator = () => {
  const language = useAppSelector(store => store.settings.language);

  useEffect(() => {
    i18next.changeLanguage(language === "de" ? "de" : "en");
  }, [language])

  const CustomerDetailFormValidator = z.object({
      companyName: z.string().max(50).nonempty(),
      sapCustomerId: z.string().max(50),
      internalId: z.string().max(10),    
      zipCode: z.string().max(12)
  });

  return {
    CustomerDetailFormValidator
  }
}
