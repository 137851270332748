import { useAppDispatch, useAppSelector } from "../../../../../../Infrastructure/Store/hooks";
import { StripedDataGrid } from "../../../../../../Layout/DataGrid/StripedDataGrid";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { ResponseLog } from "../Models/ResponseLog";
import { LanguageConsumer, getTranslation } from "../../../../../../Infrastructure/Internationalisation/TranslationService";
import { CopyChip } from "../../../../../../Layout/CopyChip";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { PiArrowRightLight, PiTrash } from "react-icons/pi";
import { NavLink, useNavigate } from "react-router-dom";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { useEffect, useState } from "react";
import { DataGridSearch } from "../../../../../../Layout/DataGrid/DataGridSearch";
import { DeleteLogsPopup } from "./DeleteLogsPopup";
import { toggleDeleteLogsPopup } from "../Store/DeleteLogsPopupSlice";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall";
import { ResponseLogsResponseDto } from "../Models/ResponseLogsResponseDto";
import { RootState, store } from "../../../../../../Infrastructure/Store/store";

export const LogsTable = () => {
    const dispatch = useAppDispatch();
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const {deletePopupVisible} = useAppSelector(store => store.deleteLogs);
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState<string>("");
    const {data, refetch, isFetching} = useQuery({
        queryKey: ["logs"],
        queryFn: async () => await fetchMyDepragApi<ResponseLogsResponseDto>(
            null,
            "highlevel/logs",
            'POST',
            dispatch,
            store.getState() as RootState),
    });


    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const convertToDate = (date: Date | string) => {
        return new Date( Date.parse(date.toString())).toLocaleDateString(langIsDe ? 'de-de': 'en-us', { weekday:"long", year:"numeric", month:"numeric", day:"numeric", hour:"numeric", minute: "numeric"})
    }
    
    const columns: GridColDef[] = [
        {
            field: 'CreatedOn',
            headerName: getTranslation('HighLevelLogsCreatedOn'),
            flex: 1,
            minWidth: 150,
            valueGetter: (params: GridRenderCellParams<any, ResponseLog, any>) => convertToDate(params.row.CreatedOn),
        },
        {
            field: 'Origin',
            headerName: getTranslation('HighLevelLogsOrigin'),
            flex: 1,
            minWidth: 100,
            valueGetter: (params: GridRenderCellParams<any, ResponseLog, any>) => params.row.Origin,
        },
        {
            field: 'RequestUrl',
            headerName: getTranslation('HighLevelLogsRequestUrl'),
            flex: 1,
            minWidth: 100,
            valueGetter: (params: GridRenderCellParams<any, ResponseLog, any>) => params.row.RequestUrl,
        },
        {
            field: 'LogId',
            headerName: getTranslation("HighLevelLogsResponseLogId"),
            flex: 0.2,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<any, ResponseLog, any>) => {
                return <CopyChip showIconAfterCopy label={params.row.ResponseLogId ? params.row.ResponseLogId.toString().replace(/^0+/, '') : ''} isCopyEnabled  />
            }
        },
        {
            field: 'OccuredByUserId',
            headerName: getTranslation('HighLevelLogsOccuredByUserId'),
            flex: 1,
            minWidth: 100,
            valueGetter: (params: GridRenderCellParams<any, ResponseLog, any>) => params.row.OccuredByUserId,
        },
        {
            field: '',
            headerName: '',
            flex: 0.1,
            minWidth: 50,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, ResponseLog, any>) => (
                <strong>
                    <Tooltip title={getTranslation("CommonDetails")}>
                        <IconButton color={'darky'} component={NavLink} to={`/highlevel/logs/details/${params.row.ResponseLogId}`}>
                            <PiArrowRightLight />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        }
    ]

    const handleClick = (e: GridRowParams<any>, ResponseLogId: any) => {
        navigate("/highlevel/logs/details/" + ResponseLogId);
    }

    const openDeletePopup = () => {
        dispatch(toggleDeleteLogsPopup())
    }

    useEffect(() => {
        if(deletePopupVisible === false){
            refetch();
        } 
    }, [deletePopupVisible, refetch])

    return (
        <>
            <LanguageConsumer>
            {({getTranslatedText}) => 
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} textAlign='center'>
                        <Grid container spacing={2}>
                            <Grid item xs={4} my={'auto'} sx={{textAlign: 'left', overflowX: 'hidden'}}>
                                <DataGridSearch searchValue={searchValue} search={search} />
                            </Grid>

                            <Grid item xs={8} my={'auto'} sx={{ textAlign: 'right' }}>
                                    <Button startIcon={<PiTrash/>} sx={{ml: 1}} onClick={openDeletePopup}
                                    variant="contained" color="error">{getTranslatedText("HighLevelLogsDeleteAllLogs")}</Button>
                            </Grid>
                        </Grid>

                        <StripedDataGrid
                            localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                            rows={data ? data.Logs.filter(x =>
                                    (x.Content && x.Content?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                        convertToDate(x.CreatedOn).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                        x.OccuredByUserId && x.OccuredByUserId?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                        x.Origin && x.Origin?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                        x.RequestUrl && x.RequestUrl?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())||
                                        x.ResponseLogId && x.ResponseLogId?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                                        ),) : []}
                            rowHeight={50}
                            autoHeight={true}
                            columns={columns}
                            disableColumnSelector
                            onRowDoubleClick={(e,) => handleClick(e, e.row.ResponseLogId)}
                            getRowId={(x) => x.ResponseLogId}
                            loading={isFetching}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                        />
                    </Grid>

                    <DeleteLogsPopup />
                </Grid>
            }
            </LanguageConsumer>
        </>
    )
}