
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMyDepragApi } from '../../../../../../../../../../Infrastructure/Api/ApiBaseCall';
import { RootState } from '../../../../../../../../../../Infrastructure/Store/store';
import { PaidLicencesUnlockLicenceRequestDto } from '../../../../../../Model/PaidLicencesUnlockLicenceRequestDto';
import { PaidLicencesUnlockResponseDto } from '../../../../../../Model/PaidLicencesUnlockResponseDto';
import { LicenseResult } from '../../../../../../Model/UnlockResponseDto';

export type LicenseActivationState = {
  loading: boolean;
  success: boolean;
  error: boolean;
  releaseFile: string | null;
  releaseResult: LicenseResult | null;
}

export const initialStateLicenseActivation: LicenseActivationState = {
  loading: false,
  success: false,
  error: false,
  releaseFile: null,
  releaseResult: null
};

export const licenseActivate = createAsyncThunk('licenseActivation', async (request: PaidLicencesUnlockLicenceRequestDto, thunkAPI) => {
  var response =  await fetchMyDepragApi<PaidLicencesUnlockResponseDto>(request, 'licences/ordernumber/unlock', 'POST', thunkAPI.dispatch, thunkAPI.getState() as RootState);
  return response;
});

export const licenseActivationSlice = createSlice({
  name: 'licenseActivation',
  initialState: initialStateLicenseActivation,
  reducers: {
    resetActivationBooleans: (state) => {
        state.success = false;
        state.error = false;
        state.loading = false;
        state.releaseFile = null;
        state.releaseResult = null;
    }
  },
  extraReducers: builder => {
    builder.addCase(licenseActivate.fulfilled, (state, action) => {
      state.success = true;
      state.loading = false;
      state.error = false;
      state.releaseFile = action.payload.UnlockKeyInBase64;
      state.releaseResult = action.payload.LicenceResult;

    }).addCase(licenseActivate.pending, (state) => {
        state.loading = true;
    }).addCase(licenseActivate.rejected, (state) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.releaseFile = null;
        state.releaseResult = null;
    })
  }
});

export const { resetActivationBooleans } = licenseActivationSlice.actions;

export default licenseActivationSlice.reducer;
