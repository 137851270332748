import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Typography, DialogContent, Grid, DialogActions, Button, useMediaQuery, useTheme, Box, Tab } from "@mui/material";
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../Users/OpenRegistrations/Components/Popups/HelperComponents/DialogComponents";
import { FileContentValidationResult } from "../../Models/FileContentValidationResult";
import { useEffect, useState } from "react";
import { getSingleError } from "./Components/SingleError";
import { getTabTitle } from "./Components/CustomTabTiitle";

interface CourseErrorsProps {
    visible: boolean,
    close: () => void,
    validationErrors: FileContentValidationResult | null
}

export const CourseErrors = (props: CourseErrorsProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = useState<string>('1');
    const isMobile = useMediaQuery('(max-width:500px)');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (props.validationErrors) {
            checkWhichTabShouldBeSelected()
        }
    }, [props.validationErrors])

    const checkWhichTabShouldBeSelected = () => {
        let errors = props.validationErrors;
        if (errors) {
            if (errors.CourseErrors.Items.length > 0) {
                setValue('1')
                return
            }

            if (errors.ModuleErrors.Items.length > 0) {
                setValue('2')
                return
            }

            if (errors.ChapterErrors.Items.length > 0) {
                setValue('3')
                return
            }

            if (errors.ContentErrors.Items.length > 0) {
                setValue('4')
                return
            }
        }
    }



    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <BootstrapDialog
                        scroll="paper"
                        fullScreen={fullScreen}
                        sx={{ zIndex: 99999999 }}
                        maxWidth={'md'}
                        PaperProps={{ sx: { minWidth: '40%', minHeight: '30%' } }}
                        onClose={props.close}
                        aria-labelledby="customized-dialog-title"
                        open={props.visible}
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" >
                            <Typography variant="h5" fontWeight={500}>{getTranslatedText("WeFoundSomeErrors")}</Typography>
                        </BootstrapDialogTitle>
                        <DialogContent>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>{getTranslatedText("WeFoundSomeErrorsDescription")}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {props.validationErrors && <Box component={'div'} sx={{ width: '100%', typography: 'body1' }}>
                                        <TabContext value={value}>
                                            <Box component={'div'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <TabList orientation={isMobile ? "vertical" : "horizontal"} TabIndicatorProps={{ sx: { background: '#f03e3e' } }} sx={{ ".Mui-selected": { color: '#f03e3e !important' } }} onChange={handleChange} aria-label="lab API tabs example">
                                                    <Tab color="error" disabled={props.validationErrors.CourseErrors.Items.length === 0} label={getTabTitle(getTranslatedText("CourseErrorsTitle"), props.validationErrors.CourseErrors.Items.length)} value="1" />
                                                    <Tab color="error" disabled={props.validationErrors.ModuleErrors.Items.length === 0} label={getTabTitle(getTranslatedText("ModuleErrorsTitle"), props.validationErrors.ModuleErrors.Items.length)} value="2" />
                                                    <Tab color="error" disabled={props.validationErrors.ChapterErrors.Items.length === 0} label={getTabTitle(getTranslatedText("ChapterErrorsTitle"), props.validationErrors.ChapterErrors.Items.length)} value="3" />
                                                    <Tab color="error" disabled={props.validationErrors.ContentErrors.Items.length === 0} label={getTabTitle(getTranslatedText("ContentErrorsTitle"), props.validationErrors.ContentErrors.Items.length)} value="4" />
                                                </TabList>
                                            </Box>
                                            <TabPanel value="1">
                                                <Grid container direction={'row'} spacing={2}>
                                                    {props.validationErrors.CourseErrors.Items.length > 0 && props.validationErrors.CourseErrors.Items.map((error, eKey) => {
                                                        return getSingleError(error, `CourseError-${eKey}`, theme)
                                                    }
                                                    )}
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value="2">
                                                <Grid container direction={'row'} spacing={2}>
                                                    {props.validationErrors.ModuleErrors.Items.length > 0 && props.validationErrors.ModuleErrors.Items.map((error, eKey) => {
                                                        return getSingleError(error, `ModuleErrors-${eKey}`, theme)
                                                    }
                                                    )}
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value="3">
                                                <Grid container direction={'row'} spacing={2}>
                                                    {props.validationErrors.ChapterErrors.Items.length > 0 && props.validationErrors.ChapterErrors.Items.map((error, eKey) => {
                                                        return getSingleError(error, `ChapterErrors-${eKey}`, theme)
                                                    }
                                                    )}
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value="4">
                                                <Grid container direction={'row'} spacing={2}>
                                                    {props.validationErrors.ContentErrors.Items.length > 0 && props.validationErrors.ContentErrors.Items.map((error, eKey) => {
                                                        return getSingleError(error, `ContentErrors-${eKey}`, theme)
                                                    }
                                                    )}
                                                </Grid>
                                            </TabPanel>
                                        </TabContext>
                                    </Box>}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="darky" onClick={props.close} autoFocus>{getTranslatedText("CommonClose")}</Button>
                        </DialogActions>
                    </BootstrapDialog>

                </>
            }
        </LanguageConsumer>
    )
}