
import { createSlice } from '@reduxjs/toolkit';

interface DeclinePopup {
  visible: boolean,
  declineEmail: string,
  declineUserId: number
}

interface AcceptPopup {
  visible: boolean,
  acceptUserId: number,
  acceptEmail: string
}

export type OpenRegistrationsState = {
  showAllOpenRegistrations: boolean,
  declinePopup: DeclinePopup,
  acceptPopup: AcceptPopup
}

export const initialStateOpenRegistrations: OpenRegistrationsState = {
  showAllOpenRegistrations: false,
  declinePopup: {
    visible: false,
    declineEmail: "",
    declineUserId: 0
  },
  acceptPopup: {
    visible: false,
    acceptUserId: 0,
    acceptEmail: ""
  }
};

export const openRegistrationsSlice = createSlice({
  name: 'customers',
  initialState: initialStateOpenRegistrations,
  reducers: {
    toggleShowAllOpenRegistrations: (state) => {
      state.showAllOpenRegistrations = !Boolean(state.showAllOpenRegistrations)
    },
    showDeclinePopup: (state, action) => {
      state.declinePopup.visible = true;
      state.declinePopup.declineEmail = action.payload.email;
      state.declinePopup.declineUserId = action.payload.userId;
    },
    closeDeclinePopup: (state) => {
      state.declinePopup.visible = false;
      state.declinePopup.declineEmail = "";
      state.declinePopup.declineUserId = 0;
    },
    showAcceptPopup: (state, action) => {
      state.acceptPopup.visible = true;
      state.acceptPopup.acceptUserId = action.payload.userId;
      state.acceptPopup.acceptEmail = action.payload.email;
    },
    closeAcceptPopup: (state) => {
      state.acceptPopup.visible = false;
      state.acceptPopup.acceptUserId = 0;
      state.acceptPopup.acceptEmail = "";
    }
  }
});

export const { showAcceptPopup, closeAcceptPopup, showDeclinePopup, closeDeclinePopup, toggleShowAllOpenRegistrations } = openRegistrationsSlice.actions;

export default openRegistrationsSlice.reducer;
