import { useQuery } from "@tanstack/react-query";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { ResellerDetailResponseDto } from "../Models/ResellerDetailResponseDto";
import { CustomBox } from "../../../../../Layout/CustomBox";
import { Autocomplete, Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import { setAppTitle } from "../../../../../Store/AppSlice";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { TextFieldFormControl } from "../../../../../Layout/Inputs/TextFieldFormControl";
import { useForm } from "react-hook-form";
import { EmptyResellerAddRequestDto } from "../Models/EmptyResellerAddRequestDto";
import { LoadingButton } from "@mui/lab";
import { CountryResponseDto } from "../../../Customers/CustomerCreate/Models/CountryResponseDto";
import { ResellerAddValidator } from "../Models/ResellerAddValidator";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomerOverviewResponseDto } from "../../../Customers/AllCustomers/Models/CustomerOverviewResponseDto";
import { CustomerOverviewItemDto } from "../../../Customers/AllCustomers/Models/CustomerOverviewItemDto";
import { StyledPopper, ListboxComponent } from "../../../../../Layout/Inputs/AutoCompleteVirtualizationHelper";
import { ResellerAddRequestDto } from "../Models/ResellerAddRequestDto";
import { CountrySelectItem } from "../../../Customers/CustomerEdit/Models/CustomerDetailResponseDto";
import { CustomSwitch } from "../../../../../Layout/Switch/CustomSwitch";

export const ResellerAdd = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
    const theme = useTheme();
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const customerId = useAppSelector(store => store.authentication.customerId);

    const { data: countries, refetch: refetchCountries } = useQuery({
        queryKey: ["countries"],
        queryFn: async () => await fetchMyDepragApi<CountryResponseDto>(
            { PreferredLanguageIsoCode: language },
            "basedata/countries",
            'POST',
            dispatch,
            store.getState() as RootState),
    });

    const { data: customerData, refetch: refetchCustomers } = useQuery({
        queryKey: ["customers"],
        queryFn: async () =>
            await fetchMyDepragApi<CustomerOverviewResponseDto>(
                { showAll: true, preferredLanguageIso: language, selectedUserId: null },
                "customers/overview/" + customerId,
                'POST',
                dispatch,
                store.getState() as RootState)
    });

    const [selectedCountries, setSelectedCountries] = useState<CountrySelectItem[]>([])
    const [selectedInputCountry, setSelectedInputCountry] = useState<string>('')
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerOverviewItemDto>();
    const [selectedInputCustomer, setSelectedInputCustomer] = useState<string>('')
    const [customerError, setCustomerError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { register, handleSubmit, watch, setValue, formState: { errors }, } = useForm({
        defaultValues: EmptyResellerAddRequestDto,
        values: EmptyResellerAddRequestDto,
        resolver: zodResolver(ResellerAddValidator),
    });

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuResellerAddBreadcrumb')));
    }, [location, language, languageFileWasLoaded, dispatch]);

    useEffect(() => {
        refetchCountries();
        refetchCustomers();
    }, [refetchCountries, refetchCustomers, language]);

    const onSubmit = async (dataToSend: ResellerDetailResponseDto) => {
        setIsSubmitting(true);
        try {
            if (!selectedCustomer) {
                return setCustomerError(true);
            }

            if (!customerError) {
                await fetchMyDepragApi(
                    {
                        Name: dataToSend.Name,
                        ResellerAddress: dataToSend.ResellerAddress,
                        Email: dataToSend.Email,
                        SelectCountryIds: selectedCountries.map(x => x.Id),
                        CustomerId: selectedCustomer && selectedCustomer.Id,
                        Currency : "?",
                        UsesTicketSystem: dataToSend.UsesTicketSystem
                    } as ResellerAddRequestDto,
                    "resellers/Add",
                    "POST",
                    dispatch,
                    store.getState() as RootState,
                    getTranslation("ResellerSuccessEdit")
                );
                navigate('/resellers');
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
                    <Grid item xs={12} sx={{ mb: 2, mx: 1 }}>
                        <Button color="darky" component={NavLink} to={'/resellers'} variant="outlined">
                            {getTranslatedText("Prev")}
                        </Button>
                    </Grid>

                    <CustomBox elevation={0} sx={{
                        mx: 3,
                        height: '100%',
                        width: '100%',
                        backgroundSize: 'cover',
                        backgroundBlendMode: 'multiply',
                        backgroundColor: isDarkMode ? "transparent" : "#fff",
                        border: `1px solid ${theme.palette.cardBorder.main}`
                    }}>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid item xs={12} container direction={"row"} spacing={3}>
                                <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                    <Typography>{getTranslatedText('ResellerCompanyName')}:</Typography>
                                </Grid>

                                <Grid item xs={10}>
                                    <TextFieldFormControl
                                        inputProps={{
                                            "data-testid": "resellers-ResellerCompanyName"
                                        }}
                                        labelText=''
                                        disabled={isSubmitting}
                                        propertyName="Name"
                                        register={register}
                                        errors={errors}
                                    />
                                </Grid>

                                <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                    <Typography>{getTranslatedText('ResellerEmail')}:</Typography>
                                </Grid>

                                <Grid item xs={10}>
                                    <TextFieldFormControl
                                        inputProps={{
                                            "data-testid": "resellers-ResellerEmail"
                                        }}
                                        labelText=''
                                        disabled={isSubmitting}
                                        propertyName="Email"
                                        register={register}
                                        errors={errors}
                                    />
                                </Grid>

                                <Grid item xs={2} minWidth={135} mt={2}>
                                    <Typography>{getTranslatedText('ResellerCountryName')}:</Typography>
                                </Grid>

                                <Grid item xs={10} container direction={'column'} >
                                    <Autocomplete
                                        multiple
                                        disableListWrap
                                        PopperComponent={StyledPopper}
                                        ListboxComponent={ListboxComponent}
                                        renderOption={(props, option, state) =>
                                            [props, `${option.CountryName}`, state.index] as React.ReactNode
                                        }
                                        options={countries?.Items ?? []}
                                        getOptionLabel={(option) => option ? option.CountryName : ''}
                                        defaultValue={selectedCountries}
                                        value={selectedCountries ? selectedCountries : []}
                                        onChange={(_e, value) => setSelectedCountries(value)}
                                        filterSelectedOptions
                                        renderInput={(params) => (<TextField {...params} />)}
                                        disabled={(isSubmitting)}
                                        inputValue={selectedInputCountry}
                                        onInputChange={(_e, input) => setSelectedInputCountry(input)}
                                        isOptionEqualToValue={(option, value) => option.Id === value.Id}
                                    />
                                </Grid>

                                <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                    <Typography>{getTranslatedText('ResellerAddress')}:</Typography>
                                </Grid>

                                <Grid item xs={10}>
                                    <TextFieldFormControl
                                        inputProps={{
                                            "data-testid": "resellers-ResellerAddress"
                                        }}
                                        labelText=''
                                        disabled={isSubmitting}
                                        propertyName="ResellerAddress"
                                        register={register}
                                        errors={errors}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction={"row"} spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                    <Typography>{getTranslatedText('ResellerEditCustomerName')}:</Typography>
                                </Grid>

                                <Grid item xs={10}>
                                    <Autocomplete
                                        disableListWrap
                                        disableClearable
                                        PopperComponent={StyledPopper}
                                        ListboxComponent={ListboxComponent}
                                        renderOption={(props, option, state) => option &&
                                            [props, `${option.CompanyName} [${option.SapCustomerId}]${option.CountryName && ', ' + option.CountryName}`, state.index]}
                                        disabled={isSubmitting}
                                        noOptionsText={getTranslatedText("NoCustomersAvailable")}
                                        fullWidth
                                        id="customers"
                                        isOptionEqualToValue={(option, value) => {
                                            return option.CompanyName === value.CompanyName
                                                && option.CountryName === value.CountryName
                                                && option.Id === value.Id
                                                && option.SapCustomerId === value.SapCustomerId;
                                        }}
                                        options={customerData ? customerData.Items : []}
                                        value={selectedCustomer}
                                        getOptionLabel={x => `${x.CompanyName} [${x.SapCustomerId}]${x.CountryName && ', ' + x.CountryName}`}
                                        onChange={(_event, value) => { setSelectedCustomer(value) }}
                                        inputValue={selectedInputCustomer}
                                        onInputChange={(_e, input) => {
                                            setCustomerError(false)
                                            setSelectedInputCustomer(input);
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                fullWidth
                                                error={customerError}
                                                helperText={customerError ? getTranslatedText('CustomerIsEmpty') : ''}
                                            />}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction={"row"} spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={2} minWidth={135} container alignContent={'center'}>
                                    <Typography>{getTranslatedText('ResellerEditUsesTicketSystem')}:</Typography>
                                </Grid>

                                <Grid item xs={10} sx={{display: "flex", justifyContent: "flex-end"}}>
                                        <CustomSwitch 
                                            checked={watch("UsesTicketSystem")}
                                            disabled={isSubmitting}
                                            onChange={() => setValue("UsesTicketSystem", !watch("UsesTicketSystem"))}
                                            />
                                    </Grid>
                            </Grid>

                            <Grid container direction={"row"} spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        type='submit'
                                        disabled={isSubmitting}
                                        color="success"
                                        fullWidth
                                        variant="contained"
                                    >
                                        {getTranslatedText("CommonSave")}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    </CustomBox>
                </Grid>
            }

        </LanguageConsumer>
    )
}