import { useCallback } from "react"
import { useAppSelector } from "../../Store/hooks"

export const useNumberLanguageFormatter = () => {
    const language = useAppSelector(store => store.settings.language);
    
    const formatNumberAccordingLanguage = useCallback((value: number, fractionDigits: number = 2) => {
        return Intl.NumberFormat(language, {minimumFractionDigits: fractionDigits}).format(value);
    }, [language])

    return {
        formatNumberAccordingLanguage
    }
}