import { getTranslation } from "../../../../Infrastructure/Internationalisation/TranslationService";

export const getStateColor = (state: number) => {
    if (state === 1)
        return "info"; // new 
    if (state === 4)
        return "darky"; // closed
    else
        return "success"; // all others -> Open 

}

export const getStateTranslationKey = (state: number) => {
    if (state === 1)
        return getTranslation("TicketStateNew");
    if (state === 4)
        return getTranslation("TicketStateClosed"); // closed
    else
        return getTranslation("TicketStateOpen"); // all others -> Open 

}