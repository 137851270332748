import { styled, Theme, CSSObject } from "@mui/material/styles";
import { drawerWidth } from "./AppBarLayout";
import MuiDrawer from "@mui/material/Drawer";
import { useAppSelector } from "../Infrastructure/Store/hooks";
import { LanguageConsumer } from "../Infrastructure/Internationalisation/TranslationService";
import { MobileAndDesktopDrawerContent } from "./DrawerContent.tsx/MobileAndDesktopDrawerContent";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== "open"})(
  ({theme, open}) => ({
    width: 10, //drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }),
);


export const DrawerHeader = styled("div")(({theme}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const DrawerLayout = () => {
  const open = useAppSelector(store => store.global.drawerOpen);
  const hasFavoriteAppsDrawer = useAppSelector(store => store.global.hasFavoriteAppDrawer);

  return (<LanguageConsumer>
      {({getTranslatedText}) =>
        <>
          <Drawer variant="permanent"
                  PaperProps={{
                    className: "verticalScroll",
                    sx: {left: hasFavoriteAppsDrawer ? "60px" : "0px", zIndex: 0},
                  }}
                  open={open}
                  sx={{minHeight: "100vh", display: "flex", flexDirection: "column"}}>
            <MobileAndDesktopDrawerContent/>
          </Drawer>
        </>
      }
    </LanguageConsumer>
  );
};
