import { FormControlLabel, Grid, TextField } from "@mui/material";
import { CustomSwitch } from "../../../../../Layout/Switch/CustomSwitch";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { SingleClaim } from "../../../Highlevel/Claims/Details/Models/ClaimsEditResponseDto";

interface ClaimsEditSingleProps {
    item: SingleClaim,
}

export const UserEditClaimsDisplay = (props: ClaimsEditSingleProps) => {
    const { item } = props;

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction={'row'} spacing={2} sx={{ mt: -1 }}>
                        <Grid item xs={12} my={'auto'}>

                            <FormControlLabel sx={{ ml: 2 }} control={<CustomSwitch />}
                                checked={props.item.IsEnabled}
                                disabled={false}
                                label={getTranslatedText(item.Claim.toString())}                    
                            />
                        </Grid>
                        {item.IsEnabled && item.CanHasValue && <Grid item xs={9} my={'auto'}>
                            <TextField fullWidth value={item.Value}/>
                        </Grid>}
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}