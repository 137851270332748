import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { IoPencilSharp } from "react-icons/io5";
import { useState } from "react";
import { getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { OpenRegistrationOverviewItem } from "../Models/OpenRegistrationOverviewItem";

export const useOpenRegistrationsTableColumnDefinitions = (language: string) => {
    const [selectedUserId, setSelectedUserId] = useState<number>(0);
    const [selectedUserEmail, setSelectedUserEmail] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const langIsDe = language === "de";

    const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, userId: number, email: string) => {
        setSelectedUserId(userId);
        setSelectedUserEmail(email);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns: GridColDef[] = [
                 {
            field: 'UserId',
            headerName: "Id",
            flex: 0,
            minWidth: 0,
        },
        {
            field: 'Email',
            headerName: getTranslation("OpenRegistrationsEmail"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, OpenRegistrationOverviewItem, any>) => (
                <Chip label={params.row.Email} />
            )
        },
        {
            field: 'FirstName',
            headerName: getTranslation("OpenRegistrationsFirstName"),
            flex: 1,
            minWidth: 150,
            valueGetter: (params:  GridRenderCellParams<any, OpenRegistrationOverviewItem, any>) => params.row.UserRegistration.FirstName,
        },
        {
            field: 'LastName',
            headerName: getTranslation("OpenRegistrationsLastName"),
            flex: 1,
            minWidth: 150,
            valueGetter: (params:  GridRenderCellParams<any, OpenRegistrationOverviewItem, any>) => params.row.UserRegistration.LastName
        },
        {
            field: 'CompanyName',
            headerName: getTranslation("OpenRegistrationsCompanyName"),
            flex: 1,
            minWidth: 150,
            valueGetter: (params:  GridRenderCellParams<any, OpenRegistrationOverviewItem, any>) => params.row.UserRegistration.CompanyName
        },
        {
            field: 'Country',
            headerName: getTranslation("OpenRegistrationsCountry"),
            flex: 1,
            minWidth: 150,
            valueGetter: (params:  GridRenderCellParams<any, OpenRegistrationOverviewItem, any>) =>params.row.UserRegistration.Country.IsoCode
        },
        {
            field: 'AcceptPrivacyOn',
            headerName: getTranslation("RegisteredSince"),
            flex: 1,
            minWidth: 150,
            valueGetter: (params:  GridRenderCellParams<any, OpenRegistrationOverviewItem, any>) => new Date(params.row.UserRegistration.AcceptPrivacyOn).toLocaleDateString(langIsDe ? 'DE-de' : 'EN-us')},
        {
            field: '',
            headerName: '',
            flex: 0.1,
            minWidth: 100,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, OpenRegistrationOverviewItem, any>) => (
                <strong>
                     <Tooltip title={getTranslation("CommonEdit")}>
                        <IconButton onClick={(e) => handleClick(e, params.row.UserId, params.row.Email)} color={'darky'}>
                            <IoPencilSharp />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },
    ];

    
    return {
        columns: columns as GridColDef[],
        anchorEl,
        openMenu,
        handleClose,
        selectedUserId,
        selectedUserEmail,
    }
}