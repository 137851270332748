import { Chip } from "@mui/material";
import { useState } from "react";
import toast from "react-hot-toast";
import HtmlParser from "react-html-parser";
import { PiCheckCircleLight, PiCopyLight } from "react-icons/pi";
import { LanguageConsumer, getTranslation } from "../Infrastructure/Internationalisation/TranslationService";
import { HtmlTooltip } from "./Tooltips/HtmlTooltip";

interface CopyChipProps {
    label: string,
    color?: 'default' | 'success' | 'info' | 'warning' | 'whitey' | 'error' | 'darky' | 'gold',
    isCopyEnabled: boolean,
    copyText?: string,
    showIconAfterCopy?: boolean,
    followCursor?: boolean,
    size?: 'small' | 'medium',
    sx?: any,
    variant?: 'filled' | 'outlined',
    copyHtmlText?: boolean
}

export const CopyChip = (props: CopyChipProps) => {
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const copyToClipboardAndShowToastMessage = async () => {
        if ('clipboard' in navigator) {
            if (props.copyHtmlText) {
                return await navigator.clipboard.write([new ClipboardItem({ 'text/html': new Blob([props.copyText || props.label], { type: 'text/html' }) })]);
            }
            else{
                return await navigator.clipboard.writeText(props.copyText || props.label);
            }
        } else {
            return document.execCommand('copy', true, props.copyText || props.label);
        }
    }

    const handleCopyClick = () => {
        copyToClipboardAndShowToastMessage()
            .then(() => {
                toast.success(getTranslation("SuccessfullyCopiedToClipboard", [props.copyText || props.label]))
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 3500);
            })
            .catch((err) => {

            });
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <HtmlTooltip followCursor={props.followCursor ? props.followCursor : true} title={props.isCopyEnabled ? HtmlParser(getTranslatedText('CopyToClipboard')) : ''}>
                        <Chip variant={props.variant ? props.variant : 'filled'} size={props.size ? props.size : 'medium'} onClick={handleCopyClick} label={props.label} onDelete={handleCopyClick} deleteIcon={isCopied && props.showIconAfterCopy ? <PiCheckCircleLight size={20} color="#32992c" /> : <PiCopyLight />} sx={{ cursor: props.isCopyEnabled ? 'pointer' : 'default', ...props.sx }} color={props.color ? props.color : 'default'} />
                    </HtmlTooltip>
                </>
            }
        </LanguageConsumer>
    )
}