import { Backdrop, Box, Button, Grid, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useAppSelector } from "../../Infrastructure/Store/hooks";
import { CustomBox } from "../CustomBox";

interface HeroTopBannerProps {
    bgImageUrl: string,
    minHeight: number,
    headLine: ReactNode | string,
    headSubLine?: ReactNode | string,
    hasHeadSubLine?: boolean,
    sx?: SxProps<Theme> | undefined,
    hasNoBackgroundImage?: boolean,
    backgroundColor?: string,
    hasBackButton?: boolean,
    blurIntensity?: number,
    topRightComponent?: ReactNode | string
}

const speed = 0.3;

export const HeroTopBanner = (props: HeroTopBannerProps) => {
    const theme = useTheme();
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:500px)');
    const backgroundRef = useRef(null);
    const [blurValue, setBlurValue] = useState<number>(10);


    return(
        <LanguageConsumer>
        {({ getTranslatedText }) =>
            <>
            <CustomBox ref={backgroundRef} elevation={0} sx={{
                background: props.hasNoBackgroundImage ? (props.backgroundColor ? props.backgroundColor : '#e6e7e9') : `url(${props.bgImageUrl})`,
                backgroundColor: '#e6e7e9',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                minHeight: props.minHeight,
                height: 'auto',
                maxHeight: '100%',
                position: 'relative',
                mx: 3,
                overflow: 'hidden',
                backgroundAttachment: 'scroll',
                ...props.sx
            }}>

                <Backdrop open  sx={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100%', 
                    borderRadius: 2, 
                    p: 0, 
                    m: 0, 
                    background: isDarkMode ? 'rgba(0, 0, 0, 0.589)' : 'rgba(255,255,255,0.2)', 
                    alignItems: 'flex', 
                    justifyContent: 'space-evenly',
                     backdropFilter: `blur(${props.blurIntensity ? props.blurIntensity : 0}px)` }}>

                    {props.topRightComponent && !isMobile ? <Box sx={{position: 'absolute', top: 10, right: 10, p: 1, pl: 2, borderRadius: 2, background: 'rgba(255,255,255,0.4)', backdropFilter: 'blur(130px)'}} component={'div'}>{props.topRightComponent}</Box> : <></>}

                    {props.hasBackButton && <Button sx={{ position: 'absolute', top: 20, left: 20, zIndex: 99999 }} color="darky" onClick={() => navigate(-1)} variant="outlined">{getTranslatedText("Prev")}</Button>}

                    {props.hasNoBackgroundImage && !isMobile &&
                        <>
                            <img src={props.bgImageUrl} onDoubleClick={() => setBlurValue(old => old === 10 ? 0 : 10)} alt="" title="" style={{ position: "absolute", top: '50%', left: '80%', transform: 'translate(-50%, -50%) rotate(30deg)', filter: `blur(${blurValue}px)`, maxWidth: 300 }} />
                            <img src={props.bgImageUrl} onDoubleClick={() => setBlurValue(old => old === 10 ? 0 : 10)} alt="" title="" style={{ position: "absolute", top: '80%', left: '20%', transform: 'translate(-50%, -50%) rotate(-30deg)', filter: `blur(${blurValue}px)`, maxWidth: 300 }} />
                        </>
                    }

                    <Grid container direction={'row'} spacing={2} sx={{ zIndex: 1 }}>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            {props.headLine}
                        </Grid>
                        {props.hasHeadSubLine && <Grid item xs={12} mx={isMobile? 2 : 10} sx={{ textAlign: 'center' }}>
                            {props.headSubLine}
                        </Grid>}
                    </Grid>

                </Backdrop>

            </CustomBox>

            </>
        }
    </LanguageConsumer>
    )
} 