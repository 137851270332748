
import { createSlice } from '@reduxjs/toolkit';


export type DashboardState = {
  showAll: boolean
}

export const initialStateDashboard: DashboardState = {
  showAll: false
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialStateDashboard,
  reducers: {
    toggleShowAllDashboard: (state) => {
      state.showAll = !Boolean(state.showAll)
    },
  }
});

export const { toggleShowAllDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
