export const GRID_DEFAULT_LOCALE_TEXT_DE = {
    // Root
    noRowsLabel: 'Keine Einträge vorhanden',
    noResultsOverlayLabel: 'Keine Ergebnisse gefunden.',
  
    // Density selector toolbar button text
    toolbarDensity: 'Abstand',
    toolbarDensityLabel: 'Abstand',
    toolbarDensityCompact: 'Kompakt',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Weit',
  
    // Columns selector toolbar button text
    toolbarColumns: 'Spalten',
    toolbarColumnsLabel: 'Spalten auswählen',
  
    // Filters toolbar button text
    toolbarFilters: 'Filter',
    toolbarFiltersLabel: 'Filter anzeigen',
    toolbarFiltersTooltipHide: 'Filter ausblenden',
    toolbarFiltersTooltipShow: 'Filter anzeigen',
    toolbarFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} aktive Filter` : `${count} aktive Filter`,
  
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Suchen...',
    toolbarQuickFilterLabel: 'Suchen',
    toolbarQuickFilterDeleteIconLabel: 'Zurücksetzen',
  
    // Export selector toolbar button text
    toolbarExport: 'Export',
    toolbarExportLabel: 'Export',
    toolbarExportCSV: 'Download als CSV',
    toolbarExportPrint: 'Drucken',
    toolbarExportExcel: 'Download als Excel',
  
    // Columns panel text
    columnsPanelTextFieldLabel: 'Spalte finden',
    columnsPanelTextFieldPlaceholder: 'Spaltentitel',
    columnsPanelDragIconLabel: 'Spalten neu ordnen',
    columnsPanelShowAllButton: 'Alle anzeigen',
    columnsPanelHideAllButton: 'Alle ausblenden',
  
    // Filter panel text
    filterPanelAddFilter: 'Filter hinzufügen',
    filterPanelDeleteIconLabel: 'Löschen',
    filterPanelLogicOperator: 'Logische Verknüpfung',
    filterPanelOperator: 'Verknüpfung',
    filterPanelOperatorAnd: 'Und',
    filterPanelOperatorOr: 'Oder',
    filterPanelColumns: 'Spalten',
    filterPanelInputLabel: 'Wert',
    filterPanelInputPlaceholder: 'Filter Wert',
  
    // Filter operators text
    filterOperatorContains: 'enthält',
    filterOperatorEquals: 'gleich',
    filterOperatorStartsWith: 'beginnt mit',
    filterOperatorEndsWith: 'endet mit',
    filterOperatorIs: 'ist',
    filterOperatorNot: 'ist nicht',
    filterOperatorAfter: 'ist danach',
    filterOperatorOnOrAfter: 'ist genau oder danach',
    filterOperatorBefore: 'ist davor',
    filterOperatorOnOrBefore: 'ist genau oder davor',
    filterOperatorIsEmpty: 'ist leer',
    filterOperatorIsNotEmpty: 'ist nicht leer',
    filterOperatorIsAnyOf: 'ist eins von',
  
    // Filter values text
    filterValueAny: 'irgendeins',
    filterValueTrue: 'true',
    filterValueFalse: 'false',
  
    // Column menu text
    columnMenuLabel: 'Menü',
    columnMenuShowColumns: 'Spalten anzeigen',
    columnMenuManageColumns: 'Spalten verwalten',
    columnMenuFilter: 'Filter',
    columnMenuHideColumn: 'Spalte ausblenden',
    columnMenuUnsort: 'Sortierung aufheben',
    columnMenuSortAsc: 'Aufsteigend sortieren',
    columnMenuSortDesc: 'Absteigend sortieren',
  
    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} aktive Filter` : `${count} aktive Filter`,
    columnHeaderFiltersLabel: 'Filter anzeigen',
    columnHeaderSortIconLabel: 'Sortieren',
  
    // Rows selected footer text
    footerRowSelected: (count) =>
      count !== 1
        ? `${count.toLocaleString()} Zeilen ausgewählt`
        : `${count.toLocaleString()} Zeile ausgewählt`,
  
    // Total row amount footer text
    footerTotalRows: 'Anzeil Zeilen:',
  
    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
      `${visibleCount.toLocaleString()} von ${totalCount.toLocaleString()}`,
  
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox Auswahl',
    checkboxSelectionSelectAllRows: 'Alle Zeilen auswählen',
    checkboxSelectionUnselectAllRows: 'Auswahl aufheben',
    checkboxSelectionSelectRow: 'Zeile auswählen',
    checkboxSelectionUnselectRow: 'Zeile aufheben',
  
    // Boolean cell text
    booleanCellTrueLabel: 'Ja',
    booleanCellFalseLabel: 'Nein',
  
    // Actions cell more text
    actionsCellMore: 'mehr',
  
    // Column pinning text
    pinToLeft: 'Links anpinnen',
    pinToRight: 'Rechts anpinnen',
    unpin: 'Loslösen',
  
    // Tree Data
    treeDataGroupingHeaderName: 'Gruppe',
    treeDataExpand: 'Kinder ansehen',
    treeDataCollapse: 'Kinder ausblenden',
  
    // Grouping columns
    groupingColumnHeaderName: 'Gruppe',
    groupColumn: (name) => `Gruppieren nach ${name}`,
    unGroupColumn: (name) => `Gruppierung aufheben nach ${name}`,
  
    // Master/detail
    detailPanelToggle: 'Detail panel toggle',
    expandDetailPanel: 'Erweitern',
    collapseDetailPanel: 'Schließen',
  
    // Used core components translation keys
    MuiTablePagination: {
      labelDisplayedRows: ({ from, to, count }) =>
        `${from} - ${to} von ${count}`,
    },
  
    // Row reordering text
    rowReorderingHeaderName: 'Row reordering',
  
    // Aggregation
    aggregationMenuItemHeader: 'Aggregation',
    aggregationFunctionLabelSum: 'sum',
    aggregationFunctionLabelAvg: 'avg',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'size',
  }