import { Button, Chip, Grid, IconButton, Skeleton, Typography, useTheme } from "@mui/material"
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBox } from "../../../../../Layout/CustomBox";
import { AttachFile, FileUpload, Info } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import HtmlParser from "react-html-parser";
import { DropzoneArea } from "react-mui-dropzone";
import { useState } from "react";
import toast from "react-hot-toast";
import { fileToBase64 } from "../../../../../Infrastructure/Utils/FileToBase64";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { useAppDispatch } from "../../../../../Infrastructure/Store/hooks";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { UploadResponseDto } from "../Models/UploadResponseDto";
import { DefaultResponse } from "../../../../../Infrastructure/Api/Model/DefaultResponse";
import { FileContentValidationResult } from "../Models/FileContentValidationResult";
import { CourseErrors } from "./CourseErrors/CourseErrors";
import { RxExclamationTriangle } from "react-icons/rx";

interface CourseUploadProps {
    courseId: number,
    setIdToHandle: (id: number) => void
}

export const CourseUpload = (props: CourseUploadProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [uploadFileList, setUploadFileList] = useState<File[] | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<FileContentValidationResult | null>(null);
    const [errorPopupVisible, setErrorPopupVisible] = useState<boolean>(false);

    const closeErrorPopup = () => {
        setErrorPopupVisible(false);
    }

    const handleFileDrop = (files: File[]) => {
        setUploadFileList(files);
    }

    const handleFileDelete = (file: File) => {
        setUploadFileList(x => x ? [...x.filter(y => y.name !== file.name)] : null)
    }

    const submitFileToBackend = async() => {
        if(uploadFileList && uploadFileList.length > 0){
            setIsSubmitting(true);
            let fileToUpload = uploadFileList[0];
            var fileAsBase64 = await fileToBase64(fileToUpload);
            var onlyBase64 = fileAsBase64.split(",")[1] ? fileAsBase64.split(",")[1] : "";
            try {
                var response = await fetchMyDepragApi<DefaultResponse>({CourseFileContentBase64Encoded: onlyBase64}, `onlinetraining/courses/${props.courseId}/upload`, 'POST', dispatch, store.getState() as RootState, "SuccessfullyUploadedFile", true);

                if(response && response.Success){
                    setValidationErrors(null);
                    var newCourseId = (response.Payload as UploadResponseDto).CourseId;
                    setIsSubmitting(false);
                    props.setIdToHandle(newCourseId);  

                } else if (response && !response.Success && response.ErrorMessage.trim().length === 0){
                    setIsSubmitting(false);
                    var validationErrorResponse = (response.Payload as FileContentValidationResult);
                    if(validationErrorResponse){
                        setValidationErrors(validationErrorResponse);
                        setErrorPopupVisible(true);
                    }
                    
                }
            } catch (error) {
                setIsSubmitting(false);
                toast.error(error as string);
                setValidationErrors(null);
            }
        }
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <CustomBox elevation={0} sx={{
                        minHeight: 200,
                        height: '100%',
                        backgroundColor: 'transparent',
                        border: `1px solid ${theme.palette.cardBorder.main}`,
                        backgroundSize: 'cover',
                        position: 'relative',
                    }}>

                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={12} sx={{ textAlign: 'left' }}>
                                <Typography variant="h6" fontWeight={600}>
                                    {getTranslatedText("CourseEditUploadTitle")}

                                {validationErrors && <Chip onClick={() => setErrorPopupVisible(old => !Boolean(old))} component={'span'} icon={<Info />} size="small" sx={{ml: 1, cursor: 'pointer'}} color="error" label={getTranslatedText("ErrorsFound", [(validationErrors.ChapterErrors.Items.length + validationErrors.CourseErrors.Items.length + validationErrors.ContentErrors.Items.length + validationErrors.ModuleErrors.Items.length).toString()])} />}
                                </Typography>
                                
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'left' }}>
                            {!isSubmitting ? <DropzoneArea
                                    onChange={handleFileDrop}
                                    onDelete={handleFileDelete}
                                    onAlert={(message) => {
                                        if (message === "Maximum allowed number of files exceeded. Only 1 allowed") {
                                            toast.error(getTranslatedText("MaxFileLimitSucceded"), { id: "MaxFileLimitSucceded" })
                                        }
                                        if(message.includes("File type not supported")){
                                            toast.error(getTranslatedText("FileTypeNotSupported"), { id: "FileTypeNotSupported" })
                                        }
                                    }}
                                    filesLimit={1}
                                    acceptedFiles={['.xls']}
                                    maxFileSize={1e+7}
                                    clearOnUnmount={true}
                                    dropzoneText={getTranslatedText("RequestVideoCourseDragAndDropZoneText")}
                                    showPreviews={true}
                                    previewText={getTranslatedText("VideoCourseLoadedFiles")}
                                    showPreviewsInDropzone={false}
                                    showAlerts={false}
                                    onDropRejected={(rejectedFiles) => {
                                        rejectedFiles.forEach(x => {
                                            if (x.size > 1e+7) {
                                                toast.error(getTranslatedText("FileSizeExceeded"))
                                            }
                                        })
                                    }}
                                    useChipsForPreview={true}
                                    dropzoneParagraphClass="dropzone-text-xls"
                                    dropzoneClass="dropzone-body-xls"
                                    Icon={() => <IconButton><AttachFile /></IconButton>}
                                /> : <Skeleton width={'100%'} height={300} sx={{mt: -8, mb: -5}} />}
                            </Grid>

                            <Grid item xs={12}>
                                <LoadingButton onClick={submitFileToBackend} loading={isSubmitting} disabled={!uploadFileList || uploadFileList.length === 0} startIcon={<FileUpload />} fullWidth color="success" variant="contained">{getTranslatedText("UploadVideoCourseXls")}</LoadingButton>
                            </Grid>
                        </Grid>

                    </CustomBox>
                    <CourseErrors visible={errorPopupVisible} close={closeErrorPopup} validationErrors={validationErrors} />
                </>
            }
        </LanguageConsumer>
    )
}