import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Chip, Grid } from "@mui/material";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import { useState } from "react";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { UserMailHistoryItem } from "../Models/UserDetailResponseDto";
import { globalDateFormatOptions } from "../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";

interface UserEditOutgoingMailHistoryTableProps {
    data: UserMailHistoryItem[] | undefined
}

export const UserEditOutgoingMailHistoryTable = (props: UserEditOutgoingMailHistoryTableProps) => {

    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const language = useAppSelector(store => store.settings.language);
    const [searchValue, setSearchValue] = useState<string>("");

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const columns: GridColDef[] = [
            {
                field: 'Subject',
                headerName: getTranslation("UserEditOutgoingMailHistoryTableSubject"),
                flex: 1,
                minWidth: 150,
            },
            {
                field: 'SentOn',
                headerName: getTranslation("UserEditOutgoingMailHistoryTableSentOn"),
                flex: 1,
                minWidth: 150,
                type: 'date',
                renderCell: (params: GridRenderCellParams<any, UserMailHistoryItem, any>) => {
                    return <Chip variant="outlined" label={new Date(params.row.SentOn).toLocaleDateString(language, globalDateFormatOptions)} />
                }
            }
    ]

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>

                                    <div 
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("UserEditOutgoingMailHistoryTableDescription") }}
                                    />
                                </Grid>

                                <Grid item xs={4} my={'auto'} sx={{textAlign: 'left', overflowX: 'hidden'}}>
                                  <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        disableColumnSelector
                                        localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={props.data ? props.data.filter(x =>
                                            x.Subject.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        getRowId={(x) => x.Id}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                          }
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}