import { Close } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogTitleProps, IconButton, styled } from "@mui/material";
import { useAppDispatch } from "../../../../../../../Infrastructure/Store/hooks";
import { closeAcceptPopup } from "../../../Store/OpenRegistrationsSlice";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2),
    },
  }));

  export function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children,  ...other } = props;
    const dispatch = useAppDispatch();

    const close = () => {
        dispatch(closeAcceptPopup())
    }
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}        
      </DialogTitle>
    );
  }