import { getTranslation } from "../Internationalisation/TranslationService";
import { SelectOption } from "../../Layout/Inputs/Models/ControlledInputProps";


export function mapEnumToOptions(object: {}): SelectOption[] {
  let options: SelectOption[] = [];
  for (const [key, value] of Object.entries(object)) {
    const option: SelectOption = {
      label: getTranslation(value as string),
      value: key,
    };
    options.push(option);
  }
  return options;
}
