import { Grid, Stack, Typography } from "@mui/material"
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService"
import { NavLink } from "react-router-dom"

export const ImportantLinks = () => {

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
                <Grid container direction={'row'} spacing={2}>
                    <Grid item xs={12}>
                        <Stack spacing={4} direction={{ xs: 'column', md: 'row'}} justifyContent={'center'}>
                            <Typography fontWeight={400} component={NavLink} to='/Imprint' sx={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>{getTranslatedText("Imprint")}</Typography>
                            <Typography fontWeight={400} component={NavLink} to='/Privacy' sx={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>{getTranslatedText("Privacy")}</Typography>
                            <Typography fontWeight={400} component={NavLink} to='/ThirdParty' sx={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>{getTranslatedText("ThirdParty")}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </>
            }
        </LanguageConsumer>
    )
}