import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToBottomHandler = (offset: number = 1) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(false);
  const location = useLocation();

  const handleScroll = (event: Event) => {
    const { scrollHeight, scrollTop, clientHeight } = (event.target as Document).scrollingElement as HTMLElement;
    setIsScrolledToBottom(Math.abs(scrollHeight - clientHeight - scrollTop) < offset);
  }

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    isScrolledToBottom
  }
}