import { useState, MouseEvent } from "react";
import { Logout } from "@mui/icons-material";
import { Avatar, Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService"
import { removeAuthentication } from "../../../Features/Infrastructure/Login/Store/LoginSlice";
import { stringAvatar } from "../../../Infrastructure/Utils/UserColorGenerator";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks";

export const ProfileButton = () => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(store => store.authentication);
    const [anchorElProfile, setAnchorElProfile] = useState<null | HTMLElement>(null);
    const openProfileMenu = Boolean(anchorElProfile);


    const handleClickProfile = (event: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      setAnchorElProfile(event.currentTarget);
    };

    const handleCloseProfile = () => {
      setAnchorElProfile(null);
    };


    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Box  component={'div'} onClick={handleClickProfile} sx={{ float: 'right', ml: 4, pr: 2, textAlign: 'left', cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                        <Avatar className="global-toolbar-profile" {...stringAvatar(`${currentUser.firstName} ${currentUser.lastName}`, { mr: 2 })} />
                    </Box>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorElProfile}
                        open={openProfileMenu}
                        onClose={handleCloseProfile}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ borderRadius: 3 }}
                    >
                        <MenuItem>
                            <ListItemText
                                secondary={<Typography sx={{ maxWidth: '170px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} fontSize={12} color={'darky'}>
                                    {currentUser.company}</Typography>}>
                                <Typography fontWeight={600}>{currentUser.firstName} {currentUser.lastName}</Typography>
                            </ListItemText>
                        </MenuItem>
                        <Divider sx={{ my: 2 }} />
                        <MenuItem onClick={() => {
                            dispatch(removeAuthentication())
                            handleCloseProfile();
                        }}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{getTranslatedText("Logout")}</ListItemText>
                        </MenuItem>
                    </Menu>
                </>
            }
        </LanguageConsumer>
    )
}