import { Button, Chip, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { DefaultResponse } from "../../../../../Infrastructure/Api/Model/DefaultResponse";
import { LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch } from "../../../../../Infrastructure/Store/hooks";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { CustomBox } from "../../../../../Layout/CustomBox";
import { ClaimsEditSingle } from "./ClaimsEditSingle";
import { ClaimsEditGoldMember } from "./ClaimsEditGoldMember";
import { ClaimsEditResponseDto, SingleClaim } from "./Models/ClaimsEditResponseDto";


export const ClaimsEdit = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const theme = useTheme();
    const [listOfClaims, setListOfClaims] = useState<SingleClaim[]>([])
    const [goldStatus, setGoldStatus] = useState<boolean>()

    const { isLoading, data, refetch } = useQuery({
        queryKey: [`getClaim-${id}`],
        queryFn: async () => await fetchMyDepragApi<ClaimsEditResponseDto>(null,
            'highlevel/claimseditor/get/' + id!,
            'POST',
            dispatch,
            store.getState() as RootState),
        enabled: id !== undefined && parseInt(id) > 0
    });

    useEffect(() => {
        if (data) {
            setListOfClaims(data.Claims);
            setGoldStatus(data.IsGoldMember);
        }
    }, [data])

    const postToApi = async () => {
        var dataToPost = {
            userId: parseInt(id!),
            IsGoldMember: goldStatus,
            claims: listOfClaims
        }

        await fetchMyDepragApi<DefaultResponse>(dataToPost, "highlevel/claimseditor/edit", "POST", dispatch, store.getState() as RootState,
            "SuccessfullyChangedClaim")
    }

    const handleCheckedChange = (item: SingleClaim) => {
        modifyItem(item, null, true);
    }

    const handleTextChange = (item: SingleClaim, newText: string) => {
        modifyItem(item, newText, false);
    }

    const toggleGoldStatus = () => {
        setGoldStatus(! goldStatus);
    }

    const modifyItem = (item: SingleClaim, newText: string | null, shouldToggleEnable: boolean) => {
        setListOfClaims(oldClaims => [...oldClaims.map(o => {
            return o.Claim === item.Claim ? {
                ...o,
                IsEnabled: shouldToggleEnable ? !Boolean(o.IsEnabled) : o.IsEnabled,
                Value: newText ? newText : o.Value
            } : o
        })])
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 5}}>
                        <Grid item xs={12} mx={3}>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Button color="darky" component={NavLink} to={'/highlevel/allclaims'}
                                        variant="outlined">{getTranslatedText("Prev")}</Button>

                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction={'row'} my={'auto'} spacing={2}>
                                        <Chip label={data?.CompanyName} />
                                        <Chip label={data?.Email} component={NavLink} to={`mailto:${data?.Email}`} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <CustomBox elevation={0} sx={{
                                        backgroundSize: 'cover',
                                        backgroundBlendMode: 'multiply',
                                        backgroundColor: 'transparent',
                                        border: `1px solid ${theme.palette.cardBorder.main}`
                                    }}>
                                            
                                                {listOfClaims && listOfClaims.length > 0 && listOfClaims.map(item => {
                                                    return (
                                                        <ClaimsEditSingle key={item.Claim} item={item} handleCheckedChange={handleCheckedChange}
                                                            handleTextChange={handleTextChange} />
                                                    )
                                                })}
                                    </CustomBox>
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <CustomBox elevation={0} sx={{
                                        backgroundSize: 'cover',
                                        backgroundBlendMode: 'multiply',
                                        backgroundColor: 'transparent',
                                        border: `1px solid ${theme.palette.cardBorder.main}`
                                    }}>
                                        <ClaimsEditGoldMember hasGoldStatus={goldStatus ?? false} handleCheckedChange={toggleGoldStatus}        />
                                    </CustomBox>
                                </Grid>


                                <Grid item xs={12}>
                                    <Button onClick={postToApi} color="success"
                                        variant="contained" type="submit" fullWidth>{getTranslatedText("Submit")}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}
