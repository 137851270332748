import { Box, Grid, Chip, IconButton, TextField, Tooltip, FormControlLabel, Skeleton, Divider, Typography, styled, useTheme } from "@mui/material"
import { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { useQuery } from "@tanstack/react-query";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { LicenceByOrderNumberResponseDto } from "../Model/LicenceByOrderNumberResponseDto";
import { LicenceByOrderNumberResponseItem } from "../Model/LicenceByOrderNumberResponseItem";
import { globalDateFormatOptions } from "../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";
import { IoPencilSharp } from "react-icons/io5";
import { CustomSwitch } from "../../../../../Layout/Switch/CustomSwitch";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import {setShowOnlyAvailable, setFilter} from "../../ByOrderNumber/Components/Store/SearchByOrderNumberSlice";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import { EasyEncrypt } from "../../../../../Infrastructure/EasyEncryption/EasyEncryption";

export const MAX_YEAR = 9999;

export const SearchByOrderNumber = () => {
    const theme = useTheme();
    const { showOnlyAvailable, currentFilter } = useAppSelector(store => store.licenceSearchByOrderNumber);
    const navigate = useNavigate();
    const language = useAppSelector(store => store.settings.language);
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const location = useLocation(); 
    const [searchValue, setSearchValue] = useState<string>(""); 
    
    const dispatch = useAppDispatch();       

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['LicencesByOrderNumber', currentFilter], 
        queryFn: async () => await fetchMyDepragApi<LicenceByOrderNumberResponseDto>(
          {
              orderNumber: currentFilter,              
          },
      
          "licences/ordernumber",
          'POST',
          dispatch,
          store.getState() as RootState),
          enabled: !!currentFilter          
      });

      useEffect(() => {
        refetch();
      }, [location, refetch]);      

      const search = (inputText: string) => {
        setSearchValue(inputText);
      }

      const handleOnlyAvailableLicensesChanged = (value: boolean) => {
        dispatch(setShowOnlyAvailable(value));
      }

      function handelFilterChanged(e): void {
        dispatch(setFilter(e.target.value as string));
    }

      const openDetails = (params: GridRowParams) => {
            navigate(`/licences/ordernumber/detail/${EasyEncrypt(params.row.PayedLicenceId)}`)
      }      

      const columns: GridColDef[] = [        
        { 
            field: 'OrderNumber', 
            headerName: getTranslation("PaidLicenceOrderNumber"), 
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, LicenceByOrderNumberResponseItem, any>) => {                
                return <Chip label={params.row.OrderNumber} />
            } 
        },
        { 
            field: 'Customer', 
            headerName: getTranslation("PaidLicenceCompanyName"), 
            flex: 1 ,
            minWidth: 180,
        },        
         { 
            field: 'ProductName', 
            headerName: getTranslation("PaidLicenceProductName"), 
            flex: 1 ,
            minWidth: 350,
        },
        { 
            field: 'OrderCreatedOn', 
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
            headerName: getTranslation("PaidLicenceOrderCreatedOn"), 
            flex: 1, 
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, LicenceByOrderNumberResponseItem, any>) => {                
                return <Chip variant="outlined" label={new Date(params.row.OrderCreatedOn).toLocaleDateString(language, globalDateFormatOptions)} />
             }, 
        },
        { 
            field: 'LicenceActiveFrom', 
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
            headerName: getTranslation("PaidLicenceLicenceActiveFrom"), 
            flex: 1, 
            minWidth: 130,
            renderCell: (params: GridRenderCellParams<any, LicenceByOrderNumberResponseItem, any>) => {                
                return <Chip color="success" variant="outlined" label={new Date(params.row.LicenceActiveFrom).toLocaleDateString(language, globalDateFormatOptions)} />
            }
        },
        { 
            field: 'LicenceActiveUntil', 
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
            headerName: getTranslation("PaidLicenceLicenceActiveUntil"), 
            flex: 1, 
            minWidth: 130,
            renderCell: (params: GridRenderCellParams<any, LicenceByOrderNumberResponseItem, any>) => {      
                return <Chip color="error" variant="outlined" label={MAX_YEAR === new Date(params.row.LicenceActiveUntil).getFullYear() ? getTranslation("Unlimited") : new Date(params.row.LicenceActiveUntil).toLocaleDateString(language, globalDateFormatOptions)} />
            }  
        },
        { 
            field: 'Licences', 
            headerName: getTranslation("PaidLicenceLicences"), 
            flex: 0.6,
            minWidth: 100, 
            align: "right",
            renderCell: (params: GridRenderCellParams<any, LicenceByOrderNumberResponseItem, any>) =>(
                <strong>
                <Chip label={`${params.row.UsedLicence} / ${params.row.QuantityOrdered}`} />
                </strong>
            ), 
            sortable: false, 
            filterable: false
        },
        { 
            field: '', 
            headerName: '', 
            flex: 0.1, 
            minWidth: 100, 
            align:'right', 
            renderCell: (params: GridRenderCellParams<any, LicenceByOrderNumberResponseItem, any>) =>(
            <strong>
    
                    <Tooltip title={getTranslation("CommonEdit")}>
                        <IconButton color={'darky'} component={NavLink} to={`/licences/ordernumber/detail/${EasyEncrypt(params.row.PayedLicenceId.toString())}`}>
                            <IoPencilSharp />
                        </IconButton>
                    </Tooltip>
            </strong>
            ), 
            sortable: false, 
            filterable: false},
      ]; 


      return (
        <LanguageConsumer>
            { ({ getTranslatedText }) =>
                <>                
                    {<Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                           {renderPaidLicenceGrid(getTranslatedText)}
                        </Grid>
                    </Grid>}
                </>
            }
        </LanguageConsumer>
    )

    function renderPaidLicenceGrid(getTranslatedText: (key: string, values?: Array<string>) => string) {
        return (
        <div>
             <Grid container spacing={2}>
            <Grid item xs={12} sx={{textAlign: 'left'}}>
            
                <div
                    dangerouslySetInnerHTML={{ __html: getTranslatedText("SoftwareLicensingInfoText") }}
                />


                <Divider sx={{my: 3, mb: 3}} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant="h6">{getTranslatedText("SearchLicenceByOrderNumberInfoText")}</Typography>                                                                                                                                                        
            </Grid>

            <Grid item md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>
            <Grid item xs={12} md={6} my={'auto'}>
                <TextField
                    hiddenLabel={true}
                    variant="standard"
                    color="success"
                    fullWidth autoFocus
                    placeholder={getTranslatedText("PaidLicenceOrderNumber")}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{sx: {textAlign: 'center', fontSize: 23, fontWeight: 700, border: 0, outline: 0, '&::placeholder': {fontWeight: 400, opacity: 0.1}}}}
                    sx={{border: `1px solid ${theme.palette.footer.main}`, padding: 1, borderRadius: 8, '&:has(.Mui-focused)': {border: `2px solid ${theme.palette.success.main}`}}}
                    type={'search'}
                    value={currentFilter}
                    onChange={(e) => handelFilterChanged(e)}
                />                                                                    
            </Grid>         
            <Grid item md={3} sx={{display: {xs: 'none', md: 'block'}}}></Grid>                          

            <Grid item xs={12} sx={{ textAlign: 'left' }}>                                                                            
                <Divider sx={{ my: 3 }} /> 
            </Grid>                                     

            
            {data !== undefined && data.Items.length > 0 &&
              <>
                <Grid item xs={4} my={'auto'}  sx={{textAlign: 'left', overflowX: 'hidden'}}>
                    <DataGridSearch searchValue={searchValue} search={search} />
                </Grid>
                <Grid item xs={8} my={'auto'} sx={{textAlign: 'right'}}>
                    <FormControlLabel control={<CustomSwitch />} checked={showOnlyAvailable} onChange={(e, value)=> handleOnlyAvailableLicensesChanged(value)} label={getTranslatedText("OnlyShowAvailableLicenses")}/>
                </Grid>
              </>
             }
            </Grid>

            {data !== undefined && data.Items.length > 0 &&
            <Grid item xs={12} sx={{mt: 1}}>
                <Box component={'div'} sx={{ width: '100%' }}>
                 <StripedDataGrid
                localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                rows={data ? data.Items.filter(x => 
                    (x.OrderNumber.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                    x.ProductName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())  ||
                    x.Customer.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())  ||
                    new Date(x.LicenceActiveFrom).toLocaleDateString(language, globalDateFormatOptions).includes(searchValue.toLocaleLowerCase()) ||
                    new Date(x.LicenceActiveUntil).toLocaleDateString(language, globalDateFormatOptions).includes(searchValue.toLocaleLowerCase()) ||
                    new Date(x.OrderCreatedOn).toLocaleDateString(language, globalDateFormatOptions).includes(searchValue.toLocaleLowerCase())) && ( !showOnlyAvailable || x.AvailableLicence > 0)
                ) : []}
                autoHeight={true}
                columns={columns}
                pageSize={8}
                rowsPerPageOptions={[8]}
                checkboxSelection={false}
                disableColumnFilter={false}
                disableColumnMenu={false}
                disableSelectionOnClick={true}
                disableVirtualization={false}
                hideFooter={false}
                getRowId={(x) => x.PayedLicenceId}
                onRowDoubleClick={openDetails}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  }
                components={{
                    LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                  }}
                  loading={isLoading} 
                />
            </Box>
            </Grid>         
    }
        </div>)
    }      
}
