import { AuthenticationResponseDto } from "../../../../Api/Authentication/Models/AuthenticationResponseDto";
import { RefreshTokenRequest } from "../../../../Api/Authentication/Models/RefreshTokenRequest";
import { fetchMyDepragApi } from "../../../../Infrastructure/Api/ApiBaseCall";
import { DefaultResponse } from "../../../../Infrastructure/Api/Model/DefaultResponse";
import { RootState } from '../../../../Infrastructure/Store/store';
import { refreshingAuthenticationDataReducer } from "./LoginSlice";

export const refreshingAuthenticationData = async (refreshToken: RefreshTokenRequest,
    dispatch: (action: { payload: undefined; type: string; }) => { payload: undefined; type: string; },
    rootState: RootState): Promise<string | null> => {
    var response = await fetchMyDepragApi<DefaultResponse>(refreshToken, "auth/refreshtoken", 'POST',
        dispatch, rootState, undefined, true, true);

    if (response.Success) {
        let payload = response.Payload as AuthenticationResponseDto;
        await dispatch(refreshingAuthenticationDataReducer(payload));
        return payload.Token;
    }

    return null;
}
