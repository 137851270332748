import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableRow, useTheme } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { fetchMyDepragApi } from "../../../../../../Infrastructure/Api/ApiBaseCall"
import { useAppDispatch } from "../../../../../../Infrastructure/Store/hooks"
import { store, RootState } from "../../../../../../Infrastructure/Store/store"
import { CustomBox } from "../../../../../../Layout/CustomBox"
import { useEffect, useState } from "react"
import { NavLink, useParams } from "react-router-dom"
import { ResponseLogDetailResponseDto } from "../Models/ResponseLogsDetailResponseDto"

export const LogsDetails = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const { data } = useQuery({
        queryKey: [`log${id}`],
        queryFn: async () => await fetchMyDepragApi<ResponseLogDetailResponseDto>(
            { id: id },
            `highlevel/logs/${id}`,
            'POST',
            dispatch,
            store.getState() as RootState),
    });

    const [responseLogValues, setResponseLogValues] = useState([
        { key: 'ResponseLogId', value: data && data.Log && data.Log.ResponseLogId },
        { key: 'CreatedOn', value: data && data.Log && data.Log.CreatedOn },
        { key: 'Origin', value: data && data.Log && data.Log.Origin },
        { key: 'OccuredByUserId', value: data && data.Log && data.Log.OccuredByUserId },
        { key: 'Content', value: data && data.Log && data.Log.Content },
        { key: 'RequestUrl', value: data && data.Log && data.Log.RequestUrl },
    ]);

    useEffect(() => {
        data && setResponseLogValues([
            { key: 'ResponseLogId', value: data && data.Log && data.Log.ResponseLogId },
            { key: 'CreatedOn', value: data && data.Log && data.Log.CreatedOn },
            { key: 'Origin', value: data && data.Log && data.Log.Origin },
            { key: 'OccuredByUserId', value: data && data.Log && data.Log.OccuredByUserId },
            { key: 'Content', value: data && data.Log && data.Log.Content },
            { key: 'RequestUrl', value: data && data.Log && data.Log.RequestUrl },
        ]);
    }, [data])


    return (
        <>
            <LanguageConsumer>
                {({ getTranslatedText }) =>
                    <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <Button color="darky" component={NavLink} to={'/highlevel/logs'} variant="outlined">
                                {getTranslatedText("Prev")}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={CustomBox} elevation={0} sx={{
                                backgroundSize: 'cover',
                                backgroundBlendMode: 'multiply',
                                backgroundColor: 'transparent',
                                border: `1px solid ${theme.palette.cardBorder.main}`
                            }}>
                                <Table >
                                    <TableBody>
                                        {responseLogValues.map((value) => {
                                            return (
                                                <TableRow key={value.key}>
                                                    <TableCell scope="row" width={'20%'}>
                                                        {getTranslatedText("HighLevelLogs" + value.key)}:
                                                    </TableCell>

                                                    {typeof (value.value)?.toString() === "string" ?
                                                        <TableCell scope="row">
                                                            <div dangerouslySetInnerHTML={{ __html: value.value.toString() }}>
                                                            </div>
                                                        </TableCell>
                                                        :
                                                        <TableCell scope="row">
                                                            {value.value}
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table >
                            </TableContainer>
                        </Grid>
                    </Grid>
                }
            </LanguageConsumer>
        </>
    )
}