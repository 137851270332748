
import { createSlice } from '@reduxjs/toolkit';

export interface CreateCustomerDefaultValues {
    countryName: string,
    companyName: string,
    postalCode: string
}
export type CustomersState = {
    showAllCustomers: boolean,
    generatedCustomerId: number | null,
    createCustomerDefaultValues: CreateCustomerDefaultValues | undefined
}

export const initialStateCustomers: CustomersState = {
    showAllCustomers: false,
    generatedCustomerId: null,
    createCustomerDefaultValues: undefined
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState: initialStateCustomers,
  reducers: {
    toggleShowAllCustomers: (state) => {
      state.showAllCustomers = !Boolean(state.showAllCustomers)
    },
    setGeneratedCustomerId: (state, action) => {
      state.generatedCustomerId = action.payload;
    },
    clearGeneratedCustomerId: (state) => {
      state.generatedCustomerId = null;
    },
    setCreateCustomerDefaultValues: (state, action) => {
      state.createCustomerDefaultValues = action.payload;
    },
    clearCreateCustomerDefaultValues: (state) => {
      state.createCustomerDefaultValues = initialStateCustomers.createCustomerDefaultValues;
    }
  }
});

export const { clearGeneratedCustomerId, toggleShowAllCustomers, setGeneratedCustomerId, setCreateCustomerDefaultValues, clearCreateCustomerDefaultValues } = customersSlice.actions;

export default customersSlice.reducer;
