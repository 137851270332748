import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, useTheme } from "@mui/material"
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBox, CustomBoxHeader } from "../../../../../../Layout/CustomBox"

interface EmailTriggerRulesFiltersProps {
    filterIncludeDepragMembers: boolean | null,
    filterForDaysRegisteredGreaterThan: number | null,
    filterForDaysNotLoggedInGreaterThan: number | null,
    filterIfTestPeriodHasStarted: boolean | null,
    filterForTestPeriodHasEnded: boolean | null,
    filterForGoldMembershipActive: boolean | null,
    setFilterIncludeDepragMembers: React.Dispatch<React.SetStateAction<boolean | null>>
    setFilterForDaysRegisteredGreaterThan: React.Dispatch<React.SetStateAction<number | null>>
    setFilterForDaysNotLoggedInGreaterThan: React.Dispatch<React.SetStateAction<number | null>>
    setFilterIfTestPeriodHasStarted: React.Dispatch<React.SetStateAction<boolean | null>>
    setFilterForTestPeriodHasEnded: React.Dispatch<React.SetStateAction<boolean | null>>
    setFilterForGoldMembershipActive: React.Dispatch<React.SetStateAction<boolean | null>>
}

export const EmailTriggerRulesFilters = (props: EmailTriggerRulesFiltersProps) => {
    const {
        filterIncludeDepragMembers,
        filterForDaysRegisteredGreaterThan,
        filterForDaysNotLoggedInGreaterThan,
        filterIfTestPeriodHasStarted,
        filterForTestPeriodHasEnded,
        filterForGoldMembershipActive,
        setFilterIncludeDepragMembers,
        setFilterForDaysRegisteredGreaterThan,
        setFilterForDaysNotLoggedInGreaterThan,
        setFilterIfTestPeriodHasStarted,
        setFilterForTestPeriodHasEnded,
        setFilterForGoldMembershipActive,
    } = props;

    const theme = useTheme();

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <CustomBoxHeader text={getTranslatedText("EmailTriggerRulesFilter")} />
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <CustomBox elevation={0} sx={{ background: theme.palette.cardBackground.main, height: '100%' }}>
                                <CustomBoxHeader text={getTranslatedText("FilterConditions")} sx={{fontSize: 13}} />
                                <FormGroup>
                                    <Stack direction={{xs: 'column', md: 'column', lg: 'column', xl: 'row'}} spacing={2} alignItems={'center'} justifyContent={'space-between'} sx={{width: '100%'}}>
                                        <FormControlLabel onChange={(e) => setFilterIncludeDepragMembers(old => typeof(old) === 'boolean' ? null : true)} checked={filterIncludeDepragMembers !== null ? true : false} value={filterIncludeDepragMembers} control={<Checkbox />} label={getTranslatedText("FilterIncludeDepragMembers")} />
                                        {filterIncludeDepragMembers !== null &&
                                        <FormControl sx={{width: 200}}>
                                        <InputLabel>{getTranslatedText("Value")}</InputLabel>
                                            <Select
                                                size="small"
                                                label={getTranslatedText("Value")}
                                                value={filterIncludeDepragMembers}
                                                onChange={(e) => setFilterIncludeDepragMembers(e.target.value as boolean)}
                                            >
                                                <MenuItem value={true as any}>{getTranslatedText("IsDepragMember")}</MenuItem>
                                                <MenuItem value={false as any}>{getTranslatedText("IsNoDepragMember")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        }
                                    </Stack>

                                    <Divider sx={{my: 2}} />

                                    <Stack direction={{xs: 'column', md: 'column', lg: 'column', xl: 'row'}} spacing={2} alignItems={'center'} justifyContent={'space-between'} sx={{width: '100%'}}>
                                        <FormControlLabel onChange={(e) => setFilterIfTestPeriodHasStarted(old => typeof(old) === 'boolean' ? null : true)}  checked={filterIfTestPeriodHasStarted !== null ? true : false} value={filterIfTestPeriodHasStarted} control={<Checkbox />} label={getTranslatedText("FilterIfTestPeriodHasStarted")} />
                                        {filterIfTestPeriodHasStarted !== null &&
                                         <FormControl sx={{width: 200}}>
                                         <InputLabel>{getTranslatedText("Value")}</InputLabel>
                                         <Select
                                            size="small"
                                            label={getTranslatedText("Value")}
                                            value={filterIfTestPeriodHasStarted}
                                            onChange={(e) => setFilterIfTestPeriodHasStarted(e.target.value as boolean)}
                                        >
                                            <MenuItem value={true as any}>{getTranslatedText("HasStarted")}</MenuItem>
                                            <MenuItem value={false as any}>{getTranslatedText("HasNotStarted")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        }
                                    </Stack>

                                    <Divider sx={{my: 2}} />

                                    <Stack direction={{xs: 'column', md: 'column', lg: 'column', xl: 'row'}} spacing={2} alignItems={'center'} justifyContent={'space-between'} sx={{width: '100%'}}>
                                        <FormControlLabel onChange={(e) => setFilterForTestPeriodHasEnded(old => typeof(old) === 'boolean' ? null : true)} checked={filterForTestPeriodHasEnded !== null ? true : false}  value={filterForTestPeriodHasEnded} control={<Checkbox />} label={getTranslatedText("FilterForTestPeriodHasEnded")} />

                                        {filterForTestPeriodHasEnded !== null &&
                                         <FormControl sx={{width: 200}}>
                                         <InputLabel>{getTranslatedText("Value")}</InputLabel>
                                         <Select
                                            size="small"
                                            label={getTranslatedText("Value")}
                                            value={filterForTestPeriodHasEnded}
                                            onChange={(e) => setFilterForTestPeriodHasEnded(e.target.value as boolean)}
                                        >
                                            <MenuItem value={true as any}>{getTranslatedText("HasEnded")}</MenuItem>
                                            <MenuItem value={false as any}>{getTranslatedText("HasNotEnded")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        }
                                    </Stack>

                                    <Divider sx={{my: 2}} />

                                    <Stack direction={{xs: 'column', md: 'column', lg: 'column', xl: 'row'}} spacing={2} alignItems={'center'} justifyContent={'space-between'} sx={{width: '100%'}}>
                                        <FormControlLabel onChange={(e) => setFilterForGoldMembershipActive(old => typeof(old) === 'boolean' ? null : true)} checked={filterForGoldMembershipActive !== null ? true : false}  value={filterForGoldMembershipActive} control={<Checkbox />} label={getTranslatedText("FilterForGoldMembershipActive")} />
                                        
                                        {filterForGoldMembershipActive !== null &&
                                         <FormControl sx={{width: 200}}>
                                         <InputLabel>{getTranslatedText("Value")}</InputLabel>
                                         <Select
                                            size="small"
                                            label={getTranslatedText("Value")}
                                            value={filterForGoldMembershipActive}
                                            onChange={(e) => setFilterForGoldMembershipActive(e.target.value as boolean)}
                                        >
                                            <MenuItem value={true as any}>{getTranslatedText("IsActive")}</MenuItem>
                                            <MenuItem value={false as any}>{getTranslatedText("IsInactive")}</MenuItem>
                                        </Select>
                                        </FormControl>
                                        }
                                    </Stack>
                                </FormGroup>
                            </CustomBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomBox elevation={0} sx={{ background: theme.palette.cardBackground.main, height: '100%' }}>
                                <CustomBoxHeader text={getTranslatedText("FilterDaysDependent")} sx={{fontSize: 13}} />
                                <Stack direction={'column'} spacing={2} mt={3}>
                                    <TextField
                                        label={getTranslatedText("FilterForDaysRegisteredGreaterThan")}
                                        value={filterForDaysRegisteredGreaterThan || ''}
                                        type="number"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFilterForDaysRegisteredGreaterThan(parseInt(event.target.value));
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{getTranslatedText("Days")}</InputAdornment>
                                        }}
                                    />

                                    <TextField
                                        label={getTranslatedText("FilterForDaysNotLoggedInGreaterThan")}
                                        value={filterForDaysNotLoggedInGreaterThan || ''}
                                        type="number"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFilterForDaysNotLoggedInGreaterThan(parseInt(event.target.value));
                                        }}
                                        
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{getTranslatedText("Days")}</InputAdornment>
                                        }}
                                    />
                                </Stack>
                            </CustomBox>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}