import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getTranslation } from "../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../Store/AppSlice";
import { Grid, Typography } from "@mui/material";
import { CustomBox } from "../../../Layout/CustomBox";
import "./Style/ThirdPartyContent.css"
import { ThirdPartyContentDe } from "./Components/ThirdPartyContentDe";
import { ThirdPartyContentEn } from "./Components/ThirdPartyContentEn";

export const ThirdParty = () => {

    const dispatch = useAppDispatch();
    const location = useLocation();    
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const isGerman = language ==='de';

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuThirdPartyBreadcrumb')));
    }, [location, language, languageFileWasLoaded, dispatch]);
    

    return(
        <>
        <Grid container direction="row" spacing={2} className="main-content" sx={{ mt: 1 }}>
            <Grid item xs={12}>
                <CustomBox elevation={3}>                
                    <Typography>{isGerman ? <ThirdPartyContentDe /> : <ThirdPartyContentEn/>}</Typography>
                </CustomBox>
            </Grid>
        </Grid>
        </>    
    )
}