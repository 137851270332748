import { Chip, Grid, Box } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useState } from "react";
import { globalDateFormatOptions } from "../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";
import { getTranslation, LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { LegalDocumentsContentResponseDto } from "../Models/LegalDocumentsConsent"


interface IUserEditLegalDocumentsTable {
    data: LegalDocumentsContentResponseDto[]
}

export const UserEditLegalDocumentsTable = (props: IUserEditLegalDocumentsTable) => {
    const langIsDe = useAppSelector(store => store.settings.language === 'de');
    const language = useAppSelector(store => store.settings.language);
    const [searchValue, setSearchValue] = useState<string>("");

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const columns: GridColDef[] = [
        {
            field: 'LegalDocumentId',
            headerName: getTranslation("LegalDocumentId"),
            flex: 0.5,
            minWidth: 150,
        },
        {
            field: 'AcceptedOn',
            headerName: getTranslation("AcceptedOn"),
            flex: 0.5,
            minWidth: 150,
            type: 'date',
            renderCell: (params: GridRenderCellParams<any, LegalDocumentsContentResponseDto, any>) => {
                return <Chip variant="outlined" label={new Date(params.row.AcceptedOn).toLocaleDateString(language, globalDateFormatOptions)} />
            }
        },
    ]

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                        
                                <Grid item xs={4} my={'auto'} sx={{ textAlign: 'left', overflowX: 'hidden' }}>
                                    <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        disableColumnSelector
                                        localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={props.data}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        getRowId={(x) => x.UserId + x.AcceptedOn}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    )
}