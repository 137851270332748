import { Autocomplete, Button, FormControlLabel, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { getTranslation, LanguageConsumer } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { CustomSwitch } from "../../../../../Layout/Switch/CustomSwitch";
import { setAppTitle } from "../../../../../Store/AppSlice";
import { CustomerOverviewResponseDto } from "../../../Customers/AllCustomers/Models/CustomerOverviewResponseDto";
import { clearGeneratedCustomerId, toggleShowAllCustomers } from "../../../Customers/Store/CustomersSlice";
import React from "react";
import { StyledPopper, ListboxComponent } from "../../../../../Layout/Inputs/AutoCompleteVirtualizationHelper";

interface UserEditCompanyValuesProps {
    userId: number | undefined,
    customerId: number | undefined,
    companyName: string | undefined,
    selectedCustomer: string | undefined,
    setSelectedCustomer: React.Dispatch<React.SetStateAction<string>>,
    availableCustomers: { Id: number; CompanyName: string; }[] | undefined,
    setAvailableCustomers: React.Dispatch<React.SetStateAction<{ Id: number; CompanyName: string; }[] | undefined>>
    customerError: boolean,
    setCustomerError: React.Dispatch<React.SetStateAction<boolean>>
}

export const UserEditCompanyValues = (props: UserEditCompanyValuesProps) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const language = useAppSelector(store => store.settings.language);
    const showAllCustomers = useAppSelector(store => store.customers.showAllCustomers);
    const { isDepragAmbergMember, isReseller, customerId } = useAppSelector(store => store.authentication);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const generatedCustomerId = useAppSelector(store => store.customers.generatedCustomerId);

    const [specifiedCompanyName, setSpecifiedCompanyName] = useState<string>("")

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuUserEditBreadcrumb')));
    }, [location, language, languageFileWasLoaded, dispatch]);

    const { isLoading: customerIsLoading, data: customerData, refetch: customerRefetch } = useQuery({
        queryKey: ["userEditCustomers"],
        queryFn: async () =>
            await fetchMyDepragApi<CustomerOverviewResponseDto>(
                { showAll: isDepragAmbergMember ? showAllCustomers : false, preferredLanguageIso: language, selectedUserId: props.userId },
                "customers/overview/" + customerId,
                'POST',
                dispatch,
                store.getState() as RootState)
    });

    useEffect(() => {
        customerRefetch()
    }, [showAllCustomers, location, generatedCustomerId])

    useEffect(() => {
        if (customerData && customerData.Items && customerData.Items.length > 0) {
            props.setAvailableCustomers(customerData.Items.map(c => {
                return {
                    Id: c.Id,
                    CompanyName: `${c.CompanyName} ${c.SapCustomerId && c.SapCustomerId.trim().length > 0 ? `(SAP: ${c.SapCustomerId})` : ''} ${c.InternalId ? `| ${c.InternalId}` : ''}`
                }
            }));
        }
    }, [customerData])

    useEffect(() => {
        if (props.availableCustomers && props.availableCustomers.length > 0 && generatedCustomerId) {
            props.setSelectedCustomer(props.availableCustomers.filter(x => x.Id === generatedCustomerId)[0] ? props.availableCustomers.filter(x => x.Id === generatedCustomerId)[0].CompanyName : "");
        }
    }, [props.availableCustomers, generatedCustomerId, location])

    useEffect(() => {
        if (props.selectedCustomer && props.selectedCustomer.trim().length > 0) {
            dispatch(clearGeneratedCustomerId())
        }
    }, [props.selectedCustomer])

    useEffect(() => {
        if (props.customerId !== undefined && props.availableCustomers !== undefined && generatedCustomerId === null) {
            const selectedCustomer = props.availableCustomers?.find(c => c.Id === props.customerId)
            props.setSelectedCustomer(selectedCustomer?.CompanyName ?? "")
        }
    }, [props.availableCustomers, props.customerId, location])

    useEffect(() => {
        setSpecifiedCompanyName(props.companyName ?? "")
    }, [props.companyName])

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid item xs={8}>
                        <TextField label={getTranslatedText('UserEditCompanyName')} value={specifiedCompanyName} disabled fullWidth />
                    </Grid>

                    {isDepragAmbergMember &&
                        <Grid item xs={4} my={'auto'} sx={{ textAlign: 'right' }}>
                            <FormControlLabel control={<CustomSwitch />} checked={showAllCustomers} onChange={() => dispatch(toggleShowAllCustomers())} label={getTranslatedText("ShowAllCustomers")} />
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <Autocomplete
                            disableListWrap
                            PopperComponent={StyledPopper}
                            ListboxComponent={ListboxComponent}
                            readOnly={(isReseller && props.customerId === customerId)}
                            renderOption={(props, option, state) =>
                                [props, option, state.index] as React.ReactNode
                            }
                            disabled={customerIsLoading || !customerData || (isReseller && props.customerId === customerId)}
                            noOptionsText={getTranslatedText("NoCustomersAvailable")}
                            fullWidth
                            id="customers"
                            options={props.availableCustomers ? props.availableCustomers.map(option => option.CompanyName) : []}
                            value={props.selectedCustomer}
                            ListboxProps={{
                                style: { zIndex: 10002 },
                            }}
                            onChange={(event: any, newValue: string | null) => {
                                if (newValue) {
                                    props.setCustomerError(false)
                                    props.setSelectedCustomer(newValue)
                                }
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                fullWidth
                                error={props.customerError}
                                helperText={props.customerError ? getTranslatedText("PleaseProvideACustomer") : ""}
                                label={getTranslatedText("UserEditCustomerName")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right' }}>
                        <Button color="success" variant="contained" component={NavLink} to={'/customers/create'}>{getTranslatedText("CreateCustomer")}</Button>
                    </Grid>

                </>
            }
        </LanguageConsumer >
    )
}