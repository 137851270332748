import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LanguageLine } from '../Model/LanguageLine';
import { readLanguageFile } from '../LanguageFileReader';

export interface LanguageFileState {
    languageFileContent: LanguageLine[] | null,
    languageFileWasLoaded: boolean,
    languageFileIsLoading: boolean,
}

const initialState: LanguageFileState = {
    languageFileContent: null,
    languageFileWasLoaded: false,
    languageFileIsLoading: false,
}

export const loadCsv = createAsyncThunk(
    'languageFile/loadcsv',    
    async () => {
      const response = await readLanguageFile();
      return response;
    }
  )

  export const languageFileSlice = createSlice({
    name: 'languageFile',
    initialState,
    reducers: {
      setLanguageFileIsLoading: (state, action) => {
        state.languageFileIsLoading = action.payload;
      }
    },
    extraReducers: (builder) => {
        builder.addCase(loadCsv.fulfilled, (state, action) => {
          state.languageFileContent = [...action.payload]
          state.languageFileWasLoaded = true;
          state.languageFileIsLoading = false;
        })
  }})

  export const { 
    setLanguageFileIsLoading, 
} = languageFileSlice.actions;

  export default languageFileSlice.reducer;