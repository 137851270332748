import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Tab, Grid, Typography, useMediaQuery, Box } from "@mui/material"
import ReactQuill from "react-quill"
import { LanguageConsumer } from "../../../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBoxHeader } from "../../../../../../Layout/CustomBox"
import { TextFieldFormControl } from "../../../../../../Layout/Inputs/TextFieldFormControl"
import { modules, formats } from "../EditorConfig/EditorConfig"
import { FieldErrors, UseFormRegister } from "react-hook-form"
import { EmailTriggerRuleForm } from "../Models/EmailTriggerRuleForm"
import { useMemo } from "react"

interface EmailTriggerRulesEmailContentProps {
    currentLanguageToEdit: 'de' | 'en',
    setCurrentLanguageToEdit: React.Dispatch<React.SetStateAction<"de" | "en">>,
    isSaving: boolean,
    register: UseFormRegister<EmailTriggerRuleForm>,
    errors: FieldErrors<EmailTriggerRuleForm>,
    contentDe: string,
    contentEn: string,
    setContentDe: React.Dispatch<React.SetStateAction<string>>,
    setContentEn: React.Dispatch<React.SetStateAction<string>>,
    contentDeRef: React.MutableRefObject<null>,
    contentEnRef: React.MutableRefObject<null>,
}

export const EmailTriggerRulesEmailContent = (props: EmailTriggerRulesEmailContentProps) => {
    const { currentLanguageToEdit, setCurrentLanguageToEdit, isSaving, register, errors, contentDe, contentEn, setContentDe, setContentEn, contentDeRef, contentEnRef } = props;
    const isMobile = useMediaQuery('(max-width:500px)');

    const validateDe = useMemo(() => {
        if (contentDeRef && contentDeRef.current) {
            let editor = (contentDeRef.current as any).unprivilegedEditor;
            if (editor.getLength() > 10000) {
                return false;
            }
            return true;
        }
        return true;
    }, [contentDe, currentLanguageToEdit])

    const validateEn = useMemo(() => {
        if (contentEnRef && contentEnRef.current) {
            let editor = (contentEnRef.current as any).unprivilegedEditor;
            if (editor.getLength() > 10000) {
                return false;
            }
            return true;
        }
        return true;
    }, [contentEn, currentLanguageToEdit])

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <CustomBoxHeader text={getTranslatedText("EmailTriggerRulesEmailContent")} sx={{mb: 4}} />
                    <TabContext value={currentLanguageToEdit}>
                        <Box component={'div'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList sx={{ mt: -2 }} orientation={isMobile ? "vertical" : "horizontal"} onChange={(event: React.SyntheticEvent, newValue: 'de' | 'en') => setCurrentLanguageToEdit(newValue)} aria-label="lab API tabs example">
                                <Tab label={getTranslatedText("German")} value="de" />
                                <Tab label={getTranslatedText("English")} value="en" />

                            </TabList>
                        </Box>
                        <TabPanel value="de" sx={{ p: 0, pt: 3 }}>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12}>
                                    <TextFieldFormControl
                                        disabled={isSaving}
                                        inputProps={{ "data-testid": "helpCreateOrEdit-Title" }}
                                        hasEndAdornment={false}
                                        labelText={getTranslatedText('MailHeaderDe')}
                                        propertyName='MailHeaderDe'
                                        register={register} registerOptions={{ valueAsNumber: false }} errors={errors} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReactQuill style={{height: 300, marginBottom: 45}} ref={contentDeRef} modules={modules} formats={formats} theme="snow" value={contentDe} onChange={setContentDe} />
                                </Grid>
                                {!validateDe && <Grid item xs={12}>
                                    <Typography color="error">{getTranslatedText("ContentDeCharactersMustBeBetweenTenAndThousand")}</Typography>
                                </Grid>}
                            </Grid>
                        </TabPanel>
                        <TabPanel value="en" sx={{ p: 0, pt: 3 }}>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12}>
                                    <TextFieldFormControl
                                        disabled={isSaving}
                                        inputProps={{ "data-testid": "helpCreateOrEdit-Title" }}
                                        hasEndAdornment={false}
                                        labelText={getTranslatedText('MailHeaderEn')}
                                        propertyName='MailHeaderEn'
                                        register={register} registerOptions={{ valueAsNumber: false }} errors={errors} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReactQuill style={{height: 300, marginBottom: 45}} ref={contentEnRef} modules={modules} formats={formats} theme="snow" value={contentEn} onChange={setContentEn} />
                                </Grid>
                                {!validateEn && <Grid item xs={12}>
                                    <Typography color="error">{getTranslatedText("ContentEnCharactersMustBeBetweenTenAndThousand")}</Typography>
                                </Grid>}
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </>
            }
        </LanguageConsumer>
    )
}