import { Backdrop, Box, Button, Grid, Paper, Slide, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useAppSelector } from "../../Infrastructure/Store/hooks";
import { Link, useNavigate } from "react-router-dom";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { CustomBox } from "../CustomBox";


interface CustomDashboardCardsProps {
    title: string,
    count: number,
    buttonText: string,
    navigateTo: string,
    height: number,
    positionText?: string,
    colorText?: string,
}


export const CustomDashboardCards = (props: CustomDashboardCardsProps) => {
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");
    const isMobile = useMediaQuery("(max-width:500px)");
    const navigate = useNavigate()
    const theme = useTheme()

    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <>
                <CustomBox elevation={0} sx={{
                    background: 'transparent',
                    border: { xs: 'none', sm: 'none', md: 'none', lg: `none` },
                    p: { xs: 0, sm: 0, md: 0, lg: 0 },
                    m: { xs: 0, sm: 0, md: 0, lg: 0 },
                    height: props.height,
                    mb: { xs: 4, sm: 4, md: 4, lg: 4 },
                }}>
                    <Stack direction={'column'} spacing={0} alignItems={'center'} justifyContent={'center'}>
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={12}>
                                <CustomBox elevation={0} sx={{
                                    border: `1px solid ${theme.palette.cardBorder.main}`,
                                    background: theme.palette.cardBackground.main,
                                    height: props.height,
                                    position: 'relative',
                                }}>
                                    <Stack direction={'column'} spacing={2} alignItems={'flex-start'} justifyContent={'space-between'} sx={{ width: '100%', height: '100%' }}>
                                        <Typography sx={{ textAlign: props.positionText ?? "center", color: props.colorText ?? "",}} fontFamily={"Raleway"}
                                            fontSize={18} fontWeight={600}>
                                            {props.title}
                                        </Typography>
                                      <Stack direction={'column'} display={"flex"} position={"relative"} sx={{placeSelf:'center' }}> 
                                            <Typography variant={isMobile ? "h5" : "h3"} sx={{ color: props.colorText ?? "" }} fontWeight={600}>
                                               {props.count}
                                            </Typography>
                                     </Stack>
                                        <Button component={Link} to={props.navigateTo} sx={{ float: "left", mx: 2, mt: 1, borderRadius: "15px", }}
                                            color="success" variant="contained" >
                                            {getTranslatedText(props.buttonText)}
                                        </Button>
                                    </Stack>
                                </CustomBox>
                            </Grid>

                        </Grid>
                    </Stack>

                </CustomBox>
            </>
        }
    </LanguageConsumer>)

}