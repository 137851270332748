import { Close } from "@mui/icons-material";
import { SwipeableDrawer, Grid, Typography, IconButton, useTheme, Box, Chip, Skeleton, Tooltip } from "@mui/material";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { RootState, store } from "../../../../../Infrastructure/Store/store";
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { useQuery } from "@tanstack/react-query";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { CustomerDetailsRequestDto } from "../Models/CustomerDetailsRequestDto";
import { useEffect } from "react";
import { CustomerDrawerOverviewResponseDto, CustomerDrawerResponseDto } from "../Models/CustomerDrawerResponseDto";
import { GridRenderCellParams, GridColDef } from "@mui/x-data-grid";
import { globalDateFormatOptions } from "../../../../../Infrastructure/Internationalisation/GlobalDateFormatOptions";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";



interface IAllCustomersTableDrawer {
    open: boolean;
    onClose: () => void;
    customerId: number;
}


export const AllCustomersTableDrawer = (props: IAllCustomersTableDrawer) => {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const language = useAppSelector(store => store.settings.language);
    const langIsDe = useAppSelector(store => store.settings.language === 'de');

    const { isLoading, data, refetch } = useQuery({
        queryKey: [`customersdetailsdrawer-${props.customerId}`],
        queryFn: async () => await fetchMyDepragApi<CustomerDrawerOverviewResponseDto>(
            { CustomerId: props.customerId } as CustomerDetailsRequestDto,
            `customers/details/${language}`,
            'POST',
            dispatch,
            store.getState() as RootState)
    });

    const columns = [
        {
            field: 'SapSalesOrderNumber',
            headerName: getTranslation("SapSalesOrderNumber"),
            flex: 0.8,
            minWidth: 110,
            renderCell: (params: GridRenderCellParams<any, CustomerDrawerResponseDto, any>) => {
                return <Chip variant="outlined" label={params.row.SapSalesOrderNumber} />
            },
        },
        {
            field: 'SapMaterial',
            headerName: getTranslation("SapMaterial"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'LicenceValidFrom',
            headerName: getTranslation("LicenceValidFrom"),
            flex: 0.6,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, CustomerDrawerResponseDto, any>) => {
                return <Chip component={"div"}
                    label={new Date(params.row.LicenceValidFrom).toLocaleDateString(language, globalDateFormatOptions)} />
            },
        },
        {
            field: 'LicenceValidUntil',
            headerName: getTranslation("LicenceValidUntil"),
            flex: 0.4,
            minWidth: 110,
            renderCell: (params: GridRenderCellParams<any, CustomerDrawerResponseDto, any>) => {
                return <Chip component={"div"}
                    label={new Date(params.row.LicenceValidUntil).toLocaleDateString(language, globalDateFormatOptions)} />
            },
        },
        {
            field: 'LicenceCount',
            headerName: getTranslation("LicenceCount"),
            flex: 0.5,
            minWidth: 150,
        },
    ] as GridColDef[]

    useEffect(() => {
        refetch()
    }, [language])

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <SwipeableDrawer
                        sx={{ zIndex: 100003 }}
                        PaperProps={{
                            sx: {
                                p: 3, pt: 3, width: { xs: '95%', md: '70%', lg: '40%' }, maxWidth: '100%',
                                background: theme.palette.background.default, height: '100%', borderTopLeftRadius: 20, borderBottomLeftRadius: 20
                            }
                        }}
                        anchor={'right'}
                        open={props.open}
                        onOpen={console.log}
                        onClose={props.onClose}>

                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={8}>
                                <Typography variant="h5">
                                    {getTranslatedText("AllCustomersDrawerTitel")}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <IconButton onClick={props.onClose}>
                                    <Close />
                                </IconButton>
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: 'justify' }}>
                                <Typography component={"div"}>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("AllCustomersDrawerDescription") }} />
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        localeText={langIsDe ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={data ? data.Licences : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableColumnSelector
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        getRowId={(x) => x.MyGoldLicenceId}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                        }
                                        components={{
                                            LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                        }}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Grid>

                        </Grid>
                    </SwipeableDrawer>
                </>
            }
        </LanguageConsumer>
    )
}