import { toast } from "react-hot-toast";
import { removeAuthentication } from "../../Features/Infrastructure/Login/Store/LoginSlice";
import { MyDepragApiBase } from "../Api/ApiBaseProvider";
import { getReasonPhrase } from "http-status-codes";
import { RootState } from "../Store/store";
import { getTranslation } from "../Internationalisation/TranslationService";
import { DefaultApiBaseRequest } from "../Api/Model/DefaultApiBaseRequest";
import { DefaultResponse } from "../Api/Model/DefaultResponse";
import { checkIfTokenIsStillValidAndRefreshIfNecessary } from "./RefreshToken";
import { setSavePathAllowed } from "../../Store/AppSlice";

export interface FileDownloadApi {
    fallbackFileName: string;
    isExcel?: boolean;
  }

export const fetchMyDepragApi = async <T>(
    requestBody: DefaultApiBaseRequest | null = null,
    apiRoute: string,
    apiMethod: string,
    dispatch: (action: { payload: undefined; type: string; }) => { payload: undefined; type: string; },
    rootState: RootState,
    successToastMessage?: string,
    getCompleteResponse?: boolean,
    skipTimeCheck?: boolean,
    fileDownload?: FileDownloadApi): Promise<T> => {

    try {
        let newToken = null as string | null;
        if (skipTimeCheck === undefined || !skipTimeCheck) {
            newToken = await checkIfTokenIsStillValidAndRefreshIfNecessary(dispatch, rootState);
        }

        const response = await simpleFetchCall(requestBody, apiRoute, apiMethod, dispatch, rootState, newToken, fileDownload) as DefaultResponse;

        return new Promise<T>((resolve, reject) => {
            if (response && response.Success) {
                if (successToastMessage) {
                    toast.success(getTranslation(successToastMessage));
                }

                if (getCompleteResponse) {
                    resolve(response as T)
                } else {
                    resolve(response.Payload as T)
                }
            }
            else {

                if (getCompleteResponse && response.ErrorMessage.trim().length === 0) {
                    resolve(response as T)
                } else {
                    toast.error(getTranslation(response.ErrorMessage), { id: response.ErrorMessage });
                    reject(response.ErrorMessage)
                }

            }
        })
    }
    catch (error: any) {
        toast.error(getTranslation(error.response && error.response.data ? error.response.data.MessageText.ToString() : error.ToString()));
        return Promise.reject(error.response && error.response.data ? error.response.data.MessageText : error);
    }
}

const simpleFetchCall = async (
    requestBody: DefaultApiBaseRequest | null,
    apiRoute: string,
    apiMethod: string,
    dispatch: (action: { payload: undefined; type: string; }) => { payload: undefined; type: string; },
    rootState: RootState,
    newToken?: string | null,
    fileDownload?: FileDownloadApi) => {

    var authenticationToken = newToken !== undefined && newToken !== null ? newToken : rootState.authentication.token;

    try {
        var response = await fetch(MyDepragApiBase + apiRoute, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-api-key': '5678klfljaaaasdfkwerio23423SDafakdlka',
                'Authorization': !authenticationToken ? 'No-Auth' : `Bearer ${authenticationToken}`
            }),
            body: apiMethod === 'GET' || requestBody === null ? null : JSON.stringify(requestBody),
            method: apiMethod
        })
        return handleResponse(response, dispatch, fileDownload);
    } catch {
        return {
            ErrorMessage: getTranslation("ApiError"),
            Success: false
        } as DefaultResponse
    }
}

const handleResponse = async (response: Response, dispatch: (action: { payload: undefined; type: string; }) => { payload: undefined; type: string; }, fileDownload?: FileDownloadApi) => {

    if (response.ok) {
        if (fileDownload && fileDownload !== undefined) {
            return await handleFileDownload(response, fileDownload);
        } else {
            return response.json()
        }
    }

    if (response.status === 401) {
        dispatch(setSavePathAllowed(false));
        dispatch(removeAuthentication());
    }

    try {
        var asJson = await response.json();

        return asJson as DefaultResponse;

    }
    catch { }

    return {
        ErrorMessage: getReasonPhrase(response.status),
        Success: false
    } as DefaultResponse

}

const handleFileDownload = async (response: Response, fileDownload: FileDownloadApi) => {
    let blob = await response.blob();
  
    if (blob.type !== "application/json") {
      let fileName = extractFileNameFromContentDispositionHeader(response);
      if (fileDownload.isExcel) {
        let text = await blob.text()
        let buffer = Buffer.from(text, "utf16le")
        blob = new Blob([buffer], { type: "text/plain" });
      }
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", fileName ?? fileDownload.fallbackFileName);
      document.body.appendChild(a);
      a.click();
      a.remove();
  
      return {
        ErrorMessage: "",
        Success: true,
      } as DefaultResponse;
    }
    else {
      return JSON.parse(await blob.text());
    }
  };
  
  const extractFileNameFromContentDispositionHeader = (response: Response): string | null => {
    let header = response.headers.get("Content-Disposition");
    let fileName = null as string | null;
  
    if (header) {
      let splittedHeader = header.split(";");
      splittedHeader.forEach(element => {
        if (element.includes(" filename=")) {
          let name = element.replace(" filename=", "");
          name = name.replaceAll("\"", "")
          fileName = name;
        }
      });
    }
    return fileName;
  }