import { useQuery } from "@tanstack/react-query";
import { LanguageConsumer } from "../../../../../../../../Infrastructure/Internationalisation/TranslationService"
import { fetchMyDepragApi } from "../../../../../../../../Infrastructure/Api/ApiBaseCall";
import { CustomBox } from "../../../../../../../../Layout/CustomBox";
import { Grid, Skeleton, Typography, useTheme } from "@mui/material";
import { RootState, store } from "../../../../../../../../Infrastructure/Store/store";
import { useAppDispatch, useAppSelector } from "../../../../../../../../Infrastructure/Store/hooks";
import { CustomBorderLinearProgress } from "./CustomBorderLinearProgress";
import { PaidLicencesDetailOverviewDto } from "../../../../Model/PaidLicencesDetailOverviewDto";

interface PaidLicenseDetailsStatusCardProps {
    id: string | undefined
}

export const PaidLicenseDetailsStatusCard = (props: PaidLicenseDetailsStatusCardProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const isDarkMode = useAppSelector(store => store.settings.theme === "dark");

    const { isLoading, data } = useQuery({ queryKey: ["paidLicenseDetail"], queryFn: async () => await fetchMyDepragApi<PaidLicencesDetailOverviewDto>({ payedLicenceId: parseInt(props.id as string) }, 'softwarelicenceinformation/paidlicences/detail', 'POST', dispatch, store.getState() as RootState)});
    
    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
              {!isLoading && data ?  <CustomBox elevation={0} sx={{height: '100%', background: `radial-gradient(${!isDarkMode ? '#ffffffff' : 'rgba(29, 29, 29, 1)'} 0%,${!isDarkMode ? '#f3f3f3ff' : 'rgba(139, 139, 139, 1)'}  100%)`}}>
                               
                               <Grid container direction={'row'} spacing={2}>
                                   <Grid item xs={12} sx={{textAlign: 'left'}}>
                                       <Typography fontWeight={700} variant="h6">{getTranslatedText("PaidLicensesAvailableTitle")}</Typography>
                                   </Grid>

                                   <Grid item xs={12} sx={{textAlign: 'right', mt: 2}}>
                                       <Typography>{(data.QuantityOrdered - data.AvailableLicencesForUnlock)} / {data.QuantityOrdered} {getTranslatedText("LicensesAcivated")}</Typography>
                                   </Grid>
                                   <Grid item xs={12}>
                                       <CustomBorderLinearProgress customBackgroundColor={theme.palette.cardBorder.main} customColor={theme.palette.success.main} variant="determinate" value={(100 / data.QuantityOrdered) * (data.QuantityOrdered - data.AvailableLicencesForUnlock)} />
                                   </Grid>
                               </Grid>
                               
                       </CustomBox>:
                               <Skeleton width={'100%'} height={300} sx={{mt: -8}} />
                           }
            </>
            }
        </LanguageConsumer>
    )
}