
export const modules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            ['clean'],
        ]
    }
};

export const formats = [
    'bold', 'italic', 'underline', 'strike',
];